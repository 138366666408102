import { Leakage } from '../../interfaces/BusinessObjects'
import { useEffect, useMemo, useState } from 'react'
import {
  MoveLeakage,
  UpdateLeakage,
  useCreateOrUpdateLeakageMutation,
  useDeletedLeakagesQuery,
  useDeleteLeakageMutation,
  useDeleteLeakagesMutation,
  useFinalDeleteLeakageMutation,
  useLeakagesOfProjectQuery,
  useLeakagesOfProjectWithImagesQuery,
  useMoveLeakageMutation,
  useRestoreLeakageMutation,
} from '../../api/models'
import { convertLeakage, leakageToServerUpdateLeakage } from './hookHelpers/serverResponseConversion'
import { getSelectedRealm } from 'app/helpers/manageRealms'

export const useDeletedLeakages = (skipFetch?: boolean): { leakages: Leakage[]; loading: boolean; error: boolean } => {
  const { data, loading, error } = useDeletedLeakagesQuery({
    variables: { realmName: getSelectedRealm() || '' },
    skip: !getSelectedRealm() || !!skipFetch,
  })
  const leakages: Leakage[] = useMemo(() => {
    return (
      data?.deletedLeakages?.leakages.map((l) => {
        return {
          leakTag: l.leakTag ? `${l.leakTag}` : '',
          building: l.building?.name ?? '',
          path: l.groupPath?.map((pname) => pname).slice(0, l.groupPath.length) ?? undefined,
          place: l.place ? { id: l.place.id, name: l.place.name } : undefined,
          location: l.location ?? '',
          _id: l.id,
          _deleted_at: l._deleted_at ? new Date(l._deleted_at) : new Date(0),
        }
      }) ?? []
    )
  }, [data])
  return {
    leakages,
    loading,
    error: !!error,
  }
}

export const useUpdateLeakage = (): {
  updatedLeakage: boolean
  updateLeakage: (leakage: Leakage) => Promise<void>
} => {
  const [updatedLeakage, setUpdatedLeakage] = useState(false)
  const [updateLeakageMutation, { data }] = useCreateOrUpdateLeakageMutation()

  const updateLeakage = async (leakage: Leakage): Promise<void> => {
    setUpdatedLeakage(false)
    if (!leakage._id) {
      console.error('leakage without id, skipping')
      return
    }

    const ul: UpdateLeakage = leakageToServerUpdateLeakage(leakage)
    await updateLeakageMutation({ variables: { leakage: ul, realmName: getSelectedRealm() || '' } })
  }

  useEffect(() => {
    if (data && data.createOrUpdateLeakage?.id) {
      setUpdatedLeakage(true)
    }
  }, [data])
  return { updatedLeakage, updateLeakage }
}

type MoveLeakageInfo = {
  wasCopiedOnly: boolean
  movedLeakages: boolean
  targetProjectId?: string
  targetCompanyID: string
  targetGroupID?: string
  targetCompanyName?: string
}

type UseLeakageReturn = {
  movedLeakages: boolean
  wasCopiedOnly: boolean
  targetProjectId: string
  targetCompanyID: string
  targetGroupID?: string
  moveLeakages: (
    leakageIds: string[],
    targetProjectId: string | undefined,
    targetBuildingId: string | undefined,
    targetcompanyID: string,
    copy: boolean,
    targetGroupID: string | undefined,
  ) => Promise<{ companyId: string; projectID: string; groupId?: string } | undefined>
}

export const useMoveLeakages = (): UseLeakageReturn => {
  const [info, setInfo] = useState<MoveLeakageInfo>({
    movedLeakages: false,
    targetCompanyName: '',
    targetProjectId: '',
    targetCompanyID: '',
    wasCopiedOnly: false,
  })
  const [moveLeakagesMutation] = useMoveLeakageMutation()
  const moveLeakages = async (
    leakageIds: string[],
    targetProjectId: string | undefined,
    targetBuildingId: string | undefined,
    targetcompanyID: string,
    copy: boolean,
    groupId?: string,
  ): Promise<{ companyId: string; projectID: string; groupId?: string } | undefined> => {
    setInfo({
      movedLeakages: false,
      targetProjectId: '',
      targetCompanyID: targetcompanyID,
      wasCopiedOnly: false,
    })
    if (!leakageIds.length) {
      console.error('no leakages, skipping')
      return
    }

    const params: MoveLeakage = {
      buildingName: !targetBuildingId ? null : targetBuildingId,
      companyID: targetcompanyID,
      project_id: !targetProjectId ? null : targetProjectId,
      copy,
      ids: leakageIds,
      //groupId,
    }
    const { data } = await moveLeakagesMutation({ variables: { params, realmName: getSelectedRealm() || '' } })
    setInfo({
      targetProjectId: data?.moveLeakage?.projectId ?? targetProjectId,
      targetCompanyID: data?.moveLeakage?.companyId ?? targetcompanyID,
      //targetGroupID: data?.moveLeakage?.groupId ?? groupId,
      movedLeakages: true,
      wasCopiedOnly: copy,
    })
    if (data?.moveLeakage) {
      return {
        groupId: data.moveLeakage.groupId ? data.moveLeakage.groupId : undefined,
        companyId: data.moveLeakage.companyId,
        projectID: data.moveLeakage.projectId,
      }
    }
  }

  return {
    movedLeakages: info.movedLeakages,
    moveLeakages,
    wasCopiedOnly: info.wasCopiedOnly,
    targetCompanyID: info.targetCompanyID ?? '',
    targetProjectId: info.targetProjectId ?? '',
    targetGroupID: info.targetGroupID,
  }
}

export const useLeakagesOfProject = (
  projectId: string,
  excludedIds?: string[],
): { leakages: Leakage[]; loading: boolean; error: boolean } => {
  const { data, loading, error } = useLeakagesOfProjectQuery({
    variables: { projectId, realmName: getSelectedRealm() || '' },
    skip: !projectId || !getSelectedRealm(),
  })
  const leakages = useMemo(() => {
    if (!data || !!error || !data?.leakagesOfProject?.leakages) {
      return []
    }

    const tmpLeakages = data.leakagesOfProject.leakages
    const conv = tmpLeakages.map((l) =>
      convertLeakage({
        ...l,
        place:
          l.place && l.building?.id
            ? {
                id: l.place.id,
                name: l.place.name,
                building_id: l.building?.id,
              }
            : undefined,
        date: l.date ? l.date.substring(0, 19) : l.date,
        building: { id: l.building?.id ?? '', name: l.building?.name ?? '' },
        companyId: l.companyId ?? '',
        projectId: l.projectId ?? '',
      }),
    )
    return conv.filter((l) => !excludedIds || !excludedIds.includes(l._id ?? ''))
  }, [data, error, excludedIds])

  return {
    leakages,
    loading,
    error: !!error,
  }
}

export const useLeakagesOfProjectWithImages = (
  projectId: string,
  excludedIds?: string[] | undefined,
): { leakages: Leakage[]; loading: boolean; error: boolean; reload: () => void } => {
  const [leakages, setLeakages] = useState<Leakage[]>([])
  const { data, loading, error, refetch } = useLeakagesOfProjectWithImagesQuery({
    variables: { projectId, realmName: getSelectedRealm() || '' },
    skip: !projectId || !getSelectedRealm(),
  })
  /* const leakages = useMemo(() => {
    if (!data || !!error || !data?.leakagesOfProjectWithImages?.leakages.length) {
      return []
    }

    const tmpLeakages = data.leakagesOfProjectWithImages.leakages
    const conv = tmpLeakages.map((l) =>
      convertLeakage({
        ...l,
        date: l.date ? l.date.substring(0, 19) : l.date,
        building: { id: l.building?.id ?? '', name: l.building?.name ?? '' },
        companyId: l.companyId,
        projectId: l.projectId ?? '',
        images: l.images?.map((i) => {
          return { id: i.id, name: i.name, contents: i.contents, originalImage: i.originalImage, selected: i.selected }
        }),
      }),
    )
    return conv.filter((l) => !excludedIds || !excludedIds.includes(l._id ?? ''))
  }, [data, error, excludedIds]) */

  useEffect(() => {
    if (data?.leakagesOfProjectWithImages) {
      const tmpLeakages = data.leakagesOfProjectWithImages.leakages
      const conv = tmpLeakages.map((l) =>
        convertLeakage({
          ...l,
          place:
            l.place && l.building?.id ? { id: l.place.id, name: l.place.name, building_id: l.building?.id } : undefined,
          location: l.place?.name ?? '',
          date: l.date ? l.date.substring(0, 19) : l.date,
          building: { id: l.building?.id ?? '', name: l.building?.name ?? '' },
          companyId: l.companyId,
          projectId: l.projectId ?? '',
          images: l.images?.map((i) => {
            return {
              id: i.id,
              name: i.name,
              contents: i.contents,
              originalImage: i.originalImage,
              selected: i.selected,
            }
          }),
        }),
      )
      setLeakages(conv.filter((l) => !excludedIds || !excludedIds.includes(l._id ?? '')))
    }
  }, [data?.leakagesOfProjectWithImages, error, excludedIds])

  return {
    leakages,
    loading,
    error: !!error,
    reload: refetch,
  }
}

export const useFinalDeleteLeakage = (): {
  deletedLeakage: boolean
  deleteLeakage: (id: string) => Promise<void>
} => {
  const [deleteMutation] = useFinalDeleteLeakageMutation()
  const [deletedLeakage, setDeletedLeakage] = useState<boolean>(false)

  const deleteLeakage = async (id: string): Promise<void> => {
    setDeletedLeakage(false)
    if (!id) {
      console.error('leakage without ID -- skipping')
      return
    }
    await deleteMutation({ variables: { id, realmName: getSelectedRealm() || '' } })
    setDeletedLeakage(true)
  }

  return { deleteLeakage, deletedLeakage }
}

export const useDeleteLeakage = (): {
  deletedLeakage: boolean
  deleteLeakage: (id: string) => Promise<void>
} => {
  const [deleteMutation] = useDeleteLeakageMutation()
  const [deletedLeakage, setDeletedLeakage] = useState<boolean>(false)

  const deleteLeakage = async (id: string): Promise<void> => {
    setDeletedLeakage(false)
    if (!id) {
      console.error('leakage without ID -- skipping')
      return
    }
    await deleteMutation({ variables: { id, realmName: getSelectedRealm() || '' } })
    setDeletedLeakage(true)
  }

  return { deleteLeakage, deletedLeakage }
}

export const useRestoreLeakage = (): {
  restoredLeakage: boolean
  restoreLeakage: (name: string) => Promise<void>
} => {
  const [restoreMutation] = useRestoreLeakageMutation()
  const [restoredLeakage, setRestoredLeakage] = useState<boolean>(false)

  const restoreLeakage = async (id: string): Promise<void> => {
    setRestoredLeakage(false)
    if (!id) {
      console.error('leakage without ID -- skipping')
      return
    }
    await restoreMutation({ variables: { id, realmName: getSelectedRealm() || '' } })
    setRestoredLeakage(true)
  }

  return { restoreLeakage, restoredLeakage }
}

export const useDeleteLeakages = (): {
  deletedLeakages: boolean
  deleteLeakages: (ids: string[]) => Promise<void>
} => {
  const [deleteMutation] = useDeleteLeakagesMutation()
  const [deletedLeakages, setDeletedLeakages] = useState<boolean>(false)

  const deleteLeakages = async (ids: string[]): Promise<void> => {
    setDeletedLeakages(false)
    if (!ids.length) {
      console.error('no leakages -- skipping')
      return
    }
    try {
      await deleteMutation({ variables: { ids, realmName: getSelectedRealm() || '' } })
    } catch (e) {
      console.error(e)
    }
  }

  return { deleteLeakages, deletedLeakages }
}
