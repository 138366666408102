import { channels } from '../../electronShared/ipcProtocol'
import { handleNoIpcRendererError, setupIpcListener } from './shared/ipcShared'
import { PROJECT_IMPORT_REQUEST } from '../../electronShared/ipcProtocol'

const { ipcRenderer } =
  typeof window !== 'undefined' && window.require ? window.require('electron') : { ipcRenderer: undefined }

export const requestProjectImport = async (data: string, isV1Data: boolean): Promise<boolean> => {
  if (!ipcRenderer) {
    handleNoIpcRendererError()
    return false
  }
  const promise = setupIpcListener(channels.PROJECT_IMPORT_RESPONSE)
  const request: PROJECT_IMPORT_REQUEST = { data, isV1Data }
  ipcRenderer.send(channels.PROJECT_IMPORT_REQUEST, JSON.stringify(request))
  return promise
}
