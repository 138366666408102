import { UpdateInfo } from '../../../interfaces/BusinessObjects'
import {
  createObject,
  deleteObjects,
  finalDeleteObjectById,
  getDatastore,
  getObjectByQuery,
  getObjects,
  updateObjectById,
} from './shared/shared'
import { repositoryNames } from './repositoryNames'

export const getUpdateInfos = (): Promise<UpdateInfo[]> => {
  return getObjects(getDatastore(repositoryNames.UPDATE_INFO))
}

export const deleteUpdateInfos = (): Promise<number> => {
  return deleteObjects(getDatastore(repositoryNames.UPDATE_INFO))
}

export const getUpdateInfoBySn = (sn: string): Promise<UpdateInfo> => {
  return getObjectByQuery(getDatastore(repositoryNames.UPDATE_INFO), { sn })
}

export const createUpdateInfo = (updateInfo: UpdateInfo): Promise<UpdateInfo> => {
  return createObject(getDatastore(repositoryNames.UPDATE_INFO), updateInfo)
}

export const updateUpdateInfoById = (id: string, updateInfo: UpdateInfo): Promise<UpdateInfo> => {
  return updateObjectById(getDatastore(repositoryNames.UPDATE_INFO), id, {
    sn: updateInfo.sn,
    major: updateInfo.major,
    minor: updateInfo.minor,
  })
}

export const deleteUpdateInfoById = (id: string): Promise<number> => {
  return finalDeleteObjectById(getDatastore(repositoryNames.UPDATE_INFO), id)
}
