import React, { useMemo } from 'react'
import { DisplayRowProps } from './DataTableTypes'
import TableCell from '@material-ui/core/TableCell'
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined'
import AutorenewIcon from '@material-ui/icons/AutorenewOutlined'
import Tooltip from '@material-ui/core/Tooltip'
import { useTranslation } from 'react-i18next'
import { Chip, Grid, Typography } from '@material-ui/core'
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined'

export const DataTableRowCells: React.FC<DisplayRowProps> = ({ row, columns }: DisplayRowProps) => {
  const { t } = useTranslation('common')

  const multiValuedCell = useMemo(() => {
    const rowValSplitIndex = columns.findIndex((c) => c.isChipType)
    const multiValues = rowValSplitIndex >= 0 ? row.values[rowValSplitIndex]?.split(',') : undefined
    return multiValues?.length ? multiValues.sort() : undefined
  }, [row, columns])

  return (
    <>
      {row.values
        .filter((r, i) => i < columns.length)
        .map((v, i) => {
          return (
            <TableCell key={v + columns[i].label} align={columns[i].alignment === 'right' ? 'right' : 'left'}>
              {(columns[i].isStatus || columns[i].isUpdateStatus) && v && v !== 'actions.inProgress' && (
                <Tooltip
                  title={columns[i].isStatus ? (t('actions.completed') as string) : (t('updateOverview.ok') as string)}
                >
                  <CheckCircleIcon />
                </Tooltip>
              )}
              {multiValuedCell?.length && columns[i].isChipType ? (
                <Grid item container alignItems="center" spacing={1}>
                  {multiValuedCell.length > (columns[i]?.maxChipItems ?? 4) ? (
                    <>
                      {multiValuedCell.slice(0, columns[i]?.maxChipItems ?? 4).map((mcv) => {
                        return (
                          <Grid item key={mcv}>
                            <Chip label={mcv} />
                          </Grid>
                        )
                      })}
                      <Tooltip
                        title={
                          <Typography>
                            {multiValuedCell
                              .slice(columns[i]?.maxChipItems ?? 4)
                              .map((rv) => ` ${rv}`)
                              .toString()}
                          </Typography>
                        }
                      >
                        <Chip label={<MoreHorizOutlinedIcon fontSize="small" />} />
                      </Tooltip>
                    </>
                  ) : (
                    multiValuedCell.map(
                      (mcv) =>
                        mcv && (
                          <Grid item key={mcv}>
                            {' '}
                            <Chip key={mcv} label={mcv} />
                          </Grid>
                        ),
                    )
                  )}
                </Grid>
              ) : null}
              {/* {columns[i].isChipType
                ? !v.includes(',')
                  ? v && <Chip label={v} />
                  : v
                      .split(',')
                      .sort()
                      .map((tg, c) =>
                        c <= 4 ? (
                          <Chip key={tg} label={tg} />
                        ) : (
                          <Tooltip key={tg} title={tg}>
                            <MoreHorizOutlinedIcon />
                          </Tooltip>
                        ),
                      )
                : null} */}
              {(columns[i].isStatus || columns[i].isUpdateStatus) && (!v || v === 'actions.inProgress') && (
                <Tooltip
                  title={
                    columns[i].isStatus
                      ? (t('actions.inProgress') as string)
                      : (t('updateOverview.deprecated') as string)
                  }
                >
                  <AutorenewIcon />
                </Tooltip>
              )}
              {!columns[i].isStatus && !columns[i].isChipType && !columns[i].isUpdateStatus && v}
            </TableCell>
          )
        })}
    </>
  )
}

export default DataTableRowCells
