import React, { FC, ReactElement } from 'react'
import Grid from '@material-ui/core/Grid'
import { Box, Typography } from '@material-ui/core'
import { PageHeading } from '../../partials/PageHeading'
//import { useTranslation } from 'react-i18next'

type DatabaseActionItem = {
  description: string
  children: React.ReactNode
  title: string
}

export const DatabaseActionItem: FC<DatabaseActionItem> = ({ description, children, title }): ReactElement => {
  //const { t } = useTranslation('common')

  return (
    <>
      <Grid item xs={6} lg={4}>
        <Box pr={4} pb={8} style={{ height: '100%' }}>
          <PageHeading title={title} />
          <div style={{ height: '80%' }}>
            <Typography variant="body1">{description}</Typography>
          </div>
          <div>{children}</div>
        </Box>
      </Grid>
    </>
  )
}
