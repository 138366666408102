import { Building } from '../../../interfaces/BusinessObjects'
import { PersistedBuilding } from '../types/types'
import {
  createObject,
  deleteObjects,
  finalDeleteObjectById,
  finalDeleteTrashObjects,
  getDatastore,
  getDeletedObjects,
  getObjectById,
  getObjects,
  restoreObject,
  updateObjectById,
} from './shared/shared'
import { repositoryNames } from './repositoryNames'

export const getBuildings = (): Promise<PersistedBuilding[]> => {
  return getObjects(getDatastore(repositoryNames.BUILDING))
}

export const deleteBuildings = (): Promise<number> => {
  return deleteObjects(getDatastore(repositoryNames.BUILDING))
}

export const getBuildingById = (id: string): Promise<PersistedBuilding> => {
  return getObjectById(getDatastore(repositoryNames.BUILDING), id)
}

export const getBuildingsByCompany = (companyName: string): Promise<PersistedBuilding[]> => {
  return new Promise((resolve, reject) => {
    getDatastore(repositoryNames.BUILDING).find(
      { company: companyName, _deleted_at: { $exists: false } },
      (err: Error, doc: PersistedBuilding[]) => {
        if (!err) {
          resolve(doc)
        } else {
          reject(err)
        }
      },
    )
  })
}

export const createBuilding = (building: Building): Promise<PersistedBuilding> => {
  return createObject(getDatastore(repositoryNames.BUILDING), building)
}

export const deleteBuilding = (building: Building): Promise<number> => {
  return new Promise((resolve, reject) => {
    getDatastore(repositoryNames.BUILDING).update(
      { name: building.name, company: building.company },
      { $set: { _deleted_at: new Date() } },
      { multi: true },
      (err: Error | null, doc: number) => {
        if (!err) {
          resolve(doc)
        } else {
          reject(err)
        }
      },
    )
  })
}

export const changeCompanyNameOfBuildings = (originalName: string, newName: string): Promise<number> => {
  return new Promise((resolve, reject) => {
    getDatastore(repositoryNames.BUILDING).update(
      { company: originalName },
      { $set: { company: newName } },
      { multi: true },
      (err: Error | null, doc: number) => {
        if (!err) {
          resolve(doc)
        } else {
          reject(err)
        }
      },
    )
  })
}

export const getDeletedBuildings = (): Promise<PersistedBuilding[]> => {
  return getDeletedObjects(getDatastore(repositoryNames.BUILDING))
}

export const finalDeleteBuilding = (id: string): Promise<number> => {
  return finalDeleteObjectById(getDatastore(repositoryNames.BUILDING), id)
}

export const finalDeleteTrashBuildings = (): Promise<number> => {
  return finalDeleteTrashObjects(getDatastore(repositoryNames.BUILDING))
}

export const restoreBuilding = (id: string): Promise<number> => {
  return restoreObject(getDatastore(repositoryNames.BUILDING), id)
}

export const updateBuildingById = (id: string, building: Building): Promise<PersistedBuilding> => {
  return updateObjectById(getDatastore(repositoryNames.BUILDING), id, building)
}
