/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DataTableColumn, DataTableRow } from '@csinstruments/cs-react-theme/dist/components/DataTable/DataTableTypes'
import Grid from '@material-ui/core/Grid'
import Search from '../../partials/Inputs/Search'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { DatePicker } from '../../partials/Inputs/DatePicker'
import { useTranslation } from 'react-i18next'
import CsButton from '@csinstruments/cs-react-theme/dist/components/Buttons/CsButton'
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined'
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined'
import Paper from '@material-ui/core/Paper'
import { paddingBottomActionBar, paddingLeftRight, paddingTopBottom } from '../../../../styles/Shared'
import { DataTable } from '@csinstruments/cs-react-theme/dist/components/DataTable/DataTable'
import { Typography } from '@material-ui/core'
import {
  applyFilter,
  extractValues,
  getLeakageFirstAndLastDate,
  leakagesToDataTableRows,
  RowFilter,
} from './calculations'
import { StandardConfirmationModal } from '../../partials/StandardConfirmationModal/StandardConfirmationModal'
import { MoveLeakageModal } from '../../partials/MoveLeakageModal/MoveLeakageModal'
import { SelectInput } from '../../partials/Inputs/Select'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routes'
import { ProjectParamTypes } from '../../../../interfaces/PageParams'
import {
  useDeleteLeakages,
  //useLeakagesOfProject,
  useLeakagesOfProjectWithImages,
  useMoveLeakages,
} from '../../../hooks/leakageHooks'
import { ProjectLeakagesLeakageInfo } from './ProjectLeakagesLeakageInfo'
import { FixedActionBar } from '@csinstruments/cs-react-theme/dist/components/FixedActionBar/FixedActionBar'
import { ResultAlert } from '../../partials/ResultAlert'
import { getQueryParam } from '../../../helpers/routingHelper'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlatOutlined'
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import { useReport } from '../../../hooks/useReport'
import { /* isLeakageResolved,  */ Leakage /* , totalLeakageAmount  */ } from '../../../../interfaces/BusinessObjects'
import { showSaveFileDialog, usePushDownloadFile } from '../../../hooks/usePushDownloadFile'
import { ReportType } from '../../../helpers/reportGeneration/Types'
import { ProgressBar } from '../../partials/ProgressBar/ProgressBar'
import { getCurrentDateAsFileString } from '../../../helpers/dates'
import { useProject } from '../../../hooks/projectHooks'
import { useBuildings } from '../../../hooks/buildingHooks'
import {
  Calc,
  /* calcCo2AmountPerYear,
  calcCo2PerYear,
  calcCost,
  calcEnergyAmount,
  calcEnergyUsagePerYear, */
  calcTotalSumsForLeakages,
  /* convertAmount,
  getCo2Amount,
  getCo2EmissionsPowerGeneration,
  getCostBase,
  getCostTime,
  getEnergyAmount,
  getEnergyCost,
  getPressureCost, */
  totalCosts,
  TotalSumsForLeakagesByUnit,
} from '../../../helpers/calculations'
import { usePermissions } from 'app/hooks/usePermissions'
import { useGetQueryParam } from '../utilHooks'
//import { Unit } from '../../../../interfaces/Units'

const columns: DataTableColumn[] = [
  { label: 'objects.building' },
  { label: 'objects.location' },
  { label: 'leakage.leakTag', numeric: true },
  { label: 'leakage.amount', numeric: true },
  { label: 'leakage.costPerYear', numeric: true },
  { label: 'leakage.status', isStatus: true },
  { label: 'objects.date' },
]

const dataTableActions = { checkable: true, viewable: true, sortable: true }

export const ProjectLeakages: React.FC = ({}) => {
  const [searchString, setSearchString] = useState<string>('')
  const [preselectCompany, setPreselectCompany] = useState<string>('')
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [moveModalOpen, setMoveModalOpen] = useState<boolean>(false)
  const [copyModalOpen, setCopyModalOpen] = useState<boolean>(false)
  const [filter, setFilter] = useState<RowFilter>({})
  const { projectId, id } = useParams<ProjectParamTypes>()
  const groupId = useGetQueryParam('groupId')
  const { buildings } = useBuildings(id, groupId)
  const history = useHistory()
  const { t, i18n } = useTranslation('common')
  const [deletedLeakageIds, setDeletedLeakageIds] = useState<string[]>([])
  const { leakages, loading: leakagesLoading } = useLeakagesOfProjectWithImages(projectId, deletedLeakageIds)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const redirectAfterUpdate = getQueryParam('leakageUpdated')
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const { moveLeakages, movedLeakages, wasCopiedOnly } = useMoveLeakages()
  const { deleteLeakages, deletedLeakages } = useDeleteLeakages()
  const [sumCost, setSumCost] = useState<Calc[]>([])
  const [sumLeakage, setSumLeakage] = useState<TotalSumsForLeakagesByUnit[]>([])
  const { reportInfo, generateReport } = useReport(projectId, id, groupId)
  // const { downloadAction } = usePushDownloadFile(
  //   reportInfo.reportContent as Buffer,
  //   !!reportInfo.reportDone,
  //   reportInfo.link?.filename ?? '',
  // )
  const { project } = useProject(projectId)
  const permission = usePermissions()

  const [sortedIds, setSortedIds] = useState<string[]>([])

  const reportAction = async (type: ReportType) => {
    generateReport(type, leakages, sortedIds, selectedIds)
  }

  useEffect(() => {
    if (reportInfo.link && reportInfo.reportContent) {
      showSaveFileDialog(reportInfo.link, reportInfo.reportContent).catch((err) => console.error(err))
    }
  }, [reportInfo.reportDone, reportInfo.reportContent])

  // const reportAction = useCallback(
  //   (type: ReportType): void => {
  //     if (
  //       type === reportType &&
  //       reportDone &&
  //       reportLeakages.length > 0 &&
  //       reportLeakages.map((l) => l._id).join() === selectedIds.join()
  //     ) {
  //       downloadAction()
  //     } else {
  //       setFilename(`${id} ${getCurrentDateAsFileString()}.${type}`)

  //       if (sortedIds.length) {
  //         const filteredIds = sortedIds.filter((id) => selectedIds.length && selectedIds.includes(id))
  //         //const mapped = filteredIds.map((id) => leakages.find((l) => l._id === id) as Leakage)
  //         const mapped = filteredIds.map((id) => leakages.find((l) => l._id === id) as Leakage)
  //         setReportLeakages([...mapped])
  //       } else {
  //         setReportLeakages(leakages.filter((l) => !selectedIds.length || selectedIds.includes(l._id ?? '')))
  //       }

  //       setReportType(type)
  //     }
  //   },
  //   [selectedIds, sortedIds, leakages, reportDone, reportLeakages, downloadAction, reportType, id, setReportLeakages],
  // )

  const onSortingChanged = useCallback(
    (ids: string[]) => {
      setSortedIds(ids)
      setSelectedIds(ids)
    },
    [setSortedIds],
  )

  const showProgressBar = useMemo(() => {
    return leakagesLoading || reportInfo.reportLoading
  }, [reportInfo.reportLoading, leakagesLoading])

  const rows: DataTableRow[] = useMemo(() => {
    const rows = leakagesToDataTableRows(leakages, project, buildings, t)
    //console.log({ rows })
    return !project || !project._id ? [] : rows
  }, [buildings, leakages, project, i18n.language])

  const leakagesFromToDate = useMemo(() => {
    return getLeakageFirstAndLastDate(leakages)
  }, [leakages])

  /* useEffect(() => {
    if (leakages.length > 0) {
      const energyAmount = getEnergyAmount(project, t)
      const calcEnergyAmountResult = calcEnergyAmount(project, t)
      const energyCost = getEnergyCost(project, t, 3, 3)
      const costTime = getCostTime(project, leakages[0], t)
      const co2py = calcCo2AmountPerYear(leakages, project, t)
      const co2pyl = calcCo2AmountPerYear([leakages[0]], project, t)
      const totalLeakageArray = totalLeakageAmount(leakages, project)
      const costs = calcCost(leakages[2], project, t)
      const totalSumsForLeakages = calcTotalSumsForLeakages(leakages, project, t)
      const co2EmissionsPowerGeneration = getCo2EmissionsPowerGeneration(project, t)
      const resolvedLeakages = leakages.filter((l) => isLeakageResolved(l))
      const totalCost = totalCosts(leakages, project, t)
      const savedCost = totalCosts(resolvedLeakages, project, t)
      const savedAmounts = totalLeakageAmount(resolvedLeakages, project)
      const pressureCost = getPressureCost(project, t)
      const sumLeakagesNew = selectedIds.map((id) => leakages.find((leakage) => leakage._id === id))
      const resLeakageNm3Saved = convertAmount(
        `${
          totalSumsForLeakages?.find((total) => total?.savedAmount?.unitAsEnum === Unit.LTR_MIN)?.savedAmount?.result ||
          0
        }`,
        Unit.LTR_MIN,
        Unit.M3,
        t,
      )
      const costBase = getCostBase(project, t)
      const energyPerYear = calcEnergyUsagePerYear(totalSumsForLeakages, project, t, 'sumAmount')
      const amount = totalSumsForLeakages?.find((total) => total?.sumAmount?.unitAsEnum === Unit.LTR_MIN)
      const co2perYear = calcCo2PerYear(leakages[0], project, t)
      const co2amount = getCo2Amount(project, t)

      console.log('REPORT', {
        leakage0: leakages[0],
        co2amount,
        co2perYear,
        calcEnergyAmountResult,
        costBase,
        savedAmounts,
        energyAmount,
        energyCost,
        costTime,
        co2py,
        co2pyl,
        totalLeakageArray,
        leakages,
        costs,
        totalSumsForLeakages,
        totalCost,
        savedCost,
        project,
        pressureCost,
        co2EmissionsPowerGeneration,
        selectedRows,
        rows,
        sumLeakage,
        sumCost,
        selectedIds,
        sumLeakagesNew,
        resLeakageNm3Saved,
        amount,
        energyPerYear,
      })
    }
  }, [leakages, project, selectedRows]) */

  const projectPermision = useMemo(() => {
    return permission.editProjects
  }, [permission])

  const onSelectionChanged = useCallback(
    (ids: string[]): void => {
      setTimeout(() => {
        setSelectedIds(ids)
        setSelectedRows(ids.map((id) => rows.findIndex((r) => r.id === id)))
      }, 0)
    },
    [setSelectedRows, setSelectedIds, rows],
  )

  const tableButtonClicked = useCallback(
    (leakageId: string): void => {
      let routePath = generatePath(ROUTES.leakage, { id, projectId, leakageId, sortedIds: sortedIds.join(',') })
      routePath = groupId ? routePath + `?groupId=${groupId}` : routePath
      history.push(routePath)
    },
    [history, id, projectId, sortedIds, groupId],
  )

  useEffect(() => {
    if (redirectAfterUpdate) {
      setShowSuccess(true)
    }
  }, [redirectAfterUpdate])

  useEffect(() => {
    if (deletedLeakages) {
      setShowSuccess(true)
    }
  }, [deletedLeakages])

  useEffect(() => {
    if (movedLeakages && wasCopiedOnly) {
      setShowSuccess(true)
    }
  }, [movedLeakages, wasCopiedOnly])

  useEffect(() => {
    const selectedLeakages = selectedIds?.map((id) => leakages.find((leakage) => leakage._id === id))
    if (selectedLeakages) {
      // @ts-ignore
      const costs = totalCosts(selectedLeakages, project, t)
      setSumCost(costs)
      // @ts-ignore
      const totalAmount = calcTotalSumsForLeakages(selectedLeakages, project, t)
      setSumLeakage(totalAmount)
    }
  }, [selectedIds, i18n.language])

  useEffect(() => {
    if (id) {
      setPreselectCompany(id)
    }
  }, [id])

  const updateFilter = useCallback(
    (newFilter: RowFilter): void => {
      let updateFilter = { ...filter, ...newFilter }
      // when the building is changed, reset location (as they belong to a building)
      if (newFilter.building) {
        updateFilter.location = ''
      }
      if (newFilter.building === '') {
        updateFilter = {}
      }

      setFilter(updateFilter)
    },
    [filter],
  )

  const handleDelete = useCallback((): void => {
    deleteLeakages(selectedIds)
    setDeletedLeakageIds([...selectedIds, ...deletedLeakageIds])
  }, [deleteLeakages, deletedLeakageIds, selectedIds])

  const shownRows = useMemo(() => applyFilter(rows, filter), [rows, filter])
  const moveAction = useCallback(() => setMoveModalOpen(true), [])
  const copyAction = useCallback(() => setCopyModalOpen(true), [])
  const deleteAction = useCallback(() => setDeleteModalOpen(true), [])
  const handleConfirmationClose = useCallback(() => setDeleteModalOpen(false), [setDeleteModalOpen])
  const moveCopyModalOpen = useMemo(() => moveModalOpen || copyModalOpen, [moveModalOpen, copyModalOpen])

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <FixedActionBar
          actions={[moveAction, copyAction, deleteAction]}
          labels={['actions.move', 'actions.copy', 'actions.delete']}
          icons={[TrendingFlatIcon, FileCopyIcon, DeleteIcon]}
          disabled={[!projectPermision, !projectPermision, !projectPermision]}
        />
        <StandardConfirmationModal
          handleConfirm={handleDelete}
          open={deleteModalOpen}
          handleClose={handleConfirmationClose}
        />
        <MoveLeakageModal
          handleConfirm={(
            targetCompanyID,
            targetGroupID,
            targetBuildingId,
            targetProjectId,
            copyOnly,
            shouldRedirect,
          ) => {
            moveLeakages(selectedIds, targetProjectId, targetBuildingId, targetCompanyID, copyOnly, targetGroupID)
              .then((newProjectInfo) => {
                if (shouldRedirect && newProjectInfo) {
                  let routePath = generatePath(ROUTES.projectLeakages, {
                    id: newProjectInfo.companyId,
                    projectId: newProjectInfo.projectID,
                  })
                  routePath = newProjectInfo.groupId ? routePath + `?groupId=${newProjectInfo.groupId}` : routePath
                  history.push(routePath)
                }
              })
              .catch((err) => {
                console.error('failed to move leakages', err)
              })
          }}
          open={moveCopyModalOpen}
          handleClose={() => {
            setMoveModalOpen(false)
            setCopyModalOpen(false)
          }}
          copyOnly={copyModalOpen}
          heading={moveModalOpen ? 'actions.moveTo' : 'actions.copyTo'}
          preselectCompany={preselectCompany}
        />
        <ResultAlert
          alertText={'leakage.multiUpdateSuccess'}
          showAlert={showSuccess}
          modifyShowAlert={setShowSuccess}
        />
        <Grid container justifyContent="flex-start" spacing={3} style={paddingBottomActionBar}>
          <Grid item xs={12}>
            <ProgressBar show={showProgressBar} />
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0} style={paddingTopBottom}>
              {/*container for project info and action buttons*/}
              <Grid container spacing={3} style={paddingLeftRight}>
                <Grid item xs={4}>
                  <Typography variant={'body1'}>
                    {selectedRows.length} {t('leakage.measurementsSelected')}
                  </Typography>
                  {sumLeakage.map((sum, index) => {
                    return (
                      <Typography key={index} variant={'body2'}>
                        {sum.sumAmount.text} {'' + t('leakage.totalAmount')}
                      </Typography>
                    )
                  })}
                  {sumCost.map((cost, index) => {
                    return (
                      <Typography key={index} variant={'body2'}>
                        {cost.text} {'' + t('leakage.perYear')}
                      </Typography>
                    )
                  })}
                </Grid>
                <ProjectLeakagesLeakageInfo leakages={leakages} />
                <Grid item xs={2}>
                  <CsButton fullwidth={true} label={'PDF'} onClick={() => reportAction('pdf')}>
                    <DescriptionIcon />
                  </CsButton>
                </Grid>
                <Grid item xs={2}>
                  <CsButton fullwidth={true} label={'XLS'} onClick={() => reportAction('xlsx')}>
                    <CloudDownloadIcon />
                  </CsButton>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Search changeFunction={setSearchString} label={t('leakage.searchLeakTag')} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <SelectInput
                changeHandler={(building) => updateFilter({ building })}
                text={extractValues(rows, 0)}
                xs={4}
                md={2}
                label={t('objects.building')}
              />
              <SelectInput
                disabled={!filter.building}
                changeHandler={(location) => updateFilter({ location })}
                text={extractValues(shownRows, 1)}
                xs={4}
                md={2}
                label={t('objects.location')}
              />
              <SelectInput
                changeHandler={(status) => updateFilter({ status })}
                text={['actions.inProgress', 'actions.completed']}
                xs={4}
                md={2}
                label={t('leakage.status')}
              />
              <DatePicker
                xs={4}
                md={2}
                label={'dataImport.periodFrom'}
                onChange={(d) => {
                  updateFilter({ fromdate: d })
                }}
                preselect={leakagesFromToDate.FromLeakDate}
              />
              <DatePicker
                xs={4}
                md={2}
                label={'dataImport.periodUntil'}
                onChange={(d) => {
                  updateFilter({ toDate: d })
                }}
                preselect={leakagesFromToDate.ToLeakDate}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <DataTable
              disableHover
              rows={shownRows}
              columns={columns}
              allowedActions={dataTableActions}
              filterString={searchString}
              filterColumn={2}
              onButtonClicked={tableButtonClicked}
              onSelectionChanged={onSelectionChanged}
              onSortingChanged={onSortingChanged}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </>
  )
}
