import React, { FC, ReactElement, useCallback, useMemo } from 'react'
import { useLeakageDescriptionFields } from '../../../hooks/leakageDescriptionHooks'
import { Failure, Measure, Replacement } from '../../../../interfaces/BusinessObjects'
import { DataTableRow } from '@csinstruments/cs-react-theme/dist/components/DataTable/DataTableTypes'
import { transformTextForExport } from '../../../helpers/dataExportHelpers'
import { paddingTopBottom } from '../../../../styles/Shared'
import { Grid } from '@material-ui/core'
import { DataTable } from '@csinstruments/cs-react-theme/dist/components/DataTable/DataTable'

type Props = {
  labelType: 'MEASURE' | 'FAILURE' | 'REPLACEMENT'
  idsWereSelected: (ids: string[], type: string) => void
}
const objectToTableRow = (o: Measure | Replacement | Failure): DataTableRow => {
  const text = o.text
  const exportedText = transformTextForExport(text)
  return {
    id: o._id,
    values: [text, exportedText],
  }
}

export const DataExportLabelSelection: FC<Props> = ({ labelType, idsWereSelected }): ReactElement => {
  const { fields } = useLeakageDescriptionFields()

  const rows = useMemo(() => {
    switch (labelType) {
      case 'MEASURE':
        return fields.measures.filter((f) => f.text).map((f) => objectToTableRow(f))
      case 'REPLACEMENT':
        return fields.replacements.filter((f) => f.text).map((f) => objectToTableRow(f))
      case 'FAILURE':
        return fields.failures.filter((f) => f.text).map((f) => objectToTableRow(f))
      default:
        return []
    }
  }, [fields, labelType])

  const selectionChanged = useCallback(
    (ids: string[]) => {
      idsWereSelected(ids, labelType)
    },
    [labelType, idsWereSelected],
  )

  return (
    <>
      <div style={{ ...paddingTopBottom }}>
        <Grid item xs={12}>
          <DataTable
            onSelectionChanged={selectionChanged}
            columns={[{ label: 'exportMaintenance.text' }, { label: 'exportMaintenance.exportText' }]}
            rows={rows}
            allowedActions={{ sortable: false, checkable: true }}
          />
        </Grid>
      </div>
    </>
  )
}
