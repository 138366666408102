import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import AddIcon from '@material-ui/icons/AddOutlined'
import { DataTable } from '@csinstruments/cs-react-theme/dist/components/DataTable/DataTable'
import {
  DataItemAction,
  DataTableColumn,
  DataTableRow,
} from '@csinstruments/cs-react-theme/dist/components/DataTable/DataTableTypes'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routes'
import { CompanyParamTypes } from '../../../../interfaces/PageParams'
import { useDeleteProject, useProjectsWithLeakageInfo } from '../../../hooks/projectHooks'
import { Link, ProjectWithLeakageInfo } from '../../../../interfaces/BusinessObjects'
import { paddingBottomActionBar, paddingTopBottom } from '../../../../styles/Shared'
import { FixedActionBar } from '@csinstruments/cs-react-theme/dist/components/FixedActionBar/FixedActionBar'
import { useReport } from '../../../hooks/useReport'
import { showSaveFileDialog, usePushDownloadFile } from '../../../hooks/usePushDownloadFile'
import { ProgressBar } from '../../partials/ProgressBar/ProgressBar'
import { getCurrentDateAsFileString } from '../../../helpers/dates'
import { StandardConfirmationModal } from '../../partials/StandardConfirmationModal/StandardConfirmationModal'
import { useTranslation } from 'react-i18next'
import { useGetQueryParam } from '../utilHooks'

const columns: DataTableColumn[] = [
  { label: 'project.name', alignment: 'left' },
  { label: 'objects.date', alignment: 'left' },
  { label: 'project.nLeakages', alignment: 'left', numeric: true },
  { label: 'project.totalLeakage', alignment: 'left', numeric: true },
]

const projectToTableRow = (project: ProjectWithLeakageInfo): DataTableRow => {
  const date = project.firstDateTime && project.lastDateTime ? project.firstDateTime + ' - ' + project.lastDateTime : ''
  return {
    id: project._id ?? '',
    values: [project.name ?? '', date, `${project.nLeakages}`, `${project.sumLeakages}`],
  }
}

export const ProjectsOverview: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation('common')
  const { id } = useParams<CompanyParamTypes>()
  const companyId = !id ? '' : id
  const [deletedIds, setDeletedIds] = useState<string[]>([])
  const [toBeDeletedId, setToBeDeletedId] = useState('')
  const groupId = useGetQueryParam('groupId')
  const { projects, canAdd } = useProjectsWithLeakageInfo(companyId, deletedIds, groupId, t)
  const [chosenProjectId, setChosenProjectId] = useState<string>('')
  //const { reportDone, reportContent } = useReport(chosenProjectId, undefined, 'pdf', companyId, true, groupId)
  const { reportInfo, startReport } = useReport(chosenProjectId, companyId, groupId)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { deleteProject } = useDeleteProject()
  const [toggle, setToggle] = useState(false)

  // const { downloadAction } = usePushDownloadFile(
  //   reportInfo.reportContent as Buffer,
  //   !!reportInfo.reportDone,
  //   `${companyId} ${getCurrentDateAsFileString()}.pdf`,
  // )

  //console.log('P', { id, companyId })

  useEffect(() => {
    const save = async (saveLink: Link) => {
      await showSaveFileDialog(saveLink, undefined)
    }
    if (reportInfo.reportDone && reportInfo.link) {
      save(reportInfo.link as Link).catch(console.error)
    }
  }, [reportInfo])

  const rows = useMemo(() => {
    const projectRows = projects
      .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
      .map((p) => projectToTableRow(p))
    return projectRows
  }, [projects])

  const showProgressBar = useMemo(() => {
    return !!reportInfo?.reportLoading
  }, [reportInfo.reportLoading])

  useEffect(() => {
    if (chosenProjectId) {
      startReport()
    }
  }, [toggle])

  const tableButtonClicked = (projectId: string, action: DataItemAction): void => {
    let viewRoute = generatePath(ROUTES.project, { id: companyId, projectId, action: 'leakages' })
    if (groupId) {
      viewRoute += `?groupId=${groupId}`
    }
    switch (action) {
      case 'view':
        history.push(viewRoute)
        break
      case 'generate':
        setChosenProjectId(projectId)
        setToggle(!toggle)
        break
      case 'delete':
        setToBeDeletedId(projectId)
        setModalOpen(true)
        break
      default:
        break
    }
  }

  const doDelete = useCallback(() => {
    deleteProject(toBeDeletedId)
    setDeletedIds((old) => [...old, toBeDeletedId])
    setToBeDeletedId('')
  }, [deleteProject, setDeletedIds, toBeDeletedId, setToBeDeletedId])

  // const reportAction = useCallback(
  //   (projectId: string): void => {
  //     if (reportInfo.reportDone && projectId === chosenProjectId) {
  //       downloadAction()
  //     } else {
  //       setChosenProjectId(projectId)
  //     }
  //   },
  //   [reportInfo, downloadAction, chosenProjectId],
  // )

  const newProjectAction = useCallback((): void => {
    let newProjectRoute = generatePath(ROUTES.newProject, { id: companyId })
    if (groupId) {
      newProjectRoute += `?groupId=${groupId}`
    }
    history.push(newProjectRoute)
  }, [groupId, history, companyId])

  return (
    <div style={{ ...paddingTopBottom, ...paddingBottomActionBar }}>
      <StandardConfirmationModal handleConfirm={doDelete} open={modalOpen} handleClose={() => setModalOpen(false)} />
      <FixedActionBar
        buttonProps={[{ solid: true }]}
        actions={[newProjectAction]}
        conditions={[canAdd]}
        labels={['actions.new']}
        icons={[AddIcon]}
      />
      <Grid container justifyContent="flex-start" spacing={3}>
        <Grid item xs={12}>
          <ProgressBar show={showProgressBar} />
        </Grid>
        <Grid item xs={12}>
          <DataTable
            rows={rows}
            onButtonClicked={tableButtonClicked}
            columns={columns}
            allowedActions={{ viewable: true, generatable: true, sortable: true, deletable: canAdd }}
          />
        </Grid>
      </Grid>
    </div>
  )
}
