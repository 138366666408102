export const channels = {
  FILE_IMPORT_REQUEST: 'FILE_IMPORT_REQUEST',
  FILE_IMPORT_RESPONSE: 'FILE_IMPORT_RESPONSE',
  V1_DATA_IMPORT_REQUEST: 'V1_DATA_IMPORT_REQUEST',
  V1_DATA_IMPORT_RESPONSE: 'V1_DATA_IMPORT_RESPONSE',
  PROJECT_IMPORT_REQUEST: 'PROJECT_IMPORT_REQUEST',
  PROJECT_IMPORT_RESPONSE: 'PROJECT_IMPORT_RESPONSE',
  COMPANY_EXPORT_REQUEST: 'COMPANY_EXPORT_REQUEST',
  COMPANY_EXPORT_RESPONSE: 'COMPANY_EXPORT_RESPONSE',
  REPORT_REQUEST: 'XLSX_EXPORT_REQUEST',
  REPORT_RESPONSE: 'XLSX_EXPORT_RESPONSE',
}

export type ResultCode = 200 | 400 | 500 | 299

export const resultCodes = {
  SUCCESS: 200 as ResultCode,
  CLIENT_ERROR: 400 as ResultCode,
  SERVER_ERROR: 500 as ResultCode,
  // used for exporting data for the ld500 device –> an export file was created, but it was impossible to export all data,
  // so only parts of the data are included. reason: LD500 has a limited amount of data it can import.
  OK_BUT_INCOMPLETE: 299 as ResultCode,
}

export type V1_DATA_IMPORT_REQUEST = {
  data: string
  path: string
}

export type PROJECT_IMPORT_REQUEST = {
  data: string
  isV1Data: boolean
}

export type COMPANY_EXPORT_REQUEST = {
  measureIDs: string[]
  failureIDs: string[]
  replacementIDs: string[]
  companyIDs: string[]
  exportBuildings: boolean
  exportLocations: boolean
}

export type COMPLEX_RESPONSE = {
  data: string
  resultCode: ResultCode
}
