import { useKeycloak as useKeycloakLib } from '@react-keycloak/web'
import { KeycloakInstance } from 'keycloak-js'
import { Base64 } from 'js-base64'

export const useKeycloak = (): { authenticated: boolean; initialized: boolean; keycloak?: KeycloakInstance } => {
  const { keycloak, initialized } = useKeycloakLib()
  return {
    authenticated: !!(keycloak && keycloak.authenticated),
    initialized,
    keycloak: keycloak,
  }
}

export type AppRole = {
  appName: string
  roles: string[]
}

export type SingleUserLicense = {
  groupname: string
  licenseType: string
  expirationDate: string
}

export type Organization = {
  /** name of the realm - name used by iam */
  realmName: string
  /** name of the organization which should be displayed to the user - instead of realmName */
  displayName: string
  /**
   * type of license if trial or regular
   */
  cloudLicenseType: string
  /** license expiry date */
  expirationDate: string
  /** list of roles the user has in this realm */
  appRoles: AppRole[]
  /** licenses assigned to the user */
  licenses: SingleUserLicense[]
}

export const useOrganizations = (): Organization[] => {
  const { keycloak } = useKeycloak()
  if (!keycloak?.token) {
    return []
  }
  try {
    const parsedToken = JSON.parse(Base64.decode((keycloak.token ?? '').split('.')[1]))
    const returnOrgs = parsedToken.organizations as Organization[]
    return returnOrgs
  } catch (e) {
    console.error(e)
    return []
  }
}
