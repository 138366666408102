import React, { FC, ReactElement, useCallback, useMemo, useState } from 'react'
import { useCompanies } from '../../../hooks/companyHooks'
import { paddingTopBottom } from '../../../../styles/Shared'
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core'
import { DataTable } from '@csinstruments/cs-react-theme/dist/components/DataTable/DataTable'
import { Company } from '../../../../interfaces/BusinessObjects'
import { DataTableRow } from '@csinstruments/cs-react-theme/dist/components/DataTable/DataTableTypes'
import { useTranslation } from 'react-i18next'

type Props = {
  idsWereSelected: (ids: string[], type: string) => void
  buildingsSelected: (selected: boolean) => void
  locationsSelected: (selected: boolean) => void
}

const companiesToTableRow = (c: Company): DataTableRow => {
  return {
    id: c._id || '',
    values: [c.name || ''],
  }
}

type CompanySelectionState = {
  buildingsSelected: boolean
  locationsSelected: boolean
}

export const DataExportCompanySelection: FC<Props> = ({
  idsWereSelected,
  buildingsSelected,
  locationsSelected,
}): ReactElement => {
  const [state, setState] = useState<CompanySelectionState>({ buildingsSelected: true, locationsSelected: true })
  const { companies } = useCompanies()
  const { t } = useTranslation('common')

  const selectionChanged = useCallback(
    (ids: string[]) => {
      idsWereSelected(ids, 'COMPANY')
    },
    [idsWereSelected],
  )

  const rows = useMemo(() => {
    return companies.map(companiesToTableRow)
  }, [companies])

  return (
    <>
      <div style={{ ...paddingTopBottom }}>
        <Grid container>
          <Grid item xs={12} style={{ paddingLeft: 15 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.buildingsSelected}
                  value={state.buildingsSelected}
                  onChange={(event) => {
                    const selected = event.target.checked
                    buildingsSelected(selected)
                    locationsSelected(selected)
                    setState(() => {
                      return {
                        buildingsSelected: selected,
                        locationsSelected: selected,
                      }
                    })
                  }}
                />
              }
              label={t('actions.exportBuildings')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!state.buildingsSelected}
                  checked={state.locationsSelected}
                  value={state.locationsSelected}
                  onChange={(event) => {
                    const selected = event.target.checked
                    locationsSelected(selected)
                    setState((state) => {
                      return {
                        buildingsSelected: state.buildingsSelected,
                        locationsSelected: selected,
                      }
                    })
                  }}
                />
              }
              label={t('actions.exportLocations')}
              style={{ paddingLeft: 15 }}
            />
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 20 }}>
            <DataTable
              onSelectionChanged={selectionChanged}
              columns={[{ label: 'objects.company' }]}
              rows={rows}
              allowedActions={{ sortable: false, checkable: true }}
            />
          </Grid>
        </Grid>
      </div>
    </>
  )
}
