import {
  AbsolutePath,
  GroupInput,
  GroupType,
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useGetDeletedGroupsQuery,
  useGetPathByGroupIdQuery,
  useRestoreGroupMutation,
  useSoftDeleteGroupMutation,
  useUpdateGroupMutation,
} from 'api/models'
import { getSelectedRealm } from 'app/helpers/manageRealms'
import { sortDateString } from 'app/helpers/utils'
import { useEffect, useMemo, useState } from 'react'

export const useCreateGroup = (): {
  createGroup: (groupInputParams: GroupInput) => Promise<GroupType | undefined>
  creatingGroup: boolean
} => {
  const [createGroupMut, { loading }] = useCreateGroupMutation()

  const submitNewGroup = async (groupInputParams: GroupInput): Promise<GroupType | undefined> => {
    try {
      const resp = await createGroupMut({
        variables: {
          realmName: getSelectedRealm(),
          group: groupInputParams,
        },
      })
      if (resp.data?.createGroup) {
        return resp.data.createGroup
      }
    } catch (error) {
      console.log({ error })
    }
  }
  return { createGroup: submitNewGroup, creatingGroup: loading }
}

export const useUpdateGroup = (): {
  renameGroup: (groupId: string, groupInput: GroupInput) => Promise<GroupType | undefined>
  updating: boolean
} => {
  const [renameGroupMut, { loading }] = useUpdateGroupMutation()

  const updateGroupName = async (groupId: string, groupInp: GroupInput): Promise<GroupType | undefined> => {
    try {
      const resp = await renameGroupMut({
        variables: {
          groupId: groupId,
          group: groupInp,
          realmName: getSelectedRealm(),
        },
      })
      if (resp.data?.updateGroup) {
        return resp.data.updateGroup
      }
    } catch (error) {
      console.error(error)
    }
  }

  return {
    renameGroup: updateGroupName,
    updating: loading,
  }
}

export const useDeleteGroup = (): { deletingGroup: boolean; removeGroup: (groupId: string) => Promise<boolean> } => {
  const [deleteGroupMut, { loading }] = useSoftDeleteGroupMutation()

  const confirmDelete = async (groupId: string): Promise<boolean> => {
    const resp = await deleteGroupMut({
      variables: {
        realmName: getSelectedRealm(),
        groupId,
      },
    })
    if (resp.data?.softDeleteGroup.success) {
      return true
    }
    return false
  }
  return { removeGroup: confirmDelete, deletingGroup: loading }
}

export const useGroupAbsolutePath = (groupId: string): { id: string; title: string }[] => {
  const { data } = useGetPathByGroupIdQuery({
    variables: {
      realmName: getSelectedRealm(),
      groupId,
    },
    skip: !groupId || !getSelectedRealm(),
  })
  return useMemo(() => {
    if (data?.getPathByGroupId)
      return data.getPathByGroupId.map((pn) => ({
        id: pn.pathID,
        title: pn.pathName,
      }))
    return []
  }, [groupId, data])
}

export const useDeletedGroups = (): { _id: string; name: string; path: string[]; _deleted_at: Date }[] => {
  const { data } = useGetDeletedGroupsQuery({
    variables: {
      realmName: getSelectedRealm(),
    },
    skip: !getSelectedRealm(),
  })
  return useMemo(() => {
    if (data?.deletedGroups) {
      return data.deletedGroups
        .sort((g1, g2) => (g1?._deleted_at && g2?._deleted_at ? sortDateString(g1._deleted_at, g2._deleted_at) : 1))
        .map((gd) => ({
          _id: gd?.id ?? '',
          name: gd?.groupName ?? '',
          path: gd?.groupPath?.map((gp) => gp) ?? [],
          _deleted_at: gd?._deleted_at ? new Date(gd._deleted_at) : new Date(0),
        }))
    }
    return []
  }, [data?.deletedGroups])
}

export const useRestoreGroup = (): {
  restoredGroup: boolean
  restoreGroup: (id: string) => Promise<void>
} => {
  const [restoreMutation, { data }] = useRestoreGroupMutation()
  const [success, setSuccess] = useState(false)

  const restoreGroup = async (groupId: string): Promise<void> => {
    await restoreMutation({ variables: { groupId, realmName: getSelectedRealm() || '' } })
  }

  useEffect(() => {
    if (data?.restoreGroup.success) {
      setSuccess(true)
    } else {
      setSuccess(false)
    }
  }, [data])

  return { restoreGroup, restoredGroup: success }
}

export const useFinalDeleteGroup = (): {
  deletedGroup: boolean
  deleteGroup: (id: string) => Promise<void>
} => {
  const [deleteMutation, { data }] = useDeleteGroupMutation()
  const [success, setSuccess] = useState(false)

  const deleteGroup = async (groupId: string): Promise<void> => {
    await deleteMutation({ variables: { groupId, realmName: getSelectedRealm() || '' } })
  }

  useEffect(() => {
    if (data?.deleteGroup.success) {
      setSuccess(true)
    } else {
      setSuccess(false)
    }
  }, [data])

  return { deleteGroup, deletedGroup: success }
}
