import React, { FC, ReactElement, useState } from 'react'
import { Leakage } from '../../../../interfaces/BusinessObjects'
import { useUpdateLeakage } from '../../../hooks/leakageHooks'
import { Card, CardActions, CardMedia, Checkbox, FormControlLabel, Grid } from '@material-ui/core'
import { ActionWithDescription } from '@csinstruments/cs-react-theme/dist/components/ActionWithDescription/ActionWithDescription'
import { useTranslation } from 'react-i18next'
import { ResultAlert } from '../../partials/ResultAlert'

type LeakageImageEditProps = {
  newLeakageData: Leakage
  updateNewLeakageData: (data: Leakage) => void
}

export const LeakageImageEdit: FC<LeakageImageEditProps> = ({ newLeakageData, updateNewLeakageData }): ReactElement => {
  const { t } = useTranslation('common')
  const [showAlert, setShowAlert] = useState(false)
  useUpdateLeakage()

  // console.log(
  //   'images',
  //   newLeakageData.imagesAsBase64?.length,
  //   'ismgChecked',
  //   newLeakageData.images?.map((imc) => imc.checked),
  // )

  const setImageChecked = (i: number, checked: boolean): void => {
    const imagesAsBase64 = newLeakageData.imagesAsBase64 ? [...newLeakageData.imagesAsBase64] : []
    //const images = newLeakageData.images ? [...newLeakageData.images] : []
    //const n = imagesAsBase64.reduce((prev, img) => prev + (img.checked ? 1 : 0), 0)
    console.log({ i }, { checked }, 'images Length')
    imagesAsBase64[i].checked = checked
    //images[i].checked = checked
    updateNewLeakageData({ imagesAsBase64 })
    // if (!checked || n <= 0) {
    //   imagesAsBase64[i].checked = checked
    //   //images[i].checked = checked
    //   updateNewLeakageData({ imagesAsBase64 })
    // } else {
    //   setShowAlert(true)
    // }
  }

  return (
    <>
      <ActionWithDescription heading={t('leakage.chooseImagesHeading')} description={t('leakage.chooseImagesText')} />
      <ResultAlert
        severity="warning"
        alertText="leakage.chooseTwo"
        showAlert={showAlert}
        modifyShowAlert={setShowAlert}
      />
      <Grid container spacing={3} style={{ paddingTop: 20 }}>
        {newLeakageData.imagesAsBase64?.map((img, i) => (
          <Grid item xs={12} md={6} lg={4} key={i}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Card style={{ width: 320 }}>
                <CardMedia>
                  <img src={img.src} alt="leakage" width="320px" />
                </CardMedia>
                <CardActions style={{ width: '100%', justifyContent: 'flex-end' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={img.checked}
                        onChange={() => setImageChecked(i, !img.checked)}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={t('leakage.selected')}
                  />
                </CardActions>
              </Card>
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  )
}
