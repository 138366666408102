import { TFunction } from 'i18next'
import { ISO_STANDARD } from '../../constants/calculationConstants'

export const getDateFromGermanDate = (germanDate: string): Date => {
  return new Date(
    Date.parse(
      `${germanDate.substring(6, 10)}-${germanDate.substring(3, 5)}-${germanDate.substring(
        0,
        2,
      )}T${germanDate.substring(11, 19)}Z`,
    ),
  )
}

export const dateToGermanString = (date: Date): string => {
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
}

export const dateToLocalDateString = (date: Date, locale: string): string => {
  return date.toLocaleDateString(locale)
}

export const getCurrentDateAsFileString = (): string => {
  const d = new Date()
  const start = dateToGermanString(d)
  return `${start}-${d.getHours()}.${d.getMinutes()}`
}

export const calcDifferenceInDays = (begin: Date, end: Date): number => {
  return Math.round((end.getTime() - begin.getTime()) / (1000 * 3600 * 24) + 1)
}

export const getIsoCode = (t?: TFunction, locale?: string): string => {
  //console.log({ t })
  return locale || (t && t('i18n.local')) || ISO_STANDARD
}

export const getTimeFromDate = (date: string): string => {
  const dateTime = new Date(date)
  return `${dateTime.getHours()}:${dateTime.getMinutes()}:${dateTime.getSeconds()}`
}
