import React from 'react'
import ReactDOM from 'react-dom'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { BrowserRouter as Router } from 'react-router-dom'

import './assets/fonts/fonts.css'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import { prepareSentry } from './app/helpers/sentryUtils'

import App from './app/App'
import detector from 'i18next-browser-languagedetector'
import common_cs from './translations/cs/common.json'
import common_de from './translations/de/common.json'
import common_en from './translations/en/common.json'
import common_es from './translations/es/common.json'
import common_fr from './translations/fr/common.json'
import common_it from './translations/it/common.json'
import common_jp from './translations/jp/common.json'
import common_pl from './translations/pl/common.json'
import common_pt from './translations/pt/common.json'
import common_ro from './translations/ro/common.json'
import common_ru from './translations/ru/common.json'
import common_sl from './translations/sl/common.json'
import common_sv from './translations/sv/common.json'
import common_tr from './translations/tr/common.json'
import common_ua from './translations/ua/common.json'
import common_zh from './translations/zh/common.json'
import common_zho from './translations/zho/common.json'

import { keycloak } from 'app/helpers/keycloakService'
import CloudUserProvider from 'app/components/partials/CloudAuthProvider/CloudUserProvider'
import { ThemeProvider } from 'app/components/partials/ThemeProvider'

i18next.use(detector).init({
  interpolation: { escapeValue: false },
  fallbackLng: 'en',
  resources: {
    cs: {
      common: common_cs,
    },
    en: {
      common: common_en,
    },
    de: {
      common: common_de,
    },
    es: {
      common: common_es,
    },
    fr: {
      common: common_fr,
    },
    it: {
      common: common_it,
    },
    jp: {
      common: common_jp,
    },
    pl: {
      common: common_pl,
    },
    pt: {
      common: common_pt,
    },
    ro: {
      common: common_ro,
    },
    ru: {
      common: common_ru,
    },
    sl: {
      common: common_sl,
    },
    sv: {
      common: common_sv,
    },
    tr: {
      common: common_tr,
    },
    ua: {
      common: common_ua,
    },
    zh: {
      common: common_zh,
    },
    zho: {
      common: common_zho,
    },
  },
})

prepareSentry()

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <ThemeProvider>
      <ReactKeycloakProvider authClient={keycloak} initOptions={{ onLoad: 'login-required' }} autoRefreshToken={false}>
        <CloudUserProvider>
          <Router>
            <App />
          </Router>
        </CloudUserProvider>
      </ReactKeycloakProvider>
    </ThemeProvider>
  </I18nextProvider>,
  document.getElementById('root'),
)
