import React from 'react'
import { CsButtonProps } from '@csinstruments/cs-react-theme/dist/components/Buttons/CsButton'
import { SvgIcon } from '@material-ui/core'
import { base64ToText, ConversionResult } from '../../../helpers/fileUpload'
import { TranslatedCsButton } from './TranslatedCsButton'

type SingleFileUploadProps = {
  label: string
  icon: typeof SvgIcon
  acceptedType: string
  changeHandler: (result: FileSelectionResult) => void
  buttonProps?: CsButtonProps
  isCloudDb?: boolean
}

export type FileSelectionResult = {
  contents: string
  formData?: FormData
  origin: string
  error: boolean
}

export const SingleFileUploadButton: React.FC<SingleFileUploadProps> = ({
  label,
  icon,
  changeHandler,
  acceptedType,
  buttonProps,
  isCloudDb,
}) => {
  const handleChange = (selectorFiles: FileList | null): void => {
    if (selectorFiles && selectorFiles.length > 0) {
      const reader = new FileReader()
      let data: FormData | undefined
      const file = selectorFiles.item(0)
      if (file) {
        if (file.type === 'application/json') {
          reader.readAsText(file, 'utf-8')
        } else if (isCloudDb) {
          data = new FormData()
          data.append('databasefile', file)
          reader.readAsDataURL(file)
        }

        reader.onload = () => {
          const result = reader.result as string

          let parseResult: ConversionResult = { error: true }
          if (file.type === 'application/json') {
            parseResult = { result, error: false }
          }
          if (isCloudDb) {
            parseResult = base64ToText(result)
          }
          changeHandler({
            contents: parseResult.result ?? '',
            error: parseResult.error,
            // @ts-ignore
            origin: file.path,
            formData: data,
          })
        }
      } else {
        changeHandler({ contents: '', error: true, origin: '' })
      }
    }
  }

  buttonProps = buttonProps ?? {}

  return (
    <>
      <input
        accept={acceptedType}
        style={{ display: 'none' }}
        id={`file-input-${label}`}
        multiple
        type="file"
        disabled={buttonProps.disabled}
        onChange={(e) => {
          handleChange(e.target.files)
          e.target.value = ''
        }}
      />
      <label htmlFor={`file-input-${label}`}>
        <TranslatedCsButton fileUpload={true} fullwidth={true} label={label} {...buttonProps}>
          {React.createElement(icon)}
        </TranslatedCsButton>
      </label>
    </>
  )
}
