import Modal from '@material-ui/core/Modal'
import React, { useEffect, useMemo, useState } from 'react'
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import CsButton from '@csinstruments/cs-react-theme/dist/components/Buttons/CsButton'
import Grid from '@material-ui/core/Grid/Grid'
import { paddingTop } from '../../../../styles/Shared'
import { SelectInput } from '../Inputs/Select'
import { useStyles } from './MoveLeakageModalStyle'
import { useCompanies, useCompaniesInGroup } from '../../../hooks/companyHooks'
import { DisplayCompany, DisplayGroup } from '../../../../interfaces/BusinessObjects'
import { useProjectsOfCompany, useProjectsWithLeakageInfo } from 'app/hooks/projectHooks'
import { useGetQueryParam } from 'app/components/pages/utilHooks'
import { useBuildings } from 'app/hooks/buildingHooks'
import { GroupExplorer } from '../GroupsExplorer/GroupExplorer'
import { collectSubGroupIds, convertToFileHierarchy } from 'app/helpers/convertToFileHierarchy'
import { CompanyDropDown } from '../GroupCompanyDropDown/CompanyDropDown'
import { useParams } from 'react-router-dom'
import { TagAttribute, useGetTagAttributesQuery } from 'api/models'
import { getSelectedRealm } from 'app/helpers/manageRealms'
import { TagFilter } from '../TagAttributes/TagAttributeFilter'

type MoveLeakageModalProps = {
  handleConfirm: (
    targetCompanyID: string,
    targetGroupID: string | undefined,
    targetBuildingId: string | undefined,
    targetProjectId: string | undefined,
    copyOnly: boolean,
    shouldRedirect: boolean,
  ) => void
  open: boolean
  handleClose: () => void
  heading: string
  copyOnly: boolean
  preselectCompany?: string
}

type SelectionObject = {
  companyID?: string
  buildingID?: string
  projectID?: string
  groupID?: string
}

export const MoveLeakageModal: React.FC<MoveLeakageModalProps> = ({
  handleConfirm,
  open,
  handleClose,
  heading,
  copyOnly,
  preselectCompany,
}) => {
  const { t } = useTranslation('common')
  const preSelctGroupId = useGetQueryParam('groupId')
  const [selectedTags, setSelectedTags] = useState<TagAttribute[]>([])
  const [preSelectedCompanyId, setPreSelectedCompanyId] = useState<string>()
  const [selectedCompany, setSelectedCompany] = useState<DisplayCompany>({})
  const [companies, setCompanies] = useState<DisplayCompany[]>([])
  const [selectionObject, setSelectionObject] = useState<SelectionObject>({})
  const [redirectChecked, setRedirectCheckbox] = useState<boolean>(true)
  const [selectedGroupId, setSelectedGroupId] = useState<string>()

  const [allCompanies, setAllCompanies] = useState<DisplayCompany[]>([])
  const classes = useStyles()

  const { groupCompanyData } = useCompaniesInGroup()
  const [groupsList, companiesList] = groupCompanyData
  const { data } = useGetTagAttributesQuery({
    variables: {
      realmName: getSelectedRealm(),
    },
    skip: !getSelectedRealm(),
  })

  // obsolete with new attribute tag structure
  // const { projects } = useProjectsWithLeakageInfo(selectedCompany._id ?? '', [], selectedCompany.groupId)
  // const { buildings } = useBuildings(selectedCompany._id ?? '', selectedCompany.groupId)
  //const { groupHierarchy } = convertToFileHierarchy(groupsList, undefined)

  const { projects } = useProjectsWithLeakageInfo(selectedCompany._id ?? '', [], undefined)
  const { buildings } = useBuildings(selectedCompany._id ?? '', undefined)

  useEffect(() => {
    if (preSelctGroupId && !selectedGroupId) {
      setSelectedGroupId(preSelctGroupId)
    }
  }, [preSelctGroupId])

  useEffect(() => {
    if (companiesList) {
      //console.log({ companiesList })
      setAllCompanies(companiesList)
      if (!selectedGroupId || !companies.length) {
        setCompanies(companiesList)
      }
      //setSelectedCompany({})
    }
  }, [companiesList, selectedGroupId])

  // useEffect(() => {
  //   if (selectedGroupId) {
  //     const subGroupIds: string[] = []
  //     const ids = collectSubGroupIds(groupHierarchy, subGroupIds, selectedGroupId)

  //     const filteredCompaniesByGroup = allCompanies.filter((c) => ids.includes(c?.groupId ?? ''))

  //     setCompanies(filteredCompaniesByGroup)
  //   }
  // }, [selectedGroupId])

  //console.log({ projects })

  // useEffect(() => {
  //   if (selectedCompany._id) {
  //     if (projects.length) {
  //       const companyProj = projects.map((pj) => ({
  //         _id: pj._id,
  //         name: pj.name,
  //       }))
  //       setSelectedCompany((prv) => ({
  //         ...prv,
  //         projects: companyProj,
  //       }))
  //     }
  //     if (buildings.length) {
  //       const companybuildings = buildings.map((b) => ({
  //         _id: b._id,
  //         name: b.name,
  //       }))
  //       setSelectedCompany((prv) => ({
  //         ...prv,
  //         buildings: companybuildings,
  //       }))
  //     }
  //   }
  // }, [projects, selectedCompany, buildings])

  const updateSelectionObject = (update: SelectionObject): void => {
    setSelectionObject({ ...selectionObject, ...update })
    if (update.companyID && companies.some((c) => c._id === update.companyID)) {
      const selCompObj = companies.find((c) => c._id === update.companyID)
      selCompObj && setSelectedCompany(selCompObj)
    }
  }

  useEffect(() => {
    if (preselectCompany && !preSelectedCompanyId && companies.length) {
      setPreSelectedCompanyId(preselectCompany)

      const selComp = companies.find((c) => c._id === preselectCompany)

      updateSelectionObject({ companyID: selComp?._id ?? '', groupID: selComp?.groupId })

      //setSelectedGroupId(selComp?.groupId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preselectCompany, companies])

  const confirmed = (): void => {
    handleConfirm(
      selectionObject.companyID ?? '',
      selectedCompany.groupId,
      selectionObject.buildingID,
      selectionObject.projectID,
      copyOnly,
      redirectChecked,
    )
  }

  const tagOptions = useMemo(() => {
    if (!data?.getTagAttributes.length) return []
    return data.getTagAttributes
  }, [data?.getTagAttributes])

  // const changeGroup = (groupID: string) => {
  //   setSelectedGroupId(groupID)
  //   updateSelectionObject({})
  //   setSelectedCompany({})
  // }

  const handleModalClose = () => {
    setSelectedGroupId(undefined)

    setSelectionObject({})
    handleClose()
  }
  const handleTagFilter = (ids: string[]) => {
    const resetList = tagOptions.filter((tg) => ids.includes(tg.ID))
    setSelectedTags(resetList)
    const tmpCompanyIDS = resetList
      .map((ta) => ta.attributes)
      .flat()
      .map((aid) => aid.attributeID)
    const filterdComps = allCompanies.filter((c) => c._id && tmpCompanyIDS.includes(c?._id))
    setCompanies(filterdComps.length ? filterdComps : allCompanies)
  }

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleModalClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Grid container className={classes.paper} spacing={2}>
        <Typography variant={'h4'} align="center">
          {t(heading)}
        </Typography>
        <Grid container spacing={2} style={paddingTop}>
          <Grid item container xs={12} alignItems="center" alignContent="stretch">
            {/* <Grid item xs={12}>
              <GroupExplorer
                updateTargetGroup={changeGroup}
                //groupsList={allGroups}
                selectedGroupID={selectedGroupId ?? ''}
                filterable
              />
            </Grid> */}

            <Grid item xs={12}>
              <TagFilter
                filterHandler={handleTagFilter}
                tagFilterAttributeOptions={tagOptions}
                tagFilterValues={selectedTags}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <CsButton title={'reset'} solid disabled={!selectedGroupId} onClick={() => changeGroup('')}>
                {t('actions.viewAllCompanies')}
              </CsButton>
            </Grid> */}
          </Grid>
          <CompanyDropDown
            companies={companies}
            selectedCompanyID={selectedCompany._id ?? ''}
            variant="standard"
            xs={12}
            selectCompany={(companyID) => updateSelectionObject({ companyID })}
            //helperText={isDuplicateName ? 'Es gibt Mehrer Firmen mit gleichen Name' : undefined}
          />
          <Grid item xs={12} />

          {/* <SelectInput
            changeHandler={(companyID) => updateSelectionObject({ companyID })}
            xs={12}
            keys={companies.map((c) => c._id ?? '')}
            text={companies.map((c) => c.name ?? '')}
            label={'objects.company'}
            preselect={preselectCompany}
          /> */}
          <SelectInput
            changeHandler={(buildingID) => updateSelectionObject({ buildingID })}
            xs={12}
            keys={buildings.map((b) => b._id ?? '')}
            text={buildings.map((b) => b.name ?? '')}
            textForEmpty={t('modals.transferBuilding')}
            preselect={''}
            label={'objects.building'}
          />
          <SelectInput
            changeHandler={(projectID) => updateSelectionObject({ projectID })}
            xs={12}
            keys={projects.map((p) => p._id ?? '')}
            text={projects.map((p) => p.name ?? '')}
            textForEmpty={t('modals.transferProject')}
            preselect={''}
            label={'objects.project'}
            disableTranslation={true}
          />
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="shouldRedirect"
                  color="primary"
                  checked={redirectChecked}
                  onChange={({ target }) => setRedirectCheckbox(target.checked)}
                />
              }
              label={t('modals.shouldRedirectToProject')}
            />
          </Grid>
          <Grid item xs={12}>
            {' '}
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={5}>
            <CsButton fullwidth={true} onClick={handleClose}>
              {t('modals.cancel')}
            </CsButton>
          </Grid>
          <Grid item xs={5}>
            <CsButton
              fullwidth={true}
              onClick={() => {
                confirmed()
                handleClose()
              }}
              disabled={!selectedCompany._id}
              solid={true}
            >
              {t('modals.confirm')}
            </CsButton>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}
