import React from 'react'
import { useHistory } from 'react-router-dom'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import HomeIcon from '@material-ui/icons/HomeOutlined'
import { usePathNames } from '../../../hooks/usePathNames'
import { useTranslation } from 'react-i18next'
import {
  NavigationBreadcrumb,
  NavigationBreadcrumbCompanyTitle,
  NavigationBreadcrumbProjectTitle,
} from './NavigationBreadcrumb'
import { breadcrumbIsProject } from './navigationHelper'
import { useGetQueryParam } from 'app/components/pages/utilHooks'
import { useGroupAbsolutePath } from 'app/hooks/groupHooks'
import { useStyles } from './NavigationStyle'
import { routeLabels } from 'app/helpers/routes'

export const NavigationBreadcrumbs: React.FC = () => {
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation('common')

  const [pathNames, pathTitles] = usePathNames()

  const groupId = useGetQueryParam('groupId')
  //const groupPath = useGroupAbsolutePath(!groupId ? '' : groupId)
  //console.log({ groupPath })

  const handleClick = (e: React.MouseEvent, i: number, selectedGroupId?: string): void => {
    e.preventDefault()
    let linkPath = '/' + pathNames.slice(0, i).join('/')
    // for project only, redirect to its leakages
    if (selectedGroupId) {
      linkPath = linkPath + `?groupId=${selectedGroupId}`
    } else {
      linkPath = !groupId ? linkPath : linkPath + `?groupId=${groupId}`
    }
    if (!breadcrumbIsProject(i, pathNames)) {
      history.push(linkPath)
    } else history.push(linkPath + '/leakages')
  }
  const isCompany = (position: number): string => {
    const matchPosition = 1
    const match = pathNames?.includes('companies') && position === matchPosition
    return match ? pathNames[matchPosition] : ''
  }
  const isProject = (position: number): string => {
    const matchPosition = 3
    const match = pathNames?.includes('projects') && position === matchPosition
    return match ? pathNames[matchPosition] : ''
  }

  return (
    <Breadcrumbs
      className={classes.navigationBreadcrumbStyle}
      itemsAfterCollapse={3}
      maxItems={6}
      aria-label="breadcrumb"
    >
      <Link
        variant={'body2'}
        href="/"
        onClick={(e: React.MouseEvent) => handleClick(e, 0)}
        className={classes.navigationBreadcrumbStyle}
      >
        <HomeIcon />
        {t('objects.home')}
      </Link>
      {/* {groupPath.map((p) => (
        <Link
          key={p.id}
          variant={'body2'}
          href="/"
          onClick={(e: React.MouseEvent) => handleClick(e, 0)}
          className={classes.navigationBreadcrumbStyle}
        >
          {p.title}
        </Link>
      ))} */}
      {pathTitles.map((pt, i) => {
        if (isCompany(i)) {
          return (
            <NavigationBreadcrumbCompanyTitle
              position={i}
              total={pathNames.length}
              onClick={handleClick}
              text={pt}
              id={isCompany(i)}
              key={i + pt}
            />
          )
        } else if (isProject(i)) {
          return (
            <NavigationBreadcrumbProjectTitle
              position={i}
              total={pathNames.length}
              onClick={handleClick}
              text={pt}
              id={isProject(i)}
              key={i + pt}
            />
          )
        } else {
          return (
            <NavigationBreadcrumb position={i} total={pathNames.length} onClick={handleClick} text={pt} key={i + pt} />
          )
        }
      })}
    </Breadcrumbs>
  )
}
