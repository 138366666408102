import React, { FC, ReactElement, useCallback } from 'react'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'

export type SliderDirection = 'left' | 'right'

export type ImageUploadSliderProps = {
  direction: SliderDirection
  clicked: (direction: SliderDirection) => void
}

export const ImageUploadSlider: FC<ImageUploadSliderProps> = ({ direction, clicked }): ReactElement => {
  const clickAction = useCallback(() => {
    clicked(direction)
  }, [clicked, direction])

  return (
    <div
      style={{
        zIndex: 10,
        position: 'absolute',
        height: '100%',
        width: '20%',
        verticalAlign: 'middle',
        margin: 'auto',
        right: direction === 'right' ? 0 : 'auto',

        background:
          direction === 'right'
            ? 'linear-gradient(to left, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0) 100%)'
            : 'linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0) 100%)',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          justifyContent: direction === 'right' ? 'flex-end' : 'flex-start',
          cursor: 'pointer',
        }}
        onClick={clickAction}
      >
        {direction === 'left' ? <ChevronLeft fontSize="large" /> : <ChevronRight fontSize="large" />}
      </div>
    </div>
  )
}

export default ImageUploadSlider
