import { Leakage } from '../../../interfaces/BusinessObjects'

export const getLeakageDate = (leakage: Leakage): Date => {
  return leakage && leakage.date ? new Date(leakage.date) : new Date()
}

export const getFirstLastLeakages = (leakages: Leakage[]): [Leakage, Leakage] => {
  return leakages.reduce(
    (prev, current: Leakage) =>
      !current.date || !prev[0].date || !prev[1].date
        ? prev
        : getLeakageDate(current).getTime() < getLeakageDate(prev[0]).getTime()
        ? [current, prev[1]]
        : getLeakageDate(current).getTime() > getLeakageDate(prev[1]).getTime()
        ? [prev[0], current]
        : prev,
    [leakages[0], leakages[0]],
  )
}
