/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { v4 as uuidv4 } from 'uuid'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { LeakageParamTypes } from '../../../../interfaces/PageParams'
import { ROUTES } from '../../../helpers/routes'
import CsButton from '@csinstruments/cs-react-theme/dist/components/Buttons/CsButton'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import { paddingBottomActionBar, paddingLeftRight, paddingTop, standardContentPadding } from '../../../../styles/Shared'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpwardOutlined'
import ArrowBackIcon from '@material-ui/icons/ArrowBackOutlined'
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlatOutlined'
import SaveIcon from '@material-ui/icons/SaveOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined'
import { StandardConfirmationModal } from '../../partials/StandardConfirmationModal/StandardConfirmationModal'
import { MoveLeakageModal } from '../../partials/MoveLeakageModal/MoveLeakageModal'
import { ImageUpload } from '../../partials/ImageUpload/ImageUpload'
import { DatePicker } from '../../partials/Inputs/DatePicker'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { SelectInput } from '../../partials/Inputs/Select'
import { Text } from '../../partials/Inputs/Text'
import { Label } from '../../partials/Label/Label'
import {
  useUpdateLeakage,
  useMoveLeakages,
  useDeleteLeakage,
  useLeakagesOfProjectWithImages,
} from '../../../hooks/leakageHooks'
import {
  Building,
  FAILURE,
  getDisplayNameOfProject,
  Leakage,
  MEASURE,
  Place,
  REPLACEMENT,
  Replacement,
} from '../../../../interfaces/BusinessObjects'
import { getPrevAndNextLeakageId } from './calculations'
import { useProject } from '../../../hooks/projectHooks'
import {
  UnitCurrency,
  //UnitCurrency,
  UnitDistance,
  UnitLeakage,
  UnitPressure,
  UnitPriority,
  UnitVolume,
} from '../../../../interfaces/Units'
import { ResultAlert } from '../../partials/ResultAlert'
import { useRedirect } from '../../../hooks/useRedirect'
import { FixedActionBar } from '@csinstruments/cs-react-theme/dist/components/FixedActionBar/FixedActionBar'
import { BottomShadow } from '../../partials/BottomShadow/BottomShadow'
import { SliderDirection } from '../../partials/ImageUpload/ImageUploadSlider'
import { getQueryParam } from '../../../helpers/routingHelper'
import { LeakageImageEdit } from './LeakageImageEdit'
import { BarPsiFactor, LtrMinCfmFactor, MeterInchFactor } from '../../../../constants/calculationConstants'
import { LeakageProjectMaster } from './LeakageProjectMaster'
import { useBuildings, useCreateBuilding } from '../../../hooks/buildingHooks'
import { isAvailableInProject } from '../../../helpers/projectUtils'
import { useCreateDescriptionObject, useLeakageDescriptionFields } from '../../../hooks/leakageDescriptionHooks'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'
import { convertAmount, calcCost } from '../../../helpers/calculations'
import { usePermissions } from 'app/hooks/usePermissions'
import { useCreateNewPlace, useGetPlaces } from 'app/hooks/placeHooks'
import { useGetQueryParam } from '../utilHooks'
import { useCompany } from 'app/hooks/companyHooks'

type CommValues = {
  type?: string
  value?: string
  newEntry?: boolean
}

type PlaceValues = {
  value?: string
  newPlace?: true
}

export const LeakagePage: React.FC = ({}) => {
  const { projectId, id, leakageId, sortedIds } = useParams<LeakageParamTypes>()
  const { t } = useTranslation('common')
  const groupId = useGetQueryParam('groupId')
  const history = useHistory()
  const { company, companyLoading } = useCompany(id, groupId)
  const { buildings, refreshBuildings } = useBuildings(id, groupId)
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [moveModalOpen, setMoveModalOpen] = useState<boolean>(false)
  const [copyModalOpen, setCopyModalOpen] = useState<boolean>(false)
  const { leakages } = useLeakagesOfProjectWithImages(projectId, undefined)
  const [newLeakageData, setNewLeakageData] = useState<Leakage>({})
  const [prev, next] = getPrevAndNextLeakageId(leakages, leakageId, sortedIds)
  const { project } = useProject(projectId)
  const { updateLeakage } = useUpdateLeakage()
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [updatedLeakages, setUpdatedLeakages] = useState<Leakage[]>([])
  const { moveLeakages, movedLeakages, wasCopiedOnly } = useMoveLeakages()
  const { deleteLeakage, deletedLeakage } = useDeleteLeakage()
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0)
  const [dataInitialized, setDataInitialized] = useState(false)

  const editImages = getQueryParam('images')
  const { fields, refresh } = useLeakageDescriptionFields()
  const { createObject } = useCreateDescriptionObject()
  const [commValues, setCommValues] = useState<CommValues>({})
  const [placeValues, setPlaceValues] = useState<PlaceValues>({})
  const permissions = usePermissions()

  const { createBuilding } = useCreateBuilding()
  const { places, refreshPlaces } = useGetPlaces(newLeakageData.buildingId ?? '')
  const { submitNewPlace } = useCreateNewPlace(newLeakageData.buildingId ?? '')

  //const locale = getIsoCode(t)

  const filter = createFilterOptions<Replacement>()
  const filterBuilding = createFilterOptions<Building>()
  const commReplacement = `${t('actions.add')}: `
  const filterPlaces = createFilterOptions<Place>()

  useEffect(() => {
    if (commValues?.type && commValues?.value && commValues.newEntry === true && hasProjectPermission) {
      createObject(commValues.value || '', commValues.type || '')
        .then(() => {
          refresh()
        })
        .catch((error) => {
          console.warn(error)
        })
    }
  }, [commValues])

  useEffect(() => {
    if (placeValues.newPlace && placeValues.value && hasLeakPermmision) {
      submitNewPlace(placeValues.value)
        .then((placeId) => {
          refreshPlaces()
          if (placeId && placeValues.value) {
            updateNewLeakageData({
              place: {
                id: placeId,
                name: placeValues.value,
              },
            })
          }
        })
        .catch((e) => console.warn(e))
    }
  }, [placeValues])

  useEffect(() => {
    if (updatedLeakages && updatedLeakages.length > 0) {
      setShowSuccess(true)
    }
  }, [updatedLeakages])

  useEffect(() => {
    if (movedLeakages && wasCopiedOnly) {
      setShowSuccess(true)
    }
  }, [movedLeakages, wasCopiedOnly])

  useRedirect(generatePath(ROUTES.projectLeakages, { id, projectId }) + '?leakageUpdated=true', [
    (movedLeakages && !wasCopiedOnly) || deletedLeakage,
  ])

  useEffect(() => {
    if (leakageId) {
      const usedLeakages = [
        ...leakages.filter((l) => {
          const updateLeakageIds = updatedLeakages.map((ul) => ul._id ?? '')
          return !updateLeakageIds.includes(l._id ?? '')
        }),
        ...updatedLeakages,
      ]
      const _currentLeakData = usedLeakages?.find((l) => l?._id === leakageId)
      setNewLeakageData(_currentLeakData ?? {})
    }
  }, [leakages, leakageId, updatedLeakages])

  const hasLeakPermmision = useMemo(() => {
    return permissions.Leakage
  }, [permissions])

  const hasProjectPermission = useMemo(() => {
    return permissions.editProjects
  }, [permissions])

  const updateComm = (newValue: any, type: string, field: string): void => {
    let value = ''
    let newEntry = false
    const regex = new RegExp(`${commReplacement}`, 'g')
    if (newValue && newValue?.text && newValue?._id === newValue?.text && hasProjectPermission) {
      value = newValue.text.replace(regex, '')
      newEntry = true
    }
    if (newValue && newValue?.text && newValue?._id !== newValue?.text) {
      value = newValue.text
    }
    setCommValues({ type, value, newEntry })
    updateNewLeakageData({ [field]: value })
  }
  const createOrUpdateBuilding = async (newValue: any): Promise<void> => {
    if (!newValue) return
    if (!newValue?.id) {
      const regex = new RegExp(`${commReplacement}`, 'g')
      const value = newValue?.name?.replace(regex, '')

      const newBuilding = await createBuilding({
        name: value,
        company: id,
        groupId: groupId,
      })
      if (newBuilding.id) {
        refreshBuildings()
        updateNewLeakageData({ buildingId: newBuilding.id, place: undefined })
      }
    } else {
      updateNewLeakageData({ buildingId: newValue?.id, place: undefined })
    }
    refreshPlaces()

    //return
  }

  const updateNewPlace = (newPlacevalue: any): void => {
    //console.log({ newPlacevalue })
    if (!newPlacevalue) return
    if (newPlacevalue.id) {
      updateNewLeakageData({
        place: {
          id: newPlacevalue.id,
          name: newPlacevalue.name,
        },
      })
    } else {
      const regex = new RegExp(`${commReplacement}`, 'g')
      const place_name = newPlacevalue.name.replace(regex, '')
      setPlaceValues({
        newPlace: true,
        value: place_name,
      })
    }
  }

  const goBack = (): void => {
    if (!editImages) {
      let routePath = generatePath(ROUTES.projectLeakages, { id, projectId })
      routePath = groupId ? routePath + `?groupId=${groupId}` : routePath
      history.push(routePath)
    } else {
      let leakPath = generatePath(ROUTES.leakage, { id, projectId, leakageId, sortedIds })
      leakPath = groupId ? leakPath + `?groupId=${groupId}` : leakPath
      history.push(leakPath)
    }
  }

  const goToLeakage = (leakageId: string): void => {
    setNewLeakageData({})

    setSelectedImageIndex(0)
    setDataInitialized(false)
    let leakPath = generatePath(ROUTES.leakage, { id, projectId, leakageId, sortedIds })
    leakPath = groupId ? leakPath + `?groupId=${groupId}` : leakPath
    history.push(leakPath)
  }

  const updateNewLeakageData = (data: Leakage): void => {
    //console.log('updateNewLeakageData', { data })
    setNewLeakageData({ ...newLeakageData, ...data })
  }

  const addImageToNewLeakageData = (base64: string): void => {
    const imagesAsBase64 = newLeakageData.imagesAsBase64 ? [...newLeakageData.imagesAsBase64] : []
    imagesAsBase64.push({ src: base64, _originalImage: false, checked: true })
    updateNewLeakageData({ imagesAsBase64 })
    setSelectedImageIndex(imagesAsBase64.length - 1)
  }

  const removeCurrentImage = (): void => {
    const imagesAsBase64 = newLeakageData.imagesAsBase64 ? [...newLeakageData.imagesAsBase64] : []
    imagesAsBase64.splice(selectedImageIndex, 1)
    updateNewLeakageData({ imagesAsBase64 })
    setSelectedImageIndex(Math.max(imagesAsBase64.length - 1, 0))
  }

  const slideAction = useCallback(
    (direction: SliderDirection) => {
      setSelectedImageIndex((prev) =>
        newLeakageData.imagesAsBase64
          ? direction === 'right'
            ? (prev + 1) % newLeakageData.imagesAsBase64.length
            : (newLeakageData.imagesAsBase64.length + prev - 1) % newLeakageData.imagesAsBase64.length
          : 0,
      )
    },
    [setSelectedImageIndex, newLeakageData],
  )
  const moveAction = useCallback(() => setMoveModalOpen(true), [])
  const copyAction = useCallback(() => setCopyModalOpen(true), [])
  const deleteAction = useCallback(() => setDeleteModalOpen(true), [])
  const imageClickAction = useCallback(
    () => history.push(generatePath(ROUTES.leakage, { id, projectId, leakageId, sortedIds }) + '?images=true'),
    [history, id, leakageId, projectId],
  )
  const updateAction = useCallback(() => {
    setUpdatedLeakages([...updatedLeakages.filter((ul) => ul._id !== newLeakageData._id), newLeakageData])
    updateLeakage(newLeakageData).catch((err) => console.error('error: ', err))
  }, [updateLeakage, newLeakageData, updatedLeakages])

  const costPerYear = useMemo(
    () => (dataInitialized && calcCost(newLeakageData, project, t).resultAsLocalFormat) || '',
    [newLeakageData, project, dataInitialized],
  )

  const resUnitLUpdated = useCallback(
    (resUnitL: string): void => {
      if (resUnitL === UnitLeakage.LTR_MIN) {
        setNewLeakageData((prev) => {
          const resLeakage = (parseFloat(prev.resLeakage ?? '0') * LtrMinCfmFactor).toFixed(4)
          return { ...prev, resUnitL, resLeakage }
        })
      } else {
        setNewLeakageData((prev) => {
          const resLeakage = (parseFloat(prev.resLeakage ?? '0') / LtrMinCfmFactor).toFixed(4)
          return { ...prev, resUnitL, resLeakage }
        })
      }
    },
    [leakageId],
  )

  useEffect(() => {
    if (project.unitV && newLeakageData.resLeakage && newLeakageData.resUnitL && !dataInitialized) {
      setDataInitialized(true)
      const amount = convertAmount(newLeakageData.resLeakage, newLeakageData.resUnitL, project.unitV, t)
      setNewLeakageData((newLeakageData) => {
        return { ...newLeakageData, resLeakage: amount.resultAsFormattedString, resUnitL: amount.unitAsEnum }
      })
    }
  }, [newLeakageData.resLeakage, newLeakageData.resUnitL, dataInitialized, newLeakageData.costUnit, project])

  const unitDUpdated = (unitD: string): void => {
    if (unitD === UnitDistance.INCH) {
      setNewLeakageData((prev) => {
        const distance = (parseFloat(prev.distance ?? '0') / MeterInchFactor).toFixed(2)
        return { ...prev, distance, unitD }
      })
    } else {
      setNewLeakageData((prev) => {
        const distance = (parseFloat(prev.distance ?? '0') * MeterInchFactor).toFixed(2)
        return { ...prev, distance, unitD }
      })
    }
  }

  const unitPUpdated = (unitP: string): void => {
    if (unitP === UnitPressure.BAR) {
      setNewLeakageData((prev) => {
        const pressure = (parseFloat(prev.pressure ?? '0') / BarPsiFactor).toFixed(2)
        return { ...prev, pressure, unitP }
      })
    } else {
      setNewLeakageData((prev) => {
        const pressure = (parseFloat(prev.pressure ?? '0') * BarPsiFactor).toFixed(2)
        return { ...prev, pressure, unitP }
      })
    }
  }

  const supportedCurrencies = useMemo(() => {
    const currencies = Object.keys(UnitCurrency)
    if (project.costUnit) {
      return currencies.includes(project.costUnit) ? currencies : [...currencies, project.costUnit]
    }
    return currencies
  }, [project.costUnit])
  //console.log('canEdit', newLeakageData.canEdit)
  function isDate(dateStr: string | Date): boolean {
    return !isNaN(new Date(dateStr).getDate())
  }

  const currentBuilding = buildings?.find((b) => b?.id === newLeakageData?.buildingId)
  //console.log({ currentBuilding }, { buildings })
  const mainContent = (
    <>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Grid container spacing={2} direction={'column'}>
            <Grid item xs={12}>
              <ImageUpload
                disabled={!hasLeakPermmision}
                slideAction={slideAction}
                imageClickAction={imageClickAction}
                imageRemoved={removeCurrentImage}
                imageChanged={(image: string) => addImageToNewLeakageData(image)}
                image={
                  newLeakageData.imagesAsBase64 && newLeakageData.imagesAsBase64.length > selectedImageIndex
                    ? newLeakageData.imagesAsBase64[selectedImageIndex].src.length > 100
                      ? newLeakageData.imagesAsBase64[selectedImageIndex].src
                      : ''
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  preselect={newLeakageData.date ? new Date(newLeakageData.date) : new Date()}
                  label={'objects.date'}
                  xs={12}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <SelectInput
              disabled
              preselect={company.name ?? ''}
              text={[company.name ?? '']}
              label={'objects.company'}
              xs={12}
            />
            <SelectInput
              preselect={getDisplayNameOfProject(project)}
              text={[getDisplayNameOfProject(project)]}
              label={'objects.project'}
              disabled
              xs={12}
            />
            <Grid item xs={12}>
              <Autocomplete
                key={currentBuilding?.id}
                disabled={!newLeakageData.canEdit}
                defaultValue={currentBuilding}
                onChange={(event, newValue: any) => {
                  createOrUpdateBuilding(newValue)
                }}
                filterOptions={(options, params) => {
                  const filtered = filterBuilding(options, params)
                  if (
                    params.inputValue !== '' &&
                    !filtered.find((item) => item?.name?.toLowerCase() === params.inputValue.toLowerCase())
                  ) {
                    filtered.push({
                      name: `${commReplacement}${params.inputValue}`,
                      id: '',
                    })
                  }
                  return filtered
                }}
                options={buildings || []}
                getOptionLabel={(option) => option?.name}
                renderInput={(params) => <TextField {...params} label={t('objects.building')} variant="standard" />}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Autocomplete
                disabled={!hasLeakPermmision}
                value={newLeakageData.place?.name || ''}
                onChange={(e, newValue: any) => {
                  updateNewPlace(newValue)
                }}
                filterOptions={(options, params) => {
                  const filtered = filterPlaces(options, params)
                  if (
                    params.inputValue !== '' &&
                    hasLeakPermmision &&
                    !filtered.find((item) => item.name.toLowerCase() === params.inputValue.toLowerCase())
                  ) {
                    filtered.push({
                      name: `${commReplacement}${params.inputValue}`,
                      id: '',
                    })
                  }
                  return filtered
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={places}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option
                  }
                  return option.name
                }}
                renderOption={(option) => option.name}
                fullWidth={true}
                freeSolo
                renderInput={(params) => <TextField {...params} label={t('objects.location')} variant="standard" />}
              />
            </Grid>

            {/* <Grid item xs={12}>
              <InputLabel id={t('objects.location')}>{t('objects.location')}</InputLabel>
              <Select fullWidth value={newLeakageData?.place?.id ?? ''} displayEmpty>
                {places.map((pl) => (
                  <MenuItem key={pl.id} value={pl.id}>
                    {pl.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid> */}

            {/* <SelectInput
              preselect={places.find((p) => p.id === newLeakageData.place?.id)?.name ?? ''}
              text={[places.filter((p) => p.id === newLeakageData.place?.id).pop()?.name || '']}
              label={'objects.location'}
              xs={12}
            /> */}
            {/* <Text
              displayOnly={!hasLeakPermmision}
              label={'objects.location'}
              value={newLeakageData.place || ''}
              changeHandler={(place) => updateNewLeakageData({ place })}
              columns={12}
            /> */}
            <Text
              displayOnly={!hasLeakPermmision}
              value={newLeakageData.leakTag || ''}
              changeHandler={(leakTag) => updateNewLeakageData({ leakTag })}
              label={'leakage.leakTag'}
              columns={12}
            />
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Text columns={12} value={newLeakageData.level || ''} label={'leakage.level'} displayOnly={true} />
            <Text columns={8} value={newLeakageData.pressure || ''} label={'leakage.pressure'} displayOnly={true} />
            <SelectInput
              isUnitSelect
              hasEmpty={false}
              disabled={!hasLeakPermmision}
              changeHandler={(unitP) => unitPUpdated(unitP)}
              preselect={newLeakageData.unitP}
              text={Object.keys(UnitPressure)}
              xs={4}
              label={'objects.unit'}
            />
            <Text value={newLeakageData.distance || ''} columns={8} label={'leakage.distance'} displayOnly={true} />
            <SelectInput
              changeHandler={(unitD) => unitDUpdated(unitD)}
              isUnitSelect
              hasEmpty={false}
              disabled={!hasLeakPermmision}
              preselect={newLeakageData.unitD?.toUpperCase()}
              text={Object.keys(UnitDistance)}
              xs={4}
              label={'objects.unit'}
            />
            <Grid item xs={12}>
              <LeakageProjectMaster project={project} leakage={newLeakageData} />
            </Grid>
            <Text
              columns={3}
              onlyNumbers={true}
              value={newLeakageData.costBase || ''}
              displayOnly={!hasLeakPermmision || !!isAvailableInProject(project, 'costBase')}
              changeHandler={(costBase) => updateNewLeakageData({ costBase })}
              label={'leakage.costPerVolume'}
            />
            <SelectInput
              xs={3}
              label={'project.currency'}
              disabled={true}
              changeHandler={(costUnit) => updateNewLeakageData({ costUnit })}
              preselect={newLeakageData.costUnit}
              isUnitSelect
              text={supportedCurrencies}
            />
            <Label text={'/ 1000'} xs={3} />
            <SelectInput
              preselect={project.unitV ? project.unitV : newLeakageData.unitV}
              isUnitSelect
              disabled
              text={Object.keys(UnitVolume)}
              xs={3}
              label={'objects.unit'}
            />
            <Text
              columns={12}
              onlyNumbers={true}
              changeHandler={(costTime) => updateNewLeakageData({ costTime })}
              value={newLeakageData.costTime || ''}
              displayOnly={isAvailableInProject(project, 'costTime') || !hasLeakPermmision}
              label={'project.workingHours'}
            />
            {/* <Text
              columns={8}
              changeHandler={(resLeakage) => updateLeakageRate(resLeakage)}
              value={
                convertAmount(newLeakageData.resLeakage, newLeakageData.resUnitL, project.unitV, t)
                  .resultAsFormattedString
              }
              onlyNumbers={false}
              label={'leakage.amount'}
              displayOnly={!hasLeakPermmision}
            />
            <SelectInput
              xs={4}
              hasEmpty={false}
              label={'objects.unit'}
              preselect={
                isAvailableInProject(project, 'unitV') && project.unitV
                  ? getLeakageUnit(project.unitV)
                  : newLeakageData.resUnitL
              }
              changeHandler={(resUnitL) => {
                resUnitLUpdated(resUnitL)
              }}
              isUnitSelect
              disabled={!hasLeakPermmision || isAvailableInProject(project, 'unitV')}
              text={Object.keys(UnitLeakage)}
            /> */}
            <Text
              columns={8}
              changeHandler={(resLeakage) => updateNewLeakageData({ resLeakage })}
              value={newLeakageData?.resLeakage || ''}
              onlyNumbers={false}
              label={'leakage.amount'}
              displayOnly={!newLeakageData.canEdit}
            />
            <SelectInput
              xs={4}
              hasEmpty={false}
              label={'objects.unit'}
              preselect={newLeakageData.resUnitL}
              changeHandler={(resUnitL) => {
                resUnitLUpdated(resUnitL)
              }}
              isUnitSelect
              disabled={isAvailableInProject(project, 'unitV') || !newLeakageData.canEdit}
              text={Object.keys(UnitLeakage)}
            />
            <Text columns={12} displayOnly value={costPerYear} label={'leakage.costPerYear'} />
            <Text
              columns={12}
              value={newLeakageData.comment || ''}
              changeHandler={(comment) => updateNewLeakageData({ comment })}
              multiline={true}
              displayOnly={!hasLeakPermmision}
              label={'leakage.comment'}
            />
            <SelectInput
              xs={12}
              isUnitSelect
              disabled={!hasLeakPermmision}
              text={Object.keys(UnitPriority)}
              changeHandler={(priority) => updateNewLeakageData({ priority })}
              preselect={newLeakageData.priority && newLeakageData.priority !== 'NONE' ? newLeakageData.priority : ''}
              label={'priority.priority'}
            />
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <SelectInput
              xs={12}
              disabled={!hasLeakPermmision}
              preselect={newLeakageData.commRup}
              changeHandler={(commRup) => updateNewLeakageData({ commRup })}
              label={'leakage.resolvableUnderPressure'}
              text={['actions.yes', 'actions.no']}
            />
            <Grid item xs={12} md={12}>
              <Autocomplete
                disabled={!hasLeakPermmision}
                value={newLeakageData.commF || ''}
                onChange={(event, newValue: any) => {
                  updateComm(newValue, FAILURE, 'commF')
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params)
                  if (
                    params.inputValue !== '' &&
                    !filtered.find((item) => item.text.toLowerCase() === params.inputValue.toLowerCase()) &&
                    hasProjectPermission
                  ) {
                    filtered.push({
                      text: `${commReplacement}${params.inputValue}`,
                      _id: `${commReplacement}${params.inputValue}`,
                    })
                  }
                  return filtered
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={fields.failures}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option
                  }
                  return option.text
                }}
                renderOption={(option) => option.text}
                freeSolo
                fullWidth={true}
                renderInput={(params) => <TextField {...params} label={t('leakage.failure')} variant="outlined" />}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Autocomplete
                disabled={!hasLeakPermmision}
                value={newLeakageData.commM || ''}
                onChange={(event, newValue: any) => {
                  updateComm(newValue, MEASURE, 'commM')
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params)
                  if (
                    params.inputValue !== '' &&
                    hasProjectPermission &&
                    !filtered.find((item) => item.text.toLowerCase() === params.inputValue.toLowerCase())
                  ) {
                    filtered.push({
                      text: `${commReplacement}${params.inputValue}`,
                      _id: `${commReplacement}${params.inputValue}`,
                    })
                  }
                  return filtered
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={fields.measures}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option
                  }
                  return option.text
                }}
                renderOption={(option) => option.text}
                fullWidth={true}
                freeSolo
                renderInput={(params) => <TextField {...params} label={t('leakage.measure')} variant="outlined" />}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Autocomplete
                disabled={!hasLeakPermmision}
                value={newLeakageData.commR || ''}
                onChange={(event, newValue: any) => {
                  updateComm(newValue, REPLACEMENT, 'commR')
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params)
                  //console.log('commR filterOptions', { filtered, options, params })
                  if (
                    params.inputValue !== '' &&
                    hasProjectPermission &&
                    !filtered.find((item) => item.text.toLowerCase() === params.inputValue.toLowerCase())
                  ) {
                    filtered.push({
                      text: `${commReplacement}${params.inputValue}`,
                      _id: `${commReplacement}${params.inputValue}`,
                    })
                  }
                  return filtered
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={fields.replacements}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option
                  }
                  return option.text
                }}
                renderOption={(option) => option.text}
                fullWidth={true}
                freeSolo
                renderInput={(params) => <TextField {...params} label={t('leakage.sparePart')} variant="outlined" />}
              />
            </Grid>
            <Text
              value={newLeakageData.note || ''}
              changeHandler={(note) => updateNewLeakageData({ note })}
              columns={12}
              multiline={true}
              displayOnly={!hasLeakPermmision}
              label={'leakage.note'}
            />
            <SelectInput
              xs={12}
              disabled={!hasLeakPermmision}
              changeHandler={(status) => updateNewLeakageData({ status })}
              preselect={newLeakageData.status || ''}
              label={'leakage.status'}
              text={['actions.completed', 'actions.inProgress']}
            />
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  preselect={newLeakageData.solved_at ? new Date(Date.parse(newLeakageData.solved_at ?? '')) : null}
                  onChange={(solved_at) =>
                    isDate(solved_at) && updateNewLeakageData({ solved_at: solved_at.toString() })
                  }
                  label={'leakage.solvedAt'}
                  xs={12}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Text
              columns={12}
              displayOnly={!hasLeakPermmision}
              value={newLeakageData.solved_by || ''}
              changeHandler={(solved_by) => updateNewLeakageData({ solved_by })}
              label={'leakage.solvedBy'}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )

  console.log({ leakages })

  return (
    <>
      <FixedActionBar
        buttonProps={[{}, {}, {}, { solid: true }]}
        fixedButtonSizes={[1, 1, 1, 2]}
        disabled={[!hasProjectPermission, !hasProjectPermission, !hasProjectPermission, !hasLeakPermmision]}
        actions={[moveAction, copyAction, deleteAction, updateAction]}
        labels={['', '', '', 'actions.save']}
        icons={[TrendingFlatIcon, FileCopyIcon, DeleteIcon, SaveIcon]}
      />

      <StandardConfirmationModal
        handleConfirm={() => deleteLeakage(leakageId)}
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
      />
      <MoveLeakageModal
        handleConfirm={(companyID, targetGroupID, targetBuildingId, targetProjectId, copyOnly, shouldRedirect) => {
          moveLeakages([leakageId], targetProjectId, targetBuildingId, companyID, copyOnly, targetGroupID)
            .then((mvResult) => {
              //console.log({ companyName, projID, leakageId, sortedIds })
              if (shouldRedirect && mvResult) {
                setDataInitialized(false)
                let routePath = generatePath(ROUTES.projectLeakages, {
                  id: mvResult.companyId,
                  projectId: mvResult.projectID,
                })
                routePath = mvResult.groupId ? routePath + `?groupId=${mvResult.groupId}` : routePath
                history.push(routePath)
              }
            })
            .catch((e) => {
              console.error(e)
            })
        }}
        open={moveModalOpen || copyModalOpen}
        handleClose={() => {
          setMoveModalOpen(false)
          setCopyModalOpen(false)
        }}
        copyOnly={copyModalOpen}
        heading={moveModalOpen ? 'actions.moveTo' : 'actions.copyTo'}
        preselectCompany={project.company}
      />
      <ResultAlert alertText={'leakage.updateSuccess'} showAlert={showSuccess} modifyShowAlert={setShowSuccess} />
      <div>
        <Grid container alignItems="flex-end" style={{ ...paddingLeftRight, ...paddingTop, height: 68 }}>
          <Grid item xs={3}>
            <CsButton textButton label={t('actions.back')} onClick={goBack}>
              <ArrowUpwardIcon />
            </CsButton>
          </Grid>
          <Grid item xs={1} md={3}>
            {' '}
          </Grid>
          <Grid item xs={4} md={3}>
            <CsButton
              textButton
              disabled={leakages.length == 1}
              label={t('leakage.prev')}
              fullwidth={true}
              onClick={() => goToLeakage(prev)}
            >
              <ArrowBackIcon />
            </CsButton>
          </Grid>
          <Grid item xs={4} md={3}>
            <CsButton
              textButton
              disabled={leakages.length == 1}
              label={t('leakage.next')}
              fullwidth={true}
              onClick={() => goToLeakage(next)}
            >
              <ArrowForwardIcon />
            </CsButton>
          </Grid>
        </Grid>
        <BottomShadow />
      </div>
      <Grid container style={{ ...paddingLeftRight, ...paddingBottomActionBar }}>
        <Grid item xs={12}>
          <div style={standardContentPadding}>
            {editImages ? (
              <LeakageImageEdit newLeakageData={newLeakageData} updateNewLeakageData={updateNewLeakageData} />
            ) : (
              mainContent
            )}
          </div>
        </Grid>
      </Grid>
    </>
  )
}
