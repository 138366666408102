/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint disabled for this particular rule / this file because it's too much effort to define types for
// the whole import xml
import { parseString } from 'xml2js'
import { BMP, COST, DATA, FileSelectionImage, INFO, JNL, RES } from './dataImportTypes'
import { journalToImportCompanies } from './dataImportConversion'
import { DataImportTarget, DatePickerDates, ImportCompany, ImportProject } from '../../../interfaces/DataImport'
import { identifyXmlOrigin, origins } from './dataImportAuthorization'
import { Leakage } from '../../../interfaces/BusinessObjects'
import he from 'he'
import { Brand, environment } from '../../../constants/environment'

type HasRoot = {
  $: COST | RES | INFO | DATA | BMP
}

export type DataImportRequest = {
  companies: ImportCompany[]
  uids?: string[]
  images?: FileSelectionImage[]
  target?: DataImportTarget
  journalPath?: string
  error: boolean
}

export const extractBmp = (jnl: any): BMP => {
  return getObjectRoot(jnl.BMP) as BMP
}

const extractCost = (jnl: any): COST => {
  // return getObjectRoot(jnl.COST) as COST
  const parts = jnl.COST.map((i: any) => i.$)
  const data = join(parts)
  return data as COST
}

const extractRes = (jnl: any): RES => {
  return getObjectRoot(jnl.RES) as RES
}

function join<T>(parts: T[]): T {
  const data = {}
  parts.map((p: T) => Object.assign(data, p))
  const keys = Object.keys(data)
  // @ts-ignore
  keys.map((key) => (data[key] = he.decode(data[key])))
  return data as T
}

const extractInfo = (jnl: any): INFO => {
  const parts = jnl.INFO.map((i: any) => i.$)
  const data: INFO = join(parts)
  return data
}

const extractData = (jnl: any): DATA => {
  const parts = jnl.DATA.map((i: any) => i.$)
  const data = join(parts)
  return data as DATA
}

export const getObjectRoot = (o: Array<HasRoot>): COST | RES | INFO | DATA | BMP => {
  return o[0].$
}

export const parseJnl = (jnl: any): JNL => {
  const DATA = extractData(jnl)
  const INFO = extractInfo(jnl)
  const RES = extractRes(jnl)
  const BMP = extractBmp(jnl)
  const COST = extractCost(jnl)
  return { DATA, INFO, RES, BMP, COST }
}

const checkOriginAuthorized = (journal: JNL[]): boolean => {
  const results = journal.map((jnl) => identifyXmlOrigin(parseInt(jnl.INFO.DT32), parseInt(jnl.INFO.DT33)))
  if (environment.BRAND === Brand.Testo) {
    return results.includes(origins.TESTO)
  }
  if (environment.BRAND === Brand.Beko) {
    return results.includes(origins.BEKO)
  }
  return true
}

export const cleanXmlJournal = (xml: string): string => {
  return xml.replace(/".*?"/g, (match: string) => {
    const newMatch = match
      .slice(1, -1)
      .replace('&', '&amp;')
      .replace('<', '&lt;')
      .replace('>', `&gt;`)
      .replace('"', '&apos;')
      .replace(`'`, '&quot;')
      .replace('', '')
      .replace('', '')
      .replace('', '')
      .replace('', '')
    return '"' + newMatch + '"'
  })
}

export const processXmlImport = (
  xml: string,
  dataImportText: string,
  cb: (result: DataImportRequest) => void,
  journalPath: string,
): void => {
  try {
    parseString(cleanXmlJournal(xml), (err: Error, result: any) => {
      const journal: JNL[] = result.JOURNAL.JNL.map((jnl: any) => parseJnl(jnl))
      if (!checkOriginAuthorized(journal)) {
        cb({
          error: true,
          companies: [],
        })
      } else {
        const dateString = new Date().toLocaleDateString('de-de', {
          hour: 'numeric',
          minute: 'numeric',
        })
        const companies = journalToImportCompanies(journal)
        companies.forEach((c, i, cs) => {
          const name = `${dataImportText} ${companies.length === 1 ? ' ' : `${i + 1} `}${dateString}`
          cs[i].projects = c.projects.map((p) => {
            p.name = name
            p.description = name
            return p
          })
        })
        cb({
          error: false,
          companies,
        })
      }
    })
  } catch (e) {
    console.error(e)
    cb({
      error: true,
      companies: [],
      journalPath,
    })
  }
}

const convertStringToDate = (date: string, time: string): Date => {
  const dates = date.split('.')
  const times = time.split(':')
  return new Date(
    Number(dates[2]),
    Number(dates[1]) - 1,
    Number(dates[0]),
    Number(times[0]),
    Number(times[1]),
    Number(times[2]),
    0,
  )
}

export const getFirstAndLastDateFromLeakagesFromRequests = (
  request: DataImportRequest | undefined,
): DatePickerDates => {
  if (!request) {
    return {
      first: new Date(),
      last: new Date(),
    }
  }
  const projectsRequest = request.companies
    .reduce((newProjects: any, company: any) => {
      newProjects.push(company.projects)
      return newProjects
    }, [])
    .flat() as ImportProject[]
  const leakagesRequest = projectsRequest
    .reduce((newLeakages: any, project: any) => {
      newLeakages.push(project.leakages)
      return newLeakages
    }, [])
    .flat()
    .sort(
      (a: any, b: any) => convertStringToDate(a.date, a.time).getTime() - convertStringToDate(b.date, b.time).getTime(),
    ) as Leakage[]

  const firstLeakage = leakagesRequest.shift()
  const lastLeakage = leakagesRequest.length === 0 ? firstLeakage : leakagesRequest.pop()

  return {
    first: convertStringToDate(firstLeakage?.date || '', firstLeakage?.time || ''),
    last: convertStringToDate(lastLeakage?.date || '', lastLeakage?.time || ''),
  }
}
