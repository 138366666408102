import { importProject } from '../../electronRenderer/services/fileService'
import { useEffect, useState } from 'react'
import { FileSelectionResult } from '../components/partials/Buttons/SingleFileUploadButton'
import { clearCloudDb, createCompanyExport, createDbExport, createDbImport } from '../dataServices/webDataService'

type ExportDatabaseInfo = {
  content: Buffer
  exportDone: boolean
}

type DatabaseActionInfo = {
  importDone: boolean
  importError: boolean
}

type CompanyExportInfo = {
  exportContent: string
  exportDone: boolean
  exportError: boolean
  exportIncomplete: boolean
}

export const useClearDatabase = (): {
  clearedDatabase: boolean
  clearDatabase: () => Promise<void>
} => {
  const [clearedDatabase, setClearedDatabase] = useState<boolean>(false)

  const clearDatabase = async (): Promise<void> => {
    clearCloudDb()
      .then(() => setClearedDatabase(true))
      .catch((e) => console.error('error clearing db', e))
  }

  return { clearedDatabase: clearedDatabase, clearDatabase }
}

export const useExportDatabase = (doFetch: boolean, projectId: string | undefined = undefined): ExportDatabaseInfo => {
  const [dbExportData, setDbExportData] = useState<Buffer>(new Buffer(''))
  const [exportDone, setDone] = useState<boolean>(false)

  useEffect(() => {
    doFetch &&
      createDbExport(projectId)
        .then((value: Buffer) => {
          setDbExportData(value)
          setDone(true)
        })
        .catch((e) => console.error(e))
  }, [doFetch, projectId])

  return { content: dbExportData, exportDone }
}

export const useImportDatabase = (
  data: FileSelectionResult | undefined,
  uploadProgress?: (progress: number) => void,
): DatabaseActionInfo => {
  const [importDataInfo, setImportDataInfo] = useState<DatabaseActionInfo>({ importDone: false, importError: false })

  useEffect(() => {
    if (data && !importDataInfo.importDone) {
      uploadProgress &&
        createDbImport(data, uploadProgress)
          .then((res) => {
            if (res.status === 200 && res.data) {
              setImportDataInfo({ importDone: true, importError: false })
            } else {
              setImportDataInfo({ importDone: false, importError: true })
            }
          })
          .catch(() => {
            setImportDataInfo({ importDone: false, importError: true })
          })
    }
  }, [data, importDataInfo.importDone, uploadProgress])

  return importDataInfo
}

export const useImportProject = (data: FileSelectionResult | undefined): DatabaseActionInfo => {
  const [importProjectInfo, setImportProjectInfo] = useState<DatabaseActionInfo>({
    importDone: false,
    importError: false,
  })

  useEffect(() => {
    setImportProjectInfo({ importDone: false, importError: false })
    data &&
      importProject(data).then((success) => {
        if (!success) {
          console.error('import failed')
          setImportProjectInfo({ importDone: true, importError: true })
        } else {
          setImportProjectInfo({ importDone: true, importError: false })
        }
      })
  }, [data])

  return importProjectInfo
}

export type CompanyExportParams = {
  measureIDs: string[]
  failureIDs: string[]
  replacementIDs: string[]
  companyIDs: string[]
  exportBuildings: boolean
  exportLocations: boolean
}

export const useCompanyExport = (params: CompanyExportParams): CompanyExportInfo => {
  const [companyExportInfo, setCompanyExportInfo] = useState<CompanyExportInfo>({
    exportDone: false,
    exportContent: '',
    exportError: false,
    exportIncomplete: false,
  })

  // TODO export incomplete attribute currently always false for cloud
  useEffect(() => {
    setCompanyExportInfo({ exportDone: false, exportContent: '', exportError: false, exportIncomplete: false })
    try {
      createCompanyExport(
        params.measureIDs,
        params.failureIDs,
        params.replacementIDs,
        params.companyIDs,
        params.exportBuildings,
        params.exportLocations,
      ).then((response) => {
        setCompanyExportInfo({
          exportDone: true,
          exportContent: response,
          exportError: false,
          exportIncomplete: false,
        })
      })
    } catch (e) {
      console.error(e)
      setCompanyExportInfo({ exportDone: false, exportContent: '', exportError: true, exportIncomplete: false })
    }
  }, [params.companyIDs])

  return companyExportInfo
}
