import React from 'react'
import { useTranslation } from 'react-i18next'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'
import Alert from '@material-ui/lab/Alert'

export type ResultAlertProps = {
  alertText: string
  showAlert: boolean
  modifyShowAlert: (showAlert: boolean) => void
  severity?: 'error' | 'warning' | 'success' | 'info'
  autoHideDuration?: number
}

export const ResultAlert: React.FC<ResultAlertProps> = ({
  showAlert,
  severity,
  autoHideDuration,
  modifyShowAlert,
  alertText,
}) => {
  const { t } = useTranslation('common')

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={showAlert}
      autoHideDuration={autoHideDuration ?? 1200}
      onClose={(event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
          modifyShowAlert(false)
          modifyShowAlert(true)
          // if doubleclick ('clickaway') we want the message to be shown again. Do not leave with modifyShowAlert(false)
          // => return here:
          return
        }
        modifyShowAlert(false)
      }}
    >
      <Alert onClose={() => modifyShowAlert(false)} severity={severity ?? 'success'}>
        {t(alertText)}
      </Alert>
    </Snackbar>
  )
}
