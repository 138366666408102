import React, { useMemo } from 'react'
import Link from '@material-ui/core/Link'
import { useProject } from '../../../hooks/projectHooks'
import { breadcrumbIsBuilding, breadcrumbIsProject } from './navigationHelper'
import { getDisplayNameOfProject } from '../../../../interfaces/BusinessObjects'
import { useStyles } from './NavigationStyle'
import { useCompany } from 'app/hooks/companyHooks'

type NavigationBreadcrumbCompanyTitleProps = NavigationBreadcrumbProps & {
  id: string
}

export const NavigationBreadcrumbCompanyTitle: React.FC<NavigationBreadcrumbCompanyTitleProps> = ({
  id,
  position,
  total,
  onClick,
  text,
}) => {
  const companyId = id !== 'groupsOverview' ? id : ''
  const { company, companyLoading } = useCompany(companyId)
  if (!companyLoading) {
    return (
      <NavigationBreadcrumb
        position={position}
        onClick={onClick}
        total={total}
        text={companyId ? company.name || '' : text}
      />
    )
  } else {
    return <></>
  }
}

export const NavigationBreadcrumbProjectTitle: React.FC<NavigationBreadcrumbCompanyTitleProps> = ({
  id,
  position,
  total,
  onClick,
  text,
}) => {
  const { project, projectLoading } = useProject(id)
  return (
    <>
      {!projectLoading && (
        <NavigationBreadcrumb
          position={position}
          onClick={onClick}
          total={total}
          text={getDisplayNameOfProject(project) || ''}
        />
      )}
    </>
  )
}

type NavigationBreadcrumbProps = {
  position: number
  total: number
  onClick: (e: React.MouseEvent, i: number) => void
  text: string
}
export const NavigationBreadcrumb: React.FC<NavigationBreadcrumbProps> = ({ position, total, onClick, text }) => {
  const classes = useStyles()
  const last = position === total - 1
  const getAbsolutePosition = (): number => {
    return position + 1
  }

  const handleClick = (e: React.MouseEvent): void => {
    e.preventDefault()
    onClick(e, getAbsolutePosition())
  }

  return (
    <Link
      key={text}
      variant={'body2'}
      style={{
        fontWeight: last ? 'bold' : 'normal',
        textDecoration: 'none',
      }}
      className={classes.navigationLinkStyle}
      href="#"
      onClick={(e: React.MouseEvent) => handleClick(e)}
    >
      {text}
    </Link>
  )
}
