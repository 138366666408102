import { Box, MenuItem, Select } from '@material-ui/core'
import React, { useCallback, useEffect } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { createStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import constant from '../../../../constants/constants'
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined'
import { useState } from 'react'
import { useUpdateUserLanguageMutation } from 'api/iam.models'

export const contrast = {
  primary: '#e4f6ed',
  secondary: '#ffffff',
  greyText: '#888888',
}

export const useStyles = makeStyles(() =>
  createStyles({
    select: {
      color: contrast.secondary,
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiOutlinedInput-input.MuiInputBase-input': {
        paddingRight: 30,
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    icon: {
      fill: contrast.primary,
    },
    box: {
      paddingLeft: 60,
    },
  }),
)

const { LANGUAGES } = constant
type LanguageSelectionProps = {
  userLang?: string
}
export const LanguageSelection: React.FC<LanguageSelectionProps> = ({ userLang }) => {
  const { i18n } = useTranslation('common')
  const [languageSelected, setLanguageSelected] = useState<string>(i18n.language)
  const classes = useStyles()

  const [updateLangPreference] = useUpdateUserLanguageMutation()

  const changeLang = useCallback(
    (lng: string): void => {
      i18n
        .changeLanguage(lng)
        .then(() => {
          setLanguageSelected(lng)
          if (lng !== userLang) {
            updateLangPreference({
              variables: {
                lang: lng,
              },
            })
              .then((r) => console.info(r.data?.updateLangaugePreference))
              .catch(() => console.info('lang preference update failed'))
          }
        })
        .catch((e) => console.error('could not change language', e))
    },
    [i18n, updateLangPreference, userLang],
  )

  useEffect(() => {
    if (userLang) {
      changeLang(userLang)
    }
  }, [changeLang, userLang])

  return (
    <Box display="flex" padding={1} className={classes.box}>
      <LanguageOutlinedIcon fontSize="medium" />

      <Select
        className={classes.select}
        variant={'outlined'}
        onChange={({ target }) => changeLang(target.value as string)}
        value={languageSelected}
        inputProps={{
          classes: {
            icon: classes.icon,
          },
        }}
      >
        {LANGUAGES.map((l) => {
          return (
            <MenuItem key={l} value={l}>
              {l.toUpperCase()}
            </MenuItem>
          )
        })}
      </Select>
    </Box>
  )
}

export default LanguageSelection
