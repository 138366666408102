import { Building, Project, ProjectWithLeakages } from './BusinessObjects'
import { FileSelectionImage } from '../app/helpers/dataImport/dataImportTypes'
import {
  ifScfMakeCf,
  isCfUnit,
  UnitCo2,
  UnitCurrency,
  UnitEnergy,
  UnitLeakage,
  UnitPressure,
  UnitVolume,
} from './Units'
import { getDateFromGermanDate } from '../app/helpers/dates'
import * as path from 'path'
import { PersistedBuilding } from '../electronShared/domain/types/types'
import { uniqueNumberValueFromLeakages, uniqueStringValueFromLeakages } from '../app/helpers/projectUtils'
import { DEFAULT_CO2_AMOUNT, kgToLbs } from '../constants/calculationConstants'
import { calculateEnergyCosts, calculateEnergyTotalCosts } from '../app/helpers/calculations'

export enum Role {
  CUSTOMER = 'CUSTOMER',
}
export type ImportedPath = { groupId?: string; companyId?: string; projectId?: string }
export type UpdateNewPath = (path: ImportedPath) => void

export type ImportBuilding = {
  name: string
  id: string
}

export type ImportCompany = {
  name: string
  address: UpdateAddress
  contact: UpdateContact
  buildings: ImportBuilding[]
  projects: ImportProject[]
}

export type ImportProject = {
  costBase?: number
  costTime?: number
  costUnit?: string
  energyAmount?: number
  energyUnit?: UnitEnergy
  unitV?: UnitVolume
  name: string
  description: string
  importDate: string
  leakages: ImportLeakage[]
}

export type UpdateContact = {
  email: string
  phone: string
  prename: string
  surname: string
  role: Role
}

export type UpdateAddress = {
  street: string
  number: string
  zip: string
  city: string
}

export type ImportLeakage = {
  buildingId: string
  commF: string
  commM: string
  commR: string
  commRup: string
  comment: string
  costBase: string
  costRelevantAttributesEdited: boolean
  costTime: number
  costUnit: string
  date: string
  time: string
  distance: number
  gain: number
  gasType: string
  imgName: string
  imgPath: string
  leakTag: number
  level: number
  place: string
  pressure: number
  resCost: number
  resFlag: number
  resLeakage: number
  resUnitL: UnitLeakage
  sens2x: number
  sensivity: number
  sn: number
  sw: number
  threshold: number
  unitD: string
  unitP: UnitPressure
  unitV: UnitVolume
  unitC: UnitCurrency
  version: string
  uid: string
  costElPr?: number
  costSpPw?: number
  costUspPw?: number
  costSpPwId?: string
  costCmod?: string
  costSigE3?: string
}

export const createEmptyAddress = (): UpdateAddress => {
  return {
    street: '',
    number: '',
    zip: '',
    city: '',
  }
}
export const createEmptyContact = (): UpdateContact => {
  return {
    email: '',
    phone: '',
    prename: '',
    surname: '',
    role: Role.CUSTOMER,
  }
}

export const importProjectToAppProject = (importProject: ImportProject, company: string): Project => {
  const leakages = importProject.leakages

  // function getElement<T, K extends keyof T>(leakage: T, key: K): T[K] {
  //   return leakage[key]
  // }

  // function uniqueElement<T, K extends keyof T>(leakages: T, key: K): T[K][] {
  //   return leakages
  //     .map((leakage) => {
  //       const element = getElement(leakage, key)
  //       return typeof element === 'number' ? element : undefined
  //     })
  //     .filter(isUniqueType)
  // }

  // standard mode parameters TODO: verify that SCF can be overwritten by CF
  const projectUnitV = ifScfMakeCf(importProject.unitV || uniqueStringValueFromLeakages(leakages, 'unitV'))

  let projectCostBase = importProject.costBase?.toString() || uniqueStringValueFromLeakages(leakages, 'costBase')
  const projectCostTime = importProject.costTime || uniqueNumberValueFromLeakages(leakages, 'costTime')
  const projectCostUnit = importProject.costUnit || uniqueStringValueFromLeakages(leakages, 'costUnit')

  const projectCo2Unit = projectUnitV && isCfUnit(projectUnitV) ? UnitCo2.LBS_KWH : UnitCo2.KG_KWH
  const projectCo2Amount = projectUnitV && (isCfUnit(projectUnitV) ? DEFAULT_CO2_AMOUNT * kgToLbs : DEFAULT_CO2_AMOUNT)

  // expert mode parameters
  const costElPr = uniqueNumberValueFromLeakages(leakages, 'costElPr')
  const costUSpPw = uniqueNumberValueFromLeakages(leakages, 'costUspPw')
  const costSpPw = uniqueNumberValueFromLeakages(leakages, 'costSpPw')
  const costSpPwId = uniqueStringValueFromLeakages(leakages, 'costSpPwId')
  const costCMod = uniqueStringValueFromLeakages(leakages, 'costCmod')
  const costSigE3 = uniqueStringValueFromLeakages(leakages, 'costSigE3')

  if (costCMod) {
    if (costCMod.endsWith('1') && costSpPw && costElPr) {
      projectCostBase = calculateEnergyCosts(
        projectUnitV,
        costSpPw,
        costElPr,
        projectCostUnit || '',
      ).resultAsFormattedString
    }
    if (costCMod.endsWith('3') && costSpPw && costElPr) {
      projectCostBase = calculateEnergyTotalCosts(
        projectUnitV,
        costSpPw,
        costElPr,
        projectCostUnit || '',
      ).resultAsFormattedString
    }
  }

  const cmodStandard: boolean = costCMod?.endsWith('0') || false

  const result = {
    name: importProject.name,
    description: importProject.description,
    co2Unit: projectCo2Unit ?? '',
    co2Amount: projectCo2Amount?.toString() ?? '',
    costBase: projectCostBase ?? '',
    costTime: projectCostTime?.toString() ?? '',
    costUnit: projectCostUnit ?? '',
    unitV: projectUnitV ?? '',
    energyAmount: `${costSpPw || importProject.energyAmount || ''}`,
    energyUnit: importProject.energyUnit ?? '',
    energyCost: cmodStandard ? '' : costElPr?.toString() || '',
    company: company,
    elPr: costElPr,
    spPw: costSpPw,
    spPwId: costSpPwId,
    uSpPw: costUSpPw,
    cMod: costCMod,
    sigE3: costSigE3,
  }

  return result
}

const stringify = (s: number | string | Role): string => `${s}`

export const importBuildingToAppBuilding = (importBuilding: ImportBuilding, company: string): Building => {
  return {
    name: importBuilding.name,
    company,
  }
}

export const importProjectToAppProjectWithLeakages = (
  importProject: ImportProject,
  company: ImportCompany,
  persistedBuildings: PersistedBuilding[],
  images: FileSelectionImage[] | undefined,
  uids: string[] | undefined,
  targetCompanyName: string,
  journalPath: string | undefined,
): ProjectWithLeakages => {
  const result: ProjectWithLeakages = importProjectToAppProject(importProject, targetCompanyName || company.name)
  const leakages = importProject.leakages
  const journalDir = path.dirname(journalPath || '')
  result.leakages = leakages
    .filter((l) => !uids || uids.includes(l.uid))
    .map((l) => {
      const image = images
        ? images.find((i) =>
            i.name.toLowerCase().endsWith(path.join(journalDir || '', l.imgPath, l.imgName).toLowerCase()),
          )?.contents
        : ''
      const date = getDateFromGermanDate(l.date + ' ' + l.time)
      const existingBuildingName = company.buildings.filter((building) => building.id === l.buildingId).pop()?.name
      const persistedBuildingId = persistedBuildings
        .filter((persistedBuilding) => persistedBuilding.name === existingBuildingName)
        .pop()?.id
      return {
        buildingId: persistedBuildingId || l.buildingId,
        //place: l.place.,
        leakTag: stringify(l.leakTag),
        level: stringify(l.level),
        pressure: stringify(l.pressure),
        unitP: l.unitP,
        distance: stringify(l.distance),
        unitD: l.unitD,
        costBase: l.costBase,
        costUnit: l.costUnit,
        date: !isNaN(date.getTime()) ? date.toISOString() : '',
        time: l.time,
        costTime: stringify(l.costTime),
        resLeakage: stringify(l.resLeakage),
        resUnitL: stringify(l.resUnitL),
        lossCosts: stringify(l.resCost),
        actions: '',
        status: l.commRup && l.commRup.startsWith('0x1') ? 'actions.completed' : 'actions.inProgress',
        priority: '',
        comment: l.comment,
        solved_at: '',
        solved_by: '',
        unitV: l.unitV,
        commR: l.commR,
        commM: l.commM,
        commF: l.commF,
        commRup: l.commRup && l.commRup.endsWith('1') ? 'actions.yes' : l.commRup.endsWith('2') ? 'actions.no' : '',
        imagesAsBase64: image ? [{ checked: true, _originalImage: true, src: image }] : undefined,
      }
    })
  return result
}

export type DataImportTarget = {
  companyName?: string
  projectId?: string
  groupId?: string
}

export type DatePickerDates = {
  first: Date
  last: Date
}
