import Modal from '@material-ui/core/Modal'
import React, { useState } from 'react'
import { TextField, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import CsButton from '@csinstruments/cs-react-theme/dist/components/Buttons/CsButton'
import Grid from '@material-ui/core/Grid/Grid'
import { paddingTop, paddingTopBottom } from '../../../../styles/Shared'
import { useStyles } from './RenameTextModalStyles'

type RenameTextModalProps = {
  handleConfirm: (text: string, newText: string) => void
  open: boolean
  handleClose: () => void
  text: string
}

export const RenameTextModal: React.FC<RenameTextModalProps> = ({ handleConfirm, open, text, handleClose }) => {
  const { t } = useTranslation('common')
  const [newText, setNewText] = useState('')
  const classes = useStyles()

  const confirmed = (): void => {
    handleConfirm(text, newText)
  }

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.paper}>
        <Typography variant={'h4'}>{t('exportMaintenance.rename')}</Typography>
        <Grid container spacing={2} style={paddingTopBottom}>
          <Grid item xs={12}>
            {' '}
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth={true} label={t('exportMaintenance.oldName')} value={text} disabled />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth={true}
              label={t('exportMaintenance.newName')}
              onChange={(e) => setNewText(e.target.value as string)}
            />
          </Grid>
          <Grid item xs={12} style={paddingTop}>
            {' '}
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={5}>
            <CsButton fullwidth={true} onClick={handleClose}>
              {t('modals.cancel')}
            </CsButton>
          </Grid>
          <Grid item xs={5}>
            <CsButton
              fullwidth={true}
              onClick={() => {
                confirmed()
                handleClose()
              }}
              disabled={!text || !newText}
              solid={true}
            >
              {t('modals.confirm')}
            </CsButton>
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}
