import React, { useEffect, useMemo } from 'react'
import { generatePath, Link, Route, useHistory, useParams } from 'react-router-dom'
import { ExportMaintenanceTypes } from '../../../../interfaces/PageParams'
import { ROUTES } from '../../../helpers/routes'
import { TabbedPage } from '../../partials/TabbedPage/TabbedPage'
import { ExportMaintenanceTypeView } from './ExportMaintenanceTypeView'
import { FAILURE, MEASURE, REPLACEMENT } from '../../../../interfaces/BusinessObjects'
import { usePermissions } from '../../../hooks/usePermissions'
import CsButton from '@csinstruments/cs-react-theme/dist/components/Buttons/CsButton'
import { useTranslation } from 'react-i18next'

type Subpage = 'FAILURE' | 'MEASURE' | 'REPLACEMENT'

enum Subpages {
  MEASURE,
  FAILURE,
  REPLACEMENT,
}

export const ExportMaintenance: React.FC = ({}) => {
  const { t } = useTranslation('common')
  const { type } = useParams<ExportMaintenanceTypes>()
  const permissions = usePermissions()

  const history = useHistory()
  const chosenTab: Subpages = type && type in Subpages ? Subpages[type as Subpage] : 0

  const tabChanged = (v: number): void => {
    history.push(generatePath(ROUTES.exportMaintenance, { type: Subpages[v] }))
  }

  useEffect(() => {
    if (!type) {
      history.push(generatePath(ROUTES.exportMaintenance, { type: Subpages[0] }))
    }
  }, [type, history])

  const permission = useMemo(() => {
    return permissions.exportManagement
  }, [permissions])

  return (
    <>
      <Link style={{ textDecoration: 'none' }} to={permission ? ROUTES.dataExport : history.location}>
        <CsButton
          fullwidth={false}
          solid={true}
          label={t('actions.doDataExport')}
          color={'primary'}
          disabled={!permission}
        />
      </Link>
      <TabbedPage
        chosenTab={chosenTab}
        tabChanged={tabChanged}
        labels={['exportMaintenance.measure', 'exportMaintenance.failure', 'exportMaintenance.replacement']}
      >
        <>
          <Route
            exact
            path={ROUTES.exportMaintenanceMeasure}
            render={() => <ExportMaintenanceTypeView type={MEASURE} />}
          />
          <Route
            exact
            path={ROUTES.exportMaintenanceFailure}
            render={() => <ExportMaintenanceTypeView type={FAILURE} />}
          />
          <Route
            exact
            path={ROUTES.exportMaintenanceReplacement}
            render={() => <ExportMaintenanceTypeView type={REPLACEMENT} />}
          />
        </>
      </TabbedPage>
    </>
  )
}
