/* eslint-disable @typescript-eslint/no-explicit-any */
import { Leakage, Project, ProjectWithLeakageInfo, ProjectWithLeakages } from '../../interfaces/BusinessObjects'
import { /*  useContext, */ useEffect, useMemo, useState } from 'react'
import {
  UpdateProject,
  useCreateOrUpdateProjectMutation,
  useDeletedProjectsQuery,
  useDeleteProjectMutation,
  useFinalDeleteProjectMutation,
  useGetCompanyProjectQuery,
  useGetProjectByIdQuery,
  useRestoreProjectMutation,
  Auditor,
  AuditorInput,
  useCreateOrUpdateAuditorMutation,
  useDeleteAuditorMutation,
  useGetAuditorsQuery,
  useRestoreAuditorMutation,
  useSoftDeleteAuditorMutation,
  useGetDeletedAuditorsQuery,
  useGetProjectAuditorsQuery,
  useGetAuditorQuery,
} from '../../api/models'
import {
  /* convertLeakage, */ convertProject,
  projectToUpdateProject,
  ServerProject,
} from './hookHelpers/serverResponseConversion'
import { getFirstLastLeakages } from './hookHelpers/logic'
//import { unitVToUnitL } from '../../interfaces/Units'
//import { UserAccessContext } from '../components/partials/UserProvider/UserProvider'
//import { convertAmount, totalCosts, calcTotalSumsForLeakages } from '../helpers/calculations'
import { TFunction } from 'i18next'
import { getSelectedRealm } from 'app/helpers/manageRealms'
import { totalLeakageAmount } from '../../interfaces/BusinessObjects'

export const useCreateProject = (): {
  createdProject: Project
  createProject: (project: Project) => Promise<void>
  errorMessage?: string
} => {
  const { updateProject, updatedProject, errorMessage } = useCreateOrUpdateProject()

  return { createProject: updateProject, createdProject: updatedProject, errorMessage: errorMessage }
}

export const useProjectsOfCompany = (
  companyName: string,
): { projects: Project[]; loading: boolean; error: boolean } => {
  const { data, loading, error } = useGetCompanyProjectQuery({
    variables: { companyName, realmName: getSelectedRealm() || '' },
    skip: !companyName || !getSelectedRealm(),
  })

  const projects = useMemo(() => {
    if (!data || !!error || !data?.getCompany?.company?.projects) {
      return []
    }
    const dataProjects = data.getCompany.company.projects

    return dataProjects.projects?.map((dp) => {
      return {
        _id: dp.id,
        name: dp.name ?? '',
        created: new Date(dp.importDate ?? ''),
        nLeakages: dp.leakages?.length,
        sumLeakages: dp.totalLeakage,
      }
    })
  }, [data, error])

  return {
    projects,
    loading,
    error: !!error,
  }
}

export const transformProjects = (
  project: ProjectWithLeakages[],
  excludedIds?: string[],
  t?: TFunction,
): ProjectWithLeakages[] => {
  return project
    .map((p: ProjectWithLeakages) => {
      let firstLastLeakages: [Leakage, Leakage] = [{}, {}]
      let resUnitL = undefined
      let sumLeakages = ''
      if (p.leakages && p.leakages.length > 0) {
        resUnitL = p.leakages[0].resUnitL
        firstLastLeakages = getFirstLastLeakages(p.leakages)
      }
      if (p.leakages && p.leakages.length > 0 && t) {
        // const calCLeakages = totalCosts(p?.leakages, p as Project, t)
        //   .map((cost: any) => cost.text)
        //   .join(' - ')
        // const transformedTotalLeakage = convertAmount(`${p.totalLeakage}`, resUnitL, p.unitV, t).text
        // sumLeakages = p.totalLeakage ? transformedTotalLeakage : calCLeakages
        const tla = totalLeakageAmount(p.leakages, p)
        sumLeakages = tla[0].value.toFixed(3) + ' ' + tla[0].unit
      }

      const pWithInfo: ProjectWithLeakageInfo = Object.assign({}, p)
      pWithInfo.resUnitL = resUnitL
      pWithInfo.nLeakages = p.leakages?.length ?? 0
      pWithInfo.sumLeakages = sumLeakages
      if (firstLastLeakages[0].date && firstLastLeakages[1].date) {
        pWithInfo.firstDateTime = `${new Date(firstLastLeakages[0].date.substring(0, 19)).toLocaleDateString('de-de', {
          hour: 'numeric',
          minute: 'numeric',
        })}`
        pWithInfo.lastDateTime = `${new Date(firstLastLeakages[1].date.substring(0, 19)).toLocaleDateString('de-de', {
          hour: 'numeric',
          minute: 'numeric',
        })}`
      }
      return pWithInfo
    })
    .filter((o: any) => !(excludedIds || []).includes(o._id || ''))
}

export const useProjectsWithLeakageInfo = (
  companyName: string,
  excludedIds?: string[],
  groupId?: string,
  t?: TFunction,
): { projects: ProjectWithLeakageInfo[]; loading: boolean; error: boolean; canAdd: boolean } => {
  const { data, loading, error } = useGetCompanyProjectQuery({
    variables: { companyName: decodeURIComponent(companyName), realmName: getSelectedRealm(), groupId: groupId },
    skip: !companyName || !getSelectedRealm(),
  })

  const canAdd = useMemo(() => {
    return !!data?.getCompany?.company?.projects?.meta.canAdd
  }, [data])

  const projectWithLeakages = useMemo(() => {
    if (!data || !!error || !data?.getCompany?.company?.projects) {
      return []
    }
    const dataProjects = data.getCompany.company?.projects.projects.filter((p) => p)

    const result = dataProjects.map((dp) => {
      return {
        ...dp,
        _id: dp.id,
        name: dp.name ?? '',
        unitV: dp.unitV ?? '',
        leakages:
          dp.leakages?.map((l) => {
            return {
              date: l.date ?? '',
              resLeakage: `${l.resLeakage}`,
              resUnitL: l.resUnitL ?? '',
              unitV: dp.unitV ?? '',
            }
          }) || [],
      }
    }) as ProjectWithLeakages[]

    //console.log('p', result)
    return result
  }, [data, error])

  //

  //     const result: ProjectWithLeakageInfo[] = dataProjects.map((dp) => {
  //       let firstLastLeakages: [Leakage, Leakage] = [{}, {}]
  //       let resUnitL = ''
  //       const datetimes: [string, string] = ['', '']
  //       if (dp.leakages && dp.leakages.length > 0) {
  //         resUnitL = unitVToUnitL(dp.unitV?.toString()) ?? dp.leakages[0].resUnitL ?? ''

  //         const leakages = dp.leakages.map((l) => convertLeakage(l))

  //         firstLastLeakages = getFirstLastLeakages(leakages)
  //         if (firstLastLeakages[0].date && firstLastLeakages[1].date) {
  //           datetimes[0] = `${new Date(firstLastLeakages[0].date).toLocaleDateString('de-de', {
  //             hour: 'numeric',
  //             minute: 'numeric',
  //           })}`
  //           datetimes[1] = `${new Date(firstLastLeakages[1].date).toLocaleDateString('de-de', {
  //             hour: 'numeric',
  //             minute: 'numeric',
  //           })}`
  //         }
  //       }

  //       const totalLeakage =
  //         dp.leakages?.reduce((prev, curr) => {
  //           return (
  //             prev +
  //             getLeakageFromUnit(`${curr.resLeakage}`, curr.resUnitL ? curr.resUnitL.toString() : '', dp.unitV ?? '')
  //           )
  //         }, 0) ?? 0

  //       return {
  //         _id: dp.id,
  //         name: dp.name ?? '',
  //         created: new Date(dp.importDate ?? ''),
  //         nLeakages: dp.leakages?.length,
  //         sumLeakages: `${totalLeakage.toFixed(2) ?? 0}`,
  //         resUnitL: `${resUnitL}`,
  //         firstDateTime: datetimes[0],
  //         lastDateTime: datetimes[1],
  //       }
  //     })

  //     return result.filter((o) => !(excludedIds || []).includes(o._id || ''))
  //   }, [data, error, excludedIds])

  return {
    projects: transformProjects(projectWithLeakages, excludedIds, t),
    loading,
    error: !!error,
    canAdd,
  }
}

export const useDeletedProjects = (skipFetch?: boolean): { projects: Project[]; loading: boolean; error: boolean } => {
  const { data, loading, error } = useDeletedProjectsQuery({
    variables: { realmName: getSelectedRealm() || '' },
    skip: !getSelectedRealm() || !!skipFetch,
  })
  const projects: Project[] = useMemo(() => {
    return (
      data?.deletedProjects.map((dp) => {
        return {
          name: dp.name ?? '',
          _id: dp.id,
          path: dp.groupPath?.map((pname) => pname).slice(0, dp.groupPath.length) ?? undefined,
          _deleted_at: dp._deleted_at ? new Date(dp._deleted_at) : new Date(0),
        }
      }) ?? []
    )
  }, [data])
  return {
    projects,
    loading,
    error: !!error,
  }
}

export const useFinalDeleteProject = (): {
  deletedProject: boolean
  deleteProject: (id: string) => Promise<void>
} => {
  const [deleteMutation] = useFinalDeleteProjectMutation()
  const [deletedProject, setDeletedProject] = useState<boolean>(false)

  const deleteProject = async (id: string): Promise<void> => {
    setDeletedProject(false)
    if (!id) {
      console.error('project without ID -- skipping')
      return
    }
    await deleteMutation({ variables: { id, realmName: getSelectedRealm() || '' } })
    setDeletedProject(true)
  }

  return { deleteProject, deletedProject }
}

export const useRestoreProject = (): {
  restoredProject: boolean
  restoreProject: (name: string) => Promise<void>
} => {
  const [restoreMutation] = useRestoreProjectMutation()
  const [restoredProject, setRestoredProject] = useState<boolean>(false)

  const restoreProject = async (id: string): Promise<void> => {
    setRestoredProject(false)
    if (!id) {
      console.error('project without ID -- skipping')
      return
    }
    await restoreMutation({ variables: { id, realmName: getSelectedRealm() || '' } })
    setRestoredProject(true)
  }

  return { restoreProject, restoredProject }
}

const useCreateOrUpdateProject = (): {
  updatedProject: Project
  updateProject: (project: Project) => Promise<void>
  errorMessage?: string
} => {
  const [project, setProject] = useState<Project>({})
  const [createOrUpdateProjectMutation, { data, error }] = useCreateOrUpdateProjectMutation()

  const updateProject = async (project: Project): Promise<void> => {
    setProject({})
    if (!project.company) {
      console.error('project has no company')
      return
    }
    //console.log('project', project)
    const up: UpdateProject = projectToUpdateProject(project)
    try {
      await createOrUpdateProjectMutation({ variables: { updateProject: up, realmName: getSelectedRealm() || '' } })
    } catch (e) {
      console.log('error creating/updating project ' + e)
    }
  }

  useEffect(() => {
    if (data && data.createOrUpdateProject?.name) {
      setProject({ name: data.createOrUpdateProject?.name, _id: data.createOrUpdateProject.id })
    }
  }, [data])
  return { updatedProject: project, updateProject, errorMessage: error?.message }
}

export const useUpdateProject = (): {
  updatedProject: boolean
  updateProject: (project: Project) => Promise<void>
} => {
  const { updatedProject, updateProject } = useCreateOrUpdateProject()

  return { updateProject, updatedProject: !!updatedProject?._id }
}

export const useProject = (id?: string): { project: Project; projectLoading: boolean; error: boolean } => {
  const { data, loading, error } = useGetProjectByIdQuery({
    variables: { id: id ?? '', realmName: getSelectedRealm() },
    skip: !id || !getSelectedRealm(),
  })

  const project = useMemo(() => {
    if (!data?.getProject?.id || !id) {
      return {}
    }
    return convertProject(data.getProject as ServerProject)
  }, [data?.getProject?.id, id])

  return {
    project,
    projectLoading: loading,
    error: !!error,
  }
}

export const useDeleteProject = (): {
  deleted: boolean
  deleteProject: (id: string) => Promise<void>
} => {
  const [deleteMutation] = useDeleteProjectMutation()
  const [deletedProject, setDeletedProject] = useState<boolean>(false)

  const deleteProject = async (id: string): Promise<void> => {
    setDeletedProject(false)
    if (!id) {
      console.error('project without ID -- skipping')
      return
    }
    await deleteMutation({ variables: { id, realmName: getSelectedRealm() || '' } })
    setDeletedProject(true)
  }

  return { deleteProject, deleted: deletedProject }
}

export const useGetAuditor = (
  auditorID: string,
): {
  auditorInfo?: Auditor
  err?: boolean
} => {
  const { data, error } = useGetAuditorQuery({
    variables: {
      realmName: getSelectedRealm(),
      auditor_id: auditorID,
    },
    skip: !getSelectedRealm() || !auditorID,
  })

  const auditor = useMemo(() => {
    if (data?.getAuditor) {
      let cleanAuditor: Auditor = { id: '', company: '', display_name: '' }
      for (const [k, v] of Object.entries(data.getAuditor)) {
        cleanAuditor = { ...cleanAuditor, [k]: v && v !== 'null' ? v : '' }
      }
      return cleanAuditor
    }
    return undefined
  }, [data?.getAuditor])
  return {
    auditorInfo: auditor,
    err: !!error,
  }
}

export type ProjectAuditor = {
  id: string
  name: string
  company: string
}

export const useGetProjectAuditors = (): {
  projectAuditors: ProjectAuditor[]
  err?: boolean
} => {
  const { data, error } = useGetProjectAuditorsQuery({
    variables: {
      realmName: getSelectedRealm(),
    },
    skip: !getSelectedRealm(),
  })

  const auditor = useMemo(() => {
    if (data?.getAuditors) {
      return data.getAuditors.map((a) => ({
        id: a?.id as string,
        name: a?.display_name as string,
      })) as ProjectAuditor[]
    }
  }, [data?.getAuditors])
  return {
    projectAuditors: auditor ?? [],
    err: !!error,
  }
}
export const useGetAuditors = (): {
  auditors: Auditor[]
  auditorError?: boolean
} => {
  const [auditors, setAuditors] = useState<Auditor[]>([])
  const [err, setErr] = useState<boolean>(false)
  const { data } = useGetAuditorsQuery({
    variables: {
      realmName: getSelectedRealm(),
    },
    skip: !getSelectedRealm(),
  })
  useEffect(() => {
    if (data?.getAuditors.length) {
      setAuditors(data.getAuditors as Auditor[])
    } else {
      setErr(true)
    }
  }, [data?.getAuditors])
  return {
    auditors: auditors,
    auditorError: err,
  }
}

export const useCreateUpdateAuditor = (): {
  confirmCreate: (_auditor: AuditorInput) => Promise<void>
  error?: boolean
  success?: boolean
} => {
  const [createUpdateError, setCreateUpdateError] = useState<boolean>(false)
  const [createAuditor, { data, error }] = useCreateOrUpdateAuditorMutation()
  const createAud = async (auditorInp: AuditorInput): Promise<void> => {
    try {
      if (!auditorInp.display_name) {
        return
      }
      await createAuditor({
        variables: {
          auditor: auditorInp,
          realmName: getSelectedRealm(),
        },
      })
    } catch (er) {
      //console.log(`error creating auditor`, er)
      setCreateUpdateError(true)
    }
  }
  return {
    confirmCreate: createAud,
    error: createUpdateError || !!error?.message,
    success: !!data?.createOrUpdateAuditor,
  }
}

export const useSoftDeleteAuditor = (): {
  confirmSoftDelete: (id: string) => Promise<void>
  success?: boolean
  error?: boolean
} => {
  const [deleteAuditor, { data, error }] = useSoftDeleteAuditorMutation()
  const confirmDelete = async (id: string): Promise<void> => {
    try {
      if (!id) {
        return
      }
      await deleteAuditor({
        variables: {
          auditor_id: id,
          realmName: getSelectedRealm(),
        },
      })
    } catch (error) {}
  }
  return {
    confirmSoftDelete: confirmDelete,
    success: !!data?.softDeleteAuditor,
    error: !!error,
  }
}

export const useFinalDeleteAuditor = (): {
  confirmDelete: (id: string) => Promise<void>
  success?: boolean
  error?: boolean
} => {
  const [finalDelAuditor, { data, error }] = useDeleteAuditorMutation()
  const confirmDelete = async (id: string): Promise<void> => {
    try {
      if (!id) {
        return
      }
      await finalDelAuditor({
        variables: {
          auditor_id: id,
          realmName: getSelectedRealm(),
        },
      })
    } catch (error) {}
  }
  return {
    confirmDelete: confirmDelete,
    success: !!data?.deleteAuditor,
    error: !!error,
  }
}

export const useRestoreAuditor = (): {
  restoreAuditor: (id: string) => Promise<void>
  success?: boolean
  error?: boolean
} => {
  const [restore, { data, error }] = useRestoreAuditorMutation()
  const confirmRestore = async (id: string): Promise<void> => {
    try {
      await restore({
        variables: {
          auditor_id: id,
          realmName: getSelectedRealm(),
        },
      })
    } catch (error) {}
  }
  return {
    restoreAuditor: confirmRestore,
    success: !!data?.restoreAuditor,
    error: !!error,
  }
}
export type DeletedAuditor = {
  _id: string
  name: string
  _deleted_at?: Date
}
export const useDeletedAuditors = (
  skipFetch?: boolean,
): {
  auditors: DeletedAuditor[]
  auditorError?: boolean
} => {
  const [auditors, setAuditors] = useState<Auditor[]>([])
  const [err, setErr] = useState<boolean>(false)
  const { data } = useGetDeletedAuditorsQuery({
    variables: {
      realmName: getSelectedRealm(),
    },
    skip: !getSelectedRealm() || !!skipFetch,
  })
  useEffect(() => {
    if (data?.getDeletedAuditors.length) {
      setAuditors(data?.getDeletedAuditors as Auditor[])
    } else {
      setErr(true)
    }
  }, [data?.getDeletedAuditors])
  return {
    auditors: auditors.map((a) => ({
      _id: a.id,
      name: a.display_name,
      _deleted_at: a._deleted_at ? new Date(a._deleted_at) : new Date(0),
    })) as DeletedAuditor[],
    auditorError: err,
  }
}
