import React, { useEffect, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { paddingBottomActionBar, paddingTop, standardContentPadding } from '../../../../styles/Shared'
import { StandardConfirmationModal } from '../../partials/StandardConfirmationModal/StandardConfirmationModal'
import { PageHeading } from '../../partials/PageHeading'
import { TranslatedCsButton } from '../../partials/Buttons/TranslatedCsButton'
import { Cancel, CloudUpload, Fingerprint, Launch, FileCopy } from '@material-ui/icons'
import { environment } from '../../../../constants/environment'
import {
  APP_KEY,
  useDeactivateLicense,
  useLicense,
  useSoftwareMayBeUsed,
  useUUIDForLicense,
} from '../../../hooks/useLicense'
import { Alert } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import { ProgressBar } from '../../partials/ProgressBar/ProgressBar'
import { usePushDownloadFile } from '../../../hooks/usePushDownloadFile'
import { SingleFileSelectButton } from '../../partials/Inputs/SingleFileSelectButton'
import { getClipboard } from '../../../../electronShared/domain/repositories/shared/shared'
import { ResultAlert } from '../../partials/ResultAlert'

type Props = {
  showTestVersionExpired?: boolean
}

export const License: React.FC<Props> = ({ showTestVersionExpired }) => {
  const clipboard = getClipboard()
  const [copyToClipboard, setCopyToClipboard] = useState(false)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const uuid = useUUIDForLicense()
  const { t } = useTranslation('common')
  const [keepRefreshing, setKeepRefreshing] = useState(false)
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const [offlineActivationString, setOfflineActivationString] = useState('')
  const { daysTestVersionRemaining, mayBeUsed, activated, loading } = useSoftwareMayBeUsed(
    keepRefreshing,
    offlineActivationString,
  )
  const license = useLicense()
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [confirmationOfflineModalOpen, setConfirmationOfflineModalOpen] = useState(false)
  const softwareInfoAsB64 = useMemo(() => {
    const os = require('os')
    const hostname = os.hostname()
    const systemInfo = { hostname, uuid, licenseNames: [APP_KEY] }
    const systemInfoStr = JSON.stringify(systemInfo)
    return Buffer.from(systemInfoStr).toString('base64')
  }, [uuid])
  const offlineDeactivationAsB64 = useMemo(() => {
    return Buffer.from(uuid).toString('base64')
  }, [uuid])
  const {
    deactivated,
    deactivate,
    deactivateOffline,
    loading: deactivateLoading,
    error: deactivateError,
  } = useDeactivateLicense()
  const { downloadAction } = usePushDownloadFile(softwareInfoAsB64, false, 'license-info.txt')
  const { downloadAction: downloadActionForDeactivationFile } = usePushDownloadFile(
    offlineDeactivationAsB64,
    false,
    'license-deactivation.txt',
  )

  const downloadDeactivationFile = (): void => {
    downloadActionForDeactivationFile()
    deactivateOffline()
  }

  const activateLicense = (): void => {
    window.open(
      `${environment.LICENSE_FRONTEND_URL}/activate-license?systemInfo=${softwareInfoAsB64}`,
      'width=1400,height=500',
    )
    setKeepRefreshing(true)
  }

  useEffect(() => {
    if (activated) {
      setKeepRefreshing(false)
    }
  }, [activated])

  const updateOnlineStatus = (): void => {
    setIsOnline(navigator.onLine)
  }

  const handleCopyToClipboard = (licenseKey: string | undefined): void => {
    if (licenseKey) {
      clipboard.writeText(licenseKey)
      setCopyToClipboard(true)
    }
  }

  window.addEventListener('online', updateOnlineStatus)
  window.addEventListener('offline', updateOnlineStatus)

  //console.log('isOnline', { isOnline })

  return (
    <div style={{ ...paddingTop, ...paddingBottomActionBar }}>
      <ResultAlert
        alertText={'actions.updateSuccess'}
        severity={'info'}
        showAlert={copyToClipboard}
        modifyShowAlert={setCopyToClipboard}
      />
      <StandardConfirmationModal
        handleConfirm={() => console.log('confirmed')}
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
      />
      <StandardConfirmationModal
        handleConfirm={() => deactivate(uuid)}
        text={`${t('modals.deactivate')} ${license.key}`}
        open={confirmationModalOpen}
        handleClose={() => setConfirmationModalOpen(false)}
      />
      <StandardConfirmationModal
        handleConfirm={() => downloadDeactivationFile()}
        text={`${t('modals.deactivate')} ${license.key}`}
        open={confirmationOfflineModalOpen}
        handleClose={() => setConfirmationOfflineModalOpen(false)}
      />
      <div style={standardContentPadding}>
        <PageHeading title={'objects.license'} />
        <div style={{ paddingBottom: 20 }}>
          <ProgressBar show={loading || deactivateLoading || (keepRefreshing && !activated)} />
        </div>
        {(!loading || keepRefreshing) && (
          <Grid spacing={3} container>
            <Grid item xs={12}>
              {showTestVersionExpired && !activated && !mayBeUsed && (
                <Alert severity="warning">{t('license.expirationHint')}</Alert>
              )}
              {activated && !deactivated && <Alert severity="success">{t('license.isActivatedHint')}</Alert>}
              {mayBeUsed && !activated && (
                <Alert severity="info">
                  {t('license.testVersionHint')} {Math.ceil(daysTestVersionRemaining)}
                </Alert>
              )}
            </Grid>
            {!activated && isOnline && (
              <>
                <Grid item xs={5}>
                  <TranslatedCsButton label="license.activateLicense" solid fullwidth onClick={activateLicense}>
                    <Launch />
                  </TranslatedCsButton>
                </Grid>
                <Grid item>
                  <Grid container style={{ marginBottom: '30px', marginTop: '15px' }}>
                    <Grid item>
                      <Typography variant={'body1'}>{t('license.browserNotOpenHint')}</Typography>
                    </Grid>
                    <Grid item style={{ marginTop: '15px' }}>
                      {clipboard && (
                        <TranslatedCsButton
                          onClick={() =>
                            handleCopyToClipboard(
                              `${environment.LICENSE_FRONTEND_URL}/activate-license?systemInfo=${softwareInfoAsB64}`,
                            )
                          }
                          label={'actions.copy'}
                        >
                          <FileCopy />
                        </TranslatedCsButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {!activated && isOnline && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h5">{t('license.offlineActivation')}</Typography>
                  <Typography variant="body1" display="inline" component="div">
                    <div style={{ whiteSpace: 'pre-wrap' }}>
                      {t('license.activationLinkHint')}
                      <a target="_blank" href={`${environment.LICENSE_FRONTEND_URL}/activate-license`} rel="noreferrer">
                        {`${environment.LICENSE_FRONTEND_URL}/activate-license`}
                      </a>
                    </div>
                  </Typography>
                  <Typography variant="body1" display="inline" component="div">
                    <div style={{ whiteSpace: 'pre-wrap' }}>{t('license.activationCodeHint')}</div>
                  </Typography>
                </Grid>

                <Grid item xs={5}>
                  <TranslatedCsButton label="license.downloadIdentification" solid fullwidth onClick={downloadAction}>
                    <Fingerprint />
                  </TranslatedCsButton>
                </Grid>

                <Grid item xs={5}>
                  <SingleFileSelectButton
                    acceptedType="text/*"
                    buttonProps={{ solid: true }}
                    label={t('license.useActivationFile')}
                    changeHandler={(contents) => {
                      setOfflineActivationString(contents)
                    }}
                    icon={CloudUpload}
                  />
                </Grid>
              </>
            )}
            {activated && isOnline && !deactivated && (
              <Grid item xs={12}>
                {license.key && (
                  <Grid container>
                    <Grid item>
                      <Typography style={{ marginBottom: '30px' }}>{`${t('license.yourLicense')} ${
                        license.key
                      }`}</Typography>
                    </Grid>
                    <Grid item style={{ marginLeft: '30px' }}>
                      {license.key && clipboard && (
                        <TranslatedCsButton onClick={() => handleCopyToClipboard(license.key)} label={'actions.copy'}>
                          <FileCopy />
                        </TranslatedCsButton>
                      )}
                    </Grid>
                  </Grid>
                )}
                <TranslatedCsButton
                  label="license.deactivateLicense"
                  disabled={!uuid || deactivateLoading || deactivated}
                  onClick={() => setConfirmationModalOpen(true)}
                >
                  <Cancel />
                </TranslatedCsButton>
              </Grid>
            )}
            {activated && !isOnline && (
              <>
                <Grid item xs={12}>
                  <Typography variant="body1">{t('license.offlineDeactivationHint')}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <TranslatedCsButton
                    label="license.downloadOfflineDeactivationIdentification"
                    solid
                    fullwidth
                    onClick={() => setConfirmationOfflineModalOpen(true)}
                  >
                    <Fingerprint />
                  </TranslatedCsButton>
                </Grid>
              </>
            )}
            {deactivated && (
              <Grid item xs={12}>
                <Alert severity="success">{t('license.deactivationSuccess')}</Alert>
              </Grid>
            )}
            {deactivateError && (
              <Grid item xs={12}>
                <Alert severity="error">{t('license.deactivationError')}</Alert>
              </Grid>
            )}
          </Grid>
        )}
      </div>
    </div>
  )
}

export default License
