import * as Sentry from '@sentry/browser'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'
import { environment } from '../../constants/environment'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
const fs = require('fs')

export const prepareSentry = (): void => {
  const dsn = environment.SENTRY_DSN.replace('%40', '@')

  if (dsn && dsn.length > 0) {
    try {
      Sentry.init({
        dsn,
        release: environment.META.MENU_TEXT,
        integrations: [
          // --
          // TODO: If we set console: true, we'd get previous console logs in sentry in the case of errors,
          // but logging output in browser comes from instrument.ts instead of the actual source.
          // https://github.com/getsentry/sentry-javascript/issues/1577#issuecomment-424033663
          // Is there another way to get both?
          new Sentry.Integrations.Breadcrumbs({ console: false }),
          // --
          new CaptureConsoleIntegration({ levels: ['error'] }),
        ],
      })

      Sentry.configureScope((scope) => {
        scope.setTag('environment', environment.STAGE)
        scope.setTag('app.version', environment.APP_VERSION)
      })
    } catch (e) {
      console.error('sentry failed')
    }
  }
}

export enum SentryLevels {
  Fatal = 'fatal',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Debug = 'debug',
}

type Log = {
  message: string
  type: string
  value: string
  data: unknown
  isRenderedByElectron?: boolean
  logToFile?: boolean
}

const generateSentryEventId = (): string => {
  return 'urn:uuid:' + uuidv4()
}

type ENV = {
  logging: {
    sentry: {
      enable: boolean
      dsn: string
    }
  }
}

const getSentryDsn = (isRenderedByElectron = false): string => {
  if (isRenderedByElectron) {
    const env: ENV = require('./../../environment/env.json')
    console.log({ env })
    return env.logging.sentry.dsn
  }
  console.log({ environment })
  return environment.SENTRY_DSN
}

export const logToSentry = ({
  message,
  data,
  type,
  value,
  isRenderedByElectron = false,
  logToFile = true,
}: Log): void => {
  if (logToFile) {
    // const logFile = fs.createWriteStream(__dirname + '/debug.log', { flags: 'w' })
    // logFile.write(JSON.stringify(data))
    return
  }

  const dsn = getSentryDsn(isRenderedByElectron)
  const dsnRegex = /(https:\/\/([a-z0-9]+)@.+?)\/(\d+)/i
  const [, sentryUrl, sentryKey, sentryProjectId] = dsn.match(dsnRegex) || []

  const eventId = generateSentryEventId()

  const payload = {
    event_id: eventId,
    timestamp: new Date().toISOString(),
    platform: 'node',
    message: message,
    level: SentryLevels.Info,
    logger: 'ld-reader-logger',
    release: `leak-reporter@${environment.APP_VERSION}`,
    environment: environment.STAGE === 'DEV' ? 'development' : 'production',
    extra: data,
  }

  console.log({ payload })

  axios({
    method: 'post',
    url: `${sentryUrl}/api/${sentryProjectId}/store/`,
    headers: {
      'content-type': 'application/json',
      'X-Sentry-Auth': `Sentry sentry_version=7,sentry_timestamp=${new Date().getTime()},sentry_client=sentry-curl/1.0,sentry_key=${sentryKey}`,
    },
    data: payload,
  })
    .then((response) => console.log({ response }))
    .catch((e) => console.error({ e }))
}
