import React, { FC, ReactElement, useMemo } from 'react'
import { useCurrentHardwareVersion } from '../../hooks/useCurrentHardwareVersion'
import { useUpdateInfo } from '../../hooks/useUpdateInfo'
import { DataTableColumn, DataTableRow } from '@csinstruments/cs-react-theme/dist/components/DataTable/DataTableTypes'
import { DataTable } from '@csinstruments/cs-react-theme/dist/components/DataTable/DataTable'
import { paddingBottom, paddingTopBottom, standardContentPadding } from '../../../styles/Shared'
import { Typography } from '@material-ui/core'
import { UpdateInfo } from '../../../interfaces/BusinessObjects'
import { ProgressBar } from '../partials/ProgressBar/ProgressBar'
import Grid from '@material-ui/core/Grid'
import { PageHeading } from '../partials/PageHeading'
import { useTranslation } from 'react-i18next'

const columns: DataTableColumn[] = [
  { label: 'updateOverview.serialVersion' },
  { label: 'updateOverview.version' },
  { label: '', isUpdateStatus: true },
]

export const UpdateOverview: FC = ({}): ReactElement => {
  const { t } = useTranslation('common')
  const { major, minor, hardwareVersionLoading } = useCurrentHardwareVersion()
  const info = useUpdateInfo()

  const rows: DataTableRow[] = useMemo(() => {
    if (hardwareVersionLoading) {
      return []
    }
    const demoLDVersion = [
      { sn: '123456', major: 2, minor: 3 },
      { sn: '8765432', major: 3, minor: 1 },
    ]

    const LDhwInfo = info.length ? info.sort((a, b) => (a.sn > b.sn ? 1 : -1)) : demoLDVersion
    return LDhwInfo.map((i: UpdateInfo) => {
      return {
        id: i.sn,
        values: [
          i.sn,
          `${i.major}.${i.minor}`,
          i.major > parseInt(major) || (i.major === parseInt(major) && i.minor >= parseInt(minor))
            ? 'actions.completed'
            : 'actions.inProgress',
        ],
      }
    })
  }, [major, minor, hardwareVersionLoading, info])

  return (
    <div style={standardContentPadding}>
      <div style={paddingBottom}>
        <ProgressBar show={hardwareVersionLoading || !rows.length} />
      </div>
      <Grid container justifyContent="flex-start">
        <Grid item xs={4}>
          <PageHeading title={'updateOverview.updateOverview'} />
        </Grid>
      </Grid>
      <Typography variant="subtitle1">
        {t('updateOverview.currentVersion')} {hardwareVersionLoading ? '…' : `${major}.${minor}`}
      </Typography>
      <Typography variant="subtitle1">
        {t('updateOverview.description')}{' '}
        <a target="_blank" href={t('updateOverview.link')} rel="noreferrer">
          {t('updateOverview.link')}
        </a>
      </Typography>
      <div style={paddingTopBottom}>
        <DataTable columns={columns} rows={rows} />
      </div>
    </div>
  )
}
