import constants from '../../constants/constants'
import { FAILURE, MEASURE, REPLACEMENT } from '../../interfaces/BusinessObjects'

const { NAVIGATION } = constants

export const routeLabels = new Map([
  ['', 'objects.home'],
  ['groups', 'objects.groups'],
  ['companies', 'objects.companies'],
  ['database', 'objects.database'],
  ['profile', 'objects.profile'],
  ['license', 'objects.license'],
  ['trashcan', 'objects.trashcan'],
  ['data-import', 'actions.dataImport'],
  ['places', 'objects.location'],
  ['learn', 'objects.learningProgram'],
  ['project-import', 'actions.projectImport'],
  ['projects', 'objects.projects'],
  ['buildings', 'objects.buildings'],
  ['master', 'company.masterData'],
  ['newCompany', 'company.create'],
  ['leakages', 'objects.leakages'],
  ['export-maintenance', 'exportMaintenance.exportMaintenance'],
  ['export-maintenance-data-export', 'actions.dataExport'],
  ['updates', 'objects.updates'],
  ['auditor', 'objects.auditor'],
  [MEASURE, 'exportMaintenance.measure'],
  [FAILURE, 'exportMaintenance.failure'],
  [REPLACEMENT, 'exportMaintenance.replacement'],
  ['groupsOverview', 'objects.manageGroups'],
  ['new', 'actions.new'],
])

export const ROUTES = {
  home: `/${NAVIGATION.HOME}`,
  companies: `/${NAVIGATION.COMPANIES}`,
  manageGroups: `/${NAVIGATION.COMPANIES}/groupsOverview`,
  projectImport: `/${NAVIGATION.PROJECT_IMPORT}`,
  database: `/${NAVIGATION.DATABASE}`,
  license: `/${NAVIGATION.LICENSE}`,
  profile: `/${NAVIGATION.PROFILE}`,
  learn: `/${NAVIGATION.LEARN}`,
  trashCan: `/${NAVIGATION.TRASH_CAN}`,
  dataImport: `/${NAVIGATION.DATA_IMPORT}`,
  dataExport: `/${NAVIGATION.DATA_EXPORT}`,
  newCompany: `/${NAVIGATION.NEW_COMPANY}`,
  exportMaintenanceBase: `/${NAVIGATION.EXPORT_MAINTENANCE}`,
  exportMaintenance: `/${NAVIGATION.EXPORT_MAINTENANCE}/:type`,
  exportMaintenanceMeasure: `/${NAVIGATION.EXPORT_MAINTENANCE}/measure`,
  exportMaintenanceReplacement: `/${NAVIGATION.EXPORT_MAINTENANCE}/replacement`,
  exportMaintenanceFailure: `/${NAVIGATION.EXPORT_MAINTENANCE}/failure`,
  companyAction: `/${NAVIGATION.COMPANIES}/:id/:action`,
  companyBuildings: `/${NAVIGATION.COMPANIES}/:id/buildings`,
  companyPlaces: `/${NAVIGATION.COMPANIES}/:id/places`,
  companyBuilding: `/${NAVIGATION.COMPANIES}/:id/buildings/:buildingId/:action`,
  newcompanyBuilding: `/${NAVIGATION.COMPANIES}/:id/buildings/new`,
  companyProjects: `/${NAVIGATION.COMPANIES}/:id/projects`,
  project: `/${NAVIGATION.COMPANIES}/:id/projects/:projectId/:action`,
  newProject: `/${NAVIGATION.COMPANIES}/:id/projects/new`,
  projectLeakages: `/${NAVIGATION.COMPANIES}/:id/projects/:projectId/leakages`,
  leakage: `/${NAVIGATION.COMPANIES}/:id/projects/:projectId/leakages/:leakageId/:sortedIds`,
  projectMaster: `/${NAVIGATION.COMPANIES}/:id/projects/:projectId/master`,
  companyMaster: `/${NAVIGATION.COMPANIES}/:id/master`,
  company: `/${NAVIGATION.COMPANIES}/:id`,
  updates: `/${NAVIGATION.UPDATES}`,
  auditor: `/${NAVIGATION.AUDITOR}`,
  editAuditor: `/${NAVIGATION.AUDITOR}/:id`,

  errors: {
    server: `/${NAVIGATION.SERVER_ERROR_PAGE}`,
    noAccess: `/${NAVIGATION.NO_ACCESS_PAGE}`,
  },
}
