import {
  fromCfmToltrmin,
  fromKgKwhToLbsKwh,
  fromLbsKwhToKgKwh,
  fromLtrMinToCfm,
  fromLtrMinToNm3,
  isLeakageResolved,
  Leakage,
  Project,
  totalLeakageAmount,
} from '../../interfaces/BusinessObjects'
import {
  calcAmount_DEFAULT_PRECISION,
  calcAmount_DEFAULT_TOFIXED,
  calcCo2_DEFAULT_PRECISION,
  calcCo2_DEFAULT_TOFIXED,
  calcEnergyAmount_DEFAULT_PRECISION,
  calcEnergyAmount_DEFAULT_TOFIXED,
  calcEnergyUsagePerYear_DEFAULT_PRECISION,
  calcEnergyUsagePerYear_DEFAULT_TOFIXED,
  calcRelative_DEFAULT_PRECISION,
  calcRelative_DEFAULT_TOFIXED,
  calculateEnergyCosts_DEFAULT_PRECISION,
  calculateEnergyCosts_DEFAULT_TOFIXED,
  convertProjectSpecificPower_DEFAULT_PRECISION,
  convertProjectSpecificPower_DEFAULT_TOFIXED,
  COST_BASE_SCF_TO_NM3,
  costTime_DEFAULT_PRECISION,
  costTime_DEFAULT_TOFIXED,
  DEFAULT_CO2_AMOUNT,
  DEFAULT_CO2_UNIT,
  DEFAULT_COST_TIME,
  DEFAULT_COST_UNIT,
  DEFAULT_ENERGY_AMOUNT,
  //DEFAULT_VOLUME_UNIT,
  getCo2Amount_DEFAULT_PRECISION,
  getCo2Amount_DEFAULT_TOFIXED,
  getCostBase_DEFAULT_PRECISION,
  getCostBase_DEFAULT_TOFIXED,
  kWhPerNM3ToKWPer100cfm,
  LtrMinCubicMeterHourFactor,
  totalCosts_DEFAULT_PRECISION,
  totalCosts_DEFAULT_TOFIXED,
} from '../../constants/calculationConstants'
import {
  enumValToDisplayVal,
  enumValToExportVal,
  isCfUnit,
  isM3Unit,
  Unit,
  UnitCalculation,
  UnitCo2,
  UnitCostTime,
  UnitLeakage,
  UnitSpecPower,
  UnitVolume,
} from '../../interfaces/Units'
import { TFunction } from 'i18next'
import { getIsoCode } from './dates'
import { mixedUnitsAccumulator, UnitValuePair } from '../components/pages/ProjectPage/calculations'
import { getLabelForCalcCostBaseFromProject } from './projectUtils'
//import { ProjectCostInfo } from 'app/components/pages/ProjectPage/ProjectCostInfo'

export type Calc = {
  result: number
  resultAsLocalFormat: string
  resultAsFormattedString: string
  unitAsEnum: string
  unitAsLabel: string
  text: string
}

type UnitLabel = {
  unit: string
  label: string
}

export type TotalSumsForLeakagesByUnit = {
  sumAmount: Calc
  savedAmount?: Calc
  sumAmountPercent?: Calc
  savedAmountPercent?: Calc
}

export const createCalcObject = (
  value: number,
  unit: string,
  locale: string,
  precision: number,
  fixed: number,
): Calc => {
  return {
    result: value,
    resultAsLocalFormat: formatFloat(value, precision, fixed, locale),
    resultAsFormattedString: fixedFloatAsString(value, fixed),
    unitAsEnum: unit,
    unitAsLabel: enumValToExportVal(unit),
    text: `${formatFloat(value, precision, fixed, locale)} ${enumValToExportVal(unit)} `,
  }
}

export const totalCosts = (
  leakages: Leakage[],
  project: Project,
  t: TFunction,
  precision: number = totalCosts_DEFAULT_PRECISION,
  fixed: number = totalCosts_DEFAULT_TOFIXED,
): Calc[] => {
  const locale = getIsoCode(t)

  //console.log({ locale })

  const leakageToUnitValuePairs = (leakage: Leakage): UnitValuePair => {
    const costs = calcCost(leakage, project, t)
    return {
      unit: costs.unitAsEnum,
      value: costs.result,
    }
  }
  const reducedByUnit = mixedUnitsAccumulator(leakages?.map(leakageToUnitValuePairs))

  if (reducedByUnit.length === 0) {
    const costUnit = getCostUnit(project)
    return [createCalcObject(0, costUnit?.unit, locale, precision, fixed)]
  }

  return reducedByUnit.map((object) => createCalcObject(object.value, object.unit, locale, precision, fixed))
}

export const calcTotalSumsForLeakages = (
  leakages: Leakage[],
  project: Project,
  t: TFunction,
  precision: number = calcCo2_DEFAULT_PRECISION,
  fixed: number = calcCo2_DEFAULT_TOFIXED,
): TotalSumsForLeakagesByUnit[] => {
  const resolvedLeakages = leakages.filter((l) => isLeakageResolved(l))
  const totalAmounts = totalLeakageAmount(leakages, project)
  const savedAmounts = totalLeakageAmount(resolvedLeakages, project)
  const locale = getIsoCode(t)

  return totalAmounts.map((total) => {
    const totalValue = total.value
    const totalUnit = total.unit

    const savedAmount = savedAmounts.find((savedAmount) => savedAmount.unit === totalUnit)
    const savedValue = savedAmount?.value || 0
    const savedUnit = savedAmount?.unit || totalUnit

    const savedPercent = (savedValue / totalValue) * 100

    return {
      sumAmount: {
        ...createCalcObject(totalValue, enumValToExportVal(totalUnit), locale, precision, fixed),
      },
      savedAmount: {
        ...createCalcObject(savedValue, enumValToExportVal(savedUnit), locale, precision, fixed),
      },
      savedAmountPercent: {
        ...createCalcObject(
          savedPercent,
          UnitCalculation.PERCENT,
          locale,
          calcRelative_DEFAULT_PRECISION,
          calcRelative_DEFAULT_TOFIXED,
        ),
      },
    }
  }) as TotalSumsForLeakagesByUnit[]
}

export const getPressureCost = (project: Project | undefined, t: TFunction): string => {
  if (project?.costBase && project?.costUnit && project?.unitV) {
    const locale = getIsoCode(t)
    return `${formatFloat(Number(project?.costBase || 0), 2, 2, locale)} ${enumValToExportVal(
      project?.costUnit,
    )} / ${formatFloat(1000, 0, 0, locale)} ${enumValToExportVal(project?.unitV)}`
  }
  return ''
}

export const getTotalSumsForLeakagesForReport = (
  leakages: Leakage[],
  project: Project,
  t: TFunction,
  precision: number = calcCo2_DEFAULT_PRECISION,
  fixed: number = calcCo2_DEFAULT_TOFIXED,
): {
  totalCostFormatted: string
  savedCostFormatted: string
  totalLeakageFormatted: string
  savedLeakageFormatted: string
} => {
  const totalSumsForLeakages = calcTotalSumsForLeakages(leakages, project, t, precision, fixed)

  const reduceTotalSumsForLeakages = totalSumsForLeakages.reduce(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (agg: any, totalSums) => {
      return {
        totalCostFormatted: `${totalSums.sumAmount.text} / ${agg.totalCostFormatted}`,
        savedCostFormatted: `${totalSums.sumAmount.text} / ${agg.savedCostFormatted}`,
        totalLeakageFormatted: `${totalSums?.sumAmount?.text} / ${agg.totalLeakageFormatted}`,
        savedLeakageFormatted: `${totalSums?.savedAmount?.text} / ${agg.savedLeakageFormatted}`,
      }
    },
    { totalCostFormatted: '', savedCostFormatted: '', totalLeakageFormatted: '', savedLeakageFormatted: '' },
  )

  return {
    ...reduceTotalSumsForLeakages,
  }
}

const calcKWhPerNM3ToKWPer100cfm = (value: number): number => {
  return value * kWhPerNM3ToKWPer100cfm
}
const calcKWPer100cfmToKWhPerNM3 = (value: number): number => {
  return value / kWhPerNM3ToKWPer100cfm
}

export const calcEnergyAmount = (
  project: Project,
  t: TFunction,
  targetUnit?: string,
  precision: number = calcEnergyAmount_DEFAULT_PRECISION,
  fixed: number = calcEnergyAmount_DEFAULT_TOFIXED,
): Calc => {
  const locale = getIsoCode(t)
  const unit = isCfUnit(project?.unitV || '') ? Unit.KW_CFM : Unit.KWH_M3
  let energyAmount = 0

  if (!isCfUnit(project?.unitV || '')) {
    energyAmount = parseFloat(project?.energyAmount || `${DEFAULT_ENERGY_AMOUNT}`)
  }
  if (isCfUnit(project?.unitV || '')) {
    energyAmount = parseFloat(project?.energyAmount || `${calcKWhPerNM3ToKWPer100cfm(DEFAULT_ENERGY_AMOUNT)}`)
  }
  if (targetUnit && isCfUnit(targetUnit || '')) {
    energyAmount = calcKWhPerNM3ToKWPer100cfm(energyAmount)
  }
  if (targetUnit && !isCfUnit(targetUnit || '')) {
    energyAmount = calcKWPer100cfmToKWhPerNM3(energyAmount)
  }

  return createCalcObject(energyAmount, unit, locale, precision, fixed)
}

export const getCo2Amount = (
  project: Project,
  t: TFunction,
  targetUnit?: string,
  precision: number = getCo2Amount_DEFAULT_PRECISION,
  fixed: number = getCo2Amount_DEFAULT_TOFIXED,
): Calc => {
  const locale = getIsoCode(t)
  let calc = 0
  if (project?.co2Unit === UnitCo2.KG_KWH) {
    calc = Number(project.co2Amount || DEFAULT_CO2_AMOUNT)
  }
  if (project?.co2Unit === UnitCo2.LBS_KWH) {
    calc = Number(project.co2Amount || fromKgKwhToLbsKwh(DEFAULT_CO2_AMOUNT))
  }
  if (targetUnit === UnitCo2.LBS_KWH && project?.co2Unit === UnitCo2.KG_KWH) {
    calc = fromKgKwhToLbsKwh(calc)
  }
  if (targetUnit === UnitCo2.KG_KWH && project?.co2Unit === UnitCo2.LBS_KWH) {
    calc = fromLbsKwhToKgKwh(calc)
  }
  return createCalcObject(calc, project?.co2Unit || '', locale, precision, fixed)
}

export const getCostUnit = (project: Project, leakage?: Leakage): UnitLabel => {
  const unit = project?.costUnit || leakage?.costUnit || ''
  return {
    unit,
    label: enumValToExportVal(unit),
  }
}

export const getEnergyCost = (
  project: Project,
  t: TFunction,
  precision: number = calcCo2_DEFAULT_PRECISION,
  fixed: number = calcCo2_DEFAULT_TOFIXED,
): Calc => {
  const locale = getIsoCode(t)
  const calc = Number(project?.energyCost) || 0
  const unit = `${enumValToExportVal(project.costUnit || DEFAULT_COST_UNIT)} / ${enumValToExportVal(UnitSpecPower.KWH)}`
  return {
    result: calc,
    resultAsLocalFormat: formatFloat(calc, precision, fixed, locale),
    resultAsFormattedString: fixedFloatAsString(calc, fixed),
    unitAsEnum: unit,
    unitAsLabel: unit,
    text: `${formatFloat(calc, precision, fixed, locale)} ${unit}`,
  }
}

export const getEnergyAmount = (
  project: Project,
  t: TFunction,
  precision: number = calcCo2_DEFAULT_PRECISION,
  fixed: number = calcCo2_DEFAULT_TOFIXED,
): Calc => {
  const locale = getIsoCode(t)
  const calc = Number(project?.energyAmount) || DEFAULT_ENERGY_AMOUNT
  const unit = isCfUnit(project?.unitV || '') ? UnitSpecPower.US : UnitSpecPower.ISO
  const result = fixedNumber(calc, precision)
  return createCalcObject(result, unit, locale, precision, fixed)
}

export const getCostBase = (
  project: Project,
  t: TFunction,
  leakage?: Leakage,
  unitV?: string,
  precision: number = getCostBase_DEFAULT_PRECISION,
  fixed: number = getCostBase_DEFAULT_TOFIXED,
): Calc => {
  const locale = getIsoCode(t)
  const costBase = Number(project?.costBase || leakage?.costBase || '')
  const unit = project?.unitV || leakage?.unitV || ''
  let calc = costBase
  if (!unitV) {
    return createCalcObject(calc, unit, locale, precision, fixed)
  }
  if (project?.unitV !== leakage?.unitV && project?.unitV === UnitVolume.NM3) {
    calc = costBase * COST_BASE_SCF_TO_NM3
  }
  if (project?.unitV !== leakage?.unitV && isCfUnit(project?.unitV || '')) {
    calc = costBase / COST_BASE_SCF_TO_NM3
  }
  if (unitV === UnitVolume.NM3) {
    calc = costBase * COST_BASE_SCF_TO_NM3
  }
  if (isCfUnit(unitV || '')) {
    calc = costBase / COST_BASE_SCF_TO_NM3
  }

  return createCalcObject(calc, unit, locale, precision, fixed)
}

export const getCostBaseFromProjectAndLeakage = (
  leakage: Leakage,
  project: Project,
  t: TFunction,
  precision: number = getCostBase_DEFAULT_PRECISION,
  fixed: number = getCostBase_DEFAULT_TOFIXED,
): Calc => {
  return getCostBase(project, t, leakage, '', precision, fixed)
}

export const calcRelative = (
  num1: number,
  num2: number,
  t: TFunction,
  precision: number = calcRelative_DEFAULT_PRECISION,
  fixed: number = calcRelative_DEFAULT_TOFIXED,
): Calc => {
  const locale = getIsoCode(t)
  const calc = (num1 / num2) * 100
  return createCalcObject(calc, UnitCalculation.PERCENT, locale, precision, fixed)
}

export const getAmountValues = (valuesFromLeaks: Calc[], ValuesFromProjectMaster: Calc | undefined): string => {
  const resTotalEnergy = ValuesFromProjectMaster
  if (resTotalEnergy?.text && resTotalEnergy.text) {
    return resTotalEnergy.text
  }
  return valuesFromLeaks.map((total) => total.text).join(' ')
}

export const getCostTime = (
  project: Project | undefined,
  leakage: Leakage | undefined,
  t: TFunction,
  precision: number = costTime_DEFAULT_PRECISION,
  fixed: number = costTime_DEFAULT_TOFIXED,
): Calc => {
  const locale = getIsoCode(t)
  const calc = Number(project?.costTime) || Number(leakage?.costTime) || DEFAULT_COST_TIME
  const unit = UnitCostTime.H
  const result = fixedNumber(calc, precision)
  return createCalcObject(result, unit, locale, precision, fixed)
}

export const getCo2Unit = (project: Project): string => {
  return project.co2Unit || DEFAULT_CO2_UNIT
}

export const roundNumber = (num: number, precision: number): number => {
  const precisionFactor = Math.pow(10, precision)
  return Math.round(num * precisionFactor) / precisionFactor
}

export const fixedNumber = (num: number, precision: number): number => {
  return Number(num.toFixed(precision))
}

export const calcCo2PerYear = (
  leakage: Leakage,
  project: Project,
  t: TFunction,
  precision: number = calcCo2_DEFAULT_PRECISION,
  fixed: number = calcCo2_DEFAULT_TOFIXED,
): Calc => {
  const locale = getIsoCode(t)
  const resLeakage = convertAmount(leakage.resLeakage, leakage.resUnitL, project?.unitV, t).result
  const co2AmountTargetUnit = isCfUnit(project?.unitV || '') ? Unit.LBS_KWH : Unit.KG_KWH
  const co2Amount = getCo2Amount(project, t, co2AmountTargetUnit).result
  let calc = 0
  const energyAmount = calcEnergyAmount(project, t).result
  const costTime = getCostTime(project, leakage, t).result

  calc = (((resLeakage * 60 * costTime) / 1000) * energyAmount * co2Amount) / 1000
  if (isCfUnit(project.unitV || '')) {
    calc = (resLeakage * 60 * costTime * ((energyAmount * 10) / 60 / 1000) * co2Amount) / 2204.62
  }

  return {
    result: fixedNumber(calc, precision),
    resultAsLocalFormat: formatFloat(calc, precision, fixed, locale),
    resultAsFormattedString: calc.toFixed(precision),
    text: `${formatFloat(calc, precision, fixed, locale)} ${getCo2UnitLabel(t)}`,
    unitAsEnum: getCo2UnitLabel(t),
    unitAsLabel: getCo2UnitLabel(t),
  }
}

export const calcCo2AmountPerYear = (
  leakages: Leakage[],
  project: Project,
  t: TFunction,
  precision: number = calcCo2_DEFAULT_PRECISION,
  fixed: number = calcCo2_DEFAULT_TOFIXED,
): Calc => {
  const calc = leakages.reduce((prev, leakage) => {
    return prev + calcCo2PerYear(leakage, project, t).result
  }, 0)
  const locale = getIsoCode(t)
  return {
    result: fixedNumber(calc, precision),
    resultAsLocalFormat: formatFloat(calc, precision, fixed, locale),
    resultAsFormattedString: calc.toFixed(precision),
    text: `${formatFloat(calc, precision, fixed, locale)} ${getCo2UnitLabel(t)}`,
    unitAsEnum: getCo2UnitLabel(t),
    unitAsLabel: getCo2UnitLabel(t),
  }
}

export const getCo2EmissionsPowerGeneration = (project: Project, t: TFunction): Calc => {
  const locale = getIsoCode(t)
  return createCalcObject(Number(project.co2Amount || DEFAULT_CO2_AMOUNT), project?.co2Unit || '', locale, 3, 3)
}

export const getCo2UnitLabel = (t: TFunction): string => {
  return t('reports.tons')
}

export const formatFloat = (float: number, precision: number, fixed: number, locale: string): string => {
  const formatOptions = {
    maximumFractionDigits: precision,
    minimumFractionDigits: fixed,
  }
  const num = fixedFloat(float, fixed)
  return new Intl.NumberFormat(locale, formatOptions).format(num)
}

export const fixedFloat = (float: number, fixed: number): number => {
  return Number((Math.floor(float * Math.pow(10, fixed)) / Math.pow(10, fixed)).toFixed(fixed))
}

export const fixedFloatAsString = (float: number, fixed: number): string => {
  return `${fixedFloat(float, fixed)}`
}

export const convertAmount = (
  amount = '0',
  sourceUnit = '',
  targetUnit: string | undefined,
  t: TFunction,
  precision: number = calcAmount_DEFAULT_PRECISION,
  fixed: number = calcAmount_DEFAULT_TOFIXED,
): Calc => {
  const locale = getIsoCode(t)

  if (targetUnit === Unit.NM3_HOUR && sourceUnit === UnitLeakage.LTR_MIN) {
    const calc = fromLtrMinToNm3(parseFloat(amount || '0'))
    return createCalcObject(calc, Unit.NM3_HOUR, locale, precision, fixed)
  }

  if (targetUnit && isCfUnit(targetUnit) && sourceUnit === UnitLeakage.LTR_MIN) {
    const calc = fromLtrMinToCfm(parseFloat(amount || '0'))
    const resCalObj = createCalcObject(calc, UnitLeakage.CFM, locale, precision, fixed)
    //console.log({ resCalObj })
    return resCalObj
  }

  if (targetUnit && !isCfUnit(targetUnit) && sourceUnit === UnitLeakage.CFM) {
    const calc = fromCfmToltrmin(parseFloat(amount || '0'))
    return createCalcObject(calc, UnitLeakage.LTR_MIN, locale, precision, fixed)
  }

  return {
    result: Number(amount) || 0,
    resultAsLocalFormat: `${formatFloat(parseFloat(amount || '0'), precision, fixed, locale)}`,
    resultAsFormattedString: Number(amount).toFixed(precision) || '',
    unitAsEnum: sourceUnit || '',
    unitAsLabel: enumValToDisplayVal(sourceUnit) || '',
    text: `${formatFloat(Number(amount || 0), precision, fixed, locale)} ${enumValToDisplayVal(sourceUnit) || ''}`,
  }
}

export const calcCost = (
  leakage: Leakage,
  project: Project,
  t: TFunction,
  precision: number = calcCo2_DEFAULT_PRECISION,
  fixed: number = calcCo2_DEFAULT_TOFIXED,
): Calc => {
  const locale = getIsoCode(t)
  const costTime = getCostTime(project, leakage, t).result
  const costBase = getCostBaseFromProjectAndLeakage(leakage, project, t).result
  const amount = convertAmount(leakage?.resLeakage, leakage?.resUnitL, project?.unitV, t)
  const costUnit = getCostUnit(project, leakage).unit
  let calc = 0

  //console.log('CALC', leakage, project, costTime)

  switch (amount.unitAsEnum) {
    case UnitLeakage.LTR_MIN:
      calc = (((amount.result * 60 * costTime) / 1000) * costBase) / 1000
      break
    case UnitLeakage.CFM:
      calc = ((amount.result * 60 * costTime) / 1000) * costBase
      break
    default:
    //throw new Error(`No calculation found for provided leckage unit ${amount.unitAsEnum}`)
  }

  return createCalcObject(calc, costUnit, locale, precision, fixed)
}

export const calcEnergyUsagePerYear = (
  totalSumsForLeakagesByUnit: TotalSumsForLeakagesByUnit[],
  project: Project,
  t: TFunction,
  key: string,
  precision: number = calcEnergyUsagePerYear_DEFAULT_PRECISION,
  fixed: number = calcEnergyUsagePerYear_DEFAULT_TOFIXED,
): Calc | undefined => {
  if (!project.costTime || !project.unitV) return
  const locale = getIsoCode(t)

  let calc = 0

  totalSumsForLeakagesByUnit.map((total) => {
    // @ts-ignore
    const targetUnit = getProjectUnitFromLeakageAmountUnit(total[key].unitAsEnum) || ''
    const energyFactor = convertProjectSpecificPower(
      project?.unitV || '',
      // @ts-ignore
      targetUnit,
      project?.energyAmount || '',
      t,
    )

    // @ts-ignore
    if (total[key].unitAsEnum === UnitLeakage.LTR_MIN) {
      // @ts-ignore
      const convertedLTR_MINtoM3Year = Number(project?.costTime) * total[key].result * LtrMinCubicMeterHourFactor
      calc += energyFactor.result * convertedLTR_MINtoM3Year
    }
    // @ts-ignore
    if (total[key].unitAsEnum === UnitLeakage.CFM) {
      // @ts-ignore
      const convertCFtoCFYear = Number(project?.costTime) * total[key].result * 60
      calc += (convertCFtoCFYear * ((energyFactor.result * 10) / 60)) / 1000
    }
  })

  return createCalcObject(calc, UnitSpecPower.KWH, locale, precision, fixed)
}

/* export const calcLeakageVolumePerYear = (
  leakage: Leakage,
  project: Project,
  t: TFunction,
  precision: number = calcEnergyUsagePerYear_DEFAULT_PRECISION,
  fixed: number = calcEnergyUsagePerYear_DEFAULT_TOFIXED,
): Calc | undefined => {
  const leakRate = leakage.resLeakage ?? ''
  const leakCostTime = Number(leakage.costTime ?? '0')
  const targetUnit = project.unitV ?? leakage.unitV
  const volumeUnit = project.unitV ?? Unit.M3
  const locale = getIsoCode(t)
  //console.log('leakUnit', leakage?.unitV, 'prUnitV', project.unitV)
  if (targetUnit === Unit.NM3_HOUR && leakage.resUnitL === UnitLeakage.LTR_MIN) {
    const calc = fromLtrMinToNm3(parseFloat(leakRate || '0')) * leakCostTime
    return createCalcObject(calc, volumeUnit, locale, precision, fixed)
  }

  if (targetUnit && isCfUnit(targetUnit) && leakage.resUnitL === UnitLeakage.LTR_MIN) {
    const calc = fromLtrMinToCfm(parseFloat(leakRate || '0')) * leakCostTime
    const resCalObj = createCalcObject(calc, volumeUnit, locale, precision, fixed)
    //console.log({ resCalObj })
    return resCalObj
  }

  if (targetUnit && !isCfUnit(targetUnit) && leakage.resUnitL === UnitLeakage.CFM) {
    const calc = Number(leakCostTime) * Number(leakage.resLeakage || '0') * LtrMinCubicMeterHourFactor
    return createCalcObject(calc, volumeUnit, locale, precision, fixed)
  }
} */

export const calcLeakVolAmount = (
  leakRate: string | undefined,
  sourceUnit = '',
  leakCostTime: string | undefined,
  targetUnit: string | undefined,
  t: TFunction,
  precision: number = calcAmount_DEFAULT_PRECISION,
  fixed: number = calcAmount_DEFAULT_TOFIXED,
): Calc | undefined => {
  const locale = getIsoCode(t)
  const amount = leakRate ? leakRate : '0'
  const costTime = leakCostTime ? leakCostTime : `${DEFAULT_COST_TIME}`
  //console.log({ targetUnit, sourceUnit, leakCostTime, leakRate })
  const volumeUnit = targetUnit && isCfUnit(targetUnit) ? Unit.CF : Unit.M3
  if (targetUnit === Unit.NM3 && sourceUnit === UnitLeakage.LTR_MIN) {
    const calc = fromLtrMinToNm3(parseFloat(amount)) * Number(costTime)
    return createCalcObject(calc, volumeUnit, locale, precision, fixed)
  }

  if (targetUnit && isCfUnit(targetUnit) && sourceUnit === UnitLeakage.LTR_MIN) {
    const calc = fromLtrMinToCfm(parseFloat(amount)) * Number(costTime) * 60
    const resCalObj = createCalcObject(calc, volumeUnit, locale, precision, fixed)
    //console.log({ resCalObj })
    return resCalObj
  }

  if (targetUnit && !isCfUnit(targetUnit) && sourceUnit === UnitLeakage.CFM) {
    const calc = fromCfmToltrmin(parseFloat(amount)) * Number(costTime) * 60
    return createCalcObject(calc, volumeUnit, locale, precision, fixed)
  }

  if (targetUnit && isCfUnit(targetUnit) && sourceUnit === UnitLeakage.CFM) {
    const calc = parseFloat(amount) * Number(costTime) * 60
    return createCalcObject(calc, volumeUnit, locale, precision, fixed)
  }
}

export const getProjectUnitFromLeakageAmountUnit = (leakageAmountUnit: string): string | undefined => {
  if (leakageAmountUnit === Unit.CFM) return Unit.CF
  if (leakageAmountUnit === Unit.LTR_MIN) return Unit.M3
}

export const convertProjectSpecificPower = (
  sourceUnit: string,
  targetUnit: string,
  energyAmount: string,
  t: TFunction,
  precision: number = convertProjectSpecificPower_DEFAULT_PRECISION,
  fixed: number = convertProjectSpecificPower_DEFAULT_TOFIXED,
): Calc => {
  const locale = getIsoCode(t)
  const unit = isM3Unit(targetUnit) ? Unit.KWH_M3 : Unit.KWH_CF

  if (!energyAmount && isM3Unit(targetUnit) && !targetUnit) {
    return createCalcObject(DEFAULT_ENERGY_AMOUNT, unit, locale, precision, fixed)
  }
  if (!energyAmount && isM3Unit(targetUnit) && isM3Unit(targetUnit)) {
    return createCalcObject(DEFAULT_ENERGY_AMOUNT, unit, locale, precision, fixed)
  }
  if (!energyAmount && isM3Unit(sourceUnit) && isCfUnit(targetUnit)) {
    const calc = (DEFAULT_ENERGY_AMOUNT / COST_BASE_SCF_TO_NM3) * 60 * 100
    return createCalcObject(calc, unit, locale, precision, fixed)
  }
  if (energyAmount && isCfUnit(sourceUnit) && isM3Unit(targetUnit)) {
    const calc = (parseFloat(energyAmount) * COST_BASE_SCF_TO_NM3) / (100 * 60)
    return createCalcObject(calc, unit, locale, precision, fixed)
  }
  if (energyAmount && isM3Unit(sourceUnit) && isCfUnit(targetUnit)) {
    const calc = (parseFloat(energyAmount) / COST_BASE_SCF_TO_NM3) * 60 * 100
    return createCalcObject(calc, unit, locale, precision, fixed)
  }

  return createCalcObject(parseFloat(energyAmount), unit, locale, precision, fixed)
}

export const calcLeakageEnergyUsagePerYear = (
  leakage: Leakage,
  project: Project,
  t: TFunction,
  precision: number = calcEnergyUsagePerYear_DEFAULT_PRECISION,
  fixed: number = calcEnergyUsagePerYear_DEFAULT_TOFIXED,
): Calc | undefined => {
  if (!project.unitV) return
  const locale = getIsoCode(t)

  let calc = 0

  const energyFactor = convertProjectSpecificPower(
    project?.unitV || '',
    // @ts-ignore
    leakage.unitV || '',
    project?.energyAmount || '',
    t,
  )

  if (leakage.resUnitL === UnitLeakage.LTR_MIN) {
    const convertedLTR_MINtoM3Year =
      Number(leakage?.costTime) * Number(leakage?.resLeakage) * LtrMinCubicMeterHourFactor
    calc += energyFactor.result * convertedLTR_MINtoM3Year
  }
  if (leakage.resUnitL === UnitLeakage.CFM) {
    const convertCFtoCFYear = Number(leakage?.costTime) * Number(leakage?.resLeakage) * 60
    calc += (convertCFtoCFYear * ((energyFactor.result * 10) / 60)) / 1000
  }

  return createCalcObject(calc, UnitSpecPower.KWH, locale, precision, fixed)
}

export const calculateEnergyCosts = (
  projectUnitV: string | undefined,
  specificPower: number,
  electricCost: number,
  costUnit: string,
  t?: TFunction,
  precision: number = calculateEnergyCosts_DEFAULT_PRECISION,
  fixed: number = calculateEnergyCosts_DEFAULT_TOFIXED,
): Calc => {
  const locale = getIsoCode(t)
  let calc = electricCost * specificPower * 1000
  if (isCfUnit(projectUnitV || '')) {
    calc = (electricCost * specificPower) / 6
  }
  return {
    result: calc,
    resultAsLocalFormat: formatFloat(calc, precision, fixed, locale),
    resultAsFormattedString: fixedFloatAsString(calc, fixed),
    unitAsEnum: costUnit,
    unitAsLabel: enumValToExportVal(costUnit),
    text:
      (t &&
        `${getLabelForCalcCostBaseFromProject(undefined, '0x01', t)}: ${formatFloat(
          calc,
          precision,
          fixed,
          locale,
        )} ${enumValToDisplayVal(costUnit)} / 1000 ${enumValToDisplayVal(projectUnitV)}`) ||
      '',
  }
}

export const calculateEnergyTotalCosts = (
  projectUnitV: string | undefined,
  specificPower: number,
  electricCost: number,
  costUnit: string,
  t?: TFunction,
  precision: number = calculateEnergyCosts_DEFAULT_PRECISION,
  fixed: number = calculateEnergyCosts_DEFAULT_TOFIXED,
): Calc => {
  const locale = getIsoCode(t)
  const calc = calculateEnergyCosts(projectUnitV, specificPower, electricCost, costUnit, t).result / 0.7
  return {
    result: calc,
    resultAsLocalFormat: formatFloat(calc, precision, fixed, locale),
    resultAsFormattedString: fixedFloatAsString(calc, fixed),
    unitAsEnum: costUnit,
    unitAsLabel: enumValToExportVal(costUnit),
    text:
      (t &&
        `${getLabelForCalcCostBaseFromProject(undefined, '0x03', t)}: ${formatFloat(
          calc,
          precision,
          fixed,
          locale,
        )} ${enumValToDisplayVal(costUnit)} / 1000 ${enumValToDisplayVal(projectUnitV)}`) ||
      '',
  }
}

export const calcVolUsagePerYear = (
  totalSumsForLeakagesByUnit: TotalSumsForLeakagesByUnit[],
  project: Project,
  t: TFunction,
  key: string,
  precision: number = calcEnergyUsagePerYear_DEFAULT_PRECISION,
  fixed: number = calcEnergyUsagePerYear_DEFAULT_TOFIXED,
): Calc | undefined => {
  if (!project.costTime || !project.unitV) return
  const locale = getIsoCode(t)

  let calc = 0
  const volumeUnit = project.unitV && isCfUnit(project.unitV) ? Unit.CF : Unit.M3
  totalSumsForLeakagesByUnit.map((total) => {
    // @ts-ignore
    /* const targetUnit = getProjectUnitFromLeakageAmountUnit(total[key].unitAsEnum) || ''
    const energyFactor = convertProjectSpecificPower(
      project?.unitV || '',
      // @ts-ignore
      targetUnit,
      project?.energyAmount || '',
      t,
    ) */
    //console.log({ energyFactor }, 'totalLeakRsum', total)
    // @ts-ignore
    if (total[key].unitAsEnum === UnitLeakage.LTR_MIN) {
      // @ts-ignore
      const convertedLTR_MINtoM3Year = Number(project?.costTime) * total[key].result * LtrMinCubicMeterHourFactor
      calc += convertedLTR_MINtoM3Year
      //console.log('t:ltm', calc)
    }
    // @ts-ignore
    if (total[key].unitAsEnum === UnitLeakage.CFM) {
      // @ts-ignore
      const convertCFtoCFYear = Number(project?.costTime) * total[key].result
      calc += convertCFtoCFYear * 60
      //console.log('t:cfm', calc)
    }
  })

  return createCalcObject(calc, volumeUnit, locale, precision, fixed)
}

export const getMixedUnitCalc = (mixedUnitsArray: UnitValuePair[], t: TFunction): Calc[] => {
  return mixedUnitsAccumulator(mixedUnitsArray).map((obj) => createCalcObject(obj.value, obj.unit, getIsoCode(t), 2, 2))
}

export const calcProjectLeakVolumePerYear = (leakages: Leakage[], project: Project, t: TFunction): Calc[] => {
  const leakVolumes = leakages.map((l) => {
    const calcUnitValue = calcLeakVolAmount(l.resLeakage, l.resUnitL, l.costTime, project.unitV, t)
    return {
      unit: calcUnitValue?.unitAsEnum ?? '',
      value: calcUnitValue?.result ?? 0,
    }
  })
  return getMixedUnitCalc(leakVolumes, t)
}

export const calcEnergyFromLeakSums = (leakages: Leakage[], project: Project, t: TFunction): Calc[] => {
  const leakEnergySums = leakages.map((l) => {
    const calcUnitValue = calcLeakageEnergyUsagePerYear(l, project, t)
    return {
      unit: calcUnitValue?.unitAsEnum ?? '',
      value: calcUnitValue?.result ?? 0,
    }
  })
  return getMixedUnitCalc(leakEnergySums, t)
}
