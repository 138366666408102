import {
  PlaceInput,
  useCreatePlaceMutation,
  useDeletedPlacesQuery,
  useDeletePlaceMutation,
  useGetPlacesQuery,
  useRestorePlaceMutation,
  useSoftDeletePlaceMutation,
  useUpdatePlaceMutation,
} from 'api/models'
import { getSelectedRealm } from 'app/helpers/manageRealms'
import { Place } from 'interfaces/BusinessObjects'
import { useMemo } from 'react'

export const useGetPlaces = (buildingId: string): { places: Place[]; refreshPlaces: () => void } => {
  const { data, error, refetch } = useGetPlacesQuery({
    variables: {
      buildingID: buildingId,
      realmName: getSelectedRealm(),
    },
    skip: !buildingId || !getSelectedRealm(),
  })
  if (error) {
    console.log({ error })
  }

  /* useEffect(() => {
    if (data?.getPlaces) {
      const { getPlaces } = data
      const leakPlaces = getPlaces.map((pl) => ({
        id: pl?.id ?? '',
        name: pl?.name ?? '',
      }))

      setBuildingPlaces(leakPlaces)
    }
  }, [data, data?.getPlaces]) */

  const places = useMemo(() => {
    if (data?.getPlaces.length) {
      const { getPlaces } = data
      return getPlaces.map((pl) => ({
        id: pl?.id ?? '',
        name: pl?.name ?? '',
        usedLeakages: 0,
      }))
    }
    return []
  }, [data])

  return { places: places, refreshPlaces: refetch }
}

export const useCreateNewPlace = (
  buildingID: string,
): { submitNewPlace: (placeName: string) => Promise<string | undefined>; creatingPlace: boolean } => {
  const [createPlace, { loading }] = useCreatePlaceMutation()
  const submitPlace = async (placeName: string): Promise<string | undefined> => {
    if (placeName && buildingID) {
      const createdNewPlace = await createPlace({
        variables: {
          placeInput: {
            name: placeName,
            building_id: buildingID,
          },
          realmName: getSelectedRealm(),
        },
      })
      if (createdNewPlace.data?.createPlace) {
        return createdNewPlace.data.createPlace.id
      } else {
        console.log(createdNewPlace.errors)
      }
    }
  }
  return { submitNewPlace: submitPlace, creatingPlace: loading }
}

export const useUpdatePlace = (
  buildingID: string,
): { updating?: boolean; updatePlace: (place: Place) => Promise<boolean | undefined> } => {
  const [submitUpdatedPlace, { loading }] = useUpdatePlaceMutation()

  const updateOldPlaceName = async (newPlace: Place): Promise<boolean | undefined> => {
    //console.log({ newPlace }, { buildingID })
    if (!buildingID || !newPlace.id || !newPlace.name) return
    const placeInput: PlaceInput = {
      name: newPlace.name,
      building_id: buildingID,
    }
    try {
      const updatedPlace = await submitUpdatedPlace({
        variables: {
          placeInput,
          realmName: getSelectedRealm(),
          placeID: newPlace.id,
        },
      })
      if (updatedPlace.data?.updatePlace) {
        return !!updatedPlace.data.updatePlace.id
      }
    } catch (error) {
      console.error({ error })
    }
  }

  return { updating: loading, updatePlace: updateOldPlaceName }
}

export const useDeletePlace = (): {
  deletingPlace: boolean
  confirmDeletePlace: (placeId: string) => Promise<boolean | undefined>
} => {
  const [submitDeletePlace, { loading }] = useSoftDeletePlaceMutation()
  const deletePlace = async (placeId: string): Promise<boolean | undefined> => {
    if (!placeId) return

    try {
      const deletedPlace = await submitDeletePlace({
        variables: {
          placeID: placeId,
          realmName: getSelectedRealm(),
        },
      })
      if (deletedPlace.data?.softDeletePlace.success) {
        return true
      }
    } catch (error) {
      console.log(error)
    }
  }
  return { deletingPlace: loading, confirmDeletePlace: deletePlace }
}

export const useDeletedPlaces = (skipFetch?: boolean): { deletedPlaces: Place[]; fetchingPlaces: boolean } => {
  const { data, loading } = useDeletedPlacesQuery({
    variables: {
      realmName: getSelectedRealm(),
    },
    skip: !getSelectedRealm() || !!skipFetch,
  })

  const places: Place[] = useMemo(() => {
    if (data?.deletedPlaces) {
      return data.deletedPlaces.map((p) => ({
        _id: p?.id ?? '',
        id: p?.id ?? '',
        name: p?.name ?? '',
        usedLeakages: 0,
        _deleted_at: p?._deleted_at ? new Date(p._deleted_at) : new Date(0),
      }))
    }
    return []
  }, [data])

  return { deletedPlaces: places, fetchingPlaces: loading }
}

export const useFinalDeletePlace = (): {
  deletingPlace: boolean
  confirmDeletePlace: (placeId: string) => Promise<boolean | undefined>
} => {
  const [submitDeletePlace, { loading }] = useDeletePlaceMutation()
  const deletePlace = async (placeId: string): Promise<boolean | undefined> => {
    if (!placeId) return

    try {
      const deletedPlace = await submitDeletePlace({
        variables: {
          placeID: placeId,
          realmName: getSelectedRealm(),
        },
      })
      if (deletedPlace.data?.deletePlace.success) {
        return true
      }
    } catch (error) {
      console.log(error)
    }
  }
  return { deletingPlace: loading, confirmDeletePlace: deletePlace }
}

export const useRestorePlace = (): {
  restoringPlace: boolean
  restorePlace: (placeId: string) => Promise<boolean | undefined>
} => {
  const [submitDeletePlace, { loading }] = useRestorePlaceMutation()
  const handleRestore = async (placeId: string): Promise<boolean | undefined> => {
    if (!placeId) return

    try {
      const deletedPlace = await submitDeletePlace({
        variables: {
          placeID: placeId,
          realmName: getSelectedRealm(),
        },
      })
      if (deletedPlace.data?.restorePlace.success) {
        return true
      }
    } catch (error) {
      console.log(error)
    }
  }
  return { restoringPlace: loading, restorePlace: handleRestore }
}
