import React, { useCallback, useContext, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { paddingBottomActionBar, paddingTop, standardContentPadding } from '../../../styles/Shared'
import { Text } from '../partials/Inputs/Text'
import SaveIcon from '@material-ui/icons/SaveOutlined'
import { ImageUpload } from '../partials/ImageUpload/ImageUpload'
import { User } from '../../../interfaces/BusinessObjects'
import { ResultAlert } from '../partials/ResultAlert'
import { FixedActionBar } from '@csinstruments/cs-react-theme/dist/components/FixedActionBar/FixedActionBar'
import { UserAccessContext } from '../partials/UserProvider/UserProvider'
import { PageHeading } from '../partials/PageHeading'

export const Profile: React.FC = () => {
  const [newUserData, setNewUserData] = useState<User>({})
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const { user, createOrUpdateUser, updatedUser } = useContext(UserAccessContext)

  useEffect(() => {
    if (user) {
      setNewUserData(user)
    }
  }, [user])

  useEffect(() => {
    if (updatedUser) {
      setShowSuccess(true)
    }
  }, [updatedUser])

  const updateNewUserData = (data: User): void => {
    setNewUserData({ ...newUserData, ...data })
  }

  const updateAction = useCallback(() => createOrUpdateUser(newUserData), [createOrUpdateUser, newUserData])

  return (
    <div style={{ ...paddingTop, ...paddingBottomActionBar }}>
      <FixedActionBar
        buttonProps={[{ solid: true }]}
        actions={[updateAction]}
        labels={['actions.save']}
        icons={[SaveIcon]}
      />
      <ResultAlert alertText={'profile.updateSuccess'} showAlert={showSuccess} modifyShowAlert={setShowSuccess} />
      <div style={standardContentPadding}>
        <PageHeading title={'objects.profile'} />
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <Grid spacing={3} container>
              <Text
                changeHandler={(company) => updateNewUserData({ company })}
                value={newUserData.company ?? ''}
                columns={12}
                variant="outlined"
                label={'objects.company'}
              />
              <Text
                changeHandler={(surname) => updateNewUserData({ surname })}
                value={newUserData.surname ?? ''}
                columns={12}
                variant="outlined"
                label={'contact.lastName'}
              />
              <Text
                changeHandler={(prename) => updateNewUserData({ prename })}
                value={newUserData.prename ?? ''}
                columns={12}
                variant="outlined"
                label={'contact.firstName'}
              />
              <Text
                changeHandler={(phone) => updateNewUserData({ phone })}
                value={newUserData.phone ?? ''}
                columns={12}
                variant="outlined"
                label={'contact.phone'}
              />
              <Text
                changeHandler={(email) => updateNewUserData({ email })}
                value={newUserData.email ?? ''}
                columns={12}
                variant="outlined"
                label={'contact.email'}
              />
              <Text
                changeHandler={(street) => updateNewUserData({ street })}
                value={newUserData.street ?? ''}
                columns={12}
                variant="outlined"
                label={'address.street'}
              />
              <Text
                changeHandler={(zip) => updateNewUserData({ zip })}
                value={newUserData.zip ?? ''}
                columns={12}
                variant="outlined"
                label={'address.postCode'}
              />
              <Text
                changeHandler={(city) => updateNewUserData({ city })}
                value={newUserData.city ?? ''}
                columns={12}
                variant="outlined"
                label={'address.city'}
              />
              {/*<Grid item xs={12}>*/}
              {/*  <FormControlLabel*/}
              {/*    control={*/}
              {/*      <Checkbox*/}
              {/*        checked={newUserData && newUserData.printFooter !== undefined ? newUserData.printFooter : false}*/}
              {/*        onChange={(e) => {*/}
              {/*          updateNewUserData({ printFooter: e.target.checked })*/}
              {/*        }}*/}
              {/*        color="primary"*/}
              {/*      />*/}
              {/*    }*/}
              {/*    label={t('reports.csBrandingFooter')}*/}
              {/*    labelPlacement="end"*/}
              {/*  />*/}
              {/*</Grid>*/}
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <ImageUpload image={newUserData.image} imageChanged={(image) => updateNewUserData({ image })} />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Profile
