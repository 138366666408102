import { Company, DisplayCompany, DisplayGroup } from '../../interfaces/BusinessObjects'
import { useEffect, useMemo, useState } from 'react'
import {
  /* ImportCompaniesMutationVariables,
  ImportCompany, */
  Role,
  UpdateCompany,
  UpdateCompanyMutationVariables,
  useCopyMoveCompanyToGroupMutation,
  useDeletedCompaniesQuery,
  useFinalDeleteCompanyMutation,
  useGetCompanyQuery,
  //useGetGroupsAndCompaniesLazyQuery,
  useGetGroupsAndCompaniesQuery,
  useGetRootContentQuery,
  //useImportCompaniesMutation,
  useRestoreCompanyByIdMutation,
  useSoftDeleteCompanyMutation,
  useUpdateCompanyMutation,
} from '../../api/models'
import {
  convertAddress,
  convertContact,
  convertProject,
  convertServerBuilding,
  dropId,
} from './hookHelpers/serverResponseConversion'
import { getSelectedRealm } from 'app/helpers/manageRealms'
import transformToNestedGroups from './hookHelpers/subGroups'

export const useCompanies = (): {
  companies: DisplayCompany[]
  loading: boolean
  error: boolean
  rootAccess: boolean
  editAccess: boolean
} => {
  // const {
  //   data: result,
  //   loading,
  //   error,
  // } = useGetCompaniesQuery({ variables: { realmName: getSelectedRealm() || '' }, skip: !getSelectedRealm() })
  const {
    data: result,
    loading,
    error,
  } = useGetRootContentQuery({ variables: { realmName: getSelectedRealm() || '' }, skip: !getSelectedRealm() })
  const companiesPage = result?.getRootContent
  const data = companiesPage?.companies
  const rootAccess = !!companiesPage?.groups.length || false
  const editAccess = !!companiesPage?.companies.some((c) => c?.canEdit && c.canEdit) || false

  const [companies, setCompanies] = useState<DisplayCompany[]>([])

  useEffect(() => {
    // if setRows(convertCompaniesToRows(companies)) on component level:
    // setRows -> triggers -> companies -> triggers -> setRows

    setCompanies(
      data
        ?.map((d) => {
          return {
            _id: d?.id ?? '',
            name: d?.company ?? '',
            canEdit: !!d?.canEdit,
            nProjects: d?.nProjects,
            nBuildings: d?.nBuildings,
            buildings: [],
            projects: [],
          }
        })
        .sort((a, b) => (a.name || '').localeCompare(b.name || '')) ?? [],
    )
  }, [data, result])

  //   const companies: DisplayCompany[] =
  //     data
  //       ?.map((d) => {
  //         return {
  //           _id: d?.id ?? '',
  //           name: d?.company ?? '',
  //           buildings: d?.buildings ?? [],
  //           projects: d?.projects?.projects?.map((p) => {
  //             return {
  //               name: p.name ?? '',
  //               _id: p.id ?? '',
  //             }
  //           }),
  //         }
  //       })
  //       .sort((a, b) => (a.name || '').localeCompare(b.name || '')) ?? []

  return {
    companies: companies,
    loading,
    error: !!error,
    rootAccess,
    editAccess,
  }
}

export const useCompaniesInGroup = (
  groupId?: string,
): {
  groupCompanyData: [DisplayGroup[], DisplayCompany[], DisplayGroup | undefined]
  groupCompaniesLoading: boolean
  refresh: () => void
} => {
  const [groupsList, setGroupsList] = useState<DisplayGroup[]>([])
  const [companiesInGroup, setCompaniesInGroup] = useState<DisplayCompany[]>([])
  const [groupInfo, setGroupInfo] = useState<DisplayGroup>()
  const {
    data: rootData,
    loading: rootDataLoading,
    refetch,
  } = useGetRootContentQuery({
    variables: {
      realmName: getSelectedRealm(),
    },
    skip: !getSelectedRealm() || !!groupId,
  })
  // const { data: groupData, loading: groupDataloading } = useGetGroupsAndCompaniesQuery({
  //   variables: {
  //     realmName: getSelectedRealm(),
  //     groupId: groupId,
  //   },
  //   skip: !groupId || !getSelectedRealm(),
  // })
  useEffect(() => {
    if (rootData?.getRootContent) {
      const allCompanies = rootData.getRootContent.companies
      const rootGroupsInfo = rootData.getRootContent.groups
        .map((grp) => {
          return {
            id: grp?.id ?? '',
            groupName: grp?.groupName,
            parentGroupId: grp?.parentGroupId ? grp.parentGroupId : undefined,
            canEdit: !!grp?.canEdit,
            canDelete: !allCompanies.some((c) => c?.parentGroupId === grp?.id),
            canCreateCompany: !!grp?.canCreateCompany,
            subGroups: [],
          }
        })
        .filter((grp) => grp.id && grp.id)
        .sort((a, b) => (a.groupName || '').localeCompare(b.groupName || ''))
      setGroupsList(rootGroupsInfo)
      const rootCompaniesInfo =
        allCompanies
          .map((d) => {
            return {
              _id: d?.id ?? '',
              name: d?.company ?? '',
              path: d?.groupPath ? d.groupPath : undefined,
              groupId: d?.parentGroupId ? d.parentGroupId : undefined,
              canEdit: !!d?.canEdit,
              nProjects: d?.nProjects,
              canDelete: !!d?.nProjects || !!d?.nBuildings,
              nBuildings: d?.nBuildings,
              buildings: [],
              projects: [],
            }
          })
          .sort((a, b) => (a.name || '').localeCompare(b.name || '')) ?? []
      setCompaniesInGroup(rootCompaniesInfo)
    }
  }, [rootData?.getRootContent])
  // useEffect(() => {
  //   if (groupId && groupData?.getGroupsAndCompanies) {
  //     // const rootGroupsInfo = groupData?.getGroupsAndCompanies.groups
  //     //   .map((grp) => {
  //     //     return {
  //     //       id: grp?.id ?? '',
  //     //       groupName: grp?.groupName,
  //     //       parentGroupId: grp?.parentGroupId ?? '',
  //     //       canEdit: !!grp?.canEdit,
  //     //       canCreateCompany: !!grp?.canCreateCompany,
  //     //     }
  //     //   })
  //     //   .filter((grp) => grp.id && grp.id)
  //     //   .sort((a, b) => (a.groupName || '').localeCompare(b.groupName || ''))
  //     // const transforedSubListGroups = transformToNestedGroups(groupsList, groupId, rootGroupsInfo)
  //     // setGroupsList(transforedSubListGroups)

  //     //setGroupsList(rootGroupsInfo)

  //     const rootCompaniesInfo =
  //       groupData?.getGroupsAndCompanies.companies
  //         .map((d) => {
  //           return {
  //             _id: d?.id ?? '',
  //             name: d?.company ?? '',
  //             path: d?.groupPath ? d.groupPath : undefined,
  //             parentId: d?.parentGroupId ? d.parentGroupId : undefined,
  //             canEdit: !!d?.canEdit,
  //             nProjects: d?.nProjects,
  //             nBuildings: d?.nBuildings,
  //             buildings: [],
  //             projects: [],
  //           }
  //         })
  //         .sort((a, b) => (a.name || '').localeCompare(b.name || '')) ?? []
  //     setCompaniesInGroup(rootCompaniesInfo)
  //     const currentGroupInfo = groupData?.getGroupsAndCompanies.groupInfo
  //     setGroupInfo({
  //       groupName: currentGroupInfo?.groupName,
  //       id: currentGroupInfo?.id ?? '',
  //       canCreateCompany: currentGroupInfo?.canCreateCompany ?? undefined,
  //       parentGroupId: currentGroupInfo?.parentGroupId ?? undefined,
  //     })
  //   }
  // }, [groupData?.getGroupsAndCompanies, groupId])

  return {
    groupCompanyData: [groupsList, companiesInGroup, groupInfo],
    groupCompaniesLoading: rootDataLoading,
    refresh: refetch,
  }
}

export const useCreateCompany = (): {
  createdCompany: boolean
  createCompany: (company: Company) => Promise<void>
  createError?: boolean
} => {
  const [updateCompanyMut] = useUpdateCompanyMutation()
  const [createdCompany, setCreatedCompany] = useState(false)
  const [createError, setCreateError] = useState<boolean>()

  const createCompany = async (company: Company): Promise<void> => {
    setCreatedCompany(false)
    if (!company.name) {
      return
    }

    try {
      const c: UpdateCompany = {
        groupId: company.groupId ?? undefined,
        id: company._id ?? null,
        image: company.logo ?? null,
        company: company.name ?? '',
        address: company.address ? dropId(company.address) : {},
        contact: company.contact ? dropId({ ...company.contact, role: Role.Customer }) : {},
      }
      const variables: UpdateCompanyMutationVariables = { company: c, realmName: getSelectedRealm() || '' }
      const createSuccess = await updateCompanyMut({ variables })
      if (createSuccess.data?.createOrUpdateCompany.id) {
        setCreatedCompany(true)
      }
    } catch (error) {
      setCreateError(true)
    }
  }

  return { createCompany, createdCompany, createError }
}

export const useCompany = (
  name: string,
  groupId?: string,
): { company: DisplayCompany; companyLoading: boolean; error: boolean } => {
  const { data, loading, error } = useGetCompanyQuery({
    variables: {
      groupId: groupId,
      name: decodeURIComponent(name),
      realmName: getSelectedRealm() || '',
    },
    skip: !name || !getSelectedRealm(),
  })

  const company = useMemo(() => {
    let c = {}

    if (data && data.getCompany?.company) {
      const responseCompany = data.getCompany.company
      const address = convertAddress(responseCompany.address)
      const contact = convertContact(responseCompany.contact)
      const buildings = responseCompany.buildings?.map((b) => convertServerBuilding(b))
      const projects = responseCompany.projects?.projects?.map((p) => convertProject(p))

      c = {
        name: responseCompany.company,
        _id: responseCompany.id,
        address,
        contact,
        buildings,
        projects,
        canEdit: responseCompany.canEdit ?? false,
        logo: responseCompany.logo ?? undefined,
      }
    }
    return c
  }, [data])

  return {
    company,
    companyLoading: loading,
    error: !!error,
  }
}

export const useUpdateCompany = (): {
  updatedCompany: boolean
  updateCompany: (company: Company, originalName: string) => Promise<void>
  updateError?: boolean
} => {
  const [updateCompanyMut] = useUpdateCompanyMutation()
  const [updatedCompany, setUpdatedCompany] = useState(false)
  const [updateError, setUpdateError] = useState<boolean>()

  const updateCompany = async (company: Company): Promise<void> => {
    setUpdatedCompany(false)
    if (!company.name) {
      return
    }

    try {
      const c: UpdateCompany = {
        groupId: company.groupId ?? undefined,
        id: company._id ?? null,
        image: company.logo ?? null,
        company: company.name ?? '',
        address: company.address ? dropId(company.address) : {},
        contact: company.contact ? dropId({ ...company.contact, role: Role.Customer }) : {},
      }
      const variables: UpdateCompanyMutationVariables = { company: c, realmName: getSelectedRealm() || '' }
      const updateSuccess = await updateCompanyMut({ variables })
      if (updateSuccess.data?.createOrUpdateCompany.id) {
        setUpdatedCompany(true)
      }
    } catch (error) {
      setUpdateError(true)
    }
  }

  return { updateCompany, updatedCompany, updateError }
}

export const useCopyMoveCompanies = (companyIDs: string[], copy: boolean) => {
  const [copyMoveMut, { data }] = useCopyMoveCompanyToGroupMutation()

  const confirmMove = async (targetGroupId: string) => {
    if (targetGroupId && companyIDs.length) {
      try {
        await copyMoveMut({
          variables: {
            realmName: getSelectedRealm(),
            companyIDs: companyIDs,
            targetGroupID: targetGroupId,
            copyOnly: copy,
          },
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
  return { copyMove: confirmMove, moveSucess: data?.copyMoveCompanyToGroup.success }
}

export const useDeletedCompanies = (
  skipFetch?: boolean,
): { companies: Company[]; loading: boolean; error: boolean } => {
  const { data, loading, error } = useDeletedCompaniesQuery({
    variables: { realmName: getSelectedRealm() || '' },
    skip: !!skipFetch || !getSelection(),
  })

  const companies: Company[] = useMemo(() => {
    return (
      data?.deletedCompanies.map((dc) => {
        return {
          name: dc.company,
          _id: dc.id,
          path: dc.groupPath?.map((pname) => pname).slice(0, dc.groupPath.length) ?? undefined,
          _deleted_at: dc._deleted_at ? new Date(dc._deleted_at) : new Date(0),
        }
      }) ?? []
    )
  }, [data])
  return {
    companies,
    loading,
    error: !!error,
  }
}

export const useDeleteCompany = (): {
  deleted: boolean
  deleteCompany: (name: string, groupId?: string) => Promise<void>
} => {
  const [deleteMutation, { data }] = useSoftDeleteCompanyMutation()
  const [success, setSuccess] = useState(false)

  const deleteCompany = async (name: string, groupId?: string): Promise<void> => {
    await deleteMutation({ variables: { name: name, realmName: getSelectedRealm() || '', groupId } })
  }

  useEffect(() => {
    if (data?.softDeleteCompanyByName.success) {
      setSuccess(true)
    } else {
      setSuccess(false)
    }
  }, [data])

  return { deleteCompany, deleted: success }
}

export const useFinalDeleteCompany = (): {
  deletedCompany: boolean
  deleteCompany: (id: string) => Promise<void>
} => {
  const [deleteMutation, { data }] = useFinalDeleteCompanyMutation()
  const [success, setSuccess] = useState(false)

  const deleteCompany = async (id: string): Promise<void> => {
    await deleteMutation({ variables: { id, realmName: getSelectedRealm() || '' } })
  }

  useEffect(() => {
    if (data?.deleteCompany.success) {
      setSuccess(true)
    } else {
      setSuccess(false)
    }
  }, [data])

  return { deleteCompany, deletedCompany: success }
}

export const useRestoreCompany = (): {
  restoredCompany: boolean
  restoreCompany: (id: string) => Promise<void>
} => {
  const [restoreMutation, { data }] = useRestoreCompanyByIdMutation()
  const [success, setSuccess] = useState(false)

  const restoreCompany = async (id: string): Promise<void> => {
    await restoreMutation({ variables: { id, realmName: getSelectedRealm() || '' } })
  }

  useEffect(() => {
    if (data?.restoreCompanyById.success) {
      setSuccess(true)
    } else {
      setSuccess(false)
    }
  }, [data])

  return { restoreCompany, restoredCompany: success }
}
