import {
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { DisplayCompany } from 'interfaces/BusinessObjects'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  companies: DisplayCompany[]
  selectedCompanyID: string
  showNewCompanyMenu?: boolean
  selectCompany: (companyID: string) => void
  disabled?: boolean
  helperText?: string
  variant?: 'filled' | 'outlined' | 'standard'
  xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
}

type CMenuItem = DisplayCompany & {
  group: string
}
export const CompanyDropDown: FC<Props> = ({
  companies,
  selectCompany,
  selectedCompanyID,
  helperText,
  variant,
  showNewCompanyMenu,
  xs,
  md,
}: Props): ReactElement => {
  const { t } = useTranslation('common')
  const [selected, setSelected] = useState<string>('')
  const [menuCompanies, setMenuCompanies] = useState<DisplayCompany[]>([])
  const [showHelper, setSetShowHelper] = useState<boolean>(false)

  useEffect(() => {
    setSelected(selectedCompanyID)
  }, [selectedCompanyID])
  useEffect(() => {
    if (companies) {
      setMenuCompanies(companies)
    }
  }, [companies])

  const handleChange = useCallback(
    (id: string) => {
      setSelected(id)
      selectCompany(id)

      showHelper && setSetShowHelper(false)
    },
    [selectCompany],
  )

  useEffect(() => {
    if (helperText) {
      setSetShowHelper(true)
    }
  }, [helperText])

  const emptyText = useMemo(() => {
    return { _id: 'new', text: t('dataImport.createNew') }
  }, [])

  const groupedMenuItems: { [group: string]: CMenuItem[] } = menuCompanies.reduce(
    (groups: { [group: string]: CMenuItem[] }, item) => {
      const groupPath = !item?.path ? t('objects.noGroup') : '' + item.path.join('/ ')
      if (!groups[groupPath]) {
        groups[groupPath] = []
      }
      const cMenItem: CMenuItem = { ...item, group: groupPath }
      groups[groupPath].push(cMenItem)
      return groups
    },
    {},
  )

  return (
    <Grid item xs={xs ? xs : 6} md={md ? md : xs ? xs : 6}>
      <InputLabel htmlFor="grouped-select">{t('objects.company')}</InputLabel>
      <TextField
        fullWidth
        select
        variant={variant}
        id="grouped-select"
        helperText={
          showHelper ? (
            <Typography variant="caption" color="error">
              {helperText}
            </Typography>
          ) : null
        }
        onChange={({ target }) => handleChange(target.value)}
        SelectProps={{
          value: !selected && showNewCompanyMenu ? emptyText._id : selected,

          //input: <Input />,
        }}
      >
        {showNewCompanyMenu && showNewCompanyMenu ? <MenuItem value={emptyText._id}>{emptyText.text}</MenuItem> : null}

        {Object.entries(groupedMenuItems).map(([group, items], ix) => [
          <ListSubheader key={ix} disableSticky>
            <em>{group}</em>
          </ListSubheader>,
          items?.map((item) => (
            <MenuItem key={item._id} value={item._id}>
              {item.name}
            </MenuItem>
          )),
        ])}
      </TextField>
    </Grid>
  )
}
