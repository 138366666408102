import React from 'react'
import { CompanyMasterDataView } from './CompanyMasterDataView'
import { CompanyMasterDataEdit } from './CompanyMasterDataEdit'
import { useLocation } from 'react-router-dom'

export const CompanyMasterData: React.FC = ({}) => {
  const location = useLocation().search
  const query = new URLSearchParams(location)
  const isEditPage = query.get('edit')
  if (!isEditPage) {
    return <CompanyMasterDataView />
  } else {
    return <CompanyMasterDataEdit />
  }
}
