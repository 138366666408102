const checkForEnvironmentVariable = (
  variable: string | undefined,
  csv = false,
): string | null | undefined | string[] => {
  if (variable && variable === 'undefined') {
    return undefined
  }

  if (variable === 'null') {
    return null
  }

  if (csv) {
    try {
      return (variable as string).split(',').map((x) => x.trim())
    } catch (error) {
      console.error("error parsing environment variable '%s' as csv: %o", variable, error)
      return null
    }
  }

  return variable
}
export enum Brand {
  Cs = 'cs',
  Beko = 'beko',
  Testo = 'testo',
  Neutral = 'neutral',
}

export type Meta = {
  MENU_TEXT: string
}

export type Environment = {
  APP_VERSION: string
  SENTRY_DSN: string
  STAGE: string
  META: Meta
  REACT_APP_API_GQL_HTTP_URI: string
  REACT_APP_API_DATA_HTTP_URI: string
  REACT_APP_API_XLSX_REPORT_URI: string
  REACT_APP_API_PDF_REPORT_URI: string
  REACT_APP_LOGIN_URL: string
  REACT_APP_LOGIN_REALM: string
  REACT_APP_IAM_URL: string
  LICENSE_FRONTEND_URL: string
  LICENSE_SERVER_URL: string
  LICENSE_SERVER_PUBLIC_KEY: string
  VERSION_SERVER_URL: string
  BUILD_TIMESTAMP: string
  GIT_VERSION: string
  USER_PROFILE_URL: string
  WEB_URL: string
  BRAND: Brand
}

export const environment: Environment = {
  BRAND: (process.env.REACT_APP_BRAND as unknown as Brand) || Brand.Cs,
  BUILD_TIMESTAMP: (checkForEnvironmentVariable(process.env.REACT_APP_BUILD_TIMESTAMP) as string) || '',
  GIT_VERSION: (checkForEnvironmentVariable(process.env.REACT_APP_GIT_VERSION) as string) || '',
  APP_VERSION: (checkForEnvironmentVariable(process.env.REACT_APP_VERSION) as string) || '',
  SENTRY_DSN: (checkForEnvironmentVariable(process.env.REACT_APP_SENTRY_DSN) as string) || '',
  USER_PROFILE_URL: (checkForEnvironmentVariable(process.env.REACT_APP_USER_PROFILE_FRONTEND) as string) || '',
  STAGE: (checkForEnvironmentVariable(process.env.REACT_APP_STAGE) as string) || '',
  WEB_URL: (checkForEnvironmentVariable(process.env.REACT_APP_WEB_URL) as string) || '',
  META: {
    MENU_TEXT: (checkForEnvironmentVariable(process.env.REACT_APP_META_MENU_TEXT) as string) || '',
  },
  REACT_APP_API_GQL_HTTP_URI: (checkForEnvironmentVariable(process.env.REACT_APP_API_GQL_HTTP_URI) as string) || '',
  REACT_APP_API_DATA_HTTP_URI: (checkForEnvironmentVariable(process.env.REACT_APP_API_DATA_HTTP_URI) as string) || '',
  REACT_APP_API_XLSX_REPORT_URI:
    (checkForEnvironmentVariable(process.env.REACT_APP_API_XLSX_REPORT_URI) as string) || '',
  REACT_APP_API_PDF_REPORT_URI: (checkForEnvironmentVariable(process.env.REACT_APP_API_PDF_REPORT_URI) as string) || '',
  REACT_APP_LOGIN_URL: (checkForEnvironmentVariable(process.env.REACT_APP_LOGIN_URL) as string) || '',
  REACT_APP_LOGIN_REALM: (checkForEnvironmentVariable(process.env.REACT_APP_LOGIN_REALM) as string) || '',
  REACT_APP_IAM_URL: (checkForEnvironmentVariable(process.env.REACT_APP_IAM_URL) as string) || '',
  LICENSE_FRONTEND_URL: (checkForEnvironmentVariable(process.env.REACT_APP_LICENSE_FRONTEND_URL) as string) || '',
  LICENSE_SERVER_URL: (checkForEnvironmentVariable(process.env.REACT_APP_LICENSE_SERVER_URL) as string) || '',
  VERSION_SERVER_URL: (checkForEnvironmentVariable(process.env.REACT_APP_VERSION_SERVER_URL) as string) || '',
  LICENSE_SERVER_PUBLIC_KEY:
    (checkForEnvironmentVariable(process.env.REACT_APP_LICENSE_SERVER_PUBLIC_KEY) as string) || '',
}
