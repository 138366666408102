import { getFs, getImageStorePath, getPath } from '../repositories/shared/shared'
import { v4 as uuidv4 } from 'uuid'

export const imageAsBase64 = (filename: string, directory: string): Promise<string> => {
  const fs = getFs()
  const path = getPath()
  return new Promise((resolve) => {
    fs.readFile(path.join(directory, filename), { encoding: 'base64' }, (err: any, data: string) => {
      if (err) {
        resolve(
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
        )
      } else {
        resolve(`data:image/${filename.split('.').pop()};base64,${data}`)
      }
    })
  })
}

export const deleteImage = (filename: string): boolean => {
  const fs = getFs()
  const path = getPath()
  fs.unlinkSync(path.join(getImageStorePath(), filename))
  return true
}

export const imagesAsBase64 = async (filenames: string[], directory: string): Promise<string[]> => {
  return await Promise.all(filenames.map((fn) => imageAsBase64(fn, directory)))
}

export const saveNewImage = (image: string): Promise<string> => {
  const fs = getFs()
  const path = getPath()
  const asBase64 = image.replace(/^data:image\/(jp(e?)g|png|gif);base64,/, '')

  const generatedFilename = `${uuidv4()}.jpg`
  return new Promise<string>((resolve, reject) => {
    const imagePath = path.join(getImageStorePath(), generatedFilename)
    fs.writeFile(imagePath, asBase64, 'base64', (err: any) => {
      if (err) {
        console.error(err)
        reject(err)
      } else {
        resolve(generatedFilename)
      }
    })
  })
}

export const copyImage = (image: string): Promise<string> => {
  const fs = getFs()
  const path = getPath()

  const source = path.join(getImageStorePath(), image)
  const newFilename = `${uuidv4()}.jpg`
  const destination = path.join(getImageStorePath(), newFilename)

  return new Promise((resolve, reject) => {
    fs.copyFile(source, destination, (err: any) => {
      if (err) reject(err)
      resolve(newFilename)
    })
  })
}
