import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CompanyParamTypes } from '../../../../interfaces/PageParams'
import {
  GetBuildingsAndPlacesQuery,
  Place,
  useCreatePlaceMutation,
  useMergePlacesMutation,
  useSoftDeletePlaceMutation,
  useUpdatePlaceMutation,
  useGetBuildingsAndPlacesQuery,
} from '../../../../api/models'
import { paddingTop } from '../../../../styles/Shared'
import Grid from '@material-ui/core/Grid'
import { Badge, Box, Button, Modal, SvgIcon, SvgIconProps, TextField, Typography } from '@material-ui/core'
import { useStyles } from '../../partials/MoveLeakageModal/MoveLeakageModalStyle'
import CsButtonPrimary from '@csinstruments/cs-react-theme/dist/components/Buttons/CsButtonPrimary'
import Autocomplete from '@material-ui/lab/Autocomplete'
import AddIcon from '@material-ui/icons/AddOutlined'
import { ProgressBar } from '../../partials/ProgressBar/ProgressBar'
import { CsButtonText } from '@csinstruments/cs-react-theme'
import { Text } from '../../partials/Inputs/Text'
import { ResultAlert } from '../../partials/ResultAlert'
import { CallMerge, Tune } from '@material-ui/icons'
import { buildingCardDefault } from './BuildingCardStyles'
import { StandardConfirmationModal } from '../../partials/StandardConfirmationModal/StandardConfirmationModal'
import Edit from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { useTranslation } from 'react-i18next'
import { SelectInput } from '../../partials/Inputs/Select'
import { JSX } from '@babel/types'
import HomeIcon from '@material-ui/icons/Home'
import { getSelectedRealm } from 'app/helpers/manageRealms'

type FilterModalProps = {
  open: boolean
  handleClose: () => void
  setFilteredBuildings: (filterIDs: string[]) => void
  setFilteredPlaces: (filterIDs: string[]) => void
  filteredBuildings: string[] | undefined
  filteredPlaces: string[] | undefined
  buildings?: GetBuildingsAndPlacesQuery
}

const FilterModal: React.FC<FilterModalProps> = ({
  buildings,
  open,
  filteredBuildings,
  handleClose,
  setFilteredBuildings,
  filteredPlaces,
  setFilteredPlaces,
}) => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const defaultValues = filteredBuildings
    ? buildings?.buildings.filter((b) => filteredBuildings?.includes(b.id))
    : undefined

  const places =
    defaultValues?.map((building) => building.places)?.flat() ||
    buildings?.buildings.map((building) => building.places)?.flat() ||
    []

  const placeDefaultValues = filteredPlaces ? places?.filter((p) => filteredPlaces?.includes(p?.id || '')) : undefined

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <div className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography variant={'h4'}>Filter</Typography>
          </Grid>
          <Grid item xs={12} style={{}}>
            <Autocomplete
              onChange={(event, newValue: any) => setFilteredBuildings(newValue.map((nv: any) => nv?.id))}
              multiple
              defaultValue={defaultValues}
              options={buildings?.buildings || []}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              renderInput={(params) => <TextField {...params} placeholder={t('objects.building')} />}
            />
          </Grid>
          <Grid item xs={12} style={{}}>
            <Autocomplete
              onChange={(event, newValue: any) => setFilteredPlaces(newValue.map((nv: any) => nv?.id))}
              multiple
              defaultValue={placeDefaultValues}
              options={places || []}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              renderInput={(params) => <TextField {...params} placeholder={t('objects.location')} />}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={paddingTop}>
          <Grid item xs={6}>
            <CsButtonText onClick={handleClose}>{t('modals.cancel')}</CsButtonText>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <CsButtonPrimary onClick={handleClose}>{t('modals.confirm')}</CsButtonPrimary>
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}
//return <></>
//}

type MergeModalProps = {
  open: boolean
  handleClose: () => void
  place: Place | undefined
  targetPlaceId?: string
  targetLocationId?: string
  buildings?: GetBuildingsAndPlacesQuery
  setMergeTargetPlaceId: (targetPlaceId: string) => void
  setMergeTargetLocationId: (targetLocationId: string) => void
  handleMerge: () => void
}

const MergeModal: React.FC<MergeModalProps> = ({
  handleClose,
  open,
  place,
  buildings,
  handleMerge,
  targetLocationId,
  targetPlaceId,
  setMergeTargetPlaceId,
  setMergeTargetLocationId,
}) => {
  const [buildingId, setBuildingId] = useState()
  const classes = useStyles()
  const { t } = useTranslation('common')
  const currentBuilding = useMemo(() => {
    return buildings?.buildings.find((b) => {
      if (b?.id == buildingId) {
        return true
      }
      if (!buildingId) {
        const currentPlace = b?.places?.find((p) => p?.id == place?.id)
        if (currentPlace) {
          return true
        }
      }
    })
  }, [buildingId])
  useEffect(() => {
    if (currentBuilding?.id) {
      setMergeTargetLocationId(currentBuilding?.id)
    }
  }, [currentBuilding?.id])
  const places = buildings?.buildings.find((b) => b?.id == currentBuilding?.id)?.places || []

  const disableSave = useMemo(() => {
    if (!targetLocationId || !targetPlaceId) return true

    return place?.id === targetPlaceId
  }, [targetLocationId, targetPlaceId, place])

  //return <></>
  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <div className={classes.paper} style={{ width: 800 }}>
        <Grid container>
          <Grid item xs={12} style={{ textAlign: 'center', paddingBottom: 40 }}>
            <Typography variant={'h4'}>Zusammenfassen</Typography>
          </Grid>
          <Grid item xs={12}>
            <SelectInput
              disabled
              preselect={place?.name ?? ''}
              text={[place?.name ?? '']}
              label={'objects.location'}
              xs={12}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'body2'} style={{ paddingTop: '10px', paddingBottom: '30px' }}>
              verschieben in
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              key={currentBuilding?.id}
              defaultValue={currentBuilding}
              onChange={(event, newValue: any) => {
                // createOrUpdateBuilding(newValue)
                setBuildingId(newValue?.id)
                // setMergeTargetLocationId(newValue?.id)
              }}
              options={buildings?.buildings || []}
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => <TextField {...params} label={t('objects.building')} variant="standard" />}
            />
          </Grid>
          <Grid item xs={12} style={{ paddingTop: '20px', paddingBottom: '10px' }}>
            <Autocomplete
              key={`${buildingId}`}
              onChange={(event, newValue: any) => {
                setMergeTargetPlaceId(newValue?.id)
              }}
              options={places}
              value={places.find((p) => p.id === targetLocationId)}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label={t('objects.location')} variant="standard" />}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginTop: 40, borderTop: '1px solid lightgrey' }}>
          <Grid item xs={6}>
            <CsButtonText onClick={handleClose}>{t('modals.cancel')}</CsButtonText>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <CsButtonPrimary disabled={disableSave} onClick={handleMerge}>
              {t('actions.save')}
            </CsButtonPrimary>
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}

type CreateOrUpdatePlaceModalProps = {
  open: boolean
  handleClose: () => void
  handleCreate: () => void
  handleUpdate: () => void
  setPlaceName: (name: string) => void
  placeName: string
  setBuildingId: (id: string) => void
  buildingId: string
  buildings?: GetBuildingsAndPlacesQuery
  selectedPlaceId?: string
}

const CreateOrUpdatePlaceModal: React.FC<CreateOrUpdatePlaceModalProps> = ({
  buildings,
  open,
  handleClose,
  selectedPlaceId,
  setBuildingId,
  setPlaceName,
  placeName,
  buildingId,
  handleCreate,
  handleUpdate,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('common')

  const currentBuilding = buildings?.buildings?.find((b) => b.id == buildingId)
  //return <></>

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <div className={classes.paper} style={{ width: 800 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ textAlign: 'center', paddingBottom: 40 }}>
            <Typography variant={'h4'}>Neuer Ort</Typography>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              defaultValue={currentBuilding}
              onChange={(event, newValue: any) => setBuildingId(newValue?.id || '')}
              options={buildings?.buildings || []}
              getOptionLabel={(option) => option?.name}
              style={{ marginTop: 15 }}
              renderInput={(params) => <TextField {...params} variant="standard" placeholder="Buildings" />}
            />
          </Grid>
          <Grid item xs={6}>
            <Text
              label={t('actions.add')}
              value={placeName}
              changeHandler={(name) => setPlaceName(name)}
              columns={12}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginTop: 40, borderTop: '1px solid lightgrey' }}>
          <Grid item xs={6}>
            <CsButtonText onClick={handleClose}>{t('modals.cancel')}</CsButtonText>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <CsButtonPrimary
              onClick={selectedPlaceId ? handleUpdate : handleCreate}
              disabled={buildingId?.length < 1 || placeName.length < 1}
            >
              {t('actions.save')}
            </CsButtonPrimary>
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}

type PlaceCardProps = {
  handleUpdate: (placeId: string) => void
  handleDelete: (placeId: string) => void
  handleMergeModalOpen: (place: Place) => void
  place: Place | undefined
}

const PlaceCard: React.FC<PlaceCardProps> = ({ handleDelete, handleUpdate, place, handleMergeModalOpen }) => {
  const { t } = useTranslation('common')
  return (
    <Grid item xs={4} lg={3}>
      <Box border={1} borderRadius={8} borderColor="primary" style={{ ...buildingCardDefault, background: 'white' }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant={'h6'}>
              <PlaceIcon style={{ marginRight: '10px' }} />
              {place?.name}
            </Typography>
            <Typography variant={'body1'}>
              {t('project.nLeakages')}: {place?.usedLeakages || 0}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '30px' }}>
          <Grid container>
            <Grid item xs={3}>
              <Button onClick={() => place?.id && handleUpdate(place.id)}>
                <Edit />
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                onClick={() => place?.id && handleDelete(place.id)}
                disabled={(place?.usedLeakages as number) > 0}
              >
                <DeleteIcon />
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button onClick={() => place && handleMergeModalOpen(place)}>
                <CallMerge />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

export const CompanyPlaces: React.FC = () => {
  //return <></>
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [modalCreateOrUpdateOpen, setModalCreateOrUpdateOpen] = useState<boolean>(false)
  const [modalMergeOpen, setModalMergeOpen] = useState<boolean>(false)
  const [modalFilterOpen, setModalFilterOpen] = useState<boolean>(false)
  const [modalDeleteOpen, setModalDeleteOpen] = useState<boolean>(false)
  const [filteredBuildings, setFilteredBuildings] = useState<string[] | undefined>(undefined)
  const [filteredPlaces, setFilteredPlaces] = useState<string[] | undefined>(undefined)
  const [selectedPlaceId, setSelectedPlaceId] = useState<string>('')
  const [unfilteredBuildings, setUnfilteredBuildings] = useState<GetBuildingsAndPlacesQuery>()
  const [mergeSourcePlace, setMergeSourcePlace] = useState<Place>()
  const [mergeTargetPlaceId, setMergeTargetPlaceId] = useState<string>()
  const [mergeTargetLocationId, setMergeTargetLocationId] = useState<string>()
  const { id: companyId } = useParams<CompanyParamTypes>()
  const {
    data: buildings,
    loading: buildingsLoading,
    refetch,
  } = useGetBuildingsAndPlacesQuery({
    variables: {
      companyId: companyId,
      realmName: getSelectedRealm(),
      filterIDs: filteredBuildings,
      placesFilterIDs: filteredPlaces,
    },
  })
  const [deletePlace] = useSoftDeletePlaceMutation()
  const [buildingId, setBuildingId] = useState<string>('')
  const [placeName, setPlaceName] = useState<string>('')
  const [createPlace] = useCreatePlaceMutation()
  const [updatePlace] = useUpdatePlaceMutation()
  const [mergePlaces] = useMergePlacesMutation()

  if (!buildingsLoading && !unfilteredBuildings && buildings?.buildings && buildings?.buildings?.length >= 1) {
    setUnfilteredBuildings(buildings)
  }

  const handleDelete = () => {
    deletePlace({ variables: { placeID: selectedPlaceId, realmName: getSelectedRealm() } }).then(() => refetch())
    setShowSuccess(true)
  }

  const handleModalDelete = (placeId: string): void => {
    setSelectedPlaceId(placeId)
    setModalDeleteOpen(true)
  }

  const handleMergeModalOpen = (place: Place): void => {
    setMergeSourcePlace(place)
    setModalMergeOpen(true)
  }
  const handleModalUpdate = (placeId: string): void => {
    const currentBuilding = buildings?.buildings.find((b) => b?.places?.find((p) => p?.id == placeId)?.id == placeId)
    const currentPlace = currentBuilding?.places?.find((p) => p?.id == placeId)
    setSelectedPlaceId(placeId)
    currentPlace?.name && setPlaceName(currentPlace.name)
    currentBuilding?.id && setBuildingId(currentBuilding.id)
    setModalCreateOrUpdateOpen(true)
  }

  const handleUpdate = (): void => {
    updatePlace({
      variables: {
        placeInput: { name: placeName, building_id: buildingId },
        placeID: selectedPlaceId,
        realmName: getSelectedRealm(),
      },
    }).then(() => {
      refetch().then((refetchedBuildings) => {
        if (refetchedBuildings) {
          setUnfilteredBuildings({ ...unfilteredBuildings, ...refetchedBuildings.data })
        }
        setModalCreateOrUpdateOpen(false)
        setShowSuccess(true)
      })
    })
  }

  const handleMerge = (): void => {
    if (mergeTargetPlaceId && mergeTargetLocationId) {
      mergePlaces({
        variables: {
          sourcePlaceId: mergeSourcePlace?.id || '',
          targetPlaceId: mergeTargetPlaceId || '',
          targetBuildingId: mergeTargetLocationId || '',
          realmName: getSelectedRealm() || '',
        },
      }).then(() => {
        refetch()
        setModalMergeOpen(false)
        setShowSuccess(true)
      })
    }
  }

  const handleOpenCreateOrUpdateModal = () => {
    setSelectedPlaceId('')
    setBuildingId('')
    setPlaceName('')
    setModalCreateOrUpdateOpen(true)
  }
  const handleOpenFilterModal = () => {
    setModalFilterOpen(true)
  }

  const handleFilter = (filterIDs: string[]) => {
    const newFilterIDs = filterIDs.length > 0 ? filterIDs : undefined
    setFilteredBuildings(newFilterIDs)
  }
  const handleFilterPlaces = (filterIDs: string[]) => {
    const newFilterIDs = filterIDs.length > 0 ? filterIDs : undefined
    setFilteredPlaces(newFilterIDs)
  }

  const handleCreate = () => {
    createPlace({
      variables: {
        placeInput: { building_id: buildingId, name: placeName },
        realmName: getSelectedRealm(),
      },
    }).then((createdResponse) => {
      refetch().then((refetchedBuildings) => {
        setModalCreateOrUpdateOpen(false)
        setShowSuccess(true)
        if (refetchedBuildings) {
          setUnfilteredBuildings({ ...unfilteredBuildings, ...refetchedBuildings.data })
        }
      })
    })
  }

  return (
    <>
      <ResultAlert alertText={'leakage.updateSuccess'} showAlert={showSuccess} modifyShowAlert={setShowSuccess} />
      <MergeModal
        buildings={unfilteredBuildings}
        open={modalMergeOpen}
        handleClose={() => setModalMergeOpen(false)}
        place={mergeSourcePlace}
        setMergeTargetPlaceId={setMergeTargetPlaceId}
        handleMerge={handleMerge}
        targetLocationId={mergeTargetLocationId}
        targetPlaceId={mergeTargetPlaceId}
        setMergeTargetLocationId={setMergeTargetLocationId}
      />
      <CreateOrUpdatePlaceModal
        open={modalCreateOrUpdateOpen}
        buildings={unfilteredBuildings}
        handleClose={() => setModalCreateOrUpdateOpen(false)}
        selectedPlaceId={selectedPlaceId}
        setPlaceName={setPlaceName}
        placeName={placeName}
        setBuildingId={setBuildingId}
        buildingId={buildingId}
        handleCreate={handleCreate}
        handleUpdate={handleUpdate}
      />
      <FilterModal
        filteredBuildings={filteredBuildings}
        filteredPlaces={filteredPlaces}
        open={modalFilterOpen}
        buildings={unfilteredBuildings}
        handleClose={() => setModalFilterOpen(false)}
        setFilteredBuildings={handleFilter}
        setFilteredPlaces={handleFilterPlaces}
      />
      <StandardConfirmationModal
        handleConfirm={() => handleDelete()}
        open={modalDeleteOpen}
        handleClose={() => setModalDeleteOpen(false)}
      />
      <Grid container spacing={3} style={paddingTop}>
        <Grid item xs={6} style={{ textAlign: 'left' }}>
          <CsButtonPrimary onClick={handleOpenCreateOrUpdateModal} style={{}}>
            <AddIcon /> Neuer Ort
          </CsButtonPrimary>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Badge badgeContent={filteredBuildings?.length} color="secondary">
            <CsButtonPrimary
              onClick={handleOpenFilterModal}
              style={{ paddingLeft: 50, paddingRight: 50, borderRadius: '50px' }}
            >
              <Tune /> Filter
            </CsButtonPrimary>
          </Badge>
        </Grid>
      </Grid>
      <ProgressBar show={buildingsLoading} />
      <Grid container justifyContent="flex-start" spacing={3} style={paddingTop}>
        {buildings?.buildings.map((b) => (
          <Grid
            key={b.id}
            container
            spacing={3}
            style={{ background: '#e0dfdf', borderRadius: '15px', marginTop: '20px' }}
          >
            <Grid item xs={12}>
              <Typography variant={'h6'}>
                <HomeIcon color="primary" style={{ marginRight: '10px' }} /> {b.name}
              </Typography>
            </Grid>
            {b?.places?.map((p) => (
              <PlaceCard
                key={p?.id}
                place={p as Place}
                handleDelete={handleModalDelete}
                handleUpdate={handleModalUpdate}
                handleMergeModalOpen={handleMergeModalOpen}
              />
            ))}
          </Grid>
        ))}
      </Grid>
    </>
  )
}

function PlaceIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props}>
      <path
        d="M19 19V4H15V3H5V19H3V21H15V6H17V21H21V19H19ZM13 19H7V5H13V19ZM10 11H12V13H10V11Z"
        fill="black"
        fillOpacity="0.87"
      />
    </SvgIcon>
  )
}
