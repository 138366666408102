import { Building } from '../../interfaces/BusinessObjects'
import { useEffect, useMemo, useState } from 'react'
import {
  CreateBuildingMutationVariables,
  UpdateBuilding,
  useCreateBuildingMutation,
  useDeleteBuildingMutation,
  useDeletedBuildingsQuery,
  useFinalDeleteBuildingMutation,
  useGetBuildingByIdQuery,
  useGetCompanyBuildingsQuery,
  useMergeBuildingsMutation,
  useRestoreBuildingMutation,
  useUpdateBuildingMutation,
} from '../../api/models'
import { getSelectedRealm } from 'app/helpers/manageRealms'
//import { UserAccessContext } from '../components/partials/UserProvider/UserProvider'

export const useBuildings = (
  name: string,
  groupId?: string,
): {
  buildings: Building[]
  loading: boolean
  error: boolean
  canAdd: boolean
  canEdit: boolean
  refreshBuildings: () => void
} => {
  //const { user } = useContext(UserAccessContext)

  const { data, loading, error, refetch } = useGetCompanyBuildingsQuery({
    skip: !name || !getSelectedRealm(),
    variables: { name: decodeURIComponent(name), realmName: getSelectedRealm() || '', groupId: groupId },
  })

  const { canAdd, canEdit } = useMemo(() => {
    // todo ????
    const canAdd = !!data?.getCompany?.meta?.addBuildings
    const canEdit = !!data?.getCompany?.meta?.editBuildings
    return { canAdd, canEdit }
  }, [data])

  const buildings = useMemo(() => {
    const dataBuildings = data?.getCompany?.company?.buildings
    const buildings: Building[] =
      dataBuildings?.map((b) => {
        return {
          name: b.name,
          id: b.id,
          assigned: !!b.assigned,
          places:
            b.places?.map((pl) => {
              return {
                name: '',
                assigned: false,
                id: pl?.id ?? '',
              }
            }) ?? [],
        }
      }) ?? []
    return buildings
  }, [data?.getCompany?.company?.buildings])
  const refresh = (): void => {
    refetch({
      groupId: groupId,
      realmName: getSelectedRealm(),
      name: name,
    })
  }
  return {
    buildings,
    loading,
    error: !!error,
    canAdd,
    canEdit,
    refreshBuildings: refresh,
  }
}

export const useDeletedBuildings = (
  skipFetch?: boolean,
): { buildings: Building[]; loading: boolean; error: boolean } => {
  //const { user } = useContext(UserAccessContext)

  const { data, loading, error } = useDeletedBuildingsQuery({
    variables: { realmName: getSelectedRealm() || '' },
    skip: !getSelectedRealm() || !!skipFetch,
  })
  const buildings: Building[] = useMemo(() => {
    return (
      data?.deletedBuildings.map((db) => {
        return {
          name: db.name,
          _id: db.id,
          path: db.groupPath?.map((pname) => pname).slice(0, db.groupPath.length) ?? undefined,
          _deleted_at: db._deleted_at ? new Date(db._deleted_at) : new Date(0),
        }
      }) ?? []
    )
  }, [data])
  return {
    buildings,
    loading,
    error: !!error,
  }
}

export const useCreateBuilding = (): {
  createBuilding: (building: Building) => Promise<Building>
} => {
  const [createBuildingMut, { data }] = useCreateBuildingMutation()

  //const { user } = useContext(UserAccessContext)

  const createBuilding = async (building: Building): Promise<Building> => {
    if (!building.name) {
      console.error('no name provided, skipping')
      return {}
    }

    const b: UpdateBuilding = {
      company: building.company ?? '',
      name: building.name,
      groupId: building.groupId,
    }
    const variables: CreateBuildingMutationVariables = { building: b, realmName: getSelectedRealm() || '' }
    const { data } = await createBuildingMut({ variables })
    if (data?.createBuilding) {
      return data.createBuilding
    }
    return {}
  }

  return { createBuilding }
}

export const useUpdateBuilding = (): {
  updatedBuilding: boolean
  updateBuilding: (building: Building) => Promise<void>
} => {
  const [updatedBuilding, setUpdatedBuilding] = useState<boolean>(false)
  const [updateBuildingMut] = useUpdateBuildingMutation()
  //const { user } = useContext(UserAccessContext)

  const updateBuilding = async (building: Building): Promise<void> => {
    if (!building.name || !building.id) {
      console.error("name can't be empty")
      return
    }
    setUpdatedBuilding(false)
    const b: UpdateBuilding = {
      company: building.company ?? '',
      name: building.name,
      id: building.id,
      groupId: building.groupId,
    }
    await updateBuildingMut({ variables: { building: b, realmName: getSelectedRealm() || '' } })
    setUpdatedBuilding(true)
  }

  return { updatedBuilding, updateBuilding }
}

export const useDeleteBuilding = (): {
  deleted: Building
  deleteBuilding: (building: Building) => Promise<void>
} => {
  const [deleteMutation] = useDeleteBuildingMutation()
  const [deletedBuilding, setDeletedBuilding] = useState<Building>({})
  //const { user } = useContext(UserAccessContext)

  const deleteBuilding = async (building: Building): Promise<void> => {
    setDeletedBuilding({})
    if (!building.id) {
      console.error('building without ID -- skipping')
      return
    }
    await deleteMutation({ variables: { id: building.id, realmName: getSelectedRealm() || '' } })
    setDeletedBuilding(building)
  }

  return { deleteBuilding, deleted: deletedBuilding }
}

export const useMergeBuildings = (): {
  merged: boolean
  mergeBuildings: (id: string, mergeInto: string) => Promise<void>
} => {
  const [mergeMutation] = useMergeBuildingsMutation()
  const [merged, setMerged] = useState(false)
  //const { user } = useContext(UserAccessContext)

  const mergeBuildings = async (id: string, mergeInto: string): Promise<void> => {
    setMerged(false)
    if (!id || !mergeInto) {
      console.error('building without ID -- skipping')
      return
    }
    await mergeMutation({ variables: { id, mergeInto, realmName: getSelectedRealm() || '' } })
    setMerged(true)
  }

  return { mergeBuildings, merged }
}

export const useFinalDeleteBuilding = (): {
  deletedBuilding: boolean
  deleteBuilding: (name: string) => Promise<void>
} => {
  const [deleteMutation, { data }] = useFinalDeleteBuildingMutation()
  const [success, setSuccess] = useState(false)
  //const { user } = useContext(UserAccessContext)

  const deleteBuilding = async (id: string): Promise<void> => {
    await deleteMutation({ variables: { id, realmName: getSelectedRealm() || '' } })
  }

  useEffect(() => {
    if (data?.deleteBuilding.success) {
      setSuccess(true)
    } else {
      setSuccess(false)
    }
  }, [data])

  return { deleteBuilding, deletedBuilding: success }
}

export const useRestoreBuilding = (): {
  restoredBuilding: boolean
  restoreBuilding: (name: string) => Promise<void>
} => {
  const [restoreMutation, { data }] = useRestoreBuildingMutation()
  const [success, setSuccess] = useState(false)
  //const { user } = useContext(UserAccessContext)

  const restoreBuilding = async (id: string): Promise<void> => {
    await restoreMutation({ variables: { id, realmName: getSelectedRealm() || '' } })
  }

  useEffect(() => {
    if (data?.restoreBuilding.success) {
      setSuccess(true)
    } else {
      setSuccess(false)
    }
  }, [data])

  return { restoreBuilding, restoredBuilding: success }
}

export const useGetBuilding = (buildingId: string): { building?: Building; loadingBuildings?: boolean } => {
  const { data, loading } = useGetBuildingByIdQuery({
    skip: !buildingId || !getSelectedRealm(),
    variables: {
      buildingId: buildingId,
      realmName: getSelectedRealm(),
    },
  })

  const persisedBuilding = useMemo(() => {
    if (data?.getBuildingById) {
      const { name, id, places, assigned } = data.getBuildingById
      return {
        name,
        id,
        assigned: !!assigned,
        places:
          places?.map((pl) => ({
            id: pl?.id ?? '',
            assigned: !!pl?.assigned,
            name: pl?.name ?? '',
          })) ?? [],
      }
    }
  }, [data?.getBuildingById])

  return { building: persisedBuilding, loadingBuildings: loading }
}
