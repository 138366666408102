import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { BuildingCard } from './BuildingCard'
import { paddingTop } from '../../../../styles/Shared'
import {
  useBuildings,
  useCreateBuilding,
  useDeleteBuilding,
  useMergeBuildings,
  useUpdateBuilding,
} from '../../../hooks/buildingHooks'
import { Building } from 'interfaces/BusinessObjects'
import { useParams } from 'react-router-dom'
import { CompanyParamTypes } from '../../../../interfaces/PageParams'
import { StandardConfirmationModal } from '../../partials/StandardConfirmationModal/StandardConfirmationModal'
import { ResultAlert } from '../../partials/ResultAlert'
import { v4 as uuidv4 } from 'uuid'
import { useGetQueryParam } from '../utilHooks'

export const CompanyBuildings: React.FC = () => {
  const { id } = useParams<CompanyParamTypes>()
  const [selected, setSelected] = useState<string>('')
  const companyId = id
  const groupId = useGetQueryParam('groupId')
  const { buildings, canAdd, canEdit, refreshBuildings } = useBuildings(companyId, groupId)
  const { createBuilding } = useCreateBuilding()
  const { deleted, deleteBuilding } = useDeleteBuilding()
  const [createdBuildings, setCreatedBuildings] = useState<Building[]>([])
  const [deletedBuildings, setDeletedBuildings] = useState<Building[]>([])
  const [filteredBuildings, setFilteredBuildings] = useState<Building[]>([])
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { updateBuilding } = useUpdateBuilding()
  const { mergeBuildings } = useMergeBuildings()
  const [error, setError] = useState(false)

  useEffect(() => {
    if (buildings) {
      setFilteredBuildings(buildings)
      setCreatedBuildings(buildings)
    }
  }, [buildings])

  useEffect(() => {
    if (deleted.name && !deletedBuildings.map((b) => b.name).includes(deleted.name)) {
      setDeletedBuildings([...deletedBuildings, deleted])
    }
  }, [deleted, deletedBuildings])

  const buildingClicked = (name: string): void => {
    if (selected === name) {
      setSelected('')
    } else {
      setSelected(name)
    }
  }

  const updateRequested = async (enteredName: string, oldName: string): Promise<void> => {
    const building = [...buildings, ...createdBuildings].filter((b) => b.name === oldName).pop()
    await updateBuilding({ ...building, name: enteredName, company: companyId })
  }

  const deleteRequested = (): void => {
    const building = [...buildings, ...createdBuildings].filter((b) => b.name === selected).pop()
    if (building) deleteBuilding(building)
    setSelected('')
  }

  const childDeleteClicked = (name: string): void => {
    setSelected(name)
    setModalOpen(true)
  }

  const mergeRequested = (mergeFrom: string, mergeInto: string): void => {
    const selBuilding = [...buildings, ...createdBuildings].filter((b) => b.name === mergeFrom).pop()
    const building = [...buildings, ...createdBuildings].filter((b) => b.name === mergeInto).pop()
    mergeBuildings(selBuilding?.id ?? '', building?.id ?? '')
    setDeletedBuildings([...deletedBuildings, selBuilding ?? {}])
  }

  const creationRequested = async (name: string): Promise<void> => {
    const existing = [...buildings, ...createdBuildings].some((b) => b.name === name)
    if (existing) {
      setError(true)
      return
    }

    await createBuilding({ name, company: companyId, id: uuidv4() })
    refreshBuildings()
  }

  return (
    <>
      <StandardConfirmationModal
        handleConfirm={() => deleteRequested()}
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
      />
      <ResultAlert
        alertText={'actions.addBuildingError'}
        severity={'warning'}
        showAlert={error}
        modifyShowAlert={setError}
      />
      <Grid container justifyContent="flex-start" spacing={3} style={paddingTop}>
        {[...buildings]
          .filter((b) => !deletedBuildings.map((d) => d.name).includes(b.name))
          .map((b: Building, ix) => (
            <BuildingCard
              canEdit={canEdit}
              key={!b?.id ? ix : b.id + ix}
              name={b.name}
              mergeAction={mergeRequested}
              modifyAction={updateRequested}
              deleteAction={childDeleteClicked}
              buildings={[...buildings].filter((b2) => b2.name !== b.name)}
              selected={selected === b.name}
              onClick={(name) => buildingClicked(name)}
              deletable={canEdit && !!b.places?.length}
            />
          ))}
        {canAdd && <BuildingCard canEdit={canEdit} editable={true} submitAction={creationRequested} />}
      </Grid>
    </>
  )
}
