import Keycloak, { KeycloakProfile } from 'keycloak-js'
import { createContext, useContext } from 'react'
import { getSelectedRealm } from 'app/helpers/manageRealms'

export interface UserContextData {
  isAuthenticated?: boolean
  keycloak?: Keycloak
  selectRealm: (realmName: string) => void
  globalRealmName: string
}
export const UserContextDefault: UserContextData = {
  isAuthenticated: false,
  keycloak: undefined,
  selectRealm: () => null,
  globalRealmName: getSelectedRealm(),
}
export interface UserProfile extends KeycloakProfile {
  locale?: string
  userName?: string
  preferred_org?: string
  admin_message?: string
}
export const UserContext = createContext<UserContextData>(UserContextDefault)

export const useUserContext = (): UserContextData => useContext(UserContext)
