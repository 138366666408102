import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { theme } from '../../../../styles/AppTheme'
import { fullHeight } from '../../../../styles/Shared'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navigationMain: {
      backgroundColor: theme.palette.secondary.main,
      marginBottom: '10px',
    },
    navigationBreadcrumbStyle: {
      color: theme.palette.secondary.main,
      paddingTop: 30,
      paddingBottom: 30,
      ...fullHeight,
    },
    navigationLinkStyle: { color: theme.palette.secondary.main, ...fullHeight },
  }),
)

// export const navigationBreadcrumbStyle = { color: theme.brandColor, paddingTop: 30, paddingBottom: 30, ...fullHeight }

// export const navigationLinkStyle = { color: theme.brandColor, ...fullHeight }
