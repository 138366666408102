import React, { ChangeEvent, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { actionIcon, buildingCardDefault, buildingName, editIcon, textField } from './BuildingCardStyles'
import { Button, Collapse, List, ListItem, ListItemText, Select, Tooltip, Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField/TextField'
import AddIcon from '@material-ui/icons/AddCircle'
import DeleteIcon from '@material-ui/icons/Delete'
import HomeIcon from '@material-ui/icons/Home'
import Box from '@material-ui/core/Box/Box'
import Edit from '@material-ui/icons/Edit'
import CheckCircle from '@material-ui/icons/CheckCircle'
import { TranslatedCsButton } from '../../partials/Buttons/TranslatedCsButton'
import TrendingFlat from '@material-ui/icons/TrendingFlat'
import CancelIcon from '@material-ui/icons/Cancel'
import { Building, Place } from '../../../../interfaces/BusinessObjects'
import MenuItem from '@material-ui/core/MenuItem'
import ClearIcon from '@material-ui/icons/Clear'
import { useTranslation } from 'react-i18next'

export type BuildingCardCardProps = {
  name?: string
  highlight?: boolean
  onClick?: (name: string) => void
  selected?: boolean
  editable?: boolean
  modifyAction?: (entered: string, name: string) => void
  submitAction?: (textEntered: string) => void
  deleteAction?: (name: string) => void
  buildings?: Building[]
  mergeAction?: (mergeFrom: string, mergeInto: string) => void
  canEdit: boolean
  deletable?: boolean
}

export const BuildingCard: React.FC<BuildingCardCardProps> = ({
  name,
  modifyAction,
  submitAction,
  editable,
  deleteAction,
  buildings,
  mergeAction,
  canEdit,
  deletable,
}) => {
  const [enteredText, setEnteredText] = useState<string>('')
  const [editing, setEditing] = useState<boolean>(false)
  const [updatedName, setUpdatedName] = useState<string | undefined>(name)
  const [mergeBuilding, setMergeBuilding] = useState('')
  const { t } = useTranslation('common')

  const handleNewSubmission = (): void => {
    if (!editing && submitAction && enteredText) {
      submitAction(enteredText)
      setEnteredText('')
    } else if (editing && modifyAction) {
      modifyAction(enteredText, updatedName ?? '')
      setUpdatedName(enteredText)
      setEditing(false)
    }
  }

  useEffect(() => {
    setEnteredText(name ?? '')
  }, [name])

  const handleChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>): void => {
    const newSelected = e.target.value as string
    setMergeBuilding(newSelected)
  }

  const merge = (): void => {
    if (mergeAction) {
      mergeAction(updatedName ?? '', mergeBuilding)
    }
  }

  return (
    <>
      <Grid item xs={4} lg={3}>
        <Box border={1} borderRadius={8} borderColor="primary" style={buildingCardDefault}>
          {updatedName && !editing && (
            <>
              <div style={{ width: '100%' }}>
                <HomeIcon color="primary" />
                <Typography color="primary" style={buildingName} variant={'h6'}>
                  {updatedName}
                </Typography>
              </div>
              {canEdit && (
                <div>
                  <div style={{ display: 'inline' }}>
                    <Select
                      onChange={handleChange}
                      displayEmpty
                      fullWidth
                      value={mergeBuilding}
                      style={{ bottom: 7, width: '60%' }}
                    >
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      {(buildings || []).map((b) => (
                        <MenuItem key={b.name} value={b.name}>
                          {b.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  {!mergeBuilding?.trim() && (
                    <>
                      <Tooltip title={!!deletable ? '' : (t('actions.delete') as string)}>
                        <Button onClick={() => deleteAction && deleteAction(updatedName)} disabled={!!deletable}>
                          <DeleteIcon style={{ ...actionIcon, marginLeft: 20 }} />
                        </Button>
                      </Tooltip>
                      <Tooltip title={t('actions.edit') as string}>
                        <Edit
                          style={{ ...actionIcon, marginLeft: 10 }}
                          color="primary"
                          onClick={() => setEditing(true)}
                        />
                      </Tooltip>
                    </>
                  )}
                  {mergeBuilding?.trim() && (
                    <>
                      <Tooltip title={t('actions.move') as string}>
                        <TrendingFlat style={{ ...actionIcon, marginLeft: 20 }} color="primary" onClick={merge} />
                      </Tooltip>
                      <Tooltip title={t('actions.cancel') as string}>
                        <ClearIcon
                          style={{ ...actionIcon, marginLeft: 10 }}
                          color="primary"
                          onClick={() => setMergeBuilding('')}
                        />
                      </Tooltip>
                    </>
                  )}
                </div>
              )}
            </>
          )}
          {(editable || editing) && (
            <div style={{ width: '100%' }}>
              <div>
                <HomeIcon color="primary" />
                <TextField
                  size="small"
                  style={textField}
                  onKeyDown={(e) => e.key === 'Enter' && handleNewSubmission()}
                  value={enteredText ?? ''}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setEnteredText(e.target.value)}
                />
              </div>
              <div style={editIcon}>
                {editing && (
                  <>
                    <TranslatedCsButton small textButton title={'actions.cancel'} onClick={() => setEditing(false)}>
                      {editing && <CancelIcon />}
                    </TranslatedCsButton>
                  </>
                )}
                <TranslatedCsButton small textButton title={'actions.add'} onClick={handleNewSubmission}>
                  {!editing && <AddIcon />}
                  {editing && <CheckCircle />}
                </TranslatedCsButton>
              </div>
            </div>
          )}
        </Box>
      </Grid>
    </>
  )
}
