import { CsButton, CsButtonPrimary, CsButtonSecondary, FixedActionBar } from '@csinstruments/cs-react-theme'
import { Grid, Typography } from '@material-ui/core'
import {
  useGetManageAbleAttributesQuery,
  useUpdateTagAttributeMutation,
  useSoftDeleteTagAttributeMutation,
  TagAttribute,
  Attribute,
} from 'api/models'
import { DataTable } from 'app/components/partials/DataTable/DataTable'
import { DataItemAction, DataTableColumn, DataTableRow } from 'app/components/partials/DataTable/DataTableTypes'
import Search from 'app/components/partials/Inputs/Search'
import { ResultAlert } from 'app/components/partials/ResultAlert'
import { StandardConfirmationModal } from 'app/components/partials/StandardConfirmationModal/StandardConfirmationModal'
import { CreateUpdateTagAttribute } from 'app/components/partials/TagAttributes/CreateUpdateTagAttribute'
import { getSelectedRealm } from 'app/helpers/manageRealms'
import { useCompanies, useCompaniesInGroup } from 'app/hooks/companyHooks'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddIcon from '@material-ui/icons/AddOutlined'
import DeleteIcon from '@material-ui/icons/Delete'
import { useHistory } from 'react-router-dom'
import { ROUTES } from 'app/helpers/routes'
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined'

type DisplayAttribute = Attribute & {
  name: string
}
type DisplayTagAttribute = {
  tagID: string
  tagName: string
  attributes: string[]
}
export const Tagmanagement: React.FC = () => {
  const { t } = useTranslation('common')
  const history = useHistory()
  const [selected, setSelected] = useState<string[]>([])
  const [editTagMode, setEdiTagMode] = useState<boolean>(false)
  const [selectedTagAtrb, setSelectedTagAtrb] = useState<TagAttribute[]>([])

  const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
  //const [copyOnly, setCopyOnly] = useState<boolean>(false)
  const [copyToGroupModal, setCopyToGroupModal] = useState<boolean>(false)
  const [tableRows, setTableRows] = useState<DataTableRow[]>([])
  //const [targetGroupId, setTargetGroup] = useState<string>('')
  const [warning, setWarning] = useState(false)

  const [searchString, setSearchString] = useState<string>()
  const [attrTags, setAttrTags] = useState<DisplayTagAttribute[]>([])
  const { data, refetch } = useGetManageAbleAttributesQuery({
    variables: { realmName: getSelectedRealm() },
    skip: !getSelectedRealm(),
  })
  const { groupCompanyData, refresh } = useCompaniesInGroup()
  const [_, companies] = groupCompanyData
  //const { companies } = useCompanies()

  const [softDeleteTagAttrFunc] = useSoftDeleteTagAttributeMutation()
  const allTagAttribues = useMemo(() => {
    if (!data?.getAllTagAttributes.length) return []
    return data.getAllTagAttributes
  }, [data?.getAllTagAttributes])
  const confirmDelete = async (id: string) => {
    if (id && getSelectedRealm()) {
      const deletedDa = await softDeleteTagAttrFunc({
        variables: {
          ID: id,
          realmName: getSelectedRealm(),
        },
      })
      if (deletedDa.data?.softDeleteTagAttribute) {
        refetch()
      }
    }
  }
  useEffect(() => {
    if (data?.getAllTagAttributes && companies.length) {
      const transToDisp = data?.getAllTagAttributes.map((tg) => ({
        tagID: tg.ID,
        tagName: tg.name,
        attributes: companies
          .filter((c) => tg.attributes.some((tga) => tga.attributeID === c._id))
          .map((cmp) => cmp.name)
          .filter((compname) => compname && compname) as string[],
      }))

      setAttrTags(transToDisp)
      const tRows = transToDisp
        .sort((tg1, tg2) => (tg1.tagName > tg2.tagName ? 1 : -1))
        .map((atg) => ({
          id: atg.tagID,
          values: atg.attributes.length ? [atg.tagName, `${atg.attributes.map((atb) => atb)}`] : [atg.tagName, ''],
          scopes: atg.tagName ? ['edit'] : [],
        }))
      setTableRows(tRows)
    }
  }, [companies, data])

  const groupTableColumns: DataTableColumn[] = [
    { label: t('objects.groups') },
    { label: 'objects.companies', isChipType: true },
  ]

  // const tableRows: DataTableRow[] = useMemo(() => {
  //   if (!attrTags.length) return []
  //   return attrTags
  //     .sort((tg1, tg2) => (tg1.tagName > tg2.tagName ? 1 : -1))
  //     .map((atg) => ({
  //       id: atg.tagID,
  //       values: atg.attributes.length ? [atg.tagName, `${atg.attributes.map((atb) => atb)}`] : [atg.tagName, ''],
  //       scopes: atg.tagName ? ['edit'] : [],
  //     }))
  // }, [attrTags, companies])

  function tableButtonClicked(id: string, action: DataItemAction): void {
    const foundTagAttr = allTagAttribues.find((c) => c.ID === id)

    if (foundTagAttr) {
      switch (action) {
        case 'edit':
          setSelectedTagAtrb([foundTagAttr])
          setEdiTagMode(true)
          break

        default:
          break
      }
    }
  }

  const onCloseModal = () => {
    setCopyToGroupModal(false)
    setSelected([])
    //selectionChanged([])
  }

  function handleDelete(): void {
    const ifAttributesExist = allTagAttribues.some((at) => selected.includes(at.ID) && at.attributes.length)
    if (!ifAttributesExist) {
      selected.forEach((sid) => confirmDelete(sid))
    } else {
      setWarning(true)
    }
  }

  function newGroup(): void {
    setCopyToGroupModal(true)
    //throw new Error('Function not implemented.')
  }

  function deleteGroups(): void {
    setDeleteDialog(true)
  }

  const refreshTagCompanyData = () => {
    refetch()
    refresh()
  }

  return (
    <>
      <CreateUpdateTagAttribute
        openModal={copyToGroupModal}
        closeModal={onCloseModal}
        selectedTagAtt={() => {
          return
        }}
        companyIDs={[]}
        textInfo={t('objects.editGroupInfo')}
        currentTagAttributes={allTagAttribues}
        currentCompanies={companies}
        refreshTags={refreshTagCompanyData}
      />

      <CreateUpdateTagAttribute
        openModal={editTagMode}
        closeModal={() => setEdiTagMode(false)}
        selectedTagAtt={setSelectedTagAtrb}
        companyIDs={selectedTagAtrb.length ? selectedTagAtrb[0].attributes.map((at) => at.attributeID) : []}
        textInfo={t('objects.editGroupInfo')}
        currentTagAttributes={selectedTagAtrb}
        currentCompanies={companies}
        isEditMode={editTagMode}
        refreshTags={refreshTagCompanyData}
      />
      <FixedActionBar
        disabled={[selected.length === 0, false]}
        buttonProps={[{}, { solid: true }]}
        actions={[deleteGroups, newGroup]}
        labels={['actions.delete', 'actions.new']}
        icons={[DeleteIcon, AddIcon]}
      />
      <StandardConfirmationModal
        handleConfirm={handleDelete}
        open={deleteDialog}
        handleClose={() => setDeleteDialog(false)}
      />

      <ResultAlert
        alertText={'objects.deleteGroupWarning'}
        showAlert={warning}
        modifyShowAlert={() => {
          setWarning(false)
        }}
        severity="warning"
        autoHideDuration={5000}
      />
      <Grid container>
        <Grid container justifyContent="flex-start" spacing={3}>
          <Grid item xs={12}>
            <Grid spacing={3} container direction="column" justifyContent="flex-start">
              <Grid item xs={12}>
                <CsButton title={t('actions.backToCompaniesOverview')} onClick={() => history.push(ROUTES.companies)}>
                  <BusinessOutlinedIcon /> {' ' + t('actions.backToCompaniesOverview')}
                </CsButton>
              </Grid>
              <Grid item xs={12}>
                <Search changeFunction={setSearchString} label={t('actions.search')} />
              </Grid>
              <Grid item xs={12}>
                <Typography align="left" variant="subtitle2">
                  {'* ' + t('objects.groupNote')}{' '}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <DataTable
                  rows={tableRows}
                  columns={groupTableColumns}
                  allowedActions={{
                    editable: true,
                    checkable: true,
                    //sortable: true,
                  }}
                  filterString={searchString}
                  filterColumn={0}
                  onSelectionChanged={setSelected}
                  onButtonClicked={tableButtonClicked}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
