import { DataTableRow } from 'app/components/partials/DataTable/DataTableTypes'
import { DisplayCompany } from '../../../../interfaces/BusinessObjects'

import { TFunction } from 'i18next'

export const convertCompaniesToRows = (companies: DisplayCompany[], t: TFunction): DataTableRow[] => {
  return companies.map((c) => {
    // const buildings = c.buildings ?? []
    // const projects = c.projects ?? []
    let companyName = ''
    if (c.name) {
      companyName += c.name
      if (c._isRestored) {
        companyName += ' ' + t('company.isRestored')
      }
    }
    const path = c.path ? c.path.join(',') : ''
    return {
      id: c._id ?? '',
      values: [path, companyName, c.nBuildings, c.nProjects] as string[],
      scopes: ['view', 'edit'],
    }
  })
}
