import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { TFunction } from 'i18next'
import { routeLabels } from '../helpers/routes'

const getPathNames = (pathname: string, includeHome: boolean): string[] => {
  let pathNames = pathname.split('/').filter((x) => x)
  if (pathNames.length === 0 && includeHome) {
    pathNames = ['']
  }
  return pathNames.slice(0, 5)
}

export const getPathTitle = (path: string, t: TFunction): string => {
  const label = routeLabels.get(path)
  if (typeof label === 'string') {
    return t(label)
  }
  return path
}

export const usePathNames = (includeHome = false): [string[], string[]] => {
  const { t } = useTranslation('common')
  const location = useLocation()

  const pathNames = getPathNames(location.pathname, includeHome)
  const pathTitles = pathNames.map((pn) => getPathTitle(pn, t))

  return [pathNames, pathTitles]
}
