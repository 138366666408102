import React, { useCallback, useEffect, useState } from 'react'
import Search from '../../partials/Inputs/Search'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { paddingBottomActionBar, paddingTopBottom } from '../../../../styles/Shared'
import AddIcon from '@material-ui/icons/AddOutlined'
import DeleteIcon from '@material-ui/icons/Delete'
import { generatePath, useHistory } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routes'

import { convertCompaniesToRows } from '../CompaniesOverview/conversion'
import { useCopyMoveCompanies, useDeleteCompany } from '../../../hooks/companyHooks'
import { FixedActionBar } from '@csinstruments/cs-react-theme/dist/components/FixedActionBar/FixedActionBar'
import { StandardConfirmationModal } from '../../partials/StandardConfirmationModal/StandardConfirmationModal'
import { ResultAlert } from 'app/components/partials/ResultAlert'
import { DisplayCompany } from 'interfaces/BusinessObjects'
import { useGetQueryParam } from '../utilHooks'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlatOutlined'
import { CreateUpdateTagAttribute } from 'app/components/partials/TagAttributes/CreateUpdateTagAttribute'
import { TagAttribute } from 'api/models'
import { DataItemAction, DataTableColumn, DataTableRow } from 'app/components/partials/DataTable/DataTableTypes'
import DataTable from 'app/components/partials/DataTable/DataTable'

const columns: DataTableColumn[] = [
  { label: 'objects.groups', alignment: 'left', isChipType: true, maxChipItems: 2 },
  { label: 'objects.companies', alignment: 'left' },
  { label: 'objects.buildings', alignment: 'right', numeric: true },
  { label: 'objects.projects', alignment: 'right', numeric: true },
]
export type CompaniesOverviewProps = {
  companies: DisplayCompany[]
  allCompanies: DisplayCompany[]
  tagAttributes: TagAttribute[]
  canCreateCompany: boolean
  rootAccess: boolean
  refresh: () => void
}
export const CompaniesOverview: React.FC<CompaniesOverviewProps> = ({
  companies,
  canCreateCompany,
  tagAttributes,
  rootAccess,
  allCompanies,
  refresh,
}: CompaniesOverviewProps) => {
  const { t } = useTranslation('common')
  const [searchString, setSearchString] = useState<string>('')
  const [selected, setSelected] = useState<string[]>([])
  const [rows, setRows] = useState<DataTableRow[]>([])
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
  //const [copyOnly, setCopyOnly] = useState<boolean>(false)
  const [copyToGroupModal, setCopyToGroupModal] = useState<boolean>(false)
  //const [targetGroupId, setTargetGroup] = useState<string>('')
  const [warning, setWarning] = useState(false)
  const { deleteCompany } = useDeleteCompany()
  const history = useHistory()
  const groupId = useGetQueryParam('groupId')
  //const { copyMove, moveSucess } = useCopyMoveCompanies(selected, copyOnly)

  //const { companies, rootAccess, editAccess } = useCompanies()
  //console.log('companies', companies)

  useEffect(() => {
    // if setRows(convertCompaniesToRows(companies)) on component level:
    // setRows -> triggers -> companies -> triggers -> setRows
    setRows(convertCompaniesToRows(companies, t))
  }, [companies, t, allCompanies])

  const deleteAction = (): void => setDeleteDialog(true)
  const handleDelete = (): void => {
    if (selected.every((sCompany) => companies.find((c) => c._id === sCompany)?.canEdit)) {
      selected.map((companyName) => {
        deleteCompany(companyName, groupId)
        setRows((currArr) => currArr.filter((company) => company.id !== companyName))
      })
    } else {
      setWarning(true)
    }
  }

  const tableButtonClicked = (id: string, action: DataItemAction): void => {
    const foundCompany = companies.find((c) => c._id === id)

    if (foundCompany) {
      let subpage = ''
      switch (action) {
        case 'edit':
          if (!foundCompany?.canEdit) {
            setWarning(true)
            return
          }
          subpage = 'master'
          break
        case 'view':
          subpage = 'projects'
          break
        default:
          break
      }

      const routePath = generatePath(ROUTES.companyAction, { id: foundCompany._id, action: subpage }) + '?edit=true'
      //const groupSubPath = !groupId ? undefined : `&groupId=${groupId}`
      // if (foundCompany.groupId && foundCompany.groupId !== '0') {
      //   routePath += `&groupId=${foundCompany.groupId}`
      // }
      history.push(routePath)
    }
  }

  const newCompanyAction = (): void => {
    let newCompanyPath = generatePath(ROUTES.newCompany)
    if (groupId) {
      newCompanyPath += `?groupId=${groupId}`
    }
    history.push(newCompanyPath)
  }

  const selectionChanged = useCallback(
    (selection: string[]) => {
      setSelected(selection)
    },
    [selected],
  )

  const moveAction = () => {
    setCopyToGroupModal(true)
  }
  // useEffect(() => {
  //   if (moveSucess) {
  //     selectionChanged([])
  //     refresh()
  //   }
  // }, [moveSucess])

  const onCloseModal = () => {
    refresh()
    setCopyToGroupModal(false)
    setSelected([])
    //selectionChanged([])
  }

  // const confirmGroupAction = async () => {
  //   await copyMove(targetGroupId)
  //   setRows([])
  //   setCopyToGroupModal(false)
  // }
  return (
    <>
      <CreateUpdateTagAttribute
        openModal={copyToGroupModal}
        closeModal={onCloseModal}
        selectedTagAtt={() => {
          return
        }}
        companyIDs={selected}
        textInfo={t('objects.editGroupInfo')}
        currentTagAttributes={tagAttributes}
        currentCompanies={allCompanies}
      />
      <FixedActionBar
        disabled={[selected.length === 0, false]}
        buttonProps={[{}, { solid: true }]}
        actions={[deleteAction, newCompanyAction]}
        labels={['actions.delete', 'actions.new']}
        conditions={[canCreateCompany, canCreateCompany]}
        icons={[DeleteIcon, AddIcon]}
      />
      <StandardConfirmationModal
        handleConfirm={handleDelete}
        open={deleteDialog}
        handleClose={() => setDeleteDialog(false)}
      />

      <ResultAlert
        alertText={'company.errorWarning'}
        showAlert={warning}
        modifyShowAlert={() => {
          setWarning(false)
        }}
        severity="warning"
        autoHideDuration={5000}
      />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Search changeFunction={setSearchString} label={t('actions.search') + ' ' + t('objects.companies')} />
        </Grid>
        <Grid item xs={12}>
          <DataTable
            rows={rows}
            columns={columns}
            onSelectionChanged={selectionChanged}
            allowedActions={{
              editable: canCreateCompany,
              checkable: canCreateCompany,
              viewable: true,
              sortable: true,
            }}
            filterColumn={1}
            onButtonClicked={tableButtonClicked}
            filterString={searchString}
          />
        </Grid>
      </Grid>
    </>
  )
}
