export const backgroundImage = {
  position: 'fixed' as const,
  bottom: 0,
  right: 0,
  zIndex: 0,
  width: '30%',
  pointerEvents: 'none' as const,
}

export const aboveBackgroundImage = {
  zIndex: 10,
  position: 'relative' as const,
}
