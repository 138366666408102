import { Grid, Paper } from '@material-ui/core'
import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react'
import CsButton from '@csinstruments/cs-react-theme/dist/components/Buttons/CsButton'
import { useTranslation } from 'react-i18next'
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined'
import chart from '../../../../assets/images/chart.png'
import { calculateEnergyCosts, calculateEnergyTotalCosts } from '../../../helpers/calculations'
import { getLabelForCalcCostBaseFromProject } from '../../../helpers/projectUtils'
import { isCfUnit } from '../../../../interfaces/Units'

type ProjectCostInfoProps = {
  energyAmount?: string
  energyCost?: string
  costUnit?: string
  unitV?: string
  cMod?: string
  setCost: (cost: number, label: string, cMod: string) => void
  setCostBase?: (newCostBase: string) => void
}

export const ProjectCostInfo: FC<ProjectCostInfoProps> = ({
  energyAmount,
  energyCost,
  costUnit,
  unitV,
  cMod = '0x01',
  setCost,
  setCostBase,
}): ReactElement => {
  const { t } = useTranslation('common')
  const [isFullCost, setIsFullCost] = useState(false)

  const labelEnergyPrefix = getLabelForCalcCostBaseFromProject(undefined, '0x01', t)
  const labelTotalPrefix = getLabelForCalcCostBaseFromProject(undefined, '0x03', t)

  useEffect(() => {
    if (cMod.endsWith('3')) {
      setIsFullCost(true)
    }
    if (cMod.endsWith('1')) {
      setIsFullCost(false)
    }
  }, [cMod])

  const energyCostCalc = useMemo(() => {
    if (costUnit)
      return calculateEnergyCosts(unitV, parseFloat(`${energyAmount}`), parseFloat(`${energyCost}`), costUnit, t)
  }, [costUnit, energyAmount, energyCost, t, unitV])

  const energyCostCalcTotal = useMemo(() => {
    if (costUnit)
      return calculateEnergyTotalCosts(unitV, parseFloat(`${energyAmount}`), parseFloat(`${energyCost}`), costUnit, t)
  }, [costUnit, energyAmount, energyCost, t, unitV])
  /* const energyCostCalcTotal = calculateEnergyTotalCosts(
    unitV,
    parseFloat(`${energyAmount}`),
    parseFloat(`${energyCost}`),
    costUnit,
    t,
  ) */

  useEffect(() => {
    if (isFullCost && energyCostCalcTotal?.resultAsFormattedString) {
      setCostBase && setCostBase(energyCostCalcTotal.resultAsFormattedString)
      //setCost(energyCostCalcTotal.result, labelTotalPrefix, '0x03')
    }
    if (!isFullCost && energyCostCalc?.resultAsFormattedString) {
      setCostBase && setCostBase(energyCostCalc.resultAsFormattedString)
      //setCost(energyCostCalc?.result, labelEnergyPrefix, '0x01')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyCostCalc?.resultAsFormattedString, energyCostCalcTotal?.resultAsFormattedString, isFullCost])

  if (!energyAmount || !energyCost || !costUnit || !unitV) return <></>
  const n = parseFloat(`${energyCost}`) * parseFloat(`${energyAmount}`) * (isCfUnit(unitV) ? 1 : 1000)
  if (isNaN(n)) {
    return <></>
  }

  const labelEnergy = energyCostCalc?.text

  const labelTotal = energyCostCalcTotal?.text
  return (
    <>
      <Grid item xs={8}>
        <CsButton
          label={labelEnergy}
          fullwidth
          color={'primary'}
          solid={!isFullCost}
          onClick={() => {
            if (energyCostCalc?.result) {
              setCost(energyCostCalc?.result, labelEnergyPrefix, '0x01')
              setIsFullCost(false)
            }
          }}
        >
          <ArrowDropUpOutlinedIcon />
        </CsButton>
      </Grid>
      <Grid item xs={8}>
        <CsButton
          color={'primary'}
          solid={isFullCost}
          label={labelTotal}
          fullwidth
          onClick={() => {
            if (energyCostCalcTotal?.result) {
              setCost(energyCostCalcTotal.result, labelTotalPrefix, '0x03')
              setIsFullCost(true)
            }
          }}
        >
          <ArrowDropUpOutlinedIcon />
        </CsButton>
      </Grid>
      <Grid item xs={8}>
        <Paper variant="outlined" style={{ padding: 20 }}>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <div>
              <img src={chart} alt="energy chart" />
            </div>
            <div>
              <ChartLegend color="#a4a4a4" label={t('project.energyCost') + ' [70%]'} />
              <ChartLegend color="#c35810" label={t('project.investmentCost') + ' [21%]'} />
              <ChartLegend color="#fbbd02" label={t('project.maintenanceCost') + ' [9%]'} />
            </div>
          </div>
        </Paper>
      </Grid>
    </>
  )
}

type ChartLegendProps = {
  color: string
  label: string
}

const ChartLegend: FC<ChartLegendProps> = ({ color, label }): ReactElement => {
  return (
    <div style={{ padding: 5, paddingLeft: 50 }}>
      <span
        style={{
          display: 'inline-block',
          width: '15px',
          height: '15px',
          background: color,
        }}
      />{' '}
      <span style={{ verticalAlign: 'top' }}>{label}</span>
    </div>
  )
}
