import { CsButton, CsButtonPrimary } from '@csinstruments/cs-react-theme'
import { Badge, Button, Grid, TextField, Typography } from '@material-ui/core'

import { useCompaniesInGroup } from 'app/hooks/companyHooks'
import { usePermissions } from 'app/hooks/usePermissions'
import { DisplayCompany, DisplayGroup } from 'interfaces/BusinessObjects'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddIcon from '@material-ui/icons/AddOutlined'
import { Tune } from '@material-ui/icons'

import { CompaniesOverview } from './CompaniesOverview'
import { TagAttribute, useGetTagAttributesQuery } from 'api/models'
import { paddingTopBottom, paddingBottomActionBar } from 'styles/Shared'
import { CreateUpdateTagAttribute } from 'app/components/partials/TagAttributes/CreateUpdateTagAttribute'
import { TagAttributeFilter } from 'app/components/partials/TagAttributes/TagAttributeFilter'
import { getSelectedRealm } from 'app/helpers/manageRealms'

import { useHistory } from 'react-router-dom'
import { ROUTES } from 'app/helpers/routes'
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined'

type GroupsOverviewProps = {
  groupId?: string
  groupsInfo?: DisplayGroup[]
  company?: DisplayCompany[]
}

const initDisplayGroups: DisplayGroup[] = [
  {
    id: '0',
    groupName: 'Europe',
    subGroups: [
      {
        id: '01',
        groupName: 'Germany',
        parentGroupId: '0',
      },
      {
        id: '02',
        groupName: 'Italie',
        parentGroupId: '0',
      },
      {
        id: '03',
        groupName: 'Belgium',
        parentGroupId: '0',
      },
    ],
  },
]

const GroupsOverview: React.FC<GroupsOverviewProps> = () => {
  const { t } = useTranslation('common')

  const [selectedTags, setSelectedTags] = useState<TagAttribute[]>([])
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false)
  const [openFilterModal, setFilterModal] = useState<boolean>(false)
  const [comps, setCompsList] = useState<DisplayCompany[]>([])
  const [allCompanies, setAllCompanies] = useState<DisplayCompany[]>([])
  const permissions = usePermissions()
  const history = useHistory()
  const { groupCompanyData, refresh } = useCompaniesInGroup()
  const { data, refetch } = useGetTagAttributesQuery({
    variables: {
      realmName: getSelectedRealm(),
    },
    skip: !getSelectedRealm(),
  })
  const [_, companiesList] = groupCompanyData

  const [rootAdmin, canCreateCompany, canCreateGroup] = useMemo(() => {
    return [!!permissions.rootAdmin, !!permissions.addCompanies, !!permissions.addGroup]
  }, [permissions])

  useEffect(() => {
    if (companiesList.length) {
      setAllCompanies(companiesList)
      const tmpCompanyIDS = selectedTags
        .map((ta) => ta.attributes)
        .flat()
        .map((aid) => aid.attributeID)
      const filterdComps = companiesList.filter((c) => c._id && tmpCompanyIDS.includes(c?._id))
      selectedTags.length ? setCompsList(filterdComps) : setCompsList(companiesList)
    }
  }, [companiesList, selectedTags])

  const handleOpenModal = () => {
    setOpenCreateModal(true)
  }

  const refreshData = () => {
    refresh()
    refetch()
  }
  const closeCreateModal = () => {
    refreshData()
    setOpenCreateModal(false)
  }
  const handleOpenFilterModal = () => {
    setFilterModal(true)
  }
  const closeFilterModal = () => {
    setFilterModal(false)
  }

  const handleFilter = (tagAttibs: TagAttribute[]) => {
    setSelectedTags(tagAttibs)
    const tmpCompanyIDS = tagAttibs
      .map((ta) => ta.attributes)
      .flat()
      .map((aid) => aid.attributeID)
    const filterdComps = allCompanies.filter((c) => c._id && tmpCompanyIDS.includes(c?._id))
    setCompsList(filterdComps)
  }

  const tagOptions = useMemo(() => {
    if (!data?.getTagAttributes.length) return []
    return data.getTagAttributes
  }, [data?.getTagAttributes])

  function handleManageGroups(): void {
    history.push(ROUTES.manageGroups)
  }

  return (
    <div style={{ ...paddingTopBottom, ...paddingBottomActionBar }}>
      <CreateUpdateTagAttribute
        openModal={openCreateModal}
        closeModal={closeCreateModal}
        selectedTagAtt={handleFilter}
        currentTagAttributes={tagOptions}
        currentCompanies={allCompanies}
        textInfo={t('objects.newGroupInfo')}
        enableCreate
        companyIDs={[]}
      />
      <TagAttributeFilter
        showFilterOption={openFilterModal}
        closeFilterOptions={closeFilterModal}
        filterCompanies={handleFilter}
        filterAttributes={selectedTags}
        tagAttributeOptions={tagOptions}
      />
      <Grid container spacing={2}>
        <Grid item container justifyContent="space-between">
          <Grid item xs={2}>
            {rootAdmin ? (
              <>
                {/* <CsButtonPrimary onClick={handleOpenModal}>
                  <AddIcon /> {t('objects.addNewGroup')}
                </CsButtonPrimary> */}

                <CsButton label={t('objects.manageGroups')} onClick={handleManageGroups}>
                  <AccountTreeOutlinedIcon />
                </CsButton>
              </>
            ) : null}
          </Grid>
          <Grid item xs={8} />
          {/* <Grid item xs={3}>
            {rootAdmin ? <ManageTagAttributes refreshCompanies={refreshData} companies={allCompanies} /> : null}
          </Grid> */}
          <Grid item xs={2}>
            <Badge badgeContent={selectedTags?.length} color="secondary">
              <CsButtonPrimary onClick={handleOpenFilterModal} style={{ borderRadius: 25 }}>
                <Tune /> {t('objects.filterCompanies')}
              </CsButtonPrimary>
            </Badge>
          </Grid>
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={12} />
        <Grid item xs={12} container>
          <CompaniesOverview
            companies={comps}
            tagAttributes={tagOptions}
            allCompanies={allCompanies}
            canCreateCompany={canCreateCompany}
            rootAccess={rootAdmin}
            refresh={refreshData}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default GroupsOverview
