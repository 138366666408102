import React from 'react'
import CsButton, { CsButtonProps } from '@csinstruments/cs-react-theme/dist/components/Buttons/CsButton'
import { useTranslation } from 'react-i18next'

export const TranslatedCsButton: React.FC<CsButtonProps> = React.memo((props) => {
  const { t } = useTranslation('common')
  const { label, title } = props
  return <CsButton {...props} title={title ? t(title) : ''} label={label ? t(label) : ''} />
})

TranslatedCsButton.displayName = 'TranslatedCsButton'
