import { ApolloClient, DefaultOptions, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client'

import { setContext } from '@apollo/client/link/context'

import { getApolloClientURI } from './hookHelpers/clientRoutes'
import { useKeycloak } from '@react-keycloak/web'

// eslint-disable-next-line
export const useCloudClient = (): ApolloClient<any> => {
  const { keycloak } = useKeycloak()
  const cache = new InMemoryCache()
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
  const link = new HttpLink({
    uri: (operation) => {
      return getApolloClientURI(operation.operationName)
    },
    credentials: 'same-origin',
  })

  const tkn = async (): Promise<string | undefined> => {
    if (keycloak?.authenticated && keycloak?.isTokenExpired(5)) {
      try {
        const refreshed = await keycloak?.updateToken(5)
        if (refreshed) {
          return keycloak?.token as string
        }
      } catch (err: unknown) {
        keycloak.clearToken()
        console.error('refresh access token failed', err)
      }
    }
    return keycloak?.token
  }

  const authLink: ApolloLink = setContext(async (_, { headers }) => {
    const _accessTkn = await tkn()
    if (keycloak?.authenticated) {
      return {
        headers: {
          ...headers,
          authorization: _accessTkn ? `Bearer ${_accessTkn}` : '',
        },
      }
    }
  })
  return new ApolloClient({
    link: authLink.concat(link),
    cache,
    defaultOptions,
  })
}
