/* eslint-disable @typescript-eslint/no-explicit-any */
import { Leakage, Project } from '../../interfaces/BusinessObjects'
import { ImportLeakage } from '../../interfaces/DataImport'
import { isUniqueNumber, isUniqueString } from './utils'
import { TFunction } from 'i18next'

const distinct = (value: any, index: number, self: any[]): boolean => {
  return self.indexOf(value) === index
}

export const getLabelForCostEnergy = (t: TFunction): string => {
  return `${t('project.energyCost')} [70%]`
}

export const getLabelForCostTotal = (t: TFunction): string => {
  return `${t('project.totalCost')} [100%]`
}

export const getLabelForCalcCostBaseFromProject = (
  costCalcBase: string | undefined,
  cMod: string | undefined,
  t: TFunction,
): string => {
  if (costCalcBase) {
    return costCalcBase
  }
  if (cMod?.endsWith('1')) {
    return getLabelForCostEnergy(t)
  }
  if (cMod?.endsWith('3')) {
    return getLabelForCostTotal(t)
  }
  return ''
}

export const getFromProjectOrLeakage = <P extends keyof Project & keyof Leakage>(
  project: Project,
  leakages: Leakage[],
  prop: P,
): Project[P] | undefined => {
  if (isAvailableInProject(project, prop)) {
    return project[prop] as any
  } else if (leakages && leakages.length > 0) {
    return leakages.map((l) => l[prop]).filter(distinct).length === 1 ? (leakages[0][prop] as any) : undefined
  }
  return undefined
}

export const isAvailableInProject = <P extends keyof Project>(project: Project, prop: P): boolean => {
  return (
    project && project[prop] && (typeof project[prop] === 'number' ? !isNaN(project[prop] as unknown as number) : true)
  )
}

export const roundOperation = (num: number, precision: number): number => {
  const precisionFactor = Math.pow(10, precision)
  return Math.round(num * precisionFactor) / precisionFactor
}

export const uniqueStringValueFromLeakages = (
  leakages: ImportLeakage[],
  key: keyof ImportLeakage,
): string | undefined => {
  const uniqueValues = uniqueStrings(leakages, key)
  if (uniqueValues.length === 1) {
    return uniqueValues.pop()
  }
  return
}
export const uniqueNumberValueFromLeakages = (
  leakages: ImportLeakage[],
  key: keyof ImportLeakage,
): number | undefined => {
  const uniqueValues = uniqueNumbers(leakages, key)
  if (uniqueValues.length === 1) {
    return uniqueValues.pop()
  }
  return undefined
}

const uniqueStrings = (leakages: ImportLeakage[], key: keyof ImportLeakage): (string | undefined)[] => {
  return leakages
    .map((leakage) => {
      const value = leakage[key]
      if (typeof value === 'string') {
        return value
      }
      return
    })
    .filter(isUniqueString)
}

const uniqueNumbers = (leakages: ImportLeakage[], key: keyof ImportLeakage): (number | undefined)[] => {
  return leakages
    .map((leakage) => {
      const value = leakage[key]
      if (typeof value === 'number') {
        return value
      }
      return
    })
    .filter(isUniqueNumber)
}
