import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { paddingBottom, paddingBottomActionBar, paddingTop, standardContentPadding } from '../../../../styles/Shared'
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined'
import { Text } from '../../partials/Inputs/Text'
import SaveIcon from '@material-ui/icons/SaveOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Theme, Typography } from '@material-ui/core'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { ProjectParamTypes } from '../../../../interfaces/PageParams'
import { StandardConfirmationModal } from '../../partials/StandardConfirmationModal/StandardConfirmationModal'
import { SelectInput } from '../../partials/Inputs/Select'
import { Label } from '../../partials/Label/Label'
import { Project } from '../../../../interfaces/BusinessObjects'
import {
  ProjectAuditor,
  useCreateProject,
  useDeleteProject,
  useGetProjectAuditors,
  useProject,
  useUpdateProject,
} from '../../../hooks/projectHooks'
import { ROUTES } from '../../../helpers/routes'
import {
  enumValToDisplayVal,
  enumValToExportVal,
  isCfUnit,
  UnitCo2,
  UnitCurrency,
  UnitSpecPower,
  UnitVolume,
} from '../../../../interfaces/Units'
import { ResultAlert } from '../../partials/ResultAlert'
import { FixedActionBar } from '@csinstruments/cs-react-theme/dist/components/FixedActionBar/FixedActionBar'
import { useExportDatabase } from '../../../hooks/databaseHooks'
import { usePushDownloadFile } from '../../../hooks/usePushDownloadFile'
import { PageHeading } from '../../partials/PageHeading'
import { ProjectCostInfo } from './ProjectCostInfo'
import { internalPrecision } from '../../../../constants/calculationConstants'
import { useTranslation } from 'react-i18next'
import { usePermissions } from '../../../hooks/usePermissions'
import { calcEnergyAmount, getCo2Amount, getCostBase } from '../../../helpers/calculations'
import { isRenderedByElectron } from 'app/helpers/renderedBy'
import { useGetQueryParam } from '../utilHooks'

export const useEditStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
    },
    pageMainHeading: {
      width: '100%',
      color: theme.palette.text.secondary,
    },
  }),
)

const changeZeroToEmptyValue = (value: string): string => {
  return value === '0' ? '' : value
}

export const ProjectMaster: React.FC = () => {
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [showCreateProjError, setShowCreateProjError] = useState<boolean>(false)
  const [auditors, setAuditors] = useState<ProjectAuditor[]>([])
  const { projectId, id } = useParams<ProjectParamTypes>()
  const companyId = decodeURIComponent(id)
  const groupId = useGetQueryParam('groupId')
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [createProjError, setCreateProjError] = useState<string>('')
  const [newProjectData, setNewProjectData] = useState<Project>({ company: companyId, groupId: groupId })
  const { project, projectLoading } = useProject(projectId)
  const { createProject, createdProject, errorMessage } = useCreateProject()
  const { updateProject, updatedProject } = useUpdateProject()
  const { deleted, deleteProject } = useDeleteProject()
  const [doFetchData, setDoFetchData] = useState<boolean>(false)
  const { content, exportDone } = useExportDatabase(doFetchData, projectId)
  const { downloadAction } = usePushDownloadFile(content, exportDone, `project-${projectId}.zip`)
  const { t } = useTranslation('common')
  const [shownEnergyAmount, setShownEnergyAmount] = useState<string>('')
  const permissions = usePermissions()
  const [costBase, setCostBase] = useState<string>('')
  const { projectAuditors } = useGetProjectAuditors()
  const history = useHistory()

  // no id given in URL: Create a new project
  const isNewPage = !projectId

  useEffect(() => {
    if (project && project.costBase === 'NaN') {
      project.costBase = undefined
    }
    setNewProjectData({
      ...project,
      groupId: groupId,
      company: id,
      costBase: changeZeroToEmptyValue(getCostBase(project, t).resultAsFormattedString || ''),
    })

    project.energyAmount && setShownEnergyAmount(`${Number(project.energyAmount)}`)
  }, [project, projectLoading, id, t, groupId])

  useEffect(() => {
    if (updatedProject) {
      setShowSuccess(true)
    }
  }, [updatedProject])

  const updateNewProjectData = (data: Project): void => {
    setNewProjectData({ ...newProjectData, ...data, company: id })
    data.costBase ? setCostBase(data.costBase) : newProjectData.costBase && setCostBase(newProjectData.costBase)
    //const tempProjectUpdate = { ...newProjectData, ...data, company: id }
  }

  useEffect(() => {
    if (errorMessage && errorMessage) {
      newProjectData.name?.split(' ').some((s) => errorMessage.split(' ').includes(s)) &&
        setCreateProjError('project.duplicateProjectError')
      setShowCreateProjError(true)
    }
  }, [errorMessage, newProjectData.name])

  //console.log('project', project)

  useEffect(() => {
    if (newProjectData.unitV === UnitVolume.CF) {
      shownEnergyAmount &&
        setNewProjectData((data) => {
          //return { ...data, company: id, energyAmount: `${Number(shownEnergyAmount) / 6}` }
          return { ...data, company: id, energyAmount: `${Number(shownEnergyAmount)}` }
        })
    } else {
      shownEnergyAmount &&
        setNewProjectData((data) => {
          return { ...data, company: id, energyAmount: `${Number(shownEnergyAmount)}` }
        })
    }
    //console.log('newProjectData', newProjectData)
  }, [shownEnergyAmount, newProjectData.unitV, setNewProjectData, id])

  useEffect(() => {
    if (projectAuditors?.length) {
      setAuditors(projectAuditors)
    }
  }, [projectAuditors])

  //useRedirect(generatePath(ROUTES.companyProjects, { id }), [createdProject.name, deleted])
  useEffect(() => {
    if (createdProject.name || deleted) {
      let companyProjectsPath = generatePath(ROUTES.companyProjects, { id })
      companyProjectsPath = !groupId ? companyProjectsPath : companyProjectsPath + `?groupId=${groupId}`
      history.push(companyProjectsPath)
    }
  }, [createdProject.name, deleted, groupId, history, id])

  const deleteAction = useCallback(() => setModalOpen(true), [])
  const updateAction = useCallback(
    () =>
      !projectId
        ? createProject({ ...newProjectData, company: companyId })
        : updateProject(
            costBase
              ? { ...newProjectData, company: companyId, costBase: costBase }
              : { ...newProjectData, company: companyId, costBase: newProjectData.costBase },
          ).then(() => (costBase ? setNewProjectData({ ...newProjectData, costBase }) : '')),
    [projectId, createProject, newProjectData, companyId, updateProject, costBase],
  )

  const exportAction = useCallback(() => {
    if (!exportDone) {
      setDoFetchData(true)
    } else {
      downloadAction()
    }
  }, [setDoFetchData, downloadAction, exportDone])

  const unitVUpdated = (unitV: string): void => {
    const costBase = changeZeroToEmptyValue(getCostBase(newProjectData, t, undefined, unitV).resultAsFormattedString)
    const energyAmount = calcEnergyAmount({ ...newProjectData }, t, unitV).resultAsFormattedString
    setShownEnergyAmount(energyAmount)
    updateNewProjectData({ unitV, costBase })
  }

  const co2UnitUpdated = (co2Unit: string): void => {
    const co2Amount = getCo2Amount(newProjectData, t, co2Unit).resultAsFormattedString
    updateNewProjectData({ co2Unit, co2Amount })
  }

  const updateProjectAuditor = (auditorName: string): void => {
    const updateAuditor = auditors.find((a) => a.name === auditorName)

    updateNewProjectData({
      Auditor: updateAuditor,
    })
  }

  const editingPermitted = useMemo(() => {
    return permissions.editProjects
  }, [permissions.editProjects])

  const costUnitDisplayVal =
    newProjectData.costUnit === UnitCurrency.OTHER
      ? newProjectData.otherCostUnit
      : enumValToDisplayVal(newProjectData.costUnit)

  const supportedCurrencies = useMemo(() => {
    const currencies = Object.keys(UnitCurrency)
    if (newProjectData.costUnit) {
      const displayCurrency = newProjectData.costUnit
      return currencies.includes(displayCurrency) ? currencies : [...currencies, displayCurrency]
    }
    return currencies
  }, [newProjectData.costUnit])
  //console.log({ costBase })
  return (
    <div style={{ ...paddingTop, ...paddingBottomActionBar }}>
      <FixedActionBar
        buttonProps={[{}, {}, { solid: true }]}
        actions={[deleteAction, exportAction, updateAction]}
        labels={['actions.delete', 'actions.export', 'actions.save']}
        conditions={[!!projectId && editingPermitted, editingPermitted, editingPermitted]}
        icons={[DeleteIcon, CloudDownloadIcon, SaveIcon]}
      />
      <ResultAlert alertText={'actions.updateSuccess'} showAlert={showSuccess} modifyShowAlert={setShowSuccess} />
      <ResultAlert
        alertText={createProjError}
        showAlert={showCreateProjError}
        modifyShowAlert={setShowCreateProjError}
        severity={'error'}
        autoHideDuration={5000}
      />
      <StandardConfirmationModal
        handleConfirm={() => projectId && deleteProject(projectId)}
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
      />
      <Grid container spacing={3} style={standardContentPadding}>
        <Grid item xs={12} xl={10}>
          <Grid spacing={2} container>
            {isNewPage && (
              <Grid item xs={12}>
                <PageHeading title={'project.create'} />
              </Grid>
            )}
            {!isNewPage && (
              <Grid item xs={12}>
                <div>
                  <PageHeading title={'project.edit'} />
                </div>
              </Grid>
            )}
            <Text
              columns={12}
              changeHandler={(name) => updateNewProjectData({ name })}
              label={'objects.name'}
              displayOnly={!editingPermitted}
              value={newProjectData.name || ''}
            />
            <Text
              columns={12}
              displayOnly={!editingPermitted}
              changeHandler={(description) => updateNewProjectData({ description })}
              label={'objects.description'}
              value={newProjectData.description || ''}
            />
            <Text
              changeHandler={(costBase) => updateNewProjectData({ costBase })}
              columns={3}
              displayOnly={
                (!!newProjectData?.energyCost && !!newProjectData?.energyAmount) ||
                (!newProjectData.canEdit && !isNewPage) ||
                !editingPermitted ||
                (!!newProjectData.energyAmount && !!newProjectData.energyCost)
              }
              onlyNumbers={true}
              label={'project.cost'}
              value={newProjectData.costBase || ''}
            />
            <SelectInput
              changeHandler={(costUnit) => updateNewProjectData({ costUnit })}
              xs={3}
              disabled={!editingPermitted}
              label={'project.currency'}
              preselect={newProjectData.costUnit}
              isUnitSelect
              text={supportedCurrencies}
            />
            {newProjectData.costUnit === UnitCurrency.OTHER && (
              <Text
                changeHandler={(otherCostUnit) => updateNewProjectData({ otherCostUnit })}
                displayOnly={!editingPermitted}
                columns={3}
                label={'project.currency'}
                value={newProjectData.otherCostUnit || ''}
              />
            )}
            <Label text={'/ 1000'} xs={3} />
            <SelectInput
              xs={3}
              hasEmpty={false}
              changeHandler={(unitV) => unitVUpdated(unitV)}
              label={'objects.unit'}
              disabled={!editingPermitted}
              isUnitSelect
              preselect={newProjectData.unitV}
              // TODO: verify that SCF can be used as synonym to CF
              text={Object.keys(UnitVolume).filter((key) => key !== UnitVolume.SCF)}
            />
            {!isRenderedByElectron() && (
              <SelectInput
                xs={3}
                md={6}
                hasEmpty={false}
                changeHandler={(selectedAuditor) => updateProjectAuditor(selectedAuditor)}
                label={'reports.auditor'}
                disabled={!editingPermitted}
                preselect={newProjectData.Auditor?.name}
                text={auditors.map((a) => a.name)}
              />
            )}
            <Text
              columns={6}
              displayOnly={!editingPermitted}
              changeHandler={(costTime) => updateNewProjectData({ costTime })}
              label={'project.workingHours'}
              onlyNumbers={true}
              value={newProjectData.costTime || ''}
            />
            <Grid item xs={12} style={{ paddingTop: 50 }}>
              <div>
                <Typography color="secondary" style={paddingBottom} variant={'h6'}>
                  {t('project.expertSettings')}
                </Typography>
              </div>
            </Grid>
            <Text
              columns={8}
              displayOnly={!editingPermitted}
              changeHandler={(co2Amount) => updateNewProjectData({ co2Amount })}
              label={'project.co2'}
              onlyNumbers={true}
              value={newProjectData.co2Amount || ''}
            />
            <SelectInput
              xs={4}
              disabled={!editingPermitted}
              changeHandler={(co2Unit) => co2UnitUpdated(co2Unit)}
              label={'objects.unit'}
              hasEmpty={false}
              isUnitSelect
              preselect={newProjectData.co2Unit}
              text={Object.keys(UnitCo2)}
            />
            <Text
              columns={8}
              onlyNumbers={true}
              displayOnly={!editingPermitted}
              value={shownEnergyAmount}
              changeHandler={(energyAmount) => setShownEnergyAmount(energyAmount)}
              label={'project.specificPower'}
            />
            <Label
              text={enumValToExportVal(isCfUnit(newProjectData.unitV ?? '') ? UnitSpecPower.US : UnitSpecPower.ISO)}
              xs={4}
            />
            <Text
              columns={8}
              displayOnly={!editingPermitted}
              value={newProjectData.energyCost || ''}
              onlyNumbers={true}
              changeHandler={(energyCost) => updateNewProjectData({ energyCost })}
              label={'project.electricityRate'}
            />
            <Label text={costUnitDisplayVal + ' / kWh'} xs={4} />
            <ProjectCostInfo
              setCost={(costBase: number, costCalcBase: string, cMod: string) =>
                updateNewProjectData({ costBase: `${costBase.toFixed(internalPrecision)}`, costCalcBase, cMod })
              }
              setCostBase={(newCostBase) => setCostBase(newCostBase)}
              energyAmount={newProjectData.energyAmount}
              energyCost={`${newProjectData.energyCost}`}
              costUnit={
                newProjectData.costUnit !== UnitCurrency.OTHER ? newProjectData.costUnit : newProjectData.otherCostUnit
              }
              unitV={newProjectData.unitV}
              cMod={newProjectData.cMod}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
