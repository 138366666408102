import { Alert } from '@material-ui/lab'
import { disableLicBanner, licBannerShow } from 'app/helpers/manageRealms'
import { ROUTES } from 'app/helpers/routes'
import { useCloudLicense } from 'app/hooks/useLicense'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

export const CloudLicense: React.FC = () => {
  const license = useCloudLicense()
  const { t } = useTranslation('common')
  const [showBanner, setShowBanner] = useState<boolean>(false)

  const location = useLocation()

  const disableAlert = (): void => {
    setShowBanner(false)
    disableLicBanner()
  }

  const showLicBanner = licBannerShow()
  useEffect(() => {
    /* if (!licBannerShow()) {
      enableLicBanner()
    } */
    if (showLicBanner && location.pathname === ROUTES.home) {
      setShowBanner(true)
    }
  }, [location, showLicBanner])

  const resultSeverity = (days: number): 'success' | 'error' | 'info' | 'warning' => {
    if (days <= 90 && days > 0) return 'warning'
    //else if (days > 30 && days <= 60) return 'info'
    else if (days > 90) return 'success'
    return 'error'
  }
  return (
    <>
      {license?.hasAccess && showBanner && (
        <Alert
          severity={resultSeverity(license?.daysRemaining ?? 0)}
          onClose={() => !license?.expired && disableAlert()}
        >
          {license?.expired && t('cloudMessages.expiredMessage', { displayName: license.organizationDisp })}
          {!license?.expired && `${t('cloudMessages.daysRemaining') + license?.daysRemaining}`}
        </Alert>
      )}
    </>
  )
}
