import React, { FC, ReactElement, useCallback } from 'react'
import { Box, Grid, MenuItem, Select } from '@material-ui/core'
import { useStyles } from '@csinstruments/cs-react-theme/dist/components/AppTop/LanguageSelectionStyles'
import { Organization } from '../../../hooks/useKeycloak'
import BusinessCenterRoundedIcon from '@material-ui/icons/BusinessCenterRounded'
import { useUserContext } from '../CloudAuthProvider/UserAuthProvider'
import { useHistory } from 'react-router-dom'
import { ROUTES } from 'app/helpers/routes'
import { useChangeOrgPreferenceMutation } from 'api/iam.models'

type Props = {
  // should be non-empty
  organizations: Organization[]
}

export const OrganizationSelection: FC<Props> = ({ organizations }): ReactElement => {
  const classes = useStyles()
  const { globalRealmName, selectRealm } = useUserContext()
  const history = useHistory()
  //const [selectedOrg, setSelectedOrg] = useState<string>(globalRealmName ?? organizations[0].realmName)

  const [updateOrgPreference] = useChangeOrgPreferenceMutation()

  const changeOrg = useCallback(
    (realm: string): void => {
      selectRealm(realm)

      if (globalRealmName !== realm) {
        updateOrgPreference({
          variables: {
            realmName: realm,
          },
        })
          .then((updated) => console.log({ updated }))
          .catch(() => console.log('failed to update org preference'))
      }
      history.push(ROUTES.home)
    },
    [globalRealmName, history, selectRealm, updateOrgPreference],
  )

  return (
    <Box display="flex" padding={1} style={{ paddingLeft: 60 }}>
      <BusinessCenterRoundedIcon fontSize="medium" />
      <Grid item xs />
      <Select
        className={classes.select}
        variant={'outlined'}
        onChange={({ target }) => changeOrg(target.value as string)}
        value={globalRealmName}
        inputProps={{
          classes: {
            icon: classes.icon,
          },
        }}
      >
        {organizations.map((org) => {
          return (
            <MenuItem key={org.realmName} value={org.realmName}>
              {org.displayName}
            </MenuItem>
          )
        })}
      </Select>
    </Box>
  )
}
