import * as path from 'path'
import constants from '../../constants/constants'
import { invalidateDbCache } from '../../electronShared/domain/services/multiDataService'
import { DIRECTORY_NAME } from '../../electronShared/domain/repositories/shared/constants'
import { getFs, getImageStorePath } from '../../electronShared/domain/repositories/shared/shared'
import { FileSelectionResult } from '../../app/components/partials/Buttons/SingleFileUploadButton'
import { deleteProjects, getProjectById } from '../../electronShared/domain/repositories/projectRepository'
import { deleteCompanies, getCompanyByName } from '../../electronShared/domain/repositories/companyRepository'
import { deleteLeakages, getLeakagesByProject } from '../../electronShared/domain/repositories/leakageRepository'
import { deleteBuildings, getBuildingsByCompany } from '../../electronShared/domain/repositories/buildingRepository'
import { repositoryNames } from '../../electronShared/domain/repositories/repositoryNames'
import { requestProjectImport } from '../ipc/projectImport'
import { DbData } from '../../electronShared/domain/types/types'
import { deleteAddresses } from '../../electronShared/domain/repositories/addressRepository'
import { deleteContacts } from '../../electronShared/domain/repositories/contactRepository'
import {
  deleteFailures,
  deleteMeasures,
  deleteReplacements,
} from '../../electronShared/domain/repositories/leakageDescriptionRepos'
import { deleteUpdateInfos } from '../../electronShared/domain/repositories/updateInfoRepository'

const getRemote = (): any => {
  const { remote } =
    typeof window !== 'undefined' && window.require ? window.require('electron') : { remote: undefined }
  return remote
}

const getApp = (remote?: any): any => {
  remote = remote ?? getRemote()
  return remote ? remote.app : undefined
}

export const getDatastorePath = (datastore: string, isDb = true): string => {
  const app = getApp()
  const finalName = isDb ? `${datastore}.db` : datastore
  return path.join(app ? app.getPath('appData') : '', DIRECTORY_NAME, finalName)
}

const readFile = (directory: string, filePath: string, asBuffer = false): Promise<string | Buffer> => {
  const remote = getRemote()
  return new Promise<string>((resolve, reject) => {
    remote
      .require('fs')
      .readFile(path.join(directory, filePath), asBuffer ? undefined : 'utf8', (err: any, data: string) => {
        if (err) {
          reject(err)
        } else {
          resolve(data)
        }
      })
  })
}

const pathExists = (path: string): Promise<boolean> => {
  return new Promise<boolean>((resolve) => {
    getFs().stat(path, (err: any, stats: any) => {
      if (err || !(stats.isDirectory() || stats.isFile())) {
        if (err) {
          console.error(err)
        }
        resolve(false)
      } else {
        resolve(true)
      }
    })
  })
}

const getFileNames = async (directory: string, onlyDbFiles = false): Promise<string[]> => {
  const remote = getRemote()
  const exists = await pathExists(directory)
  if (!exists) {
    return []
  }
  return new Promise<string[]>((resolve, reject) => {
    remote.require('fs').readdir(directory, (err: any, files: string[]) => {
      if (err) {
        reject(err)
      } else {
        resolve(files.filter((f) => !onlyDbFiles || f.endsWith('.db')))
      }
    })
  })
}

const writeFile = (filePath: string, contents: string): Promise<void> => {
  const fs = getRemote().require('fs')
  return new Promise<void>((resolve, reject) => {
    fs.writeFile(filePath, contents, (err: any) => {
      if (err) {
        console.error(err)
        reject(err)
      } else {
        resolve()
      }
    })
  })
}

const doImportData = async (parsed: DbData): Promise<boolean> => {
  const remote = getRemote()
  const directory = path.join(getApp(remote).getPath('appData'), DIRECTORY_NAME)

  try {
    await Promise.all(parsed.entries.map((e) => writeFile(path.join(directory, e.filename), e.contents)))
    return true
  } catch (err) {
    console.error(err)
    return false
  }
}

/* const deleteDirectory = (directory: string): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    getRemote()
      .require('fs')
      .rmdir(directory, { recursive: true }, (err: any) => {
        if (err) {
          console.error(err)
          reject(err)
        } else {
          resolve()
        }
      })
  })
} */

/* const createDirectory = (directory: string): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    getRemote()
      .require('fs')
      .mkdir(directory, (err: any) => {
        if (err) {
          console.error(err)
          reject(err)
        } else {
          resolve()
        }
      })
  })
} */

export const emptyDatabase = async (): Promise<void> => {
  await deleteAddresses()
  await deleteBuildings()
  await deleteCompanies()
  await deleteContacts()
  await deleteLeakages()
  await deleteProjects()
  await deleteFailures()
  await deleteUpdateInfos()
  await deleteMeasures()
  await deleteReplacements()
  invalidateDbCache()
}

const getAdmZip = (): any => {
  return typeof window !== 'undefined' && window.require ? window.require('adm-zip') : require('adm-zip')
}

const exportDatabaseInZipArchiveAsBuffer = async (
  exportData: DbData,
  projectId: string | undefined = undefined,
): Promise<Buffer> => {
  const zip = new (getAdmZip())()
  let files: string[] = await getFileNames(getImageStorePath())
  if (projectId) {
    const leakages = await getLeakagesByProject(projectId)
    const fileRequired = (name: string): boolean =>
      leakages
        .map((l) => (l.images || []).map((i) => i?.src ?? ''))
        .flat()
        .includes(name)
    files = files.filter((f) => fileRequired(f))
  }
  const contents = (await Promise.all(files.map((f) => readFile(getImageStorePath(), f, true)))) as Buffer[]
  contents.map((c, i) => {
    zip.addFile(`images/${files[i]}`, c)
  })
  zip.addFile('database/database.json', Buffer.from(JSON.stringify(exportData)))
  return zip.toBuffer()
}

const extractZipArchiveDatabase = async (zipFile: string): Promise<string> => {
  const zip = getAdmZip()(zipFile)
  zip.getEntries().forEach((entry: any) => {
    if (entry.entryName !== 'database/database.json') {
      zip.extractEntryTo(entry, getImageStorePath(), false, true)
    }
  })
  return zip.readAsText('database/database.json')
}

const filterDbExportByProjectId = async (
  projectId: string,
  fileNames: string[],
  contents: string[],
): Promise<string[]> => {
  const project = await getProjectById(projectId)
  const company = await getCompanyByName(project.company ?? '')
  const leakages = await getLeakagesByProject(projectId)
  const buildingIdsOfProject = leakages.map((leakage) => leakage.buildingId)
  const buildings = (await getBuildingsByCompany(project.company ?? '')).filter((building) =>
    buildingIdsOfProject.includes(building.id),
  )

  const ids = [
    project._id,
    company.addressId,
    company.contactId,
    company._id,
    ...leakages.map((l) => l._id),
    ...buildings.map((b) => b.id),
  ]

  // for project export, only these tables need to be exported:
  const requiredFilenames = [
    repositoryNames.COMPANY,
    repositoryNames.BUILDING,
    repositoryNames.LEAKAGE,
    repositoryNames.PROJECT,
    repositoryNames.CONTACT,
    repositoryNames.ADDRESS,
  ].map((name) => `${name}.db`)

  return fileNames.map((fn, i) =>
    !requiredFilenames.includes(fn)
      ? ''
      : contents[i]
          .split('\n')
          .filter((s) => {
            return ids.filter((id) => s.includes(`_id":"${id}`)).length > 0
          })
          .join('\n'),
  )
}

export const createDbExport = async (projectId: string | undefined = undefined): Promise<Buffer> => {
  const remote = getRemote()
  if (!remote) {
    console.error('electron app could not be gotten - is this an electron environment?')
    return new Buffer('')
  }
  const directory = path.join(getApp(remote).getPath('appData'), DIRECTORY_NAME)
  let fileNames = await getFileNames(directory, true)
  fileNames = fileNames.filter((filename) => filename !== `${repositoryNames.LICENSE_INFO}.db`)
  console.log({ fileNames })
  let contents = await Promise.all(fileNames.map((fp) => readFile(directory, fp)))

  // project export: only export those data that are needed
  if (projectId) {
    contents = await filterDbExportByProjectId(projectId, fileNames, contents as string[])
    fileNames = fileNames.filter((fn, i) => contents[i])
    contents = contents.filter((c) => c)
  }

  const entries: DbData = {
    entries: fileNames.map((filename, i) => {
      return { filename, contents: contents[i] as string }
    }),
    appVersion: constants.CS_LD_VERSION,
  }
  return await exportDatabaseInZipArchiveAsBuffer(entries, projectId)
}

export const importData = async (importData: FileSelectionResult): Promise<boolean> => {
  if (importData.origin.endsWith('.zip')) {
    const extractionResult = await extractZipArchiveDatabase(importData.origin)
    const parsed: DbData = JSON.parse(extractionResult)
    if (parsed.appVersion) {
      const success = await doImportData(parsed)
      if (success) {
        // clear the cache so that the imported data is directly available in the app
        invalidateDbCache()
      }
      return success
    }
  }
  return false
}

export const importProject = async (importData: FileSelectionResult): Promise<boolean> => {
  if (importData.origin.endsWith('.zip')) {
    const extractionResult = await extractZipArchiveDatabase(importData.origin)
    const parsed: DbData = JSON.parse(extractionResult)
    if (parsed.appVersion) {
      return requestProjectImport(extractionResult, false)
    } else {
      // zip data, but no version id: this format is unknown
      return false
    }
  }
  return requestProjectImport(importData.contents, true)
}
