import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

export const useEditStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
    },
    pageHeading: {
      width: '100%',
      paddingBottom: '20px',
    },
    pageMainHeading: {
      width: '100%',
      color: theme.palette.text.secondary,
      paddingBottom: '20px',
    },
  }),
)

export const useViewStyles = makeStyles((theme: Theme) =>
  createStyles({
    // paper: {
    //   padding: theme.spacing(3),
    //   textAlign: 'left',
    //   color: theme.palette.text.secondary,
    // },
    itemContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    infoContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-around',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '80%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '60%',
      },
    },
    infoContentContainer: {
      alignContent: 'center',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '80%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '60%',
      },
    },
    avatarContainer: {
      marginRight: '20px',
    },
    buttonsContainer: {
      width: '100%',
      textAlign: 'right',
    },
  }),
)
