import { JNL } from './dataImportTypes'
import {
  createEmptyAddress,
  createEmptyContact,
  ImportBuilding,
  ImportCompany,
  ImportLeakage,
  ImportProject,
} from '../../../interfaces/DataImport'
import { Unit } from '../../../interfaces/Units'
import { v4 as uuidv4 } from 'uuid'

export const journalToImportCompanies = (journal: JNL[]): ImportCompany[] => {
  const companies = journal.map((jnl) => jnl.INFO.Company)
  const unique = companies.filter((item, i, self) => self.indexOf(item) === i)
  return unique.map((company) => createImportCompany(journal, company))
}

const leakageFromJnl = (jnl: JNL, buildingId: string): ImportLeakage => {
  const l = {
    buildingId: buildingId,
    commF: jnl.INFO.CommF,
    commM: jnl.INFO.CommM,
    commR: jnl.INFO.CommR,
    commRup: jnl.INFO.CommRup ?? '',
    comment: jnl.INFO.Comment,
    costBase: jnl.COST.Base,
    costRelevantAttributesEdited: false,
    costTime: parseInt(jnl.COST.Time),
    costUnit: jnl.COST.UnitC,
    date: jnl.INFO.Date,
    time: jnl.INFO.Time,
    distance: parseInt(jnl.DATA.Distance),
    gain: parseInt(jnl.DATA.Gain),
    gasType: jnl.DATA.GasType,
    imgName: jnl.BMP.Name,
    imgPath: jnl.BMP.Path,
    leakTag: parseInt(jnl.INFO.LeakTag),
    level: parseInt(jnl.DATA.Level),
    place: jnl.INFO.Place,
    pressure: parseFloat(jnl.DATA.Pressure),
    resCost: parseFloat(jnl.RES.Cost),
    resFlag: jnl.RES.Sign ? (jnl.RES.Sign.includes('-') ? -1 : 1) : 1,
    resLeakage: parseFloat(jnl.RES.Leakage),
    resUnitL: jnl.RES.UnitL === 'cfm' ? Unit.CFM : Unit.LTR_MIN,
    sens2x: parseInt(jnl.DATA.Sens2x),
    sensivity: parseInt(jnl.DATA.Sensivity),
    sn: parseInt(jnl.INFO.SN),
    sw: parseFloat(jnl.INFO.SW),
    threshold: parseInt(jnl.DATA.Threshold),
    // TODO: Which units are available?
    unitD: jnl.DATA.UnitD === 'm' ? Unit.M : Unit.INCH,
    unitP: jnl.DATA.UnitP === 'psi' ? Unit.PSI : Unit.BAR,
    unitV: jnl.COST.UnitV === 'SCF' ? Unit.SCF : Unit.NM3,
    unitC: Unit.EURO,
    version: jnl.INFO.Version,
    uid: jnl.INFO.UID,
    costElPr: jnl.COST.ElPr ? parseFloat(jnl.COST.ElPr) : undefined,
    costSpPw: jnl.COST.SpPw ? parseFloat(jnl.COST.SpPw) : undefined,
    costUspPw: jnl.COST.USpPw ? parseFloat(jnl.COST.USpPw) : undefined,
    costSpPwId: jnl.COST.SpPwId ? jnl.COST.SpPwId : undefined,
    costCmod: jnl.COST.CMod ? jnl.COST.CMod : undefined,
    costSigE3: jnl.COST.SigE3 ? jnl.COST.SigE3 : undefined,
  }

  return l
}

const projectFromJnls = (jnl: JNL[], leakages: ImportLeakage[]): ImportProject => {
  const firstCostBase = jnl[0].COST.Base
  const costBaseValid = jnl.filter((j) => j.COST.Base === firstCostBase).length === jnl.length
  const firstCostTime = jnl[0].COST.Time
  const costTimeValid = jnl.filter((j) => j.COST.Time === firstCostTime).length === jnl.length
  const firstUnitV = jnl[0].COST.UnitV
  const unitVValid = jnl.filter((j) => j.COST.UnitV === firstUnitV).length === jnl.length
  const firstCostUnit = jnl[0].COST.UnitC
  const costUnitValid = jnl.filter((j) => j.COST.UnitC === firstCostUnit).length === jnl.length
  const costUnit = costUnitValid
    ? firstCostUnit === '$'
      ? Unit.USD
      : firstCostUnit === 'R'
      ? Unit.R
      : firstCostUnit === '€'
      ? Unit.EURO
      : firstCostUnit
    : undefined

  return {
    costBase: costBaseValid ? parseFloat(firstCostBase) : undefined,
    costTime: costTimeValid ? parseInt(firstCostTime) : undefined,
    costUnit: costUnit,
    energyUnit: Unit.KWH_CF,
    energyAmount: undefined,
    unitV: unitVValid ? (firstUnitV === 'SCF' ? Unit.CF : Unit.NM3) : undefined,
    name: '',
    description: '',
    importDate: new Date().toDateString(),
    leakages,
  }
}

const buildingsFromJournal = (jnls: JNL[], company: string): ImportBuilding[] => {
  const names: string[] = jnls.filter((jnl) => jnl.INFO.Company === company).map((jnl) => jnl.INFO.Location)
  const uniqueBuildingNames = names.filter((item, i, self) => self.indexOf(item) === i)
  const buildings = uniqueBuildingNames.map((name) => ({ name, id: uuidv4() }))
  return buildings
}

const createImportCompany = (journal: JNL[], company: string): ImportCompany => {
  const relevantEntries = journal.filter((jnl) => jnl.INFO.Company === company)
  const buildings = buildingsFromJournal(journal, company)
  const leakages: ImportLeakage[] = relevantEntries.map((jnl: JNL) => {
    const buildingId = buildings.filter((building) => building.name === jnl.INFO.Location).pop()?.id || ''
    return leakageFromJnl(jnl, buildingId)
  })
  const projects = [projectFromJnls(journal, leakages)]
  const address = createEmptyAddress()
  const contact = createEmptyContact()
  return {
    // all journals passed to this function have the same company
    name: company,
    address,
    contact,
    buildings,
    projects,
  }
}
