import React, { FC, ReactElement, useCallback, useState } from 'react'
import { paddingBottomActionBar } from '../../../../styles/Shared'
import { StepperWizard } from '@csinstruments/cs-react-theme/dist/components/StepperWizard/StepperWizard'
import { useTranslation } from 'react-i18next'
import { DataExportLabelSelection } from './DataExportLabelSelection'
import { FixedActionBar } from '@csinstruments/cs-react-theme/dist/components/FixedActionBar/FixedActionBar'
import { DataExportCompanySelection } from './DataExportCompanySelection'
import { generatePath, Link } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routes'
import CsButton from '@csinstruments/cs-react-theme/dist/components/Buttons/CsButton'
import { DataExportCompletion } from './DataExportCompletion'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

type DataExportState = {
  activeStep: number
  measureIDs: string[]
  failureIDs: string[]
  replacementIDs: string[]
  companyIDs: string[]
  exportBuildings: boolean
  exportLocations: boolean
}

const initialState = {
  activeStep: 0,
  measureIDs: [],
  failureIDs: [],
  replacementIDs: [],
  companyIDs: [],
  exportBuildings: true,
  exportLocations: true,
}

export const DataExport: FC = (): ReactElement => {
  const { t } = useTranslation('common')
  const [state, setState] = useState<DataExportState>(initialState)

  const setActiveStep = (n: number): void => {
    setState((state) => {
      return { ...state, activeStep: n }
    })
  }

  const backAction = useCallback(
    () =>
      setState((state) => {
        return { ...state, activeStep: state.activeStep - 1 }
      }),
    [],
  )

  const continueAction = useCallback(
    () =>
      setState((state) => {
        return { ...state, activeStep: state.activeStep + 1 }
      }),
    [],
  )

  const idsWereSelected = useCallback((ids: string[], type: string) => {
    switch (type) {
      case 'MEASURE':
        setState((state) => {
          return { ...state, measureIDs: [...ids] }
        })
        return
      case 'FAILURE':
        setState((state) => {
          return { ...state, failureIDs: [...ids] }
        })
        return
      case 'REPLACEMENT':
        setState((state) => {
          return { ...state, replacementIDs: [...ids] }
        })
        return
      case 'COMPANY':
        setState((state) => {
          return { ...state, companyIDs: [...ids] }
        })
        return
    }
  }, [])

  const buildingsSelected = useCallback(
    (selected: boolean) =>
      setState((state) => {
        return { ...state, exportBuildings: selected }
      }),
    [],
  )

  const locationsSelected = useCallback(
    (selected: boolean) =>
      setState((state) => {
        return { ...state, exportLocations: selected }
      }),
    [],
  )

  return (
    <>
      <Link style={{ textDecoration: 'none' }} to={generatePath(ROUTES.exportMaintenance, { type: 'MEASURE' })}>
        <CsButton fullwidth={false} label={t('actions.backToMaintenance')}>
          <NavigateBeforeIcon />
        </CsButton>
      </Link>
      <FixedActionBar
        actions={[backAction, continueAction]}
        buttonProps={[{}, { solid: true }]}
        labels={['actions.back', 'actions.continue']}
        disabled={[state.activeStep === 0, state.activeStep === 4]}
        icons={[NavigateBeforeIcon, NavigateNextIcon]}
      />
      <div style={{ ...paddingBottomActionBar, paddingTop: 30 }}>
        <StepperWizard
          step={state.activeStep}
          disabled={[true, true, true, true, true]}
          labels={[
            t('exportMaintenance.measure'),
            t('exportMaintenance.failure'),
            t('exportMaintenance.replacement'),
            t('objects.companies'),
            t('actions.complete'),
          ]}
          onStepClicked={setActiveStep}
        />

        {state.activeStep === 0 && <DataExportLabelSelection labelType="MEASURE" idsWereSelected={idsWereSelected} />}
        {state.activeStep === 1 && <DataExportLabelSelection labelType="FAILURE" idsWereSelected={idsWereSelected} />}
        {state.activeStep === 2 && (
          <DataExportLabelSelection labelType="REPLACEMENT" idsWereSelected={idsWereSelected} />
        )}
        {state.activeStep === 3 && (
          <DataExportCompanySelection
            buildingsSelected={buildingsSelected}
            locationsSelected={locationsSelected}
            idsWereSelected={idsWereSelected}
          />
        )}
        {state.activeStep === 4 && (
          <DataExportCompletion
            exportBuildings={state.exportBuildings}
            exportLocations={state.exportLocations}
            companyIDs={state.companyIDs}
            failureIDs={state.failureIDs}
            measureIDs={state.measureIDs}
            replacementIDs={state.replacementIDs}
          />
        )}
      </div>
    </>
  )
}
