import React, { FC, ReactElement, useMemo } from 'react'
import { Leakage, Project } from '../../../../interfaces/BusinessObjects'
import { useTranslation } from 'react-i18next'
import { Box, Paper, Typography } from '@material-ui/core'
import { getCostTime, getPressureCost } from '../../../helpers/calculations'

type LeakageProjectMasterProps = {
  project?: Project
  leakage: Leakage
}

export const LeakageProjectMaster: FC<LeakageProjectMasterProps> = React.memo(({ project, leakage }): ReactElement => {
  const { t, i18n } = useTranslation('common')

  const costTime = useMemo(() => {
    return getCostTime(project, leakage, t)
  }, [project, leakage, t])
  const pressureCost = useMemo(() => {
    return getPressureCost(project, t)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, [leakage], i18n.language])

  if (!project || !project.costTime || !project.costBase || !project.costUnit || !project.unitV) return <></>

  return (
    <Paper variant="outlined">
      <Box m={2}>
        <Typography variant={'h6'}>{t('reports.projectMaster')}</Typography>
        <br />
        {costTime && (
          <Typography variant={'body2'}>
            {t('project.workingHours')}: {costTime.text}
          </Typography>
        )}
        {pressureCost && (
          <Typography variant={'body2'}>
            {t('reports.pressureCost')}: {pressureCost}
          </Typography>
        )}
      </Box>
    </Paper>
  )
})

LeakageProjectMaster.displayName = 'LeakageProjectMaster'
