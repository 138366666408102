export const isUniqueString = (item: string | undefined, index: number, array: (string | undefined)[]): boolean =>
  array.indexOf(item) === index
export const isUniqueNumber = (item: number | undefined, index: number, array: (number | undefined)[]): boolean =>
  array.indexOf(item) === index

// export function isUniqueType<T, [T]>(item: number | undefined, index: number, array: (number | undefined)[]): boolean {
//   array.indexOf(item) === index
// }

export const isExpired = (dateToConvert?: string): boolean => {
  if (dateToConvert) {
    /* const currentDate = new Date().getTime()
    const expiryDate = new Date(dateToConvert).getTime()
    const diffTime = expiryDate - currentDate */
    return getRemainingDays(dateToConvert) <= 0
  }
  return true
}

export const getRemainingDays = (dateToConvert?: string): number => {
  if (dateToConvert) {
    const currentDate = new Date().getTime()
    const expiryDate = new Date(dateToConvert).getTime()
    const diffTime = expiryDate - currentDate
    const days = Math.ceil(diffTime / (1000 * 3600 * 24))
    return days > 0 ? days : 0
  }
  return 0
}

export const sortDateString = (date1: string, date2: string): number => {
  return new Date(date1).getTime() - new Date(date2).getTime() > 0 ? -1 : 1
}

export const enableMaintenanceAlert = (): void => {
  localStorage.setItem('_showAlert', 'true')
}

export const disableMaintenanceAlert = (): void => {
  localStorage.setItem('_showAlert', 'false')
}

export const isAlertNotSet = (): boolean => {
  return localStorage.getItem('_showAlert') === null
}

export const getMaintenanceAlert = (): boolean => {
  return localStorage.getItem('_showAlert') === 'true'
}
