import { finalDeleteTrashBuildings } from '../repositories/buildingRepository'
import { finalDeleteTrashCompanies } from '../repositories/companyRepository'
import { finalDeleteTrashLeakages } from '../repositories/leakageRepository'
import { finalDeleteTrashProjects } from '../repositories/projectRepository'
import { invalidateCache } from '../repositories/shared/shared'

export const emptyTrash = async (): Promise<number> => {
  const numbersDeleted = await Promise.all([
    finalDeleteTrashBuildings(),
    finalDeleteTrashCompanies(),
    finalDeleteTrashLeakages(),
    finalDeleteTrashProjects(),
  ])
  return numbersDeleted.reduce((prev, current) => prev + current, 0)
}

export const invalidateDbCache = (): void => {
  invalidateCache()
}

export default {
  emptyTrash,
}
