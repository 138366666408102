import { User } from '../../interfaces/BusinessObjects'
import electronService from '../../electronShared/domain/services/userService'
import { isRenderedByElectron } from '../helpers/renderedBy'

export interface UserService {
  getUserById(id: string): Promise<User>
  createOrUpdateUser(user: User): Promise<User>
}

export const getUserService = (): UserService | undefined => {
  if (isRenderedByElectron()) {
    return electronService
  }
  return undefined
}
