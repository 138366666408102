export const phraseCs: number =
  ('C'.charCodeAt(0) << 24) | ('S'.charCodeAt(0) << 16) | ('-'.charCodeAt(0) << 8) | 'I'.charCodeAt(0)
export const phraseTesto: number =
  ('T'.charCodeAt(0) << 24) | ('s'.charCodeAt(0) << 16) | ('t'.charCodeAt(0) << 8) | 'o'.charCodeAt(0)
export const phraseBeko: number =
  ('B'.charCodeAt(0) << 24) | ('E'.charCodeAt(0) << 16) | ('K'.charCodeAt(0) << 8) | 'O'.charCodeAt(0)

export const origins = {
  CS: 'CS',
  TESTO: 'TESTO',
  BEKO: 'BEKO',
}

const crypt = (value: number, key: number): number => {
  let result
  const shift = new Uint32Array(1)

  result = value
  shift[0] = key

  for (let i = 0; i < 4; i++) {
    result ^= shift[0]
    shift[0] = (shift[0] << 8) | (shift[0] >>> 24)
  }

  return result
}

export const identifyXmlOrigin = (dt32: number, dt33: number): string => {
  const result = crypt(dt33, dt32)
  if (result === phraseCs) {
    return origins.CS
  } else if (result === phraseTesto) {
    return origins.TESTO
  } else if (result === phraseBeko) {
    return origins.BEKO
  }

  // Fallback to CS
  return origins.CS
}
