import { DataItemAction, DataTable, DataTableColumn, DataTableRow, FixedActionBar } from '@csinstruments/cs-react-theme'
import { Grid, Typography } from '@material-ui/core'
import { Auditor } from 'api/models'
import Search from 'app/components/partials/Inputs/Search'
import { StandardConfirmationModal } from 'app/components/partials/StandardConfirmationModal/StandardConfirmationModal'
import { useGetAuditors, useSoftDeleteAuditor } from 'app/hooks/projectHooks'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { paddingTopBottom, paddingBottomActionBar } from 'styles/Shared'
import AddIcon from '@material-ui/icons/AddOutlined'
import DeleteIcon from '@material-ui/icons/Delete'
import { generatePath, useHistory } from 'react-router'
import { ROUTES } from 'app/helpers/routes'
import { usePermissions } from 'app/hooks/usePermissions'
import { TFunction } from 'i18next'

const AuditorMasterData: React.FC = () => {
  const { t } = useTranslation('common')
  const [searchString, setSearchString] = useState<string>('')
  const [selected, setSelected] = useState<string[]>([])
  const [rows, setRows] = useState<DataTableRow[]>([])
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
  const history = useHistory()
  //const [auditorsList, setAuditorsList] = useState<Auditor[]>([])
  const { auditors } = useGetAuditors()
  const { confirmSoftDelete } = useSoftDeleteAuditor()
  const p = usePermissions()

  const columns: DataTableColumn[] = useMemo(() => {
    return [
      {
        label: t('reports.auditor'),
      },
      {
        label: t('objects.company'),
      },
      {
        label: t('auditor.assigned'),
      },
    ]
  }, [t])

  const convertAuditorsToRows = (_auditors: Auditor[], t: TFunction): DataTableRow[] => {
    const tRows = _auditors.map((a) => ({
      id: a.id,
      values: [a.email, a.company, a.isAssigned ? t('actions.yes') : t('actions.no')],
    })) as DataTableRow[]
    return tRows
  }

  const tableButtonClicked = (id: string, action: DataItemAction): void => {
    switch (action) {
      case 'view':
        history.push(generatePath(ROUTES.editAuditor, { id: id }) + '?edit=true')
        break

      default:
        break
    }
  }

  const selectionChanged = useCallback((selection: string[]) => {
    setSelected(selection)
  }, [])

  const newAuditor = (): void => {
    history.push(generatePath(ROUTES.editAuditor, { id: 'new' }))
  }

  const deleteAction = (): void => setDeleteDialog(true)
  const handleDelete = (): void => {
    if (auditors.some((a) => selected.some((s) => a.id === s && !a.isAssigned))) {
      selected.map((auditorID) => {
        confirmSoftDelete(auditorID)
          .then(() => setRows((currArr) => currArr.filter((auditor) => auditor.id !== auditorID)))
          .catch(() => console.error('error deleting'))
      })
    }
  }

  useEffect(() => {
    const tableRows = convertAuditorsToRows(auditors, t)
    setRows(tableRows)
  }, [auditors, t])
  return (
    <div style={{ ...paddingTopBottom, ...paddingBottomActionBar }}>
      <FixedActionBar
        disabled={[
          selected.length === 0 ||
            auditors.some((a) => selected.some((s) => a.id === s && a.isAssigned)) ||
            !p.editProjects,
          !p.editProjects,
        ]}
        buttonProps={[{}, { solid: true }]}
        actions={[deleteAction, newAuditor]}
        labels={['actions.delete', 'actions.new']}
        icons={[DeleteIcon, AddIcon]}
      />
      <StandardConfirmationModal
        handleConfirm={handleDelete}
        open={deleteDialog}
        handleClose={() => setDeleteDialog(false)}
      />
      <Grid container justifyContent="flex-start" spacing={3}>
        <Grid item xs={12}>
          <Grid spacing={3} container direction="column" justifyContent="flex-start">
            <Grid item xs={12}>
              <Search changeFunction={setSearchString} label={t('actions.search')} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                {t('auditor.note')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DataTable
                rows={rows}
                columns={columns}
                onSelectionChanged={selectionChanged}
                allowedActions={{ checkable: true, viewable: true, sortable: true }}
                onButtonClicked={tableButtonClicked}
                filterColumn={0}
                filterString={searchString}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default AuditorMasterData
