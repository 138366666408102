/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { environment } from '../../constants/environment'
import constants from '../../constants/constants'
import axios, { AxiosRequestConfig } from 'axios'
import { ReportRequest } from '../helpers/reportGeneration/Types'
import { keycloak } from '../helpers/keycloakService'
import { getSelectedRealm } from 'app/helpers/manageRealms'
import { FileSelectionResult } from 'app/components/partials/Buttons/SingleFileUploadButton'

export const createDbExport = async (projectId: string | undefined = undefined): Promise<Buffer> => {
  const baseUri = environment.REACT_APP_API_DATA_HTTP_URI

  if (keycloak && keycloak.isTokenExpired(5)) {
    await keycloak.updateToken(5)
  }

  const config: AxiosRequestConfig = {
    headers: { Authorization: `Bearer ${keycloak.token}` },
    responseType: 'arraybuffer',
  }

  const url =
    baseUri +
    '/' +
    constants.WEB_APP_DB_EXPORT +
    `?realmName=${getSelectedRealm()}` +
    (projectId ? `&${constants.WEB_APP_DB_QUERY_PROJECT_ID}=${projectId}` : '')

  const result = await axios(url, config)

  return Buffer.from(result.data)
}

export const createDbImport = async (
  databasefile: FileSelectionResult,
  updateUploadProgress: (valPercent: number) => void,
): Promise<any> => {
  const baseUri = environment.REACT_APP_API_DATA_HTTP_URI

  if (keycloak && keycloak.isTokenExpired(5)) {
    await keycloak.updateToken(5)
  }

  const calculateProgress = (e: any) => {
    //console.log(e)
    const progressValue = (e.loaded / e.total) * 100
    updateUploadProgress(Math.round(progressValue))
  }

  const config: AxiosRequestConfig = {
    headers: { Authorization: `Bearer ${keycloak.token}` },
    onUploadProgress: calculateProgress,
  }
  const importDbData = databasefile.formData
  const url = baseUri + '/' + constants.WEB_APP_DB_IMPORT + `?realmName=${getSelectedRealm()}`
  //console.log('datainp', dataImp)
  const result = await axios.post(url, importDbData, config)
  //console.log('result', result)

  return result
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const clearCloudDb = async (): Promise<any> => {
  const baseUri = environment.REACT_APP_API_DATA_HTTP_URI
  if (keycloak && keycloak.isTokenExpired(5)) {
    await keycloak.updateToken(5)
  }
  const config: AxiosRequestConfig = {
    headers: { Authorization: `Bearer ${keycloak.token}` },
  }
  const url = baseUri + '/' + 'databaseClear' + `?realmName=${getSelectedRealm()}`
  //console.log('datainp', dataImp)
  const result = await axios.post(url, '', config)
  //console.log('result', result)

  return result
}

export const createCompanyExport = async (
  measureIDs: string[],
  failureIDs: string[],
  replacementIDs: string[],
  companyIDs: string[],
  exportBuildings: boolean,
  exportLocations: boolean,
): Promise<string> => {
  const baseUri = environment.REACT_APP_API_DATA_HTTP_URI
  const url = baseUri + '/' + constants.WEB_APP_COMPANY_EXPORT_ENDPOINT + `?realmName=${getSelectedRealm()}`

  if (keycloak && keycloak.isTokenExpired(5)) {
    await keycloak.updateToken(5)
  }
  //const [token] = getTokens()
  const config: AxiosRequestConfig = {
    headers: { Authorization: `Bearer ${keycloak.token}` },
    responseType: 'arraybuffer',
  }

  const data = {
    measureIDs: measureIDs,
    failureIDs: failureIDs,
    replacementIDs: replacementIDs,
    companyIDs: companyIDs,
    exportBuildings: exportBuildings,
    exportLocations: exportLocations,
  }
  const result = await axios.post(url, data, config)

  return result.data
}

export const createReport = async (req: ReportRequest): Promise<string> => {
  const url = req.type === 'xlsx' ? environment.REACT_APP_API_XLSX_REPORT_URI : environment.REACT_APP_API_PDF_REPORT_URI
  /* const data = {
    valueMap: req.valueMap,
    multiValueMap: req.multiValueMap,
    templateFile: req.templateFile,
    translations: req.translations,
  } */
  //console.log('report data', req)
  const result = await axios.post(url, req)

  return result.data
}
