import { User } from '../../interfaces/BusinessObjects'
import { useEffect, useState } from 'react'
import { getUserService } from '../dataServices/userService'

const service = getUserService()
// TODO: ???
const id = '_default_'

export const useUser = (): { user: User; loading: boolean } => {
  const [loading, setIsLoading] = useState<boolean>(false)
  const [user, setUser] = useState<User>({})

  useEffect(() => {
    const fetchUser = async (): Promise<void> => {
      setIsLoading(true)
      const user = await service?.getUserById(id)
      if (user) {
        setUser(user)
      }
      setIsLoading(false)
    }

    fetchUser()
  }, [])

  return {
    user,
    loading,
  }
}

export const useCreateOrUpdateUser = (): {
  createdUser: boolean
  createOrUpdateUser: (user: User) => Promise<void>
} => {
  const [user, setUser] = useState<boolean>(false)

  const createOrUpdateUser = async (user: User): Promise<void> => {
    setUser(false)
    await service?.createOrUpdateUser({ ...user, _id: id })
    setUser(true)
  }

  return { createOrUpdateUser, createdUser: user }
}
