import React from 'react'
import { generatePath, Link, Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom'
import { ProjectParamTypes } from '../../../../interfaces/PageParams'
import { ROUTES } from '../../../helpers/routes'
import { TabbedPage } from '../../partials/TabbedPage/TabbedPage'
import { ProjectLeakages } from './ProjectLeakages'
import { ProjectMaster } from './ProjectMaster'
import CsButton from '@csinstruments/cs-react-theme/dist/components/Buttons/CsButton'
import { useTranslation } from 'react-i18next'
import { useGetQueryParam } from '../utilHooks'

type Subpage = 'master' | 'leakages'

enum Subpages {
  master,
  leakages,
}

export const ProjectPage: React.FC = ({}) => {
  const { id, action, projectId } = useParams<ProjectParamTypes>()
  const { t } = useTranslation('common')
  const history = useHistory()
  const groupId = useGetQueryParam('groupId')
  const chosenTab: Subpages = action && action in Subpages ? Subpages[action as Subpage] : 1

  const tabChanged = (v: number): void => {
    let subRoute = generatePath(ROUTES.project, { id, action: Subpages[v], projectId })
    if (groupId) {
      subRoute += `?groupId=${groupId}`
    }
    history.push(subRoute)
  }
  let linkToProjectOverview = generatePath(ROUTES.companyProjects, { id })
  if (groupId) {
    linkToProjectOverview += `?groupId=${groupId}`
  }
  return (
    <>
      <Link style={{ textDecoration: 'none' }} to={linkToProjectOverview}>
        <CsButton
          fullwidth={false}
          solid={true}
          label={t('actions.backToProjectsOverview')}
          color={'primary'}
          onClick={() => undefined}
        />
      </Link>
      <TabbedPage chosenTab={chosenTab} tabChanged={tabChanged} labels={['project.masterData', 'objects.leakages']}>
        <Switch>
          <Route exact path={ROUTES.projectLeakages} render={() => <ProjectLeakages />} />
          <Route exact path={ROUTES.projectMaster} render={() => <ProjectMaster />} />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </TabbedPage>
    </>
  )
}
