export const isRenderedByElectron = (): boolean => {
  // https://ourcodeworld.com/articles/read/525/how-to-check-if-your-code-is-being-executed-in-electron-or-in-the-browser
  const mainProcess =
    typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron
  const rendererProcess =
    // @ts-ignore
    typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer'
  const nodeIntegratedProcess = typeof navigator === 'object' && navigator.userAgent.indexOf('Electron') >= 0
  return mainProcess || rendererProcess || nodeIntegratedProcess
}
