import React, { FC, ReactElement } from 'react'

// displays a full-width shadow at any place where its added to the page
export const BottomShadow: FC = ({}): ReactElement => {
  return (
    <div
      style={{
        zIndex: -1,
        top: -16,
        width: '100vw',
        position: 'relative',
        left: 'calc(-50vw + 50%)',
        boxShadow: '0 5px 6px -6px gray',
      }}
    >
      &nbsp;
    </div>
  )
}
