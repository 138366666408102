import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routes'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'

import { isRenderedByElectron } from '../../../helpers/renderedBy'
//import constants from '../../../../constants/constants'
import { OrganizationSelection } from './OrganizationSelection'
import { UserAccessContext } from '../UserProvider/UserProvider'
import { environment } from '../../../../constants/environment'
import { Organization, useOrganizations } from 'app/hooks/useKeycloak'
import { UserProfile, useUserContext } from '../CloudAuthProvider/UserAuthProvider'
import { clearStorage, enableLicBanner, getSelectedRealm } from 'app/helpers/manageRealms'
import { AppTop, AppTopUserInfo, CsButtonText } from '@csinstruments/cs-react-theme'
import LanguageSelection from '../Dropdown/LanguageSelection'
import { useCloudClient } from 'app/hooks/useApolloCloudClient'
import { MaintenaceAlert } from '../MaintenaceAlert'

const CsLdAppTop: React.FC = () => {
  const { t } = useTranslation('common')
  const [orgs, setOrgs] = useState<Organization[]>([])
  const history = useHistory()
  const organizations = useOrganizations()
  const { user } = useContext(UserAccessContext)
  const { keycloak, selectRealm } = useUserContext()
  const [userInfo, setUserInfo] = useState<UserProfile>()
  const cloudClient = useCloudClient()
  /* const handleLanguageChange = async (language: string): Promise<void> => {
    await i18n.changeLanguage(language)
  } */

  useEffect(() => {
    if (!userInfo && keycloak?.authenticated) {
      keycloak
        ?.loadUserInfo()
        .then((u: UserProfile) => {
          setUserInfo({
            ...u,
            username: u.email,
            locale: u.locale ?? 'en',
          })
        })
        .catch((e) => {
          console.error('er', e)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak?.authenticated])

  useEffect(() => {
    if (organizations.length && keycloak?.userInfo) {
      const loggedUserInfo = keycloak.userInfo as UserProfile
      const { preferred_org } = loggedUserInfo
      setOrgs(organizations)
      if (!organizations.some((o) => o.realmName === getSelectedRealm() || o.realmName === preferred_org)) {
        selectRealm(organizations[0].realmName)
      } else if (preferred_org) {
        selectRealm(preferred_org)
      }
      enableLicBanner()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations.length, keycloak?.userInfo])

  const logout = (): void => {
    clearStorage()
    cloudClient.clearStore()
    keycloak?.logout({ redirectUri: environment.WEB_URL + ROUTES.home })
  }

  const handleLogoClick = (): void => {
    window.open(t('reports.csLink'), '_blank')
  }

  return (
    <>
      <AppTop onLogoClick={handleLogoClick}>
        {isRenderedByElectron() && (
          <AppTopUserInfo
            clickAction={() => history.push(ROUTES.profile)}
            username={[user.surname, user.prename].join(' ')}
          />
        )}
        <MaintenaceAlert adminMessage={userInfo?.admin_message} />

        {keycloak?.authenticated && (
          <AppTopUserInfo
            clickAction={() => window.open(environment.USER_PROFILE_URL, '_blank')}
            username={userInfo?.username as string}
          />
        )}
        {/* {!isRenderedByElectron() && (
          <p>
            {environment.APP_VERSION} {environment.GIT_VERSION} {environment.BUILD_TIMESTAMP}
          </p>
        )} */}
        {keycloak?.authenticated && orgs.length && <OrganizationSelection organizations={orgs} />}
        <LanguageSelection userLang={userInfo?.locale} />

        {keycloak?.authenticated && (
          <CsButtonText id="logout" color="inherit" title="Logout" onClick={() => logout()} small>
            <ExitToAppOutlinedIcon />
          </CsButtonText>
        )}
      </AppTop>
    </>
  )
}

export default CsLdAppTop
