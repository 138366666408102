import { DataTableRow } from '@csinstruments/cs-react-theme/dist/components/DataTable/DataTableTypes'
import { Building, Leakage, Project } from '../../../../interfaces/BusinessObjects'
import { enumValToDisplayVal, isCfUnit, UnitLeakage } from '../../../../interfaces/Units'
import { convertAmount, calcCost } from '../../../helpers/calculations'
import { TFunction } from 'i18next'

export type RowFilter = {
  building?: string
  location?: string
  status?: string
  fromdate?: Date
  toDate?: Date
}

export type UnitValuePair = {
  unit: string
  value: number
}

export const mixedUnitsAccumulator = (unitValuePairs: UnitValuePair[]): UnitValuePair[] => {
  const accumulationMap = new Map<string, number>()
  const accumulatedRows = unitValuePairs.reduce((accumulator, { unit, value }) => {
    const prevAccVal = accumulator.get(unit) || 0
    const newAccVal = prevAccVal + value
    return accumulator.set(unit, newAccVal)
  }, accumulationMap)

  const accumulatedUnitValuePairs: UnitValuePair[] = Array.from(accumulatedRows).map(([unit, value]) => ({
    unit,
    value,
  }))
  return accumulatedUnitValuePairs
}

export const extractValues = (rows: DataTableRow[], column: number): string[] => {
  return rows.map((r: DataTableRow) => r.values[column]).filter((v, i, a) => a.indexOf(v) === i)
}
/** converting GermanLocaleDate to Date ***/
const getLeakageDate = (leakDate: string): Date => {
  const dateParts = leakDate.split('.')
  const date = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]))
  return date
}

export const applyFilter = (rows: DataTableRow[], filter: RowFilter): DataTableRow[] => {
  return rows.filter((r: DataTableRow) => {
    //console.log(new Date(r.values[6]))
    return (
      (!filter.building || r.values[0] === filter.building) &&
      (!filter.location || r.values[1] === filter.location) &&
      (!filter?.fromdate || new Date(getLeakageDate(r.values[6])).getTime() >= filter?.fromdate.getTime()) &&
      (!filter?.toDate || new Date(getLeakageDate(r.values[6])).getTime() <= filter?.toDate.getTime()) &&
      (!filter.status ||
        r.values[5] === filter.status ||
        (filter.status === 'actions.inProgress' && r.values[5] !== 'actions.completed'))
    )
  })
}

export const leakageUnit = (leakages: Leakage[], project: Project): string => {
  if (!project || !project.unitV) {
    return ''
  }
  return enumValToDisplayVal(isCfUnit(project.unitV) ? UnitLeakage.CFM : UnitLeakage.LTR_MIN)
}

export const leakagesToDataTableRows = (
  leakages: Leakage[],
  project: Project,
  buildings: Building[],
  t: TFunction,
): DataTableRow[] => {
  return leakages
    .sort((l1, l2) => Number(l1.leakTag) - Number(l2.leakTag))
    .map((l) => {
      const resLeakage = convertAmount(l.resLeakage, l.resUnitL, project.unitV, t)
      const building = buildings.filter((building) => building.id === l.buildingId).pop()
      return {
        id: l._id || '',
        values: [
          building?.name || '',
          l.place?.name ?? '',
          `${l.leakTag}`,
          `${resLeakage.text}`,
          calcCost(l, project, t, 2, 2).text,
          l.status,
          l.date ? new Date(l.date).toLocaleDateString('de-DE') : new Date().toLocaleDateString('de-DE'),
        ] as string[],
      }
    })
}

export type FirstLastDate = {
  FromLeakDate: Date | null
  ToLeakDate: Date | null
}
export const getLeakageFirstAndLastDate = (leakages: Leakage[]): FirstLastDate => {
  const sortedLeaksByDate = leakages.sort(
    (l1, l2) => getLeakageDate(`${l2.date}`).getTime() - getLeakageDate(`${l1.date}`).getTime(),
  )
  const firstLeakDate = sortedLeaksByDate[0]?.date
  const lastLeakDate = sortedLeaksByDate[sortedLeaksByDate.length - 1]?.date
  return {
    FromLeakDate: firstLeakDate ? new Date(firstLeakDate) : new Date(),
    ToLeakDate: lastLeakDate ? new Date(lastLeakDate) : new Date(),
  }
}
