export const buildingCardDefault = {
  display: 'flex',
  flexDirection: 'column' as const,
  //height: '150px',
  justifyContent: 'space-between',
  padding: 25,
}

export const buildingName = { paddingLeft: 10, verticalAlign: 'top', display: 'inline' }

export const actionIcon = { cursor: 'pointer' }

export const textField = { top: -2, left: 10, position: 'relative' as const, width: '80%' }

export const editIcon = { textAlign: 'center' as const, paddingTop: 5 }
