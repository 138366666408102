import { createCalcObject, formatFloat } from 'app/helpers/calculations'
import { calcAmount_DEFAULT_PRECISION, calcAmount_DEFAULT_TOFIXED } from 'constants/calculationConstants'
import { isCfUnit, Unit, UnitLeakage } from 'interfaces/Units'
import { fromCfmToltrmin, fromLtrMinToCfm, fromLtrMinToNm3, Leakage } from '../../../../interfaces/BusinessObjects'

export const getPrevAndNextLeakageId = (leakages: Leakage[], id: string, sortedIds: string): [string, string] => {
  if (!leakages || leakages.length === 0) return ['', '']
  const sorted = sortedIds.split(',').map((id) => leakages.find((l) => l && l._id === id) as Leakage)

  // const sorted = leakages.sort((a, b) => parseInt(a.leakTag ?? '0') - parseInt(b.leakTag ?? '0'))
  const index = sorted.map((s) => s._id).indexOf(id)
  return sorted.length > 0
    ? [sorted[(sorted.length + index - 1) % sorted.length]._id ?? '', sorted[(index + 1) % sorted.length]._id ?? '']
    : ['', '']
}

export const getLeakageRateValue = (
  value: string,
  locale: string,
  sourceUnit?: string,
  targetUnit?: string,
): string | undefined => {
  if (targetUnit === Unit.NM3_HOUR && sourceUnit === UnitLeakage.LTR_MIN) {
    const calc = fromLtrMinToNm3(parseFloat(value))
    return createCalcObject(calc, Unit.NM3_HOUR, locale, calcAmount_DEFAULT_PRECISION, calcAmount_DEFAULT_TOFIXED)
      .resultAsLocalFormat
  }

  if (targetUnit && isCfUnit(targetUnit) && sourceUnit === UnitLeakage.LTR_MIN) {
    const calc = fromLtrMinToCfm(parseFloat(value))
    const resCalObj = createCalcObject(
      calc,
      UnitLeakage.CFM,
      locale,
      calcAmount_DEFAULT_PRECISION,
      calcAmount_DEFAULT_TOFIXED,
    )
    //console.log({ resCalObj })
    return resCalObj.resultAsFormattedString
  }

  if (targetUnit && !isCfUnit(targetUnit) && sourceUnit && isCfUnit(sourceUnit)) {
    const calc = fromCfmToltrmin(parseFloat(value))
    //console.log({ calc })
    return createCalcObject(calc, UnitLeakage.LTR_MIN, locale, calcAmount_DEFAULT_PRECISION, calcAmount_DEFAULT_TOFIXED)
      .resultAsFormattedString
  }

  const numberValue = parseFloat(value)
  const leakageRate = createCalcObject(
    numberValue,
    UnitLeakage.LTR_MIN,
    locale,
    calcAmount_DEFAULT_PRECISION,
    calcAmount_DEFAULT_TOFIXED,
  ).resultAsFormattedString
  //console.log({ value, locale, numberValue, leakageRate })
  return leakageRate
}

export const getLeakageUnit = (projectUnitV: string): string => {
  return isCfUnit(projectUnitV) ? UnitLeakage.CFM : UnitLeakage.LTR_MIN
}
