const constants = {
  // version of the app, used e.g. for quickly identifying the type of import files
  CS_LD_VERSION: '2',
  LANGUAGES: [
    'de',
    'en',
    'es',
    'fr',
    'pt',
    'jp',
    'ro',
    'ru',
    'sv',
    'it',
    'tr',
    'zh',
    'zho',
    'ua',
    'cs',
    'sl',
    'pl',
  ].sort(),
  DATE: {
    DEFAULT_FORMAT: 'YYYY-MM-DDTHH:mm:ss',
    DATE_FORMAT: 'yyyy-MM-dd',
    ONLY_DATE: 'dd.MM.yyyy',
    GERMAN_DATE_TIME: 'dd.MM.yyyy HH:mm:ss',
    ZONED_FORMAT: 'YYYY-MM-DDTHH:mm:ssZZ',
  },
  LOGGING: {
    LOGGERS: {
      BASE: 'cs-management.base',
    },
    LEVELS: {
      TRACE: { index: 0, key: 'trace' },
      DEBUG: { index: 1, key: 'DEBUG' },
      INFO: { index: 2, key: 'info' },
      WARN: { index: 3, key: 'warn' },
      ERROR: { index: 4, key: 'error' },
    },
  },
  NAVIGATION: {
    HOME: '',
    TESTPAGE: 'testpage',
    TESTPAGE2: 'testpage2',

    GROUPS: 'groups',
    COMPANIES: 'companies',
    NEW_COMPANY: 'newCompany',
    DATA_IMPORT: 'data-import',
    DATA_EXPORT: 'export-maintenance-data-export',
    PROJECT_IMPORT: 'project-import',
    DATABASE: 'database',
    PROFILE: 'profile',
    LICENSE: 'license',
    TRASH_CAN: 'trashcan',
    EXPORT_MAINTENANCE: 'export-maintenance',
    COMPANY: 'company',
    AUDITOR: 'auditor',
    NEWAUDITOR: 'newauditor',

    LEARN: 'learn',

    SERVER_ERROR_PAGE: 'server-error',
    NO_ACCESS_PAGE: 'no-access',

    UPDATES: 'updates',
  },
  ROUTES: {},
  WEB_APP_DB_EXPORT: 'databaseExport',
  WEB_APP_DB_IMPORT: 'databaseImport',
  WEB_APP_COMPANY_EXPORT_ENDPOINT: 'companyExport',
  WEB_APP_DB_QUERY_PROJECT_ID: 'projectId',
}

export default constants
