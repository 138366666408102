import React, { ChangeEvent, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined'
import * as Sentry from '@sentry/browser'
import { FileSelectionImage } from '../../../helpers/dataImport/dataImportTypes'
import { TranslatedCsButton } from './TranslatedCsButton'
import { CsButtonProps } from '@csinstruments/cs-react-theme/dist/components/Buttons/CsButton'

export type MultiFileUploadButtonProps = {
  label: string
  fullwidth?: boolean
  xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  noGrid?: boolean
  resultHandler?: (result: MultiFileSelectionResult, error: boolean) => void
  buttonProps?: CsButtonProps
  disabled?: boolean
}

export type MultiFileSelectionResult = {
  images: FileSelectionImage[]
  textContents: string[]
  journalNames: string[]
}

export const MultiFileSelectionButton: React.FC<MultiFileUploadButtonProps> = ({
  md,
  xs,
  buttonProps,
  label,
  fullwidth,
  resultHandler,
  noGrid,
  disabled,
}) => {
  const [availableImages, setAvailableImages] = useState<number>(0)
  const [processedImages, setProcessedImages] = useState<number>(0)
  const [images, setImages] = useState<FileSelectionImage[]>([])
  const [fileFound, setFileFound] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [textContents, setTextContents] = useState<string[]>([])
  const [journalNames, setJournalNames] = useState<string[]>([])

  useEffect(() => {
    if (
      ((availableImages > 0 && processedImages === availableImages) || availableImages === -1) &&
      fileFound &&
      textContents.length > 0 &&
      textContents.length === journalNames.length
    ) {
      resultHandler && resultHandler({ images, textContents, journalNames }, false)
    }
  }, [availableImages, processedImages, fileFound, textContents, images, resultHandler, journalNames])

  type FileFromFileList = {
    name: string
    path?: string
  }

  const handleChange = (selectorFiles: FileList | null): void => {
    setAvailableImages(0)
    setProcessedImages(0)
    setFileFound(false)
    setTextContents([])
    if (selectorFiles && selectorFiles.length > 0) {
      let chosenFile: File | undefined
      let nImages = 0
      let jnlNames: string[] = []
      for (let i = 0; i < selectorFiles.length; i++) {
        if (selectorFiles.item(i)?.type === 'text/xml') {
          const reader = new FileReader()
          chosenFile = selectorFiles.item(i) || undefined
          const fn = (chosenFile as FileFromFileList)?.path || (chosenFile as FileFromFileList)?.name
          jnlNames = [...jnlNames, fn]
          reader.readAsText(chosenFile as File, 'utf-8')
          reader.onload = () => {
            const result = reader.result as string
            setTextContents((tc) => [...tc, result])
          }
          setFileFound(true)
        } else if (selectorFiles.item(i)?.type === 'image/jpeg') {
          nImages++
          const reader = new FileReader()
          const file = selectorFiles.item(i) as File
          // @ts-ignore
          const path = (selectorFiles.item(i) as File).path ?? (selectorFiles.item(i) as File).webkitRelativePath
          reader.readAsDataURL(file)
          reader.onload = () => {
            setImages((images) => [...images, { name: path, contents: reader.result as string }])
            setProcessedImages((prev) => prev + 1)
          }
        }
      }
      setJournalNames(jnlNames)
      setAvailableImages(nImages > 0 ? nImages : -1)

      if (!chosenFile) {
        console.log('no applicable file')
        resultHandler &&
          resultHandler(
            { images: [] as FileSelectionImage[], textContents: [] as string[], journalNames: [] as string[] },
            true,
          )
        try {
          // @ts-ignore
          Sentry.captureException(new Error('data import failed exception'), (scope) => {
            scope.setTransactionName('handleChange')
            scope.setExtra('number of files', selectorFiles.length)
            scope.setExtra('reason', 'no applicable file')
            return scope
          })
        } catch (e) {
          console.error('failed to send event to sentry', e)
        }
      }
    }
  }

  function deferredHandleChange(event: ChangeEvent<HTMLInputElement>): void {
    setLoading(true)
    setTimeout(() => {
      handleChange(event.target.files)
      setLoading(false)
      event.target.value = ''
    }, 500)
  }

  const main = (
    <>
      <input
        accept="*"
        style={{ display: 'none' }}
        disabled={disabled}
        id="selection-input"
        multiple
        type="file"
        // Unfortunately, this is not yet supported by the types… According to the React team,
        // should be supported in future versions
        /* @ts-expect-error */
        directory=""
        webkitdirectory=""
        onChange={(event) => {
          event.persist()
          deferredHandleChange(event)
        }}
      />
      <label htmlFor="selection-input">
        {!loading && (
          <TranslatedCsButton
            disabled={!!disabled}
            solid={true}
            fileUpload={true}
            {...buttonProps}
            fullwidth={fullwidth}
            label={label}
          >
            <CloudUploadIcon />
          </TranslatedCsButton>
        )}
        {loading && (
          <TranslatedCsButton
            disabled={true}
            solid={true}
            fileUpload={false}
            {...buttonProps}
            fullwidth={fullwidth}
            label={'actions.waitProjectImport'}
          >
            <CloudUploadIcon />
          </TranslatedCsButton>
        )}
      </label>
    </>
  )

  return noGrid ? (
    main
  ) : (
    <Grid item xs={xs ? xs : 6} md={md ? md : xs ? xs : 6}>
      {main}
    </Grid>
  )
}
