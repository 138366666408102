import React, { FC, ReactElement, useCallback, useState } from 'react'
import Select from '@material-ui/core/Select'
import { Checkbox, FormControl, Input, ListItemText, MenuItem } from '@material-ui/core'
import { InputLabel } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

type DropdownProps = {
  valueSelected: (value: string[]) => void
  values: string[]
  label: string
}

export const Dropdown: FC<DropdownProps> = ({ valueSelected, values, label }): ReactElement => {
  const [selectedValues, setSelectedValues] = useState<string[]>([])
  const { t } = useTranslation('common')

  const valueWasSelected = useCallback(
    (val: string[]) => {
      valueSelected(val)
      setSelectedValues(val)
    },
    [setSelectedValues, valueSelected],
  )

  return (
    <>
      <FormControl fullWidth={true}>
        <InputLabel>{t(label)}</InputLabel>
        <Select
          multiple
          displayEmpty
          input={<Input fullWidth={true} />}
          fullWidth={true}
          renderValue={(selected) => (selected as string[]).join(', ')}
          value={selectedValues}
          onChange={(e) => valueWasSelected(e.target.value as string[])}
        >
          {values.map((val) => (
            <MenuItem key={val} value={val}>
              <Checkbox checked={selectedValues.indexOf(val) > -1} />
              <ListItemText primary={val} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}
