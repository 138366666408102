import { Contact } from '../../../interfaces/BusinessObjects'
import { PersistedContact } from '../types/types'
import {
  createObject,
  deleteObjects,
  finalDeleteObjectById,
  getDatastore,
  getObjectById,
  getObjects,
  updateObjectById,
} from './shared/shared'
import { repositoryNames } from './repositoryNames'

export const getContacts = (): Promise<PersistedContact[]> => {
  return getObjects(getDatastore(repositoryNames.CONTACT))
}

export const deleteContacts = (): Promise<number> => {
  return deleteObjects(getDatastore(repositoryNames.CONTACT))
}

export const getContactById = (id: string): Promise<PersistedContact> => {
  return getObjectById(getDatastore(repositoryNames.CONTACT), id)
}

export const createContact = (contact: Contact): Promise<PersistedContact> => {
  return createObject(getDatastore(repositoryNames.CONTACT), contact)
}

export const updateContactById = (id: string, contact: Contact): Promise<PersistedContact> => {
  return updateObjectById(getDatastore(repositoryNames.CONTACT), id, contact)
}

export const deleteContactById = (id: string): Promise<number> => {
  return finalDeleteObjectById(getDatastore(repositoryNames.CONTACT), id)
}
