import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined'
import { chipPadding, paddingBottomActionBar, paddingTop, standardContentPadding } from '../../../styles/Shared'
import { DataTable } from '@csinstruments/cs-react-theme/dist/components/DataTable/DataTable'
import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import { DataTableColumn, DataTableRow } from '@csinstruments/cs-react-theme/dist/components/DataTable/DataTableTypes'
import { StandardConfirmationModal } from '../partials/StandardConfirmationModal/StandardConfirmationModal'
import { useDeletedBuildings, useFinalDeleteBuilding, useRestoreBuilding } from '../../hooks/buildingHooks'
import { useDeletedCompanies, useFinalDeleteCompany, useRestoreCompany } from '../../hooks/companyHooks'
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined'
import {
  useDeletedAuditors,
  useDeletedProjects,
  useFinalDeleteAuditor,
  useFinalDeleteProject,
  useRestoreAuditor,
  useRestoreProject,
} from '../../hooks/projectHooks'
import { useEmptyTrash } from '../../hooks/useEmptyTrash'
import { useDeletedLeakages, useFinalDeleteLeakage, useRestoreLeakage } from '../../hooks/leakageHooks'
import Chip from '@material-ui/core/Chip/Chip'
import BusinessIcon from '@material-ui/icons/BusinessOutlined'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenterOutlined'
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined'
import OpacityIcon from '@material-ui/icons/OpacityOutlined'
import { ResultAlert } from '../partials/ResultAlert'
import { FixedActionBar } from '@csinstruments/cs-react-theme/dist/components/FixedActionBar/FixedActionBar'
import { PageHeading } from '../partials/PageHeading'
import { usePermissions } from '../../hooks/usePermissions'
import { isRenderedByElectron } from '../../helpers/renderedBy'
import PinDropIcon from '@material-ui/icons/PinDrop'
import { useDeletedPlaces, useFinalDeletePlace, useRestorePlace } from 'app/hooks/placeHooks'
import { ProgressBar } from '../partials/ProgressBar/ProgressBar'
import { useDeletedGroups, useFinalDeleteGroup, useRestoreGroup } from 'app/hooks/groupHooks'
import {
  useDeleteTagAttributeMutation,
  useGetDeletedTagAttributesQuery,
  useRestoreTagAttributeMutation,
} from 'api/models'
import { getSelectedRealm } from 'app/helpers/manageRealms'

type TrashObject = {
  _id?: string
  name?: string
  path?: string[]
  _deleted_at?: Date
}

const columns: DataTableColumn[] = [
  { label: 'objects.name', alignment: 'left' },
  //{ label: 'objects.path', alignment: 'left' },
  { label: 'objects.date', alignment: 'left' },
]

const trashObjectToTableRow = (
  to: TrashObject & { leakTag?: string; building?: string; auditor?: string },
): DataTableRow => {
  return {
    id: to._id ?? to.name ?? '',
    values: [
      to.name ?? (to.leakTag ? `${to.leakTag} ${to.building ? '(' + to.building + '/' + ')' : ''}` : ''),
      //to.path ? to.path.join('/') : '',
      to._deleted_at?.toLocaleDateString() ?? '',
    ],
  }
}

export const TrashCan: React.FC = () => {
  const { t } = useTranslation('common')
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { buildings } = useDeletedBuildings()
  const { companies, loading } = useDeletedCompanies()
  const { projects } = useDeletedProjects()
  const { leakages } = useDeletedLeakages()
  const { auditors } = useDeletedAuditors()
  //const deletedGroups = useDeletedGroups()
  const { data: deletedTags } = useGetDeletedTagAttributesQuery({
    variables: { realmName: getSelectedRealm() },
    skip: !getSelectedRealm(),
  })
  const { deletedPlaces, fetchingPlaces } = useDeletedPlaces()

  const [selectedObjects, setSelectedObjects] = useState<DataTableRow[]>([])
  const [selectedType, setSelectedType] = useState<string>('objects.companies')
  const [hiddenObjects, setHiddenObjects] = useState<Record<string, string[]>>({})
  const { deleteBuilding } = useFinalDeleteBuilding()
  const { deleteCompany } = useFinalDeleteCompany()
  // const { deleteGroup } = useFinalDeleteGroup()
  const { deleteProject } = useFinalDeleteProject()
  const { deleteLeakage } = useFinalDeleteLeakage()
  const { confirmDelete } = useFinalDeleteAuditor()
  const { confirmDeletePlace } = useFinalDeletePlace()
  const { restoreBuilding } = useRestoreBuilding()
  const { restoreCompany } = useRestoreCompany()
  const { restoreProject } = useRestoreProject()
  const { restoreLeakage } = useRestoreLeakage()
  const { restoreAuditor } = useRestoreAuditor()
  // const { restoreGroup } = useRestoreGroup()
  const [deleteTagFunc] = useDeleteTagAttributeMutation()
  const [restoreTagFunc] = useRestoreTagAttributeMutation()
  const { restorePlace } = useRestorePlace()
  const { emptyTrash, emptied } = useEmptyTrash()
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const permissions = usePermissions()

  const deleteGroup = async (id: string) => {
    await deleteTagFunc({
      variables: {
        realmName: getSelectedRealm(),
        ID: id,
      },
    })
  }

  const restoreGroup = async (id: string) => {
    await restoreTagFunc({
      variables: {
        realmName: getSelectedRealm(),
        ID: id,
      },
    })
  }

  const deletedGroups = useMemo(() => {
    if (!deletedTags?.getDeletedTagAttributes.length) return []
    return deletedTags.getDeletedTagAttributes.map((tg) => ({
      _id: tg.ID,
      name: tg.name,
      _deleted_at: tg._deleted_at ? new Date(tg._deleted_at) : undefined,
    }))
  }, [deletedTags])

  // sets the initial state (show deleted companies) as soon as its available
  useEffect(() => {
    if (!loading) {
      const toSelect = companies.map((o) => trashObjectToTableRow(o))
      setSelectedObjects(toSelect)
    }
  }, [loading, companies])

  useEffect(() => {
    if (emptied && !fetchingPlaces) {
      setShowSuccess(true)
      const newHidden: Record<string, string[]> = {}
      newHidden['objects.companies'] = companies.map((o) => trashObjectToTableRow(o).id)
      newHidden['objects.projects'] = projects.map((o) => trashObjectToTableRow(o).id)
      newHidden['objects.buildings'] = buildings.map((o) => trashObjectToTableRow(o).id)
      newHidden['objects.leakages'] = leakages.map((o) => trashObjectToTableRow(o).id)
      newHidden['reports.auditor'] = auditors.map((o) => trashObjectToTableRow(o).id)
      newHidden['objects.location'] = deletedPlaces.map((o) => trashObjectToTableRow(o).id)
      newHidden['objects.groups'] = deletedGroups.map((o) => trashObjectToTableRow(o).id)
      setHiddenObjects(newHidden)
      setSelectedObjects([])
    }
  }, [emptied, companies, projects, buildings, leakages, auditors, deletedPlaces, fetchingPlaces, deletedGroups])

  // updates the objects that are shown in the UI
  // i.e. if a delete/restore happens, the object will be added to the hidden objects
  const updateHiddenObjects = (id: string): void => {
    const newDeleted = hiddenObjects[selectedType] ? [...hiddenObjects[selectedType], id] : [id]
    const newRecord = { ...hiddenObjects }
    newRecord[selectedType] = newDeleted
    setHiddenObjects(newRecord)
    setSelectedObjects(selectedObjects.filter((so) => !(so.id === id)))
  }

  const moveOutOfTrashActionRequested = (
    id: string,
    companyAction: (id: string) => unknown,
    groupAction: (id: string) => unknown,
    projectAction: (id: string) => unknown,
    buildingAction: (id: string) => unknown,
    leakageAction: (id: string) => unknown,
    auditorAction: (id: string) => unknown,
    placeAction: (id: string) => unknown,
  ): void => {
    switch (selectedType) {
      case 'objects.companies':
        companyAction(id)
        break
      case 'objects.groups':
        groupAction(id)
        break
      case 'objects.projects':
        projectAction(id)
        break
      case 'objects.buildings':
        buildingAction(id)
        break
      case 'objects.leakages':
        leakageAction(id)
        break
      case 'reports.auditor':
        auditorAction(id)
        break
      case 'objects.location':
        placeAction(id)
        break
    }
    updateHiddenObjects(id)
  }

  const deletionRequested = (id: string): void => {
    moveOutOfTrashActionRequested(
      id,
      deleteCompany,
      deleteGroup,
      deleteProject,
      deleteBuilding,
      deleteLeakage,
      confirmDelete,
      confirmDeletePlace,
    )
  }

  const restoreRequested = (name: string): void => {
    moveOutOfTrashActionRequested(
      name,
      restoreCompany,
      restoreGroup,
      restoreProject,
      restoreBuilding,
      restoreLeakage,
      restoreAuditor,
      restorePlace,
    )
  }

  const filterOutDeleted = (trashObjects: TrashObject[], type: string): TrashObject[] => {
    return trashObjects.filter((t) => !hiddenObjects[type]?.includes(t._id ?? ''))
  }

  const selectObjects = (type: string): void => {
    setSelectedType(type)
    switch (type) {
      case 'objects.companies': {
        setSelectedObjects(filterOutDeleted(companies, type).map((o) => trashObjectToTableRow(o)))
        break
      }
      case 'objects.projects': {
        setSelectedObjects(filterOutDeleted(projects, type).map((o) => trashObjectToTableRow(o)))
        break
      }
      case 'objects.buildings': {
        setSelectedObjects(filterOutDeleted(buildings, type).map((o) => trashObjectToTableRow(o)))
        break
      }
      case 'objects.leakages': {
        setSelectedObjects(filterOutDeleted(leakages, type).map((o) => trashObjectToTableRow(o)))
        break
      }
      case 'reports.auditor': {
        setSelectedObjects(filterOutDeleted(auditors, type).map((o) => trashObjectToTableRow(o)))
        break
      }
      case 'objects.location': {
        setSelectedObjects(filterOutDeleted(deletedPlaces, type).map((o) => trashObjectToTableRow(o)))
        break
      }
      case 'objects.groups': {
        setSelectedObjects(filterOutDeleted(deletedGroups, type).map((o) => trashObjectToTableRow(o)))
        break
      }
    }
  }

  const deleteAction = useCallback(() => setModalOpen(true), [])
  const editAllowed = useMemo(() => {
    return (
      (selectedType == 'objects.companies' && permissions.editCompanies) ||
      (selectedType == 'objects.buildings' && permissions.editBuildings) ||
      (selectedType == 'objects.projects' && permissions.editProjects) ||
      (selectedType == 'objects.leakages' && permissions.editProjects) ||
      (selectedType == 'reports.auditor' && permissions.editProjects) ||
      (selectedType == 'objects.location' && permissions.editProjects) ||
      (selectedType == 'objects.groups' && permissions.rootAdmin)
    )
  }, [selectedType, permissions])

  return loading ? (
    <></>
  ) : (
    <div style={{ ...paddingTop, ...paddingBottomActionBar }}>
      <ResultAlert alertText={'actions.trashSuccess'} showAlert={showSuccess} modifyShowAlert={setShowSuccess} />
      <ProgressBar show={fetchingPlaces || loading} />
      <StandardConfirmationModal
        handleConfirm={() => emptyTrash()}
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
      />
      {isRenderedByElectron() && (
        <FixedActionBar
          buttonProps={[{ solid: true }]}
          actions={[deleteAction]}
          labels={['actions.emptyTrash']}
          icons={[DeleteIcon]}
        />
      )}
      <div style={standardContentPadding}>
        <Grid container justifyContent="flex-start">
          <Grid item xs={4}>
            <PageHeading title={'objects.trashcan'} />
            <Grid container direction="column" justifyContent="flex-start">
              <Grid item xs={12}>
                <Box pr={2}>
                  <Chip
                    icon={<BusinessCenterIcon />}
                    label={t('objects.companies')}
                    clickable
                    style={chipPadding}
                    onClick={() => selectObjects('objects.companies')}
                    color={selectedType === 'objects.companies' ? 'secondary' : 'default'}
                  />
                  <Chip
                    icon={<BusinessIcon />}
                    label={t('objects.buildings')}
                    clickable
                    style={chipPadding}
                    onClick={() => selectObjects('objects.buildings')}
                    color={selectedType === 'objects.buildings' ? 'secondary' : 'default'}
                  />
                  <Chip
                    icon={<AssignmentIcon />}
                    label={t('objects.projects')}
                    clickable
                    style={chipPadding}
                    onClick={() => selectObjects('objects.projects')}
                    color={selectedType === 'objects.projects' ? 'secondary' : 'default'}
                  />
                  <Chip
                    icon={<OpacityIcon />}
                    label={t('objects.leakages')}
                    clickable
                    style={chipPadding}
                    onClick={() => selectObjects('objects.leakages')}
                    color={selectedType === 'objects.leakages' ? 'secondary' : 'default'}
                  />
                  <Chip
                    icon={<PinDropIcon />}
                    label={t('objects.location')}
                    clickable
                    style={chipPadding}
                    onClick={() => selectObjects('objects.location')}
                    color={selectedType === 'objects.location' ? 'secondary' : 'default'}
                  />
                  {permissions.rootAdmin && (
                    <Chip
                      icon={<FolderOutlinedIcon />}
                      label={t('objects.groups')}
                      clickable
                      style={chipPadding}
                      onClick={() => selectObjects('objects.groups')}
                      color={selectedType === 'objects.groups' ? 'secondary' : 'default'}
                    />
                  )}
                  {!isRenderedByElectron() && (
                    <Chip
                      icon={<SupervisedUserCircleOutlinedIcon />}
                      label={t('reports.auditor')}
                      clickable
                      style={chipPadding}
                      onClick={() => selectObjects('reports.auditor')}
                      color={selectedType === 'reports.auditor' ? 'secondary' : 'default'}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Grid spacing={1} container direction="column" justifyContent="flex-start">
              <Grid item xs={12}>
                <Typography variant="h5">
                  {t(selectedType)} ({selectedObjects.length})
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <DataTable
                  allowedActions={{ deletable: editAllowed, restorable: editAllowed }}
                  rows={selectedObjects}
                  columns={columns}
                  onButtonClicked={(id, action) => (action === 'delete' ? deletionRequested(id) : restoreRequested(id))}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
