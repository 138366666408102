export function truncateByBytesUTF8(chars: string, n: number): string {
  let bytes = toBytesUTF8(chars).substring(0, n)
  while (true) {
    try {
      return fromBytesUTF8(bytes)
    } catch (e) {}
    bytes = bytes.substring(0, bytes.length - 1)
  }
}

export function removeLDUnsupportedCharacters(text: string): string {
  return text.replaceAll(
    /[^\u00D6\u00d1-\u00d5\u0020-\u007E\u00a1\u00a3\u00a5\u00a9-\u00ab\u00b0-\u00b3\u00b5\u00b9-\u00cf\u00d1-\u00d5\u00d8-\u00dd\u00df-\u00ef\u00f1-\u00f6\u00f8-\u00fd\u00ff\u0102-\u0107\u010c-\u010f\u0118-\u011b\u011e\u011f\u0130\u0131\u0141-\u0144\u0147\u0148\u0152\u0153\u0158-\u015b\u015e-\u0165\u016e\u016f\u0178-\u017e\u0219\u021b\u0394\u0401\u0410-\u044f\u0451\u2019\u2026\u2030\u20ac\u2116\u2126\u2153\u2154\u215b-\u215e\u25ba\u25c4]/g,
    '',
  )
}

export function transformTextForExport(text: string): string {
  return truncateByBytesUTF8(removeLDUnsupportedCharacters(text), 32)
}

function toBytesUTF8(chars: string): string {
  return unescape(encodeURIComponent(chars))
}
function fromBytesUTF8(bytes: string): string {
  return decodeURIComponent(escape(bytes))
}
