import { Company } from '../../../interfaces/BusinessObjects'
import { PersistedCompany } from '../types/types'
import {
  createObject,
  finalDeleteObjectById,
  getDeletedObjects,
  getObjectById,
  getObjectByQuery,
  getObjects,
  updateObjectById,
  deleteObjectById,
  restoreObject,
  finalDeleteTrashObjects,
  getDatastore,
  deleteObjects,
} from './shared/shared'
import { repositoryNames } from './repositoryNames'

export const getCompanies = async (): Promise<PersistedCompany[]> => {
  return getObjects(getDatastore(repositoryNames.COMPANY))
}

export const getCompaniesWithFilter = async (incomingFilter?: unknown, sort?: unknown): Promise<PersistedCompany[]> => {
  const filter = incomingFilter

  return new Promise((resolve, reject) => {
    getDatastore(repositoryNames.COMPANY)
      .find(filter || { _deleted_at: { $exists: false } })
      .sort(sort)
      .exec((err: Error | null, doc: PersistedCompany[]) => {
        if (!err) {
          resolve(doc)
        } else {
          reject(err)
        }
      })
  })
}

export const getCompanyById = (id: string): Promise<PersistedCompany> => {
  return getObjectById(getDatastore(repositoryNames.COMPANY), id)
}

export const getCompanyByName = (name: string): Promise<PersistedCompany> => {
  return getObjectByQuery(getDatastore(repositoryNames.COMPANY), { name, _deleted_at: { $exists: false } })
}

export const createCompany = (company: Company): Promise<PersistedCompany> => {
  return createObject(getDatastore(repositoryNames.COMPANY), company)
}

export const updateCompanyById = (id: string, company: PersistedCompany): Promise<PersistedCompany> => {
  return updateObjectById(getDatastore(repositoryNames.COMPANY), id, company)
}

export const deleteCompanyById = (id: string): Promise<number> => {
  return deleteObjectById(getDatastore(repositoryNames.COMPANY), id)
}

export const getDeletedCompanies = (): Promise<PersistedCompany[]> => {
  return getDeletedObjects(getDatastore(repositoryNames.COMPANY))
}

export const finalDeleteCompany = (id: string): Promise<number> => {
  return finalDeleteObjectById(getDatastore(repositoryNames.COMPANY), id)
}

export const restoreCompany = (id: string): Promise<number> => {
  return restoreObject(getDatastore(repositoryNames.COMPANY), id)
}

export const finalDeleteTrashCompanies = (): Promise<number> => {
  return finalDeleteTrashObjects(getDatastore(repositoryNames.COMPANY))
}

export const deleteCompanies = (): Promise<number> => {
  return deleteObjects(getDatastore(repositoryNames.COMPANY))
}
