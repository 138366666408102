import { Snackbar, Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  disableMaintenanceAlert,
  enableMaintenanceAlert,
  getMaintenanceAlert,
  isAlertNotSet,
} from '../../helpers/utils'
import { AdminMessageParams } from 'interfaces/BusinessObjects'

type MaintenaceAlertProps = {
  adminMessage?: string
}
export const MaintenaceAlert: React.FC<MaintenaceAlertProps> = ({ adminMessage }: MaintenaceAlertProps) => {
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>('')
  const { t } = useTranslation('common')

  useEffect(() => {
    if (adminMessage) {
      const adminMessageParams: AdminMessageParams = JSON.parse(adminMessage)
      if (adminMessageParams.isUnderMaintenance === 'true') {
        const alertText = t('server.maintenance', { ...adminMessageParams })
        setAlertMessage(alertText)
        setShowAlert(true)
        isAlertNotSet() && enableMaintenanceAlert()
      }
    }
  }, [adminMessage, t])
  const disableAlert = useCallback(() => {
    setShowAlert(false)
    disableMaintenanceAlert()
  }, [])
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showAlert && getMaintenanceAlert()}
        onClose={disableAlert}
      >
        <Alert onClose={disableAlert} severity={'warning'}>
          <Typography align="center" variant="subtitle2">
            {alertMessage}
          </Typography>
        </Alert>
      </Snackbar>
    </>
  )
}
