import React, { useEffect, useState } from 'react'
import { ApolloProvider } from '@apollo/client'
import { useCloudClient } from './hooks/useApolloCloudClient'
import { getApolloClient } from './helpers/apolloClient'
import { useExportNotification } from './hooks/exportNotificationHooks'
import { LicenseStatusContext, LicenseStatusType } from 'context/LicenseContext'
import { FollowDrawer } from '@csinstruments/cs-react-theme'
import { Switch, Route, Redirect } from 'react-router-dom'
import { completeContainer } from 'styles/Shared'
//import { CompaniesOverview } from './components/pages/CompaniesOverview/CompaniesOverview'
import CompanyMasterDataEdit from './components/pages/CompanyPage/CompanyMasterDataEdit'
import CompanyPage from './components/pages/CompanyPage/CompanyPage'
import Database from './components/pages/Database/Database'
import { DataExport } from './components/pages/DataExport'
import { DataImport } from './components/pages/DataImport/DataImport'
import { ExportMaintenance } from './components/pages/ExportMaintenance/ExportMaintenance'
import { LeakagePage } from './components/pages/Leakage/Leakage'
import { LearningProgramPage } from './components/pages/LearningProgramPage'
import License from './components/pages/License/License'
import Profile from './components/pages/Profile'
import ProjectImport from './components/pages/ProjectImport'
import { ProjectMaster } from './components/pages/ProjectPage/ProjectMaster'
import { ProjectPage } from './components/pages/ProjectPage/ProjectPage'
import { TrashCan } from './components/pages/TrashCan'
import { UpdateOverview } from './components/pages/UpdateOverview'
import { BackgroundImage } from './components/partials/BackgroundImage/BackgroundImage'
import { aboveBackgroundImage } from './components/partials/BackgroundImage/BackgroundImageStyle'
import CsLdAppTop from './components/partials/Navigation/CsLdAppTop'
import { CsLdNavigationDrawer } from './components/partials/Navigation/Drawer'
import { NavigationBreadcrumbs } from './components/partials/Navigation/NavigationBreadcrumbs'
import { PageContent } from './components/partials/PageContent'
import { ResultAlert } from './components/partials/ResultAlert'
import { ROUTES } from './helpers/routes'
import { CloudLicense } from './components/pages/License/CloudLicense'
import AccessDenied from './components/partials/AccessDenied'
import { Dashboard } from './components/pages/Dashboard/Dashboard'
import { useUserContext } from './components/partials/CloudAuthProvider/UserAuthProvider'
import { AuditorProfile } from './components/pages/AuditorsPage/AuditorProfile'
import AuditorMasterData from './components/pages/AuditorsPage/AuditorMaster'
import { useOrganizations } from './hooks/useKeycloak'
import BuildingProfile from './components/pages/BuildingsPage/BuildingProfile'
import { BuildingPage } from './components/pages/BuildingsPage/BuildingPage'
import GroupsOverview from './components/pages/CompanyWithTagFilter/GroupsOverview'
import { useKeycloak } from '@react-keycloak/web'
import { Tagmanagement } from './components/pages/TagManagement/TagManagement'

function App(): React.ReactElement {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const { exportRecommended } = useExportNotification()
  const [showAlert, setShowAlert] = useState(true)
  const [licenseStatus, setLicenseStatus] = React.useState(LicenseStatusType.Deactivated)
  const { keycloak } = useKeycloak()
  const cloudClient = useCloudClient()
  const organizations = useOrganizations()
  const client = !keycloak.authenticated ? getApolloClient() : cloudClient

  const [tokensAvailable, setTokensAvailable] = useState(false)

  useEffect(() => {
    const [token] = [keycloak?.token ?? null]
    if (!token) {
      // wait for local storage
      setTimeout(() => {
        const [token] = [keycloak?.token ?? '']
        if (token) {
          setTokensAvailable(true)
        }
      }, 500)
    } else {
      setTokensAvailable(true)
    }
  }, [keycloak?.token])

  if (keycloak && !tokensAvailable) {
    return <></>
  }

  if (keycloak && keycloak.authenticated && tokensAvailable && !organizations.length) {
    return <AccessDenied />
  }

  return (
    <ApolloProvider client={client}>
      <div style={completeContainer}>
        <LicenseStatusContext.Provider value={{ licenseStatus, setLicenseStatus }}>
          <CsLdNavigationDrawer onDrawerAction={(open) => setDrawerOpen(open)} />
          <FollowDrawer open={drawerOpen}>
            <CsLdAppTop />
            <PageContent>
              <BackgroundImage />
              <NavigationBreadcrumbs />
              <div style={aboveBackgroundImage}>
                {exportRecommended && (
                  <ResultAlert
                    autoHideDuration={7000}
                    severity="info"
                    alertText="databaseExport.reminder"
                    modifyShowAlert={setShowAlert}
                    showAlert={showAlert}
                  />
                )}
                {keycloak?.authenticated && <CloudLicense />}
                {licenseStatus === LicenseStatusType.Activated && (
                  <Switch>
                    <Route exact path={ROUTES.home} render={() => <Dashboard />} />
                    <Route exact path={ROUTES.companies} render={() => <GroupsOverview />} />
                    <Route exact path={ROUTES.manageGroups} render={() => <Tagmanagement />} />
                    <Route exact path={ROUTES.newCompany} render={() => <CompanyMasterDataEdit />} />
                    <Route exact path={ROUTES.projectImport} render={() => <ProjectImport />} />
                    <Route exact path={ROUTES.database} render={() => <Database />} />
                    <Route exact path={ROUTES.license} render={() => <License />} />
                    <Route exact path={ROUTES.profile} render={() => <Profile />} />
                    {/* <Route exact path={ROUTES.companyBuilding} render={() => <BuildingPage />} />
                    <Route exact path={ROUTES.newcompanyBuilding} render={() => <BuildingProfile />} /> */}
                    <Route exact path={ROUTES.auditor} render={() => <AuditorMasterData />} />
                    <Route exact path={ROUTES.editAuditor} render={() => <AuditorProfile />} />
                    <Route exact path={ROUTES.trashCan} render={() => <TrashCan />} />
                    <Route exact path={ROUTES.dataImport} render={() => <DataImport />} />
                    <Route exact path={ROUTES.dataExport} render={() => <DataExport />} />
                    <Route exact path={ROUTES.companyAction} render={() => <CompanyPage />} />
                    <Route exact path={ROUTES.newProject} render={() => <ProjectMaster />} />
                    <Route exact path={ROUTES.leakage} render={() => <LeakagePage />} />
                    <Route exact path={ROUTES.project} render={() => <ProjectPage />} />
                    <Route exact path={ROUTES.exportMaintenanceBase} render={() => <ExportMaintenance />} />
                    <Route exact path={ROUTES.updates} render={() => <UpdateOverview />} />
                    <Route path={ROUTES.exportMaintenance} render={() => <ExportMaintenance />} />
                    <Route path={ROUTES.company} render={() => <CompanyPage />} />
                    <Route exact path={ROUTES.learn} render={() => <LearningProgramPage />} />
                    <Route render={() => <Redirect to="/" />} />
                  </Switch>
                )}
                {licenseStatus === LicenseStatusType.Deactivated && <License showTestVersionExpired />}
              </div>
            </PageContent>
          </FollowDrawer>
        </LicenseStatusContext.Provider>
      </div>
    </ApolloProvider>
  )
}

export default App
