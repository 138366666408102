export const repositoryNames = {
  ADDRESS: 'address',
  BUILDING: 'building',
  COMPANY: 'company',
  CONTACT: 'contact',
  LEAKAGE: 'leakage',
  PROJECT: 'project',
  USER: 'user',
  MEASURE: 'measure',
  FAILURE: 'failure',
  REPLACEMENT: 'replacement',
  UPDATE_INFO: 'updateinfo',
  LICENSE_INFO: 'licenseinfo',
  APP_DATA: 'appdata',
}

export const IMAGES_DIRECTORY = 'images'
