import logo from '../../../../assets/images/cs_partial.svg'
import React from 'react'
import { backgroundImage } from './BackgroundImageStyle'

export const BackgroundImage: React.FC = React.memo(() => {
  return (
    <div style={backgroundImage}>
      <img src={logo} alt={'logo'} width={'100%'} />
    </div>
  )
})
BackgroundImage.displayName = 'BackgroundImage'
