import React, { useEffect, useMemo, useState } from 'react'
import { DisplayCompany, DisplayGroup } from '../../../../interfaces/BusinessObjects'
import { SelectInput } from '../../partials/Inputs/Select'
import { useCompaniesInGroup } from '../../../hooks/companyHooks'
import { DataImportTarget } from '../../../../interfaces/DataImport'
import { useTranslation } from 'react-i18next'
import { usePermissions } from 'app/hooks/usePermissions'
import { GroupExplorer, NestedGroups } from 'app/components/partials/GroupsExplorer/GroupExplorer'
import { Grid } from '@material-ui/core'
import { collectSubGroupIds, convertToFileHierarchy } from 'app/helpers/convertToFileHierarchy'
import { CsButtonPrimary } from '@csinstruments/cs-react-theme'
import { CompanyDropDown } from 'app/components/partials/GroupCompanyDropDown/CompanyDropDown'
import { useProjectsOfCompany } from 'app/hooks/projectHooks'

type DataImportTargetSelectionProps = {
  handleSelectionChanged: (selection: DataImportTarget) => void
  companyName?: string
  targetGroupId?: string
  comps: DisplayCompany[]
  canCreateCompany?: boolean
}

export const DataImportTargetSelection: React.FC<DataImportTargetSelectionProps> = ({
  handleSelectionChanged,
  companyName,
  comps,
  targetGroupId,
  canCreateCompany,
}) => {
  // const { groupCompanyData } = useCompaniesInGroup(targetGroupId)
  // const [selGroupId, setSelGroupID] = useState<string>('')
  const [selectedCompany, setSelectedCompany] = useState<DisplayCompany>({})
  const [companyId, setCompanyId] = useState<string>('')
  // const [comps, setCompsList] = useState<DisplayCompany[]>([])
  // const [allCompanies, setAllCompanies] = useState<DisplayCompany[]>([])
  // const [initialGroups, setInitialGroups] = useState<DisplayGroup[]>([])
  const [selectionObject, setSelectionObject] = useState<DataImportTarget>({})
  const { projects } = useProjectsOfCompany(companyId)
  // const [groupsList, companiesList] = groupCompanyData
  // const { groupHierarchy } = convertToFileHierarchy(groupsList)
  const { t } = useTranslation('common')

  const emptyObjectText = t('dataImport.createNew')

  // const permissions = usePermissions()

  // const hasPermission = useMemo(() => {
  //   return permissions.addCompanies
  // }, [permissions])

  // useEffect(() => {
  //   if (!initialGroups.length && groupHierarchy.length) {
  //     setInitialGroups(groupHierarchy)
  //   }
  // }, [groupHierarchy])
  // useEffect(() => {
  //   if (!selGroupId && companiesList.length) {
  //     setAllCompanies(companiesList)
  //     setCompsList(companiesList)
  //   }
  // }, [selGroupId, companiesList])
  // useEffect(() => {
  //   if (selGroupId && allCompanies) {
  //     const subGroupIds: string[] = []
  //     const ids = collectSubGroupIds(groupHierarchy, subGroupIds, selGroupId)
  //     //console.log({ ids }, { allCompanies })
  //     const filteredCompaniesByGroup = allCompanies.filter((c) => c?.groupId && ids.includes(c.groupId))
  //     setCompsList(filteredCompaniesByGroup)
  //   }
  // }, [selGroupId])

  const existsDuplicate = useMemo(() => {
    const foundCompanies = comps.filter((c) => c.name?.toLowerCase() === companyName?.toLowerCase())
    return foundCompanies.length > 1
  }, [comps, companyName])

  useEffect(() => {
    if (companyName && comps.length) {
      const dispComp = comps.filter((c) => c.name?.toLowerCase() === companyName?.toLowerCase())

      dispComp.length === 1 ? setCompanyId(dispComp[0]._id as string) : setCompanyId('')
    }
  }, [companyName, comps])

  useEffect(() => {
    if (projects.length) {
      setSelectedCompany((prev) => ({
        ...prev,
        projects: projects,
      }))
    }
  }, [projects])

  const updateSelectionObject = (update: DataImportTarget): void => {
    //console.log({ update })
    setSelectionObject({
      companyName: Object.keys(update).includes('companyName') ? update.companyName : selectionObject.companyName,
      projectId: Object.keys(update).includes('projectId') ? update.projectId : selectionObject.projectId,
      groupId: Object.keys(update).includes('groupId') ? update.groupId : selectionObject.groupId,
    })
    if (update.companyName !== undefined) {
      const company = comps.filter((c) => c.name === update.companyName).reduce((prev, curr) => curr, {})
      setSelectedCompany(company)
    }
    if (update.groupId) {
      //setSelectedGroup({ id: update.groupId })
      setSelectedCompany({})
    }
  }

  useEffect(() => {
    handleSelectionChanged(selectionObject)
  }, [selectionObject, handleSelectionChanged])

  // const [availableCompanyNames, emptyCompanyAllowed, preselectionName] = useMemo(() => {
  //   const names = comps?.map((c) => c.name ?? '')
  //   if (!companyName) {
  //     return [names, true, '']
  //   }

  //   const found = names.findIndex((v) => v === companyName)
  //   return [names, hasPermission, hasPermission && found !== -1 ? names[found] : '']
  // }, [comps, companyName, hasPermission])

  // useEffect(() => {
  //   if (preselectionName && (!selectedCompany || !selectedCompany.name)) {
  //     setSelectedCompany((oldCompany) => comps.find((c) => c.name === preselectionName) ?? oldCompany)
  //   }
  // }, [preselectionName, comps, selectedCompany])

  //console.log({ companies, preselectionName, selectedCompany, companyName })

  // const handlegroupSelect = (id: string) => {
  //   setSelGroupID(id)
  //   updateSelectionObject({ groupId: id })selectedId
  // }
  const handleCompanySelect = (id: string) => {
    setCompanyId(id)
    const selDispComp = comps.find((c) => c._id === id)
    updateSelectionObject({
      ...selectionObject,
      groupId: selDispComp?.groupId,
      companyName: id,
    })
    setSelectedCompany({ ...selDispComp })
    //console.log({ selDispComp })
  }

  return (
    <>
      <Grid item container xs={5} justifyContent="center" alignContent="center" alignItems="flex-start" spacing={1}>
        {/* <SelectInput
          label={'objects.company'}
          xs={12}
          hasEmpty={emptyCompanyAllowed}
          textForEmpty={emptyObjectText}
          preselect={preselectionName}
          text={availableCompanyNames}
          changeHandler={(companyName) => updateSelectionObject({ companyName })}
        /> */}
        <CompanyDropDown
          companies={comps}
          selectedCompanyID={companyId}
          variant="standard"
          xs={12}
          showNewCompanyMenu={canCreateCompany}
          selectCompany={handleCompanySelect}
          helperText={existsDuplicate ? t('objects.duplicateCompanyName') : undefined}
        />
        <Grid item xs={12} />
        <SelectInput
          keys={selectedCompany.projects?.map((p) => p._id ?? '') || []}
          textForEmpty={emptyObjectText}
          changeHandler={(projectId) => updateSelectionObject({ projectId })}
          preselect={''}
          text={selectedCompany.projects?.map((p) => p.name?.replace(':', '.') ?? '') || []}
          label={'objects.project'}
          xs={12}
        />
      </Grid>
    </>
  )
}
