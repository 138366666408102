import React, { useEffect, useState } from 'react'
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined'
import { Box, Grid, Typography } from '@material-ui/core'
import { FileSelectionResult, SingleFileUploadButton } from '../partials/Buttons/SingleFileUploadButton'
import { useImportProject } from '../../hooks/databaseHooks'
import { ResultAlert } from '../partials/ResultAlert'
import { ActionAlertProps } from '../partials/Alert/Alert'
import { paddingBottomActionBar, paddingTop, standardContentPadding } from '../../../styles/Shared'
import {
  buttonContainer,
  descriptionContainer,
} from '@csinstruments/cs-react-theme/dist/components/ActionWithDescription/ActionWithDescription.styles'
import { PageHeading } from '../partials/PageHeading'
import { useTranslation } from 'react-i18next'

export const ProjectImport: React.FC = () => {
  const { t } = useTranslation('common')
  const [importData, setImportData] = useState<FileSelectionResult | undefined>()
  const { importDone, importError } = useImportProject(importData)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertProps, setAlertProps] = useState<ActionAlertProps>({ label: '', severity: 'success' })

  const handleImportData = (result: FileSelectionResult): void => {
    if (result.error) {
      console.error('file not valid')
    } else {
      setImportData(result)
    }
  }

  useEffect(() => {
    if (importDone) {
      if (importError) {
        setAlertProps({ label: 'databaseImport.projectFail', severity: 'error' })
      } else {
        setAlertProps({ label: 'databaseImport.projectSuccess', severity: 'success' })
      }
      setImportData(undefined)
      setShowAlert(true)
    }
  }, [importDone, importError])

  return (
    <div style={{ ...paddingTop, ...paddingBottomActionBar }}>
      <ResultAlert
        alertText={alertProps.label}
        severity={alertProps.severity}
        showAlert={showAlert}
        modifyShowAlert={setShowAlert}
      />
      <div style={standardContentPadding}>
        <Grid container>
          <Grid item xs={6} lg={4}>
            <Box pr={4}>
              <PageHeading title={'actions.projectImport'} />
              <div style={descriptionContainer}>
                <Typography variant="body1">{t('databaseImport.description')}</Typography>
              </div>
              <div style={buttonContainer}>
                <SingleFileUploadButton
                  buttonProps={{ solid: true }}
                  label={'actions.doProjectImport'}
                  icon={CloudUploadIcon}
                  acceptedType={'application/zip'}
                  changeHandler={(result) => handleImportData(result)}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default ProjectImport
