import React, { FC, ReactElement, useEffect, useState } from 'react'
import { Box, Grid, MenuItem, Select } from '@material-ui/core'
import Typography from '@material-ui/core/Typography/Typography'
import {
  buttonContainer,
  descriptionContainer,
} from '@csinstruments/cs-react-theme/dist/components/ActionWithDescription/ActionWithDescription.styles'
import { PageHeading } from '../../partials/PageHeading'
import { useTranslation } from 'react-i18next'
import * as path from 'path'

type DataImportJournalSelectionProps = {
  handleSelectJournal: (n: number) => void
  selectedJournal: string
  journalNames: string[]
}

export const DataImportJournalSelection: FC<DataImportJournalSelectionProps> = ({
  handleSelectJournal,
  selectedJournal,
  journalNames,
}): ReactElement => {
  const { t } = useTranslation('common')
  const [currentValue, setCurrentValue] = useState(0)

  useEffect(() => {
    setCurrentValue(journalNames.indexOf(selectedJournal))
  }, [selectedJournal, journalNames])

  return (
    <>
      <Grid container>
        <Grid item xs={6} lg={4}>
          <Box pr={4}>
            <PageHeading title={'dataImport.chooseXML'} />
            <div style={descriptionContainer}>
              <Typography variant="body1">{t('dataImport.folderDescription')}</Typography>
            </div>
            <div style={buttonContainer}>
              <Select
                fullWidth={true}
                variant={'outlined'}
                onChange={(e) => {
                  handleSelectJournal(e.target.value as number)
                  setCurrentValue(e.target.value as number)
                }}
                value={currentValue}
              >
                {journalNames.map((name, i) => {
                  return (
                    <MenuItem key={name + i} value={i}>
                      {path.basename(name)}
                    </MenuItem>
                  )
                })}
              </Select>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
