import Keycloak from 'keycloak-js'
import { environment } from '../../constants/environment'

//keycloak init options
export const KEYCLOAK_INIT_OPTIONS: Keycloak.KeycloakConfig = {
  url: environment.REACT_APP_LOGIN_URL,
  realm: environment.REACT_APP_LOGIN_REALM,
  clientId: 'login',
}

export const keycloak = new Keycloak(KEYCLOAK_INIT_OPTIONS)

const updateToken = (): void => {
  keycloak
    .updateToken(300)
    .then((success) => {
      if (!success) {
        console.error('could not update token')
        return
      }
      //setTokens(keycloak.token ?? '', keycloak.idToken ?? '', keycloak.refreshToken ?? '')
    })
    .catch((e) => {
      console.error('problem while refreshing token')
      console.error(e)
    })
}

export const eventLogger = (event: unknown): void => {
  if (event === 'onAuthSuccess') {
    if (keycloak.isTokenExpired(5)) {
      updateToken()
    }
  }
  if (event === 'onReady') {
  }
  if (event === 'onTokenExpired') {
    updateToken()
  }
}

export const isLoggedIn = (): boolean => {
  return !!localStorage.getItem('keycloak-token')
}

export const getTokens = (): [string | null, string | null] => {
  return [localStorage.getItem('keycloak-token'), localStorage.getItem('keycloak-refresh-token')]
}

export const setTokens = (token: string, idToken: string, refreshToken: string): void => {
  localStorage.setItem('keycloak-token', token)
  localStorage.setItem('keycloak-refresh-token', refreshToken)
  localStorage.setItem('keycloak-id-token', idToken)
}

export const keycloakLogout = async (): Promise<void> => {
  localStorage.removeItem('keycloak-token')
  localStorage.removeItem('keycloak-refresh-token')
  localStorage.removeItem('keycloak-id-token')
  return keycloak.logout()
}
