import { UpdateInfo } from '../../interfaces/BusinessObjects'
import { useMemo } from 'react'
import { useGetUpdateInfoQuery } from 'api/models'
import { getSelectedRealm } from 'app/helpers/manageRealms'

export const useUpdateInfo = (): UpdateInfo[] => {
  const hwInfo = useGetUpdateInfoQuery({
    variables: {
      realmName: getSelectedRealm(),
    },
    skip: !getSelectedRealm(),
  })

  return useMemo(() => {
    if (!hwInfo.data?.getLDVersion) {
      return []
    } else {
      return hwInfo.data.getLDVersion
        .filter((s) => s?.sn)
        .map((ld) => ({
          id: ld?.id ?? '',
          sn: ld?.sn ?? '',
          major: String(ld?.sw).split('.')[0] ?? '',
          minor: String(ld?.sw).split('.')[1] ?? 0,
        })) as unknown as UpdateInfo[]
    }
  }, [hwInfo.data?.getLDVersion])
}
