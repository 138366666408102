import React, { FC, ReactElement } from 'react'
import { useCompanyExport } from '../../../hooks/databaseHooks'
import { usePushDownloadFile } from '../../../hooks/usePushDownloadFile'
import { CsButton } from '@csinstruments/cs-react-theme'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { CloudDownload } from '@material-ui/icons'

type Props = {
  companyIDs: string[]
  measureIDs: string[]
  failureIDs: string[]
  replacementIDs: string[]
  exportBuildings: boolean
  exportLocations: boolean
}

export const DataExportCompletion: FC<Props> = (props): ReactElement => {
  const { t } = useTranslation('common')
  const { exportContent, exportDone, exportIncomplete, exportError } = useCompanyExport(props)
  const { downloadAction } = usePushDownloadFile(exportContent, exportDone, 'customer-data.zip')

  const text = exportError
    ? t('dataExport.failed')
    : exportIncomplete
    ? t('dataExport.incomplete')
    : t('dataExport.success')

  let explanation = ''
  if (!exportError) {
    explanation = t('dataExport.explanation')
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Alert severity={exportError ? 'error' : 'success'}>{text}</Alert>
          <div style={{ whiteSpace: 'pre-wrap' }}>{explanation}</div>
        </Grid>
        <Grid item xs={2} md={8} />
        <Grid item xs={10} md={4}>
          <CsButton fullwidth solid onClick={downloadAction} label={t('actions.download')}>
            <CloudDownload />
          </CsButton>
        </Grid>
      </Grid>
    </>
  )
}
