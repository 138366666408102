import { FixedActionBar } from '@csinstruments/cs-react-theme'
import { Grid } from '@material-ui/core'
import { AuditorInput } from 'api/models'
import { ImageUpload } from 'app/components/partials/ImageUpload/ImageUpload'
import { PageHeading } from 'app/components/partials/PageHeading'
import { ResultAlert } from 'app/components/partials/ResultAlert'
import { useCreateUpdateAuditor, useGetAuditor } from 'app/hooks/projectHooks'
import React, { useEffect, useState } from 'react'
import { Text } from '../../partials/Inputs/Text'
import { useHistory, useParams } from 'react-router-dom'
import SaveIcon from '@material-ui/icons/SaveOutlined'
import { paddingTop, paddingBottomActionBar, standardContentPadding } from 'styles/Shared'
import { ROUTES } from 'app/helpers/routes'
import { usePermissions } from 'app/hooks/usePermissions'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined'
import { useGetUserProfileLazyQuery } from 'api/id.models'
import { ProgressBar } from 'app/components/partials/ProgressBar/ProgressBar'

export const AuditorProfile: React.FC = () => {
  const auditorInit: AuditorInput = {
    display_name: '',
    company: '',
  }
  const history = useHistory()
  const [auditorDetails, setAuditorDetails] = useState<AuditorInput>(auditorInit)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const [copyDisable, setDisableCopy] = useState(false)
  const { id } = useParams<{ id: string }>()
  const [showNewAuditorBanner, setShowNewAuditorBanner] = useState<boolean>(id === 'new')
  const { auditorInfo } = useGetAuditor(id !== 'new' ? id : '')
  const { confirmCreate, success, error } = useCreateUpdateAuditor()
  const permissions = usePermissions()
  const [getUserInfo, userData] = useGetUserProfileLazyQuery()

  //console.log('error', error)
  useEffect(() => {
    if (id !== 'new' && auditorInfo) {
      const { display_name, first_name, last_name, zip, image, number, city, role, email, phone, street, company } =
        auditorInfo
      setAuditorDetails({
        id,
        display_name,
        first_name,
        last_name,
        zip,
        image,
        company,
        number,
        city,
        role,
        email,
        phone,
        street,
      })
    }
  }, [auditorInfo, id])

  useEffect(() => {
    if (success) {
      history.push(ROUTES.auditor)
    }
    if (error) {
      setShowError(error)
    }
  }, [success, error, history])

  useEffect(() => {
    if (userData.data?.getUserProfile) {
      const { FirstName, LastName, Zip, Phone, City, Email, Street, Image, Mobile, CompanyName } =
        userData.data.getUserProfile
      setAuditorDetails({
        first_name: FirstName,
        display_name: Email,
        company: CompanyName,
        city: City,
        street: Street,
        image: Image?.Content,
        last_name: LastName,
        zip: Zip,
        email: Email,
        phone: !Mobile ? Mobile : !Phone ? Phone : '',
      })
      setDisableCopy(true)
      //console.log('eror', userData.error?.message)
    }
  }, [userData.data, userData.error?.message])

  const updateAuditorData = (data: AuditorInput): void => {
    setAuditorDetails(data)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const updateAction = () => {
    confirmCreate(auditorDetails)
  }
  const fillUserInfo = (): void => {
    getUserInfo()
  }

  const toAuditorOverview = (): void => {
    history.push(ROUTES.auditor)
  }

  return (
    <>
      <div style={{ ...paddingTop, ...paddingBottomActionBar }}>
        <ProgressBar show={userData.loading} />
        <FixedActionBar
          buttonProps={[{}, { solid: true }, { solid: true }]}
          actions={[fillUserInfo, toAuditorOverview, updateAction]}
          labels={['actions.copyFromCSID', 'actions.back', 'actions.save']}
          disabled={[
            id !== 'new' || !permissions.editProjects || copyDisable,
            false,
            !auditorDetails.display_name || !permissions.editProjects,
          ]}
          conditions={[id === 'new', true, true]}
          icons={[FileCopyIcon, KeyboardBackspaceIcon, SaveIcon]}
        />
        <ResultAlert
          alertText={'profile.newAuditor'}
          showAlert={showNewAuditorBanner}
          severity="info"
          autoHideDuration={10000}
          modifyShowAlert={setShowNewAuditorBanner}
        />
        <ResultAlert alertText={'profile.updateSuccess'} showAlert={showSuccess} modifyShowAlert={setShowSuccess} />
        <ResultAlert
          severity="error"
          autoHideDuration={3000}
          alertText={'profile.updateError'}
          showAlert={showError}
          modifyShowAlert={setShowError}
        />
        <div style={standardContentPadding}>
          <PageHeading title={'objects.profile'} />
          <Grid container spacing={3}>
            <Grid item xs={5} md lg>
              <Grid spacing={3} container>
                {/* {id !== 'new' && (
                  <Text
                    changeHandler={(display_name) => updateAuditorData({ display_name })}
                    value={auditorDetails.display_name ?? ''}
                    required
                    columns={12}
                    variant="outlined"
                    displayOnly={!permissions.editProjects}
                    displayOnly={id !== 'new'}
                    label={'contact.DisplayName'}
                  />
                )} */}
                <Text
                  changeHandler={(email) =>
                    updateAuditorData({
                      ...auditorDetails,
                      email: email,
                      display_name: id === 'new' ? email : auditorDetails.display_name,
                    })
                  }
                  value={auditorDetails.email ?? ''}
                  columns={12}
                  variant="outlined"
                  displayOnly={!permissions.editProjects || id !== 'new'}
                  label={'contact.email'}
                />
                <Text
                  changeHandler={(first_name) => updateAuditorData({ ...auditorDetails, first_name: first_name })}
                  value={auditorDetails.first_name ?? ''}
                  columns={12}
                  variant="outlined"
                  displayOnly={!permissions.editProjects}
                  label={'contact.firstName'}
                />
                <Text
                  changeHandler={(last_name) => updateAuditorData({ ...auditorDetails, last_name: last_name })}
                  value={auditorDetails.last_name ?? ''}
                  columns={12}
                  variant="outlined"
                  displayOnly={!permissions.editProjects}
                  label={'contact.lastName'}
                />
                <Text
                  changeHandler={(company) => updateAuditorData({ ...auditorDetails, company: company })}
                  value={auditorDetails.company ?? ''}
                  columns={12}
                  variant="outlined"
                  displayOnly={!permissions.editProjects}
                  label={'objects.company'}
                />
                <Text
                  changeHandler={(phone) => updateAuditorData({ ...auditorDetails, phone: phone })}
                  value={auditorDetails.phone ?? ''}
                  columns={12}
                  variant="outlined"
                  displayOnly={!permissions.editProjects}
                  label={'contact.phone'}
                />

                <Text
                  changeHandler={(street) => updateAuditorData({ ...auditorDetails, street: street })}
                  value={auditorDetails.street ?? ''}
                  columns={12}
                  variant="outlined"
                  displayOnly={!permissions.editProjects}
                  label={'address.street'}
                />
                <Text
                  changeHandler={(zip) => updateAuditorData({ ...auditorDetails, zip: zip })}
                  value={auditorDetails.zip ?? ''}
                  columns={12}
                  variant="outlined"
                  displayOnly={!permissions.editProjects}
                  label={'address.postCode'}
                />
                <Text
                  changeHandler={(city) => updateAuditorData({ ...auditorDetails, city: city })}
                  value={auditorDetails.city ?? ''}
                  columns={12}
                  variant="outlined"
                  displayOnly={!permissions.editProjects}
                  label={'address.city'}
                />
              </Grid>
            </Grid>
            <Grid item xs={7} lg={6} md={5}>
              <ImageUpload
                disabled={!permissions.editProjects}
                image={auditorDetails.image as string}
                imageChanged={(image) => updateAuditorData({ ...auditorDetails, image: image })}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}
