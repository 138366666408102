export enum UnitCurrency {
  EURO = 'EURO',
  USD = 'USD',
  R = 'R',
  CHF = 'CHF',
  OTHER = 'OTHER',
}

export enum UnitPressure {
  BAR = 'BAR',
  PSI = 'PSI',
}

export enum UnitEnergy {
  KWH_M3 = 'KWH_M3',
  KWH_CF = 'KWH_CF',
  LBS_CF = 'LBS_CF',
}

export enum UnitCo2 {
  KG_KWH = 'KG_KWH',
  LBS_KWH = 'LBS_KWH',
}

export enum UnitCostBase {
  CF = 'CF',
  M3 = 'M3',
  NM3 = 'NM3',
}

export enum UnitVolume {
  NM3 = 'NM3',
  SCF = 'SCF',
  CF = 'CF',
}

export enum UnitPriority {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
  NONE = 'NONE',
}

export enum UnitLeakage {
  LTR_MIN = 'LTR_MIN',
  CFM = 'CFM',
}

export enum UnitSpecPower {
  ISO = 'KWH_M3',
  US = 'KW_CFM',
  KWH = 'KWH',
}

export enum UnitCostTime {
  H = 'H',
}

export enum UnitCalculation {
  PERCENT = 'PERCENT',
  NM3_HOUR = 'NM3_HOUR',
  KW_CFM = 'KW_CFM',
  KW_CFM_1000 = 'KW_CFM_1000',
}

export const isCfUnit = (unit: string): boolean => {
  return unit === Unit.CFM || unit === Unit.CF || unit === Unit.SCF
}

export const isM3Unit = (unit: string): boolean => {
  return unit === Unit.M3 || unit === Unit.NM3
}

export const ifScfMakeCf = (unitV: string | undefined): string | undefined => {
  return unitV?.toLowerCase() === UnitVolume.SCF.toLowerCase() ? UnitVolume.CF : unitV
}

export enum UnitDistance {
  M = 'M',
  INCH = 'INCH',
}

export const Unit = {
  ...UnitDistance,
  ...UnitCurrency,
  ...UnitLeakage,
  ...UnitPriority,
  ...UnitCostBase,
  ...UnitCo2,
  ...UnitEnergy,
  ...UnitPressure,
  ...UnitVolume,
  ...UnitCostTime,
  ...UnitCalculation,
  ...UnitSpecPower,
}

export const unitVToUnitL = (unitV: string | undefined): string => {
  if (unitV && unitV.toLowerCase().includes('cf')) {
    return 'CFM'
  }
  return 'LTR_MIN'
}

export const unitMapping = (): Map<string, string> => {
  const unitMap = new Map<string, string>()
  unitMap.set(Unit.EURO, '€')
  unitMap.set(Unit.KWH, 'kWh')
  unitMap.set(Unit.PERCENT, '%')
  unitMap.set(Unit.H, 'h')
  unitMap.set(Unit.M, 'm')
  unitMap.set(Unit.INCH, 'inch')
  unitMap.set(Unit.LTR_MIN, 'ltr/min')
  unitMap.set(Unit.NM3_HOUR, 'm³/h')
  unitMap.set(Unit.CFM, 'cfm')
  unitMap.set(Unit.NM3, 'm³')
  unitMap.set(Unit.CF, 'CF')
  unitMap.set(Unit.M3, 'm³')
  unitMap.set(Unit.KG_KWH, 'kg / kWh')
  unitMap.set(Unit.LBS_KWH, 'lbs / kWh')
  unitMap.set(Unit.KWH_CF, 'kWh/cf')
  unitMap.set(Unit.LBS_CF, 'lbs/cf')
  unitMap.set(Unit.KWH_M3, 'kWh / m³')
  unitMap.set(Unit.KW_CFM, 'kW / 100 cfm')
  unitMap.set(Unit.KW_CFM_1000, 'kW / 1000 cfm')
  unitMap.set(Unit.R, 'R')
  unitMap.set(Unit.USD, '$')
  unitMap.set(Unit.SCF, 'scf')
  unitMap.set(Unit.BAR, 'bar')
  unitMap.set(Unit.PSI, 'psi')
  unitMap.set(Unit.HIGH, 'priority.high')
  unitMap.set(Unit.LOW, 'priority.low')
  unitMap.set(Unit.MEDIUM, 'priority.medium')
  unitMap.set(Unit.NONE, '')
  unitMap.set(Unit.OTHER, 'currency.other')
  unitMap.set('actions.completed', '✓')
  unitMap.set('actions.inProgress', '✕')
  unitMap.set('actions.yes', '✓')
  unitMap.set('actions.no', '✕')

  Array.from(unitMap).map(([key, value]) => value && unitMap.set(value, key))

  return unitMap
}

export const enumValToDisplayVal = (enumVal?: string): string => {
  //const unit = unitMapping().get(enumVal || '') || ''
  const unit = !enumVal ? '' : unitMapping().get(enumVal) ?? enumVal
  //if (!unit) console.info('Asked for unknown enum val', enumVal)
  return unit
}

export const enumValToExportVal = (enumVal?: string): string => {
  const val = enumValToDisplayVal(enumVal)
  return val.replace('priority.', '')
}
