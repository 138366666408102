import React, { FC, ReactElement, useCallback, useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { PageHeading } from '../../partials/PageHeading'
import {
  buttonContainer,
  descriptionContainer,
} from '@csinstruments/cs-react-theme/dist/components/ActionWithDescription/ActionWithDescription.styles'
import { MultiFileSelectionButton, MultiFileSelectionResult } from '../../partials/Buttons/MultiFileSelectionButton'
import { FileSelectionImage } from '../../../helpers/dataImport/dataImportTypes'
import { ResultAlert } from '../../partials/ResultAlert'
import { useTranslation } from 'react-i18next'

export type DataImportFileSelectionProps = {
  // setDataImportRequest: (request: DataImportRequest) => void
  setSelectionImages: (images: FileSelectionImage[]) => void
  setDataImportInfo: (journalNames: string[], textContents: string[]) => void
  permitted: boolean
}

export const DataImportFolderSelection: FC<DataImportFileSelectionProps> = ({
  setDataImportInfo,
  setSelectionImages,
  permitted,
}): ReactElement => {
  const [dataImportError, setDataImportError] = useState<boolean>(false)
  const { t } = useTranslation('common')

  // const receiveImportResult = useCallback(
  //   (result: DataImportRequest): void => {
  //     if (result.error) {
  //       setDataImportError(true)
  //     } else {
  //       setDataImportError(false)
  //       setDataImportRequest(result)
  //     }
  //   },
  //   [setDataImportRequest],
  // )

  const fileSelectionHandler = useCallback(
    (result: MultiFileSelectionResult, error: boolean): void => {
      if (error) {
        setDataImportError(true)
      } else {
        setDataImportError(false)
        setSelectionImages(result.images)

        setDataImportInfo(result.journalNames, result.textContents)
      }
    },
    [setDataImportInfo, setSelectionImages], // receiveImportResult
  )

  return (
    <Grid container>
      <ResultAlert
        alertText={'dataImport.error'}
        severity={'warning'}
        showAlert={dataImportError}
        modifyShowAlert={setDataImportError}
      />
      <Grid item xs={6} lg={4}>
        <Box pr={4}>
          <PageHeading title={'actions.chooseXml'} />
          <div style={descriptionContainer}>
            <Typography variant="body1">{t('dataImport.folderDescription')}</Typography>
          </div>
          <div style={buttonContainer}>
            <MultiFileSelectionButton
              disabled={!permitted}
              noGrid
              fullwidth
              label={'actions.doProjectImport'}
              resultHandler={fileSelectionHandler}
            />
          </div>
        </Box>
      </Grid>
    </Grid>
  )
}
