import * as Sentry from '@sentry/browser'
import { COMPLEX_RESPONSE, resultCodes } from '../../../electronShared/ipcProtocol'
import { invalidateDbCache } from '../../../electronShared/domain/services/multiDataService'
const { ipcRenderer } =
  typeof window !== 'undefined' && window.require ? window.require('electron') : { ipcRenderer: undefined }

export const handleNoIpcRendererError = (): void => {
  const message =
    "(ipc/fileImport) this is no electron renderer. don't use this function: requestFileImport. files will not be imported"
  console.error(message)
  try {
    Sentry.captureException(new Error('message'), (scope) => {
      scope.setTransactionName('requestFileImport')
      return scope
    })
  } catch (e) {
    console.error('failed to send event to sentry', e)
  }
}

export const setupIpcListener = (responseChannel: string): Promise<boolean> => {
  return new Promise<boolean>((resolve) => {
    // @ts-ignore
    ipcRenderer.on(responseChannel, (event: unknown, arg: unknown) => {
      // @ts-ignore
      ipcRenderer.removeAllListeners(responseChannel)
      if (arg === resultCodes.SUCCESS) {
        invalidateDbCache()
        resolve(true)
      } else {
        resolve(false)
      }
    })
  })
}

export const setupComplexIpcListener = (responseChannel: string): Promise<COMPLEX_RESPONSE> => {
  return new Promise<COMPLEX_RESPONSE>((resolve) => {
    // @ts-ignore
    ipcRenderer.on(responseChannel, (event: unknown, arg: COMPLEX_RESPONSE) => {
      // @ts-ignore
      ipcRenderer.removeAllListeners(responseChannel)
      resolve(arg)
    })
  })
}
