import React, { FC, ReactElement, useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import { Box, Typography } from '@material-ui/core'
import { PageHeading } from '../../partials/PageHeading'
import { descriptionContainer } from '@csinstruments/cs-react-theme/dist/components/ActionWithDescription/ActionWithDescription.styles'
import { paddingTop } from '../../../../styles/Shared'
import { TranslatedCsButton } from '../../partials/Buttons/TranslatedCsButton'
import { useTranslation } from 'react-i18next'
import { useProjectsWithLeakageInfo } from '../../../hooks/projectHooks'
import { generatePath, useHistory } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routes'

type DataImportCompletedProps = {
  additionalDataAction: () => void
  companyName?: string
  project?: string
  projectIsID: boolean
  groupID?: string
}

export const DataImportCompleted: FC<DataImportCompletedProps> = ({
  additionalDataAction,
  companyName,
  project,
  projectIsID,
  groupID,
}): ReactElement => {
  const { t } = useTranslation('common')

  const history = useHistory()

  const goToProject = useCallback(() => {
    if (project && companyName) {
      let route = generatePath(ROUTES.project, { id: companyName, projectId: project, action: 'leakages' })
      route = groupID ? route + `?groupId=${groupID}` : route
      history.push(route)
    }
    if (companyName && !project) {
      let companyRoute = generatePath(ROUTES.companyProjects, { id: companyName })
      companyRoute = groupID ? companyRoute + `?groupId=${groupID}` : companyRoute
      history.push(companyRoute)
    }
  }, [companyName, history, project, projectIsID, groupID])

  return (
    <>
      <Grid item xs={6} lg={4}>
        <Box pr={4}>
          <PageHeading title={'dataImport.success'} />
          <div style={descriptionContainer}>
            <Typography variant="body1">{t('dataImport.actionAfterImportDescription')}</Typography>
          </div>
        </Box>
      </Grid>
      <Grid container direction="column" spacing={3} style={paddingTop}>
        <Grid item xs={4}>
          <TranslatedCsButton
            onClick={additionalDataAction}
            solid={true}
            fullwidth={true}
            label={'dataImport.additionalImport'}
          />
        </Grid>
        <Grid item xs={4}>
          <TranslatedCsButton onClick={goToProject} solid={true} fullwidth={true} label={'dataImport.viewData'} />
        </Grid>
      </Grid>
    </>
  )
}
