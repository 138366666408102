import { getUserById, createUser, updateUserById } from '../repositories/userRepository'
import { User } from '../../../interfaces/BusinessObjects'

export const createOrUpdateUser = async (user: User): Promise<User> => {
  const existing = await getUserById(user._id ?? '')
  if (existing && existing._id) {
    return await updateUserById(existing._id, user)
  } else {
    return await createUser(user)
  }
}

export default {
  getUserById,
  createOrUpdateUser,
}
