// too much effort to write type definitions for datastore more exactly…
import { getDatastorePath } from '../../../../electronRenderer/services/fileService'
import { IMAGES_DIRECTORY } from '../repositoryNames'
import { logToSentry } from '../../../../app/helpers/sentryUtils'

interface Repository {
  find: (o1: any, o2?: any, o3?: any) => any[]
  remove: (o1: any, o2?: any, o3?: any) => any
  findOne: (o1: any, o2?: any, o3?: any) => any
  insert: (o1: any, o2?: any, o3?: any) => any
  update: (o1: any, o2?: any, o3?: any, o4?: any) => any
}

// Makes possible to get a datastore for file system both in renderer and main/workers
// without this distinction: renderer process nedb writes to indexdb of browser.
export const getNedbDatastore = (): any => {
  return typeof window !== 'undefined' && window.require ? window.require('nedb') : require('nedb')
}

export const getFs = (): any => {
  const { remote } =
    typeof window !== 'undefined' && window.require ? window.require('electron') : { remote: undefined }
  if (remote) {
    return remote.require('fs')
  } else {
    return require('fs')
  }
}

export const getClipboard = (): any => {
  const { clipboard } =
    typeof window !== 'undefined' && window.require ? window.require('electron') : { clipboard: undefined }
  return clipboard
}

export const getPath = (): any => {
  const { remote } =
    typeof window !== 'undefined' && window.require ? window.require('electron') : { remote: undefined }
  if (remote) {
    return remote.require('path')
  } else {
    return require('path')
  }
}

const Datastore = getNedbDatastore()
let dataStores: Record<string, any> = []
let imageStorePath = ''

export const getDatastore = (name: string): any => {
  logToSentry({
    message: 'DEBUG_V1_getDatastore',
    type: 'DEBUG_V1_getDatastore',
    value: 'DEBUG_V1_getDatastore',
    data: { dataStores, name },
    isRenderedByElectron: true,
  })
  if (!dataStores[name]) {
    dataStores[name] = new Datastore({
      filename: getDatastorePath(name),
      autoload: true,
    })
  }
  logToSentry({
    message: 'DEBUG_V1_getDatastore_NEW',
    type: 'DEBUG_V1_getDatastore_NEW',
    value: 'DEBUG_V1_getDatastore_NEW',
    data: { dataStores, name, newDataStore: dataStores[name] },
    isRenderedByElectron: true,
  })
  return dataStores[name]
}

export const getImageStorePath = (): string => {
  if (!imageStorePath) {
    imageStorePath = getDatastorePath(IMAGES_DIRECTORY, false)
  }
  return imageStorePath
}

/**
 * NeDB keeps the DB not only in a file, but also in memory. If a separate process writes to the DB, the Datastore
 * needs to be reloaded, else changes will not be reflected.
 *
 * This function is expected to be called only when importing data. Data imports happen in a main/worker thread.
 * The renderer then needs to reload the DB from the file. Solution seems acceptable, as imports are not expected
 * to occur very frequently.
 */
export const invalidateCache = (): void => {
  dataStores = []
}

export const setImageStorePath = (path: string): void => {
  imageStorePath = path
}

export const setDatastore = (name: string, path: string): void => {
  dataStores[name] = new Datastore({
    filename: path,
    autoload: true,
  })
}

export const getObjects = <T>(ds: Repository): Promise<T[]> => {
  logToSentry({
    message: 'DEBUG_V1_getObjects',
    type: 'DEBUG_V1_getObjects',
    value: 'DEBUG_V1_getObjects',
    data: {},
    isRenderedByElectron: true,
  })
  return new Promise((resolve, reject) => {
    ds.find({ _deleted_at: { $exists: false } }, (err: Error | null, doc: T[]) => {
      if (!err) {
        logToSentry({
          message: 'DEBUG_V1_getObjects_OK',
          type: 'DEBUG_V1_getObjects_OK',
          value: 'DEBUG_V1_getObjects_OK',
          data: { doc },
          isRenderedByElectron: true,
        })
        resolve(doc)
      } else {
        logToSentry({
          message: 'DEBUG_V1_getObjects_ERROR',
          type: 'DEBUG_V1_getObjects_ERROR',
          value: 'DEBUG_V1_getObjects_ERROR',
          data: { err },
          isRenderedByElectron: true,
        })
        reject(err)
      }
    })
  })
}

export const deleteObjects = (ds: Repository): Promise<number> => {
  return new Promise((resolve, reject) => {
    ds.remove({}, { multi: true }, (err: Error | null, doc: number) => {
      if (!err) {
        resolve(doc)
      } else {
        reject(err)
      }
    })
  })
}

export const getObjectById = <T>(ds: Repository, id: string): Promise<T> => {
  return new Promise((resolve, reject) => {
    ds.findOne({ _id: id }, (err: Error, doc: T) => {
      if (!err) {
        resolve(doc)
      } else {
        reject(err)
      }
    })
  })
}

export const createObject = <T>(ds: Repository, object: T): Promise<T & { _id: string }> => {
  return new Promise((resolve, reject) => {
    ds.insert(object, (err: Error | null, doc: T & { _id: string }) => {
      if (!err) {
        resolve(doc)
      } else {
        reject(err)
      }
    })
  })
}

export const updateObjectById = <T>(ds: Repository, id: string, object: T): Promise<T & { _id: string }> => {
  return new Promise((resolve, reject) => {
    ds.update({ _id: id }, object, (err: Error | null, doc: T & { _id: string }) => {
      if (!err) {
        resolve(doc)
      } else {
        reject(err)
      }
    })
  })
}

export const finalDeleteObjectById = (ds: Repository, id: string): Promise<number> => {
  return new Promise((resolve, reject) => {
    ds.remove(
      {
        _id: id,
      },
      (err: Error | null, doc: number) => {
        if (!err) {
          resolve(doc)
        } else {
          reject(err)
        }
      },
    )
  })
}

export const finalDeleteTrashObjects = (ds: Repository): Promise<number> => {
  return new Promise((resolve, reject) => {
    ds.remove({ _deleted_at: { $exists: true } }, { multi: true }, (err: Error | null, doc: number) => {
      if (!err) {
        resolve(doc)
      } else {
        reject(err)
      }
    })
  })
}

export const getDeletedObjects = <T>(ds: Repository): Promise<T[]> => {
  return new Promise((resolve, reject) => {
    ds.find({ _deleted_at: { $exists: true } }, (err: Error, doc: T[]) => {
      if (!err) {
        resolve(doc)
      } else {
        reject(err)
      }
    })
  })
}

export const restoreObject = (ds: Repository, id: string): Promise<number> => {
  return new Promise((resolve, reject) => {
    ds.update(
      { _id: id },
      {
        $unset: { _deleted_at: true },
        $set: { _isRestored: true },
      },
      { multi: true },
      (err: Error | null, doc: number) => {
        if (!err) {
          resolve(doc)
        } else {
          reject(err)
        }
      },
    )
  })
}

export const deleteObjectById = (ds: Repository, id: string): Promise<number> => {
  return new Promise((resolve, reject) => {
    ds.update({ _id: id }, { $set: { _deleted_at: new Date() } }, {}, (err: Error | null, doc: number) => {
      if (!err) {
        resolve(doc)
      } else {
        reject(err)
      }
    })
  })
}

export const getObjectByQuery = <T>(ds: Repository, query: unknown): Promise<T> => {
  return new Promise((resolve, reject) => {
    ds.findOne(query, (err: Error | null, doc: T) => {
      if (!err) {
        resolve(doc)
      } else {
        reject(err)
      }
    })
  })
}

export const getObjectsByQuery = <T>(ds: Repository, query: unknown): Promise<T[]> => {
  return new Promise((resolve, reject) => {
    ds.find(query, (err: Error, doc: T[]) => {
      if (!err) {
        resolve(doc)
      } else {
        reject(err)
      }
    })
  })
}
