import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import {
  useCreateDescriptionObject,
  useDeleteLeakageDescription,
  useLeakageDescriptionFields,
  useUpdateLeakageDescription,
} from '../../../hooks/leakageDescriptionHooks'
import { DataTable } from '@csinstruments/cs-react-theme/dist/components/DataTable/DataTable'
import {
  DataItemAction,
  DataTableColumn,
  DataTableRow,
} from '@csinstruments/cs-react-theme/dist/components/DataTable/DataTableTypes'
import { FAILURE, Failure, MEASURE, Measure, REPLACEMENT, Replacement } from '../../../../interfaces/BusinessObjects'
import { ResultAlert } from '../../partials/ResultAlert'
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { paddingTopBottom } from '../../../../styles/Shared'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import AddIcon from '@material-ui/icons/AddOutlined'
import { RenameTextModal } from '../../partials/RenameTextModal/RenameTextModal'
import { transformTextForExport } from 'app/helpers/dataExportHelpers'
import { usePermissions } from 'app/hooks/usePermissions'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'

type ExportMaintenanceTypeViewProps = {
  type: string
}

const columns: DataTableColumn[] = [{ label: 'exportMaintenance.text' }, { label: 'exportMaintenance.exportText' }]

const objectToTableRow = (o: Measure | Replacement | Failure, updatedTexts: Record<string, string>): DataTableRow => {
  let text = o.text
  if (updatedTexts[text]) {
    text = updatedTexts[text]
  }
  const exportedText = transformTextForExport(text)
  return {
    id: o._id,
    values: [text, exportedText],
  }
}

export const ExportMaintenanceTypeView: FC<ExportMaintenanceTypeViewProps> = ({ type }): ReactElement => {
  const permissions = usePermissions()
  const { fields, refresh } = useLeakageDescriptionFields()
  const { deleted, deleteObject } = useDeleteLeakageDescription()
  const [showAlert, setShowAlert] = useState(false)
  const [enteredText, setEnteredText] = useState('')
  const { created, createObject } = useCreateDescriptionObject()
  const { t } = useTranslation('common')
  const [modalOpen, setModalOpen] = useState(false)
  const [textToEdit, setTextToEdit] = useState('')
  const [textToSearch, setTextToSearch] = useState('')
  const [textExistError, setTextExistsError] = useState<boolean>(false)
  const [updatedTexts, setUpdatedTexts] = useState<Record<string, string>>({})
  const { updated, updateObject } = useUpdateLeakageDescription(type)

  const permission = useMemo(() => {
    return permissions.documentationRepair
  }, [permissions])

  const rows = useMemo(() => {
    switch (type) {
      case MEASURE:
        return fields.measures.filter((f) => f.text).map((f) => objectToTableRow(f, updatedTexts))
      case REPLACEMENT:
        return fields.replacements.filter((f) => f.text).map((f) => objectToTableRow(f, updatedTexts))
      case FAILURE:
        return fields.failures.filter((f) => f.text).map((f) => objectToTableRow(f, updatedTexts))
      default:
        return []
    }
  }, [fields, type, updatedTexts])

  const tableButtonClicked = useCallback(
    (id: string, action: DataItemAction) => {
      if (action === 'delete') {
        deleteObject(id)
      } else {
        const row = rows.find((r) => r.id === id)
        if (row) {
          setTextToEdit(row.values[0])
          setModalOpen(true)
        }
      }
    },
    [deleteObject, rows],
  )

  const addClicked = useCallback(() => {
    if (rows.some((r) => r.values[0].toLocaleLowerCase() === enteredText.toLocaleLowerCase())) {
      setTextExistsError(true)
      setEnteredText('')
      return
    }
    createObject(enteredText, type)
    setEnteredText('')
  }, [rows, createObject, enteredText, type])

  useEffect(() => {
    setTextToSearch('')
    if (deleted) {
      refresh()
      setShowAlert(true)
    }
    if (updated) {
      refresh()
      setShowAlert(true)
    }
  }, [deleted, refresh, updated])

  useEffect(() => {
    if (created) {
      refresh()
      setShowAlert(true)
    }
  }, [created, refresh])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      addClicked()
    }
  }

  const handleRename = async (oldText: string, newText: string): Promise<void> => {
    if (rows.some((r) => r.values[0].toLocaleLowerCase() === newText.toLocaleLowerCase())) {
      return
    }

    setUpdatedTexts((ut) => {
      return {
        ...ut,
        [oldText]: newText,
      }
    })

    const r = rows.find((r) => r.values[0] === oldText)
    if (r) {
      await updateObject(r.id, newText)
    }
  }

  return (
    <div style={{ ...paddingTopBottom }}>
      <RenameTextModal
        handleConfirm={handleRename}
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        text={textToEdit}
      />
      <ResultAlert
        alertText={'actions.addLeakageDescriptionError'}
        modifyShowAlert={setTextExistsError}
        showAlert={textExistError}
        severity="warning"
      />
      <ResultAlert alertText={'actions.updateSuccess'} modifyShowAlert={setShowAlert} showAlert={showAlert} />
      <Grid container justifyContent="flex-start" spacing={3}>
        <Grid item xs={12}>
          <Grid spacing={3} container direction="column" justifyContent="flex-start">
            <Grid item container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="start"
                        onClick={() => {
                          permission && addClicked
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <AddIcon />
                      </InputAdornment>
                    ),
                  }}
                  disabled={!permission}
                  onKeyDown={handleKeyDown}
                  onChange={(event) => setEnteredText(event.target.value)}
                  value={enteredText}
                  variant="outlined"
                  fullWidth
                  id="standard-basic"
                  label={t('actions.add')}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  onKeyDown={handleKeyDown}
                  onChange={(event) => setTextToSearch(event.target.value)}
                  value={textToSearch}
                  variant="outlined"
                  fullWidth
                  id="standard-basic"
                  label={t('actions.search')}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" display="inline">
                {t('exportMaintenance.explanation')}
              </Typography>
              <DataTable
                columns={columns}
                rows={rows}
                filterColumn={1}
                filterString={textToSearch}
                onButtonClicked={tableButtonClicked}
                allowedActions={{
                  sortable: true,
                  deletable: permission,
                  editable: permission,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
