import { DataImportRequest } from '../helpers/dataImport/dataImportService'
import { useState } from 'react'
import { ImportCompaniesMutationVariables, ImportCompany, Role, useImportCompaniesMutation } from '../../api/models'
import { importProjectToServerImportProject } from './hookHelpers/serverResponseConversion'
import { getSelectedRealm } from 'app/helpers/manageRealms'
import { UpdateNewPath } from 'interfaces/DataImport'

export const useDataImport = (
  redirectPath: UpdateNewPath,
): {
  importData: (request: DataImportRequest) => Promise<void>
  importDone: boolean
  importProgress?: boolean

  error: boolean
} => {
  const [importCompaniesMut, { loading }] = useImportCompaniesMutation()
  const [imported, setImported] = useState(false)
  const [error, setError] = useState(false)
  //const [progessNumber, setProgressNumber] = useState(0)

  const importData = async (request: DataImportRequest): Promise<void> => {
    console.log(request.target)
    setImported(false)

    console.log({ request })
    const importCompanies: ImportCompany[] = request.companies.map((c) => {
      const projects = c.projects.map((p) => importProjectToServerImportProject(p, request.uids, c.buildings))
      return {
        company: c.name ?? '',
        address: c.address,
        contact: { ...c.contact, role: Role.Customer },
        buildings: c.buildings.map((b) => {
          return { name: b.name }
        }),
        projects: projects,
      }
    })

    const variables: ImportCompaniesMutationVariables = {
      companies: importCompanies,
      images:
        request.images?.map((i) => {
          return { name: i.name, contents: i.contents }
        }) ?? [],
      targetCompanyName: request.target?.companyName ?? null,
      targetProjectId: request.target?.projectId ?? null,
      groupId: request.target?.groupId ?? undefined,
      realmName: getSelectedRealm() || '',
    }
    try {
      const resp = await importCompaniesMut({ variables: variables })

      if (resp.data?.importCompanies.length) {
        setImported(true)
        const importComp = resp.data?.importCompanies[0]
        const importCompProj = importComp.projects?.projects
        const projId: string | undefined = importCompProj?.length === 1 ? (importCompProj[0].id as string) : undefined

        redirectPath({ groupId: importComp.groupId ?? undefined, companyId: importComp.id, projectId: projId })
      } else {
        setError(true)
      }
    } catch (e) {
      console.error(e)
      console.error('there was an error')
      setError(true)
    }
  }
  //console.log({ progessNumber })

  return { importData, importDone: imported, error, importProgress: loading }
}
