import React from 'react'

import { useTranslation } from 'react-i18next/'

import { Grid, Typography } from '@material-ui/core'
import { environment } from 'constants/environment'
import { useKeycloak } from 'app/hooks/useKeycloak'
import { clearStorage } from 'app/helpers/manageRealms'
import { ModalForm } from './ModalForm'

const AccessDenied: React.FC = () => {
  const { keycloak } = useKeycloak()
  const { t } = useTranslation('common')

  const clickCancel = (): void => {
    keycloak?.logout()
    clearStorage()
  }

  const clickActivateLicense = (): void => {
    window.open(`${environment.USER_PROFILE_URL}/activatelicense`, '_self')
  }

  return (
    <>
      <ModalForm
        formOpen={true}
        formClose={clickCancel}
        headingText={t('cloudMessages.accessError')}
        submitForm={clickActivateLicense}
        headingType="error"
      >
        <Grid item container justifyContent="flex-start" alignContent="stretch" spacing={2} direction="column">
          <Grid item xs md lg>
            <Typography variant="subtitle1" align="center" gutterBottom>
              {t('cloudMessages.accessErrorMessage')}
            </Typography>
          </Grid>
          <Grid item xs md lg>
            <Typography variant="subtitle1" align="center" gutterBottom>
              {t('cloudMessages.accessErrorMessage2')}
            </Typography>
          </Grid>
        </Grid>
      </ModalForm>
    </>
  )
}

export default AccessDenied
