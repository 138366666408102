import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { enumValToDisplayVal } from '../../../../interfaces/Units'

export type SelectText = {
  textValue: string
  disabled: boolean
}

export const isStringArray = (obj: string[] | SelectText[]): boolean => {
  return !!obj.some(isString)
}

export const isString = (obj: string | SelectText): boolean => {
  return typeof obj === typeof String
}

export type SelectProps = {
  label: string
  text?: string[]
  keys?: string[]
  xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  preselect?: string
  hasEmpty?: boolean
  changeHandler?: (selected: string) => void
  textForEmpty?: string
  disabled?: boolean
  isUnitSelect?: boolean
  variant?: 'filled' | 'outlined' | 'standard' | undefined
  disableTranslation?: boolean
  textOptions?: SelectText[]
}

export const SelectInput: React.FC<SelectProps> = ({
  text,
  preselect,
  hasEmpty,
  changeHandler,
  disabled,
  textForEmpty,
  xs,
  md,
  keys,
  variant,
  isUnitSelect,
  label,
  disableTranslation,
  textOptions,
}) => {
  const { t } = useTranslation('common')
  const [selected, setSelected] = useState<string>('')

  const getValue = useCallback((): string => {
    if (!text || disabled || !text.includes(selected)) {
      return preselect ?? ''
    }
    return selected
  }, [disabled, preselect, selected, text])

  useEffect(() => {
    setSelected(preselect ?? '')
  }, [preselect])

  const handleChange = useCallback(
    (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>): void => {
      const newSelected = e.target.value as string
      setSelected(newSelected)
      if (changeHandler) {
        let sentValue = newSelected
        if (keys && text) {
          sentValue = text.reduce((prev, current, i) => {
            return current === newSelected ? keys[i] : prev
          }, '')
        }
        if (keys && textOptions) {
          sentValue = textOptions.reduce((prev, current, i) => {
            return current.textValue === newSelected ? keys[i] : prev
          }, '')
        }
        changeHandler(sentValue)
      }
    },
    [changeHandler, keys, text, textOptions],
  )

  const emptyText = useMemo(() => textForEmpty ?? '\u00A0', [textForEmpty])
  return (
    <>
      <Grid item xs={xs ? xs : 6} md={md ? md : xs ? xs : 6}>
        <InputLabel id={label}>{t(label)}</InputLabel>
        <Select
          disabled={!!disabled}
          fullWidth={true}
          labelId={label}
          value={getValue()}
          displayEmpty={true}
          variant={variant}
          renderValue={
            !isUnitSelect
              ? undefined
              : (value: unknown) => (value ? t(enumValToDisplayVal(value as string)) : emptyText ?? '')
          }
          id={`select-${label}`}
          onChange={handleChange}
        >
          {(hasEmpty === undefined || hasEmpty) && <MenuItem value={''}>{emptyText}</MenuItem>}
          {text &&
            text.map((txt, i) => {
              return (
                <MenuItem key={keys ? keys[i] + i : txt} value={txt}>
                  {isUnitSelect ? t(enumValToDisplayVal(txt)) : disableTranslation ? txt : t(txt)}
                </MenuItem>
              )
            })}
          {textOptions &&
            textOptions.map((txt, i) => {
              return (
                <MenuItem key={keys ? keys[i] + i : txt.textValue} value={txt.textValue} disabled={txt.disabled}>
                  {isUnitSelect
                    ? t(enumValToDisplayVal(txt.textValue))
                    : disableTranslation
                    ? txt.textValue
                    : t(txt.textValue)}
                </MenuItem>
              )
            })}
        </Select>
      </Grid>
    </>
  )
}
