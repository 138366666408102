import { getDateFromGermanDate } from '../app/helpers/dates'
import {
  BarPsiFactor,
  KgKwhToLbsKwhFactor,
  KwhM3ToKwhCfFactor,
  LtrMinCfmFactor,
  LtrMinCubicMeterHourFactor,
  MeterInchFactor,
} from '../constants/calculationConstants'
import { enumValToExportVal, isCfUnit, UnitLeakage } from './Units'
import { leakageUnit, mixedUnitsAccumulator, UnitValuePair } from '../app/components/pages/ProjectPage/calculations'
import { ProjectAuditor } from 'app/hooks/projectHooks'

export type Company = {
  groupId?: string
  name?: string
  logo?: string
  path?: string[]
  contact?: Contact
  address?: Address
  _deleted_at?: Date
  _id?: string
  _isRestored?: boolean
}

export type Link = {
  url?: string
  mimeType?: string
  filename?: string
  description?: string
  extension: string
}

export type DisplayCompany = Company & {
  projects?: Project[]
  nBuildings?: number
  nProjects?: number
  buildings?: Building[]
  canEdit?: boolean
  canDelete?: boolean
}

export type DisplayGroup = {
  id: string
  groupName?: string
  parentGroupId?: string
  canEdit?: boolean
  canDelete?: boolean
  canCreateCompany?: boolean
  companiesCount?: number
  companies?: DisplayCompany[]
  subGroups?: DisplayGroup[]
  allSubGroupIds?: string[]
}

export type UpdateInfo = { sn: string; major: number; minor: number; _id?: string }

export type ProjectWithLeakages = Project & {
  leakages?: Leakage[]
}

export type ProjectWithLeakageInfo = Project & {
  nLeakages?: number
  sumLeakages?: string
  firstDateTime?: string
  lastDateTime?: string
  resUnitL?: string
}

export type User = Contact &
  Address & {
    company?: string
    printFooter?: boolean
    image?: string
    _id?: string
    language?: string
    realm?: string
  }

export type Address = {
  street?: string
  number?: string
  zip?: string
  city?: string
  _id?: string
}

export type AppData = {
  _id?: string
  lastExport?: Date
}

export type Contact = {
  prename?: string
  surname?: string
  phone?: string
  email?: string
  role?: string
  _id?: string
}

export type Project = {
  groupId?: string
  path?: string[]
  totalLeakage?: number
  name?: string
  description?: string
  costBase?: string
  costTime?: string
  costUnit?: string
  otherCostUnit?: string
  co2Amount?: string
  co2Unit?: string
  unitV?: string
  energyAmount?: string
  energyUnit?: string
  energyCost?: string
  company?: string
  created?: Date
  _deleted_at?: Date
  _id?: string
  image?: string
  canEdit?: boolean
  elPr?: number
  spPw?: number
  uSpPw?: number
  spPwId?: string
  cMod?: string
  sigE3?: string
  Auditor?: ProjectAuditor
  costCalcBase?: string
}

export type Building = {
  name?: string
  company?: string
  id?: string
  _id?: string
  _deleted_at?: Date
  assigned?: boolean
  places?: Place[]
  groupId?: string
  path?: string[]
}

export type Image = {
  src: string
  checked: boolean
  _originalImage: boolean
  loadedFromFilename?: string
}

export type Measure = {
  _id: string
  text: string
}

export type Replacement = {
  _id: string
  text: string
}

export type Failure = {
  _id: string
  text: string
}

export type LeakageDescriptionFields = {
  measures: Measure[]
  replacements: Replacement[]
  failures: Failure[]
}

export const MEASURE = 'MEASURE'
export const REPLACEMENT = 'REPLACEMENT'
export const FAILURE = 'FAILURE'

export type Permissions = {
  editBuildings: boolean
  addBuildings: boolean
  editProjects: boolean
  addProjects: boolean
  editCompanies: boolean
  addCompanies: boolean
  importProjects: boolean
  exportManagement: boolean
  histogram: boolean
  database: boolean
  project: boolean
  company: boolean
  Leakage: boolean
  documentationRepair: boolean
  rootAdmin: boolean
  exportDatabase: boolean
  addGroup: boolean
}
export type Place = {
  id: string
  name: string
  _id?: string
  assigned?: boolean
  deletedAt?: Date
  _deleted_at?: Date
  usedLeakages?: number
}
export type Leakage = {
  buildingId?: string
  path?: string[]
  place?: Place
  leakTag?: string
  level?: string
  pressure?: string
  unitP?: string
  distance?: string
  unitD?: string
  costBase?: string
  costUnit?: string
  costTime?: string
  resLeakage?: string
  resUnitL?: string
  lossCosts?: string
  date?: string
  time?: string
  actions?: string
  status?: string
  priority?: string
  comment?: string
  solved_at?: string
  solved_by?: string
  projectId?: string
  unitV?: string
  commR?: string
  commM?: string
  commF?: string
  commRup?: string
  note?: string
  _deleted_at?: Date
  image?: string
  images?: Image[]
  imagesAsBase64?: Image[]
  _id?: string
  canEdit?: boolean
}

export const LEAKAGE_RESOLVED = {
  yes: 'actions.yes',
  no: 'actions.no',
}

export const isLeakageResolved = (leakage: Leakage): boolean => {
  return !!(leakage?.status && leakage?.status !== 'actions.inProgress')
}

export const getDateFromLeakage = (leakage: Leakage): Date => {
  if (!leakage.date || leakage.date.length < 10) return new Date()
  return getDateFromGermanDate(leakage.date + ' ' + leakage.time)
}

export const getDisplayNameOfProject = (project: Project): string => {
  if (!project.name) {
    return ''
  }
  const splitAtComma = project.name.split(',') //[0] ?? ''
  if (splitAtComma.length > 1) {
    return splitAtComma[0]
  }
  return project.name.split('T')[0]
}

export const getDisplayNameOfBuilding = (building: Building): string => {
  return building.name ? building.name : ''
}

export const getLeakage = (leckage: Leakage, project: Project): number => {
  const resLeakage = leckage?.resLeakage ? parseFloat(leckage?.resLeakage) : 0
  if (!project?.unitV) {
    return resLeakage
  }
  return getLeakageFromUnit(leckage?.resLeakage ?? '', leckage?.resUnitL ?? '', project?.unitV)
}

export const getLeakageFromUnit = (resLeakageS: string, resUnitL: string, unitV: string): number => {
  let resLeakage = resLeakageS ? parseFloat(resLeakageS) : 0

  if (getUnitLByUnitV(unitV) !== resUnitL) {
    if (!isCfUnit(unitV)) {
      // project is LTR_MIN project
      resLeakage = !isCfUnit(resUnitL) ? resLeakage : fromCfmToltrmin(resLeakage)
    } else {
      // project is CFM project
      resLeakage = isCfUnit(resUnitL) ? resLeakage : fromLtrMinToCfm(resLeakage)
    }
  }

  return resLeakage
}

export const totalLeakageAmount = (leakages: Leakage[], project: Project): UnitValuePair[] => {
  const fixedLeakageUnit = leakageUnit(leakages, project)
  const leakageToUnitValuePairs = (leakage: Leakage): UnitValuePair => {
    return {
      unit: fixedLeakageUnit || enumValToExportVal(leakage.resUnitL ?? ''),
      value: getLeakage(leakage, project),
    }
  }
  return mixedUnitsAccumulator(leakages.map(leakageToUnitValuePairs))
}

export const getUnitLByUnitV = (unitV: string): string => {
  return isCfUnit(unitV) ? UnitLeakage.CFM : UnitLeakage.LTR_MIN
}

export const fromLtrMinToCfm = (ltrmin: number): number => {
  return ltrmin / LtrMinCfmFactor
}

export const fromLtrMinToNm3 = (ltrmin: number): number => {
  return ltrmin * LtrMinCubicMeterHourFactor
}

export const fromCfmToltrmin = (cfm: number): number => {
  return cfm * LtrMinCfmFactor
}

export const fromBarToPsi = (bar: number): number => {
  return bar * BarPsiFactor
}

export const fromPsiToBar = (psi: number): number => {
  return psi / BarPsiFactor
}

export const fromMeterToInch = (meter: number): number => {
  return meter * MeterInchFactor
}

export const fromInchToMeter = (inch: number): number => {
  return inch / MeterInchFactor
}

export const fromKwhM3ToKwhCf = (kwhM3: number): number => {
  return kwhM3 * KwhM3ToKwhCfFactor
}

export const fromKwhCfToKwhM3 = (kwhCf: number): number => {
  return kwhCf / KwhM3ToKwhCfFactor
}

export const fromKgKwhToLbsKwh = (kgKwh: number): number => {
  return kgKwh * KgKwhToLbsKwhFactor
}

export const fromLbsKwhToKgKwh = (lbsKwh: number): number => {
  return lbsKwh / KgKwhToLbsKwhFactor
}

export type LicenseInfo = {
  uuid: string
  firstUsed: Date
  licenseKey?: string
  // when is the last time the license server responded that this software is activated?
  lastTimeVerified?: Date
}

export type AdminMessageParams = {
  date: string
  fromTime: string
  toTime: string
  isUnderMaintenance: string
}
