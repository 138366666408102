import {
  Address,
  Building,
  Contact,
  isLeakageResolved,
  Leakage,
  LEAKAGE_RESOLVED,
  Project,
} from '../../../interfaces/BusinessObjects'
import {
  Auditor,
  ImportLeakage as ServerImportLeakage,
  ImportProject as ServerImportProject,
  Leakage as ServerLeakage,
  Priority,
  Unit,
  UpdateLeakage as ServerUpdateLeakage,
  UpdateProject,
} from '../../../api/models'
import {
  ifScfMakeCf,
  isCfUnit,
  //UnitCo2,
  UnitCurrency,
  //UnitEnergy,
  //UnitLeakage,
  //UnitPressure,
  //UnitVolume,
} from '../../../interfaces/Units'
import { ImportBuilding, ImportLeakage, ImportProject } from '../../../interfaces/DataImport'
import { getDateFromGermanDate, getTimeFromDate } from '../../helpers/dates'
import { uniqueNumberValueFromLeakages, uniqueStringValueFromLeakages } from '../../helpers/projectUtils'
import { DEFAULT_CO2_AMOUNT, kgToLbs } from '../../../constants/calculationConstants'
//import { calculateEnergyCosts, calculateEnergyTotalCosts } from '../../helpers/calculations'

type ServerString = string | null
type ServerNumber = number | null
// type ServerBool = boolean | null
// type ServerDate = Date | null

export type ServerAddress = {
  street?: ServerString
  number?: ServerString
  zip?: ServerString
  city?: ServerString
  id?: ServerString
}
export type ServerContact = {
  prename?: ServerString
  surname?: ServerString
  email?: ServerString
  phone?: ServerString
  id?: ServerString
}

export type ServerBuilding = {
  name?: ServerString
  company?: ServerString
  id?: ServerString
}

export type ServerProject = {
  name?: ServerString
  Auditor?: Auditor | null
  description?: ServerString
  costBase?: ServerNumber
  costTime?: ServerNumber
  costUnit?: ServerString
  co2Amount?: ServerNumber
  co2Unit?: ServerString
  unitV?: ServerString
  energyAmount?: ServerNumber
  energyUnit?: ServerString
  energyCost?: ServerNumber
  company?: ServerString
  id?: ServerString
  canEdit?: boolean | null
  image?: ServerString
}

export const convertServerBuilding = (building?: ServerBuilding | null): Building => {
  const result = building
    ? Object.fromEntries(Object.entries(building).map(([key, value]) => [key, value ?? undefined]))
    : {}
  if (building) result._id = building.id ?? ''
  return result
}

export const convertAddress = (address?: ServerAddress | null): Address => {
  const result = address
    ? Object.fromEntries(Object.entries(address).map(([key, value]) => [key, value ?? undefined]))
    : {}
  if (address) result._id = address.id ?? ''
  return result
}

export const convertContact = (contact?: ServerContact | null): Contact => {
  const result = contact
    ? Object.fromEntries(Object.entries(contact).map(([key, value]) => [key, value ?? undefined]))
    : {}
  if (contact) result._id = contact.id ?? ''
  return result
}

export const convertProject = (project?: ServerProject | null): Project => {
  if (!project) {
    return {}
  }
  //console.log('serverProject', project)
  const result: Project = Object.fromEntries(Object.entries(project).map(([key, value]) => [key, value ?? undefined]))
  result._id = project.id ?? ''
  result.company = project.company ?? ''
  result.Auditor = {
    id: project.Auditor?.id ?? '',
    name: project.Auditor?.display_name ?? '',
    company: project.Auditor?.company ?? '',
  }
  result.canEdit = project.canEdit ?? false
  if (result.energyUnit === 'KHW_CF') result.energyUnit = 'KWH_CF'
  /* if (!Object.keys(UnitCurrency).includes(`${result.costUnit}`)) {
    result.otherCostUnit = result.costUnit
    result.costUnit = UnitCurrency.OTHER
  } */
  return result
}

export const convertLeakage = (leakage?: ServerLeakage | null): Leakage => {
  if (!leakage) {
    return {}
  }
  const result: Leakage = Object.fromEntries(Object.entries(leakage).map(([key, value]) => [key, value ?? undefined]))
  result._id = leakage.id ?? ''
  result.projectId = leakage.projectId ?? ''

  if (leakage.building) {
    result.buildingId = leakage.building.id
  }

  if (leakage.resolvableUnderPressure !== null && leakage.resolvableUnderPressure) {
    result.commRup = LEAKAGE_RESOLVED.yes
  } else if (leakage.resolvableUnderPressure !== null && !leakage.resolvableUnderPressure) {
    result.commRup = LEAKAGE_RESOLVED.no
  }
  if (leakage.images) {
    result.imagesAsBase64 = leakage.images.map((i) => {
      return { src: i.contents, _originalImage: i.originalImage, checked: i.selected, loadedFromFilename: i.name }
    })
  }
  result.place = leakage.place ? { id: leakage.place.id, name: leakage.place.name } : undefined
  result.time = leakage.date ? getTimeFromDate(leakage.date) : undefined
  result.costUnit = leakage.costUnit ? leakage.costUnit : leakage.unitC ?? undefined
  result.solved_by = leakage.resolvedBy ?? undefined
  result.solved_at = leakage.resolvedAt ?? undefined
  result.lossCosts = leakage.lossCosts ? leakage.lossCosts.toFixed(2) : '0'
  return result
}

export const dropId = <T extends { _id?: string }>(obj: T): T => {
  return Object.fromEntries(Object.entries(obj).filter(([key]) => key !== '_id' && key !== '__typename')) as T
}

export const stringToProjectUnit = (s?: string): Unit | undefined => {
  if (!s) {
    return undefined
  }
  const result = Object.values(Unit).find((v) => s == v)
  return result
}

export const stringToPriority = (s?: string): Priority => {
  if (!s) {
    return Priority.None
  }
  const ret = Object.values(Priority).find((v) => s == v)
  return ret ?? Priority.None
}

export const projectToUpdateProject = (project: Project): UpdateProject => {
  console.log('projectToUpda', project, 'cu', UnitCurrency.OTHER)
  return {
    groupId: project.groupId,
    name: project.name ?? '',
    companyName: project.company ?? '',
    AuditorName: project.Auditor?.name ?? null,
    id: project._id,
    costBase: project.costBase ? parseFloat(project.costBase) : null,
    costTime: project.costTime ? parseFloat(project.costTime) : null,
    costUnit: project.costUnit === UnitCurrency.OTHER ? project.otherCostUnit : project.costUnit,
    energyAmount: project.energyAmount ? parseFloat(project.energyAmount) : null,
    energyUnit: project.energyUnit ? stringToProjectUnit(project.energyUnit) : null,
    unitV: project.unitV ? stringToProjectUnit(project.unitV) : null,
    description: project.description ?? null,
    importDate: project.created?.toISOString() ?? new Date().toISOString(),
    co2Amount: project.co2Amount ? parseFloat(project.co2Amount) : null,
    co2Unit: project.co2Unit ? stringToProjectUnit(project.co2Unit) : null,
    energyCost: project.energyCost ? parseFloat(project.energyCost) : null,
    elPr: project.elPr,
    spPw: project.spPw,
    uSpPw: project.uSpPw,
    spPwId: project.spPwId,
    cMod: project.cMod,
    sigE3: project.sigE3,
  }
}

export const importProjectToServerImportProject = (
  importProject: ImportProject,
  uids: string[] | undefined,
  buildings_map: ImportBuilding[],
): ServerImportProject => {
  const leakages = importProject.leakages
  // function getElement<T, K extends keyof T>(leakage: T, key: K): T[K] {
  //   return leakage[key]
  // }

  // function uniqueElement<T, K extends keyof T>(leakages: T, key: K): T[K][] {
  //   return leakages
  //     .map((leakage) => {
  //       const element = getElement(leakage, key)
  //       return typeof element === 'number' ? element : undefined
  //     })
  //     .filter(isUniqueType)
  // }

  // standard mode parameters TODO: verify that SCF can be overwritten by CF
  const projectUnitV = ifScfMakeCf(importProject.unitV || uniqueStringValueFromLeakages(leakages, 'unitV'))

  let projectCostBase = importProject.costBase
  if (!projectCostBase) {
    const costBaseStr = uniqueStringValueFromLeakages(leakages, 'costBase')
    if (costBaseStr) {
      projectCostBase = Number(costBaseStr)
    }
  }
  const projectCostTime = importProject.costTime || uniqueNumberValueFromLeakages(leakages, 'costTime')
  const projectCostUnit = importProject.costUnit || uniqueStringValueFromLeakages(leakages, 'costUnit')

  let projectCo2Unit = null
  let projectCo2Amount = null
  if (projectUnitV) {
    if (isCfUnit(projectUnitV)) {
      projectCo2Amount = DEFAULT_CO2_AMOUNT * kgToLbs
      projectCo2Unit = Unit.LbsKwh
    } else {
      projectCo2Amount = DEFAULT_CO2_AMOUNT
      projectCo2Unit = Unit.KgKwh
    }
  }

  // expert mode parameters
  const costElPr = uniqueNumberValueFromLeakages(leakages, 'costElPr')
  const costUSpPw = uniqueNumberValueFromLeakages(leakages, 'costUspPw')
  const costSpPw = uniqueNumberValueFromLeakages(leakages, 'costSpPw')
  const costSpPwId = uniqueStringValueFromLeakages(leakages, 'costSpPwId')
  const costCMod = uniqueStringValueFromLeakages(leakages, 'costCmod')
  const costSigE3 = uniqueStringValueFromLeakages(leakages, 'costSigE3')

  //   if (costCMod) {
  //     if (costCMod.endsWith('1') && costSpPw && costElPr) {
  //       projectCostBase = calculateEnergyCosts(
  //         projectUnitV,
  //         costSpPw,
  //         costElPr,
  //         projectCostUnit || '',
  //       ).resultAsFormattedString
  //     }
  //     if (costCMod.endsWith('3') && costSpPw && costElPr) {
  //       projectCostBase = calculateEnergyTotalCosts(
  //         projectUnitV,
  //         costSpPw,
  //         costElPr,
  //         projectCostUnit || '',
  //       ).resultAsFormattedString
  //     }
  //   }

  const res = {
    name: importProject.name,
    description: importProject.description,
    co2Unit: projectCo2Unit,
    co2Amount: projectCo2Amount,
    costBase: projectCostBase,
    costTime: projectCostTime,
    costUnit: projectCostUnit ?? '',
    unitV: projectUnitV ? stringToProjectUnit(projectUnitV) : null,
    energyAmount: costSpPw || importProject.energyAmount,
    energyUnit: importProject.energyUnit ? stringToProjectUnit(importProject.energyUnit) : null,
    energyCost: costElPr,
    importDate: new Date().toISOString(),
    elPr: costElPr,
    spPw: costSpPw,
    spPwId: costSpPwId,
    uSpPw: costUSpPw,
    cMod: costCMod,
    sigE3: costSigE3,
    leakages: importProject.leakages
      .filter((l) => !uids || uids.includes(l.uid))
      .map((l) => importLeakageToServerImportLeakage(l, buildings_map)),
  }

  return res
}

export const importLeakageToServerImportLeakage = (
  leakage: ImportLeakage,
  buildings_map: ImportBuilding[],
): ServerImportLeakage => {
  return {
    building_name: buildings_map.filter((building) => building.id === leakage.buildingId).pop()?.name || '',
    commF: leakage.commF,
    commM: leakage.commM,
    commR: leakage.commR,
    commRup: leakage.commRup,
    comment: leakage.comment,
    costBase: leakage.costBase,
    costRelevantAttributesEdited: leakage.costRelevantAttributesEdited,
    costTime: leakage.costTime,
    costUnit: leakage.costUnit,
    date: getDateFromGermanDate(leakage.date + ' ' + leakage.time).toISOString(),
    distance: leakage.distance,
    gain: leakage.gain,
    gasType: leakage.gasType,
    imgName: leakage.imgName,
    imgPath: leakage.imgPath,
    leakTag: leakage.leakTag,
    level: leakage.level,
    place: leakage.place,
    pressure: leakage.pressure,
    resCost: leakage.resCost,
    resFlag: leakage.resFlag,
    resLeakage: leakage.resLeakage,
    resUnitL: stringToProjectUnit(leakage.resUnitL ?? 'LTR_MIN'),
    sens2x: leakage.sens2x,
    sensivity: leakage.sensivity,
    sn: leakage.sn,
    sw: leakage.sw,
    threshold: leakage.threshold,
    unitC: stringToProjectUnit(leakage.unitC),
    unitD: leakage.unitD,
    unitP: stringToProjectUnit(leakage.unitP),
    unitV: stringToProjectUnit(leakage.unitV),
    version: leakage.version,
    lossCosts: leakage.resCost,
    CMod: leakage.costCmod,
    ElPr: leakage.costElPr,
    SigE3: leakage.costSigE3,
    SpPw: leakage.costSpPw,
    SpPwId: leakage.costSpPwId,
    USpPw: leakage.costUspPw,
  }
}

function pad(number: number): string | number {
  if (number < 10) {
    return '0' + number
  }
  return number
}

const toLocaleTimeISOString = (date: Date): string => {
  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    '.' +
    (date.getMilliseconds() / 1000).toFixed(3).slice(2, 5) +
    (date.getTimezoneOffset() > 0 ? '-' : '+') +
    pad(Math.abs(date.getTimezoneOffset()) / 60) +
    ':' +
    pad(Math.abs(date.getTimezoneOffset()) % 60)
  )
}

export const leakageToServerUpdateLeakage = (leakage: Leakage): ServerUpdateLeakage => {
  return {
    id: leakage._id,
    project_id: leakage.projectId ?? '',
    buildingId: leakage.buildingId ?? '',
    buildingName: '',
    commF: leakage.commF,
    commM: leakage.commM,
    commR: leakage.commR,
    comment: leakage.comment,
    costBase: leakage.costBase,
    costTime: parseFloat(leakage.costTime ?? '0'),
    costUnit: leakage.costUnit,
    date: leakage.date ? leakage.date.substring(0, 10) + 'T' + leakage.date.substring(11, 19) + 'Z' : leakage.date,
    distance: parseFloat(leakage.distance ?? '0'),
    gain: 0,
    gasType: null,
    images:
      leakage.imagesAsBase64?.map((i) => {
        return { checked: i.checked, content: i.src, fileId: i.loadedFromFilename ?? null }
      }) ?? [],
    leakTag: parseFloat(leakage.leakTag ?? '0'),
    level: parseFloat(leakage.level ?? '0'),
    location: leakage.place?.name,
    place: leakage.place?.name,
    placeId: leakage.place?.id,
    pressure: parseFloat(leakage.pressure ?? '0'),
    priority: stringToPriority(leakage.priority),
    resCost: 0,
    resFlag: 0,
    resolved: isLeakageResolved(leakage),
    resolvableUnderPressure: !!(leakage.commRup && leakage.commRup.includes('yes')),
    note: leakage.note,
    resolvedBy: leakage.solved_by,
    resolvedAt: leakage.solved_at ? toLocaleTimeISOString(new Date(leakage.solved_at)) : leakage.solved_at,
    resLeakage: parseFloat(leakage.resLeakage ?? '0'),
    resUnitL: stringToProjectUnit(leakage.resUnitL ?? 'LTR_MIN'),
    sens2x: null,
    sensivity: null,
    sn: null,
    sw: null,
    threshold: null,
    unitC: stringToProjectUnit(leakage.costUnit),
    unitD: leakage.unitD,
    unitP: stringToProjectUnit(leakage.unitP),
    unitV: stringToProjectUnit(leakage.unitV),
    version: null,
    lossCosts: parseFloat(leakage.lossCosts ?? '0'),
    status: leakage.status,
  }
}
