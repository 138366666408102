/* eslint-disable @typescript-eslint/no-unused-vars */
import { License } from '../../interfaces/License'
import { LicenseStatusType, useLicenseStatus } from '../../context/LicenseContext'
import { useEffect, useMemo } from 'react'
import { useKeycloak, useOrganizations } from './useKeycloak'
import { enableLicBanner, getSelectedRealm, setCloudLicenseInfo } from 'app/helpers/manageRealms'
import { getRemainingDays, isExpired } from 'app/helpers/utils'
export const APP_KEY = 'CS_LEAK_REPORTER_V2'
export const useLicense = (): License => {
  const { keycloak } = useKeycloak()

  if (keycloak?.authenticated && keycloak.userInfo) {
    const userInfo = keycloak.userInfo as License
    return {
      company: getSelectedRealm(),
      email: userInfo.email,
      family_name: userInfo.family_name,
      given_name: userInfo.given_name,
    }
  } else {
    //console.error('no user info')
    return {}
  }
}

export const isOffline = (): boolean => {
  return false
}

export const useSoftwareMayBeUsed = (
  keepRefreshingValues?: boolean,
  offlineActivationString?: string,
): {
  mayBeUsed: boolean
  activated: boolean
  daysTestVersionRemaining: number
  loading: boolean
} => {
  const { licenseStatus, setLicenseStatus } = useLicenseStatus()

  useEffect(() => {
    setLicenseStatus(LicenseStatusType.Activated)
  }, [setLicenseStatus])

  return { mayBeUsed: true, activated: true, daysTestVersionRemaining: 0, loading: false }
}

export const useUUIDForLicense = (): string => {
  return 'testuuid'
}

type LicenseDeactivation = {
  deactivated: boolean
  loading: boolean
  error: boolean
  deactivate: (uuid: string) => void
  deactivateOffline: () => void
}

// not used for cloud app. here to avoid compilation errors
export const useDeactivateLicense = (): LicenseDeactivation => {
  return {
    deactivated: false,
    loading: false,
    error: false,
    deactivate: () => {
      console.error('not implemented')
    },
    deactivateOffline: () => {
      console.error('not implemented')
    },
  }
}
export type CloudLicenseStatus = {
  hasAccess: boolean
  expired: boolean
  daysRemaining: number
  organizationDisp?: string
}
export const useCloudLicense = (): CloudLicenseStatus => {
  const organizations = useOrganizations()
  //const [licenseStatus, setLicenseStatus] = useState<CloudLicenseStatus>()
  const licenseStatus = useMemo(() => {
    if (organizations.length) {
      const selectedOrg = getSelectedRealm()
        ? organizations.find((o) => o.realmName === getSelectedRealm())
        : organizations[0]
      const licInfo = {
        hasAccess: true,
        expired: !!isExpired(selectedOrg?.expirationDate),
        daysRemaining: getRemainingDays(selectedOrg?.expirationDate),
        organizationDisp: selectedOrg?.displayName,
      }
      setCloudLicenseInfo(licInfo)
      return licInfo
    } else
      return {
        hasAccess: false,
        daysRemaining: -1,
        expired: true,
      }
  }, [organizations])

  return licenseStatus
}
