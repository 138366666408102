import { CloudLicenseStatus } from 'app/hooks/useLicense'

export const setSelectedRealm = (realmName: string): void => {
  localStorage.setItem('_regRealm', realmName)
}

export const getSelectedRealm = (): string => {
  return localStorage.getItem('_regRealm') || ''
}

export const clearStorage = (): void => {
  localStorage.clear()
}

export const setCloudLicenseInfo = (licInfo: unknown): void => {
  //console.log('licInfo', licInfo)
  localStorage.setItem('_lic', JSON.stringify(licInfo))
}

export const getCloudLicenseInfo = (): CloudLicenseStatus | undefined => {
  const currentLic = localStorage.getItem('_lic')
  if (currentLic) {
    return JSON.parse(currentLic) as CloudLicenseStatus
  }
}

export const enableLicBanner = (): void => {
  localStorage.setItem('show', 'true')
}

export const disableLicBanner = (): void => {
  return localStorage.setItem('show', 'false')
}

export const licBannerShow = (): boolean => {
  return localStorage.getItem('show') === 'true'
}
