import { Box, LinearProgress, Typography } from '@material-ui/core'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import styles from './ProgressBar.module.css'

type ProgressBarProps = {
  show: boolean
  progressValue?: number
}

export const ProgressBar: FC<ProgressBarProps> = ({ show, progressValue }): ReactElement => {
  const [progress, setProgress] = useState<number>()
  useEffect(() => {
    if (progressValue) {
      //console.log({ progressValue })
      setProgress(progressValue)
    }
  }, [progressValue])
  //console.log(progressValue)
  return (
    <div className={styles['progress-bar']} style={{ paddingBottom: '1em' }}>
      {progress && show ? (
        <Box display="flex">
          <Box width={`${progress}%`}>
            <LinearProgress variant="determinate" value={progress} color="secondary" />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="secondary">
              {`${progress} %`}
            </Typography>
          </Box>
        </Box>
      ) : (
        <></>
      )}
      {!progress ? <LinearProgress color="secondary" style={show ? {} : { display: 'none' }} /> : <></>}
    </div>
  )
}
