import { useEffect, useState } from 'react'
import axios from 'axios'
import { environment } from '../../constants/environment'

export type HardwareVersionInfo = {
  major: string
  minor: string
  hardwareVersionLoading: boolean
}

export const useCurrentHardwareVersion = (): HardwareVersionInfo => {
  const [versionInfo, setVersionInfo] = useState<HardwareVersionInfo>({
    major: '0',
    minor: '0',
    hardwareVersionLoading: true,
  })

  useEffect(() => {
    axios.get(`${environment.VERSION_SERVER_URL}/version/LEAKREPORTER`).then((result) => {
      const vString = `${result.data}`
      const parts = vString.split('.')
      if (parts.length === 1) {
        const major = parts[0]
        setVersionInfo({ major, minor: '0', hardwareVersionLoading: false })
      } else if (parts.length === 2) {
        const major = parts[0]
        const minor = parts[1]
        setVersionInfo({ major, minor, hardwareVersionLoading: false })
      } else {
        console.error('invalid result of version server', result)
      }
      setVersionInfo((old) => {
        return { ...old, hardwareVersionLoading: false }
      })
    })
  }, [])

  return versionInfo
}
