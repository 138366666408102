import { Address } from '../../../interfaces/BusinessObjects'
import { PersistedAddress } from '../types/types'
import {
  createObject,
  finalDeleteObjectById,
  deleteObjects,
  getObjectById,
  getObjects,
  updateObjectById,
  getDatastore,
} from './shared/shared'
import { repositoryNames } from './repositoryNames'

export const getAddresses = (): Promise<Address[]> => {
  return getObjects(getDatastore(repositoryNames.ADDRESS))
}

export const deleteAddresses = (): Promise<number> => {
  return deleteObjects(getDatastore(repositoryNames.ADDRESS))
}

export const getAddressById = (id: string): Promise<PersistedAddress> => {
  return getObjectById(getDatastore(repositoryNames.ADDRESS), id)
}

export const createAddress = (address: Address): Promise<PersistedAddress> => {
  return createObject(getDatastore(repositoryNames.ADDRESS), address)
}

export const updateAddressById = (id: string, address: Address): Promise<PersistedAddress> => {
  return updateObjectById(getDatastore(repositoryNames.ADDRESS), id, address)
}

export const deleteAddressById = (id: string): Promise<number> => {
  return finalDeleteObjectById(getDatastore(repositoryNames.ADDRESS), id)
}
