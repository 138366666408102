import { getCloudLicenseInfo, getSelectedRealm } from 'app/helpers/manageRealms'
import { useMemo } from 'react'
import { useGetPermissionsQuery } from '../../api/models'
import { Permissions } from '../../interfaces/BusinessObjects'
import { useOrganizations } from './useKeycloak'
//import { useCloudLicense } from './useLicense'

export const usePermissions = (): Permissions => {
  const { data } = useGetPermissionsQuery({
    variables: { realmName: getSelectedRealm() || '' },
    skip: !getSelectedRealm(),
  })
  const license = getCloudLicenseInfo()
  const orgs = useOrganizations()

  const selectedOrgIsTestLicense = useMemo(() => {
    return orgs.find((o) => o.realmName === getSelectedRealm())?.cloudLicenseType !== 'trial'
  }, [orgs])

  const p: Permissions = {
    addBuildings: false,
    editBuildings: false,
    addCompanies: false,
    editCompanies: false,
    addProjects: false,
    editProjects: false,
    importProjects: false,
    exportManagement: false,
    histogram: false,
    database: false,
    documentationRepair: false,
    company: false,
    Leakage: false,
    project: false,
    rootAdmin: false,
    exportDatabase: false,
    addGroup: false,
  }

  if (data?.getPermissions && !license?.expired) {
    const perm = data.getPermissions
    p.addProjects = perm.addProjects
    p.addBuildings = perm.addBuildings
    p.editBuildings = perm.editBuildings
    p.editCompanies = perm.editCompanies
    p.addCompanies = perm.addCompanies
    p.addProjects = perm.addProjects
    p.editProjects = perm.editProjects
    p.importProjects = perm.importProjects
    p.exportManagement = perm.exportManagement
    p.histogram = perm.histogram
    p.database = perm.database
    p.documentationRepair = perm.documentationRepair
    p.Leakage = perm.Leakage
    p.rootAdmin = perm.rootAdmin
    p.exportDatabase = selectedOrgIsTestLicense
    p.addGroup = !!perm.manageGroups
  }

  return p
}
