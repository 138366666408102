import { ImportBuilding, ImportCompany, ImportLeakage } from '../../../../interfaces/DataImport'
import { DataTableColumn, DataTableRow } from '@csinstruments/cs-react-theme/dist/components/DataTable/DataTableTypes'

export const dataImportColumns: DataTableColumn[] = [
  { label: 'objects.company', alignment: 'left' },
  { label: 'objects.building', alignment: 'left' },
  { label: 'objects.date', alignment: 'left' },
  { label: 'objects.time', alignment: 'left' },
]

export type DataImportFilter = {
  company: string[]
  building: string
  dateFrom?: Date
  dateUntil?: Date
}

const getLeakageDate = (leakage: ImportLeakage): Date => {
  const pattern = /(\d{2})\.(\d{2})\.(\d{4})/
  const date = new Date(leakage.date.replace(pattern, '$3-$2-$1') + 'T' + leakage.time)
  console.log(date)
  return date
}

const getBuildingNameById = (buildingId: string, buildings: ImportBuilding[]): string => {
  const building = buildings.filter((building) => building.id === buildingId).pop()
  return building?.name || ''
}

export const importCompaniesToTableRows = (companies: ImportCompany[], filter: DataImportFilter): DataTableRow[] => {
  const relevantCompanies = filter.company
    ? companies.filter((c) => filter.company.length === 0 || filter.company.includes(c.name))
    : companies
  return relevantCompanies
    .map((c) => {
      return c.projects[0].leakages
        .filter((l) => !filter.building || getBuildingNameById(l.buildingId, c.buildings) === filter.building)
        .filter((l) => !filter.dateFrom || getLeakageDate(l).getTime() > filter.dateFrom.getTime())
        .filter((l) => !filter.dateUntil || getLeakageDate(l).getTime() < filter.dateUntil.getTime())
        .map((l) => {
          const row: DataTableRow = {
            id: `${l.uid}`,
            values: [c.name, getBuildingNameById(l.buildingId, c.buildings), l.date, l.time],
          }
          return row
        })
    })
    .flat()
}

const onlyUnique = (value: string, index: number, self: string[]): boolean => {
  return self.indexOf(value) === index
}

export const getCompanyNames = (companies: ImportCompany[]): string[] => {
  return companies.map((c) => c.name).filter(onlyUnique)
}

export const getBuildingNames = (companies: ImportCompany[]): string[] => {
  return companies
    .map((c) => c.buildings.map((b) => b.name))
    .flat()
    .filter(onlyUnique)
}
