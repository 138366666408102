import { Link } from 'interfaces/BusinessObjects'
import { useCallback, useEffect, useRef } from 'react'

type PushDownloadResult = {
  downloadAction: () => void
}

export const usePushDownloadFile = (content: BlobPart, ready: boolean, fileName: string): PushDownloadResult => {
  const wasReady = useRef(false)
  const downloadExportFile = useCallback((): void => {
    const element = document.createElement('a')
    const file = new Blob([content], { type: 'application/octet-stream' })
    element.href = URL.createObjectURL(file)
    element.download = fileName
    document.body.appendChild(element)
    element.click()
  }, [content, fileName])

  useEffect(() => {
    if (ready && !wasReady.current) {
      downloadExportFile()
      wasReady.current = true
    } else if (!ready) {
      wasReady.current = false
    }
  }, [ready, downloadExportFile])

  return { downloadAction: downloadExportFile }
}

export const showSaveFileDialog = async (link: Link | undefined, buffer: Buffer | undefined): Promise<void> => {
  if (!link) {
    return
  }

  if (link.url) {
    const element = document.createElement('a')
    const file = new Blob([Buffer.from(link.url, 'base64')], { type: link.mimeType })
    element.href = URL.createObjectURL(file)
    element.download = link.filename ?? ''
    document.body.appendChild(element)
    element.click()
    return
  }
}
