export const getQueryParam = (param: string): string => {
  const query = window.location.href.split('?')
  //console.log({ query })
  if (query.length < 2) {
    return ''
  }
  return query[1].split('&').reduce((prevVal: string, current: string) => {
    const currentSplit = current.split('=')
    return currentSplit.length == 2 && currentSplit[0] === param ? currentSplit[1] : prevVal
  }, '')
}
