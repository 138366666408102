import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import React from 'react'
import SearchIcon from '@material-ui/icons/SearchOutlined'

export type SearchProps = {
  label: string
  changeFunction: (search: string) => void
}

export const Search: React.FC<SearchProps> = ({ label, changeFunction }) => {
  const textChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
    changeFunction(e.target.value)
  }

  return (
    <>
      <form noValidate autoComplete="off">
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          fullWidth
          id="standard-basic"
          label={label}
          onChange={textChanged}
        />
      </form>
    </>
  )
}

export default Search
