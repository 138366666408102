import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { paddingBottomActionBar, paddingTop, standardContentPadding } from '../../../../styles/Shared'
import Grid from '@material-ui/core/Grid'
import { Text } from '../../partials/Inputs/Text'
import { ImageUpload } from '../../partials/ImageUpload/ImageUpload'
import SaveIcon from '@material-ui/icons/SaveOutlined'
import CancelIcon from '@material-ui/icons/Cancel'
import Typography from '@material-ui/core/Typography/Typography'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { CompanyParamTypes } from '../../../../interfaces/PageParams'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import { useEditStyles } from './CompanyMasterDataStyle'
import { StandardConfirmationModal } from '../../partials/StandardConfirmationModal/StandardConfirmationModal'
import { useCompany, useCreateCompany, useDeleteCompany, useUpdateCompany } from '../../../hooks/companyHooks'
import { Company } from '../../../../interfaces/BusinessObjects'
import { useRedirect } from '../../../hooks/useRedirect'
import { ROUTES } from '../../../helpers/routes'
import { ResultAlert } from '../../partials/ResultAlert'
import { FixedActionBar } from '@csinstruments/cs-react-theme/dist/components/FixedActionBar/FixedActionBar'
import { PageHeading } from '../../partials/PageHeading'
import { usePermissions } from '../../../hooks/usePermissions'
//import { getQueryParam } from 'app/helpers/routingHelper'
import { useGetQueryParam } from '../utilHooks'

export const CompanyMasterDataEdit: React.FC = () => {
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const { t } = useTranslation('common')
  const { id } = useParams<CompanyParamTypes>()
  const companyName = decodeURIComponent(id)
  //const groupParams = getQueryParam('groupId')
  const groupId = useGetQueryParam('groupId')
  const classes = useEditStyles()
  const history = useHistory()
  const permissions = usePermissions()

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [newCompanyData, setNewCompanyData] = useState<Company>({})
  const { createdCompany, createCompany, createError } = useCreateCompany()
  const { updatedCompany, updateCompany, updateError } = useUpdateCompany()
  const { deleteCompany, deleted } = useDeleteCompany()
  const { company, companyLoading } = useCompany(id, groupId)
  const lastUpdateState = useRef(false)
  useRedirect(generatePath(ROUTES.companies), [createdCompany, deleted])
  useEffect(() => {
    setNewCompanyData({ ...company, groupId })
  }, [company, companyLoading, groupId])

  useEffect(() => {
    if (!updatedCompany) {
      lastUpdateState.current = false
    }
  }, [updatedCompany])

  // const createUpdateError = useMemo(() => {
  //   return id ? id && updateError : createError
  // }, [id, updateError, createError])

  useEffect(() => {
    if (updateError && updateError) {
      setShowError(true)
    }
    if (createError && createError) {
      setShowError(true)
    }
  }, [createError, updateError])

  useEffect(() => {
    if (updatedCompany && !lastUpdateState.current) {
      if (id !== newCompanyData.name) {
        const groupSubPath = !groupId ? undefined : `?groupId=${groupId}`
        history.push(generatePath(ROUTES.companies) + groupSubPath)
      } else {
        lastUpdateState.current = true
        setShowSuccess(true)
      }
    }
  }, [updatedCompany, newCompanyData.name, history, id, groupId])

  const updateNewCompanyData = (data: Company): void => {
    const address = { ...newCompanyData.address, ...data.address }
    const contact = { ...newCompanyData.contact, ...data.contact }
    setNewCompanyData({ ...newCompanyData, ...data, address, contact })
  }

  const deleteAction = useCallback(() => setModalOpen(true), [])

  const updateAction = useCallback(() => {
    !id ? createCompany(newCompanyData) : updateCompany(newCompanyData, companyName)
  }, [newCompanyData, id, companyName, createCompany, updateCompany])

  // no id given in URL: Create a new company
  const isNewPage = useMemo(() => !id, [id])

  const cancelAction = useCallback(() => {
    let cancelPath = isNewPage
      ? ROUTES.companies
      : generatePath(ROUTES.companyAction, { id: companyName, action: 'master' })
    cancelPath = !groupId ? cancelPath : cancelPath + `?groupId=${groupId}`
    history.push(cancelPath)
  }, [history, companyName])

  const permitted = useMemo(() => {
    return permissions.editCompanies
  }, [permissions])

  return !permitted ? (
    <></>
  ) : (
    <>
      <ResultAlert alertText={'actions.updateSuccess'} showAlert={showSuccess} modifyShowAlert={setShowSuccess} />
      <ResultAlert
        alertText={'objects.duplicateCompanyName'}
        severity="error"
        showAlert={showError}
        modifyShowAlert={setShowError}
        autoHideDuration={5000}
      />
      <StandardConfirmationModal
        handleConfirm={() => deleteCompany(id)}
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
      />
      <FixedActionBar
        buttonProps={[{}, {}, { solid: true }]}
        actions={[deleteAction, cancelAction, updateAction]}
        labels={['actions.delete', 'actions.cancel', 'actions.save']}
        conditions={[!!id, true, true]}
        disabled={[!newCompanyData.name, false, !newCompanyData.name]}
        icons={[DeleteIcon, CancelIcon, SaveIcon]}
      />
      <div style={{ ...paddingTop, ...paddingBottomActionBar }}>
        <Grid container spacing={3} style={standardContentPadding}>
          <Grid item xs={5}>
            <Grid spacing={2} container>
              {!isNewPage && (
                <>
                  <Grid item xs={12}>
                    <PageHeading title={newCompanyData.name || ''} />
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.pageHeading}>
                      <Typography variant={'h6'}>{t('company.edit')}</Typography>
                    </div>
                  </Grid>
                </>
              )}
              {isNewPage && (
                <div className={classes.pageMainHeading}>
                  <PageHeading title={'company.create'} />
                </div>
              )}
              <Text
                value={newCompanyData.name || ''}
                columns={12}
                variant="outlined"
                label={'objects.company'}
                changeHandler={(company) => updateNewCompanyData({ name: company })}
              />
              <Text
                value={newCompanyData.contact?.surname || ''}
                columns={12}
                variant="outlined"
                label={'contact.lastName'}
                changeHandler={(lastName) => updateNewCompanyData({ contact: { surname: lastName } })}
              />
              <Text
                value={newCompanyData.contact?.prename || ''}
                columns={12}
                variant="outlined"
                label={'contact.firstName'}
                changeHandler={(firstName) => updateNewCompanyData({ contact: { prename: firstName } })}
              />
              <Text
                value={newCompanyData.contact?.phone || ''}
                columns={12}
                variant="outlined"
                label={'contact.phone'}
                changeHandler={(phone) => updateNewCompanyData({ contact: { phone } })}
              />
              <Text
                value={newCompanyData.contact?.email || ''}
                columns={12}
                variant="outlined"
                label={'contact.email'}
                changeHandler={(email) => updateNewCompanyData({ contact: { email } })}
              />
              <Text
                value={newCompanyData.address?.street || ''}
                columns={12}
                variant="outlined"
                label={'address.street'}
                changeHandler={(street) => updateNewCompanyData({ address: { street } })}
              />
              <Text
                value={newCompanyData.address?.number || ''}
                columns={12}
                variant="outlined"
                label={'address.number'}
                changeHandler={(number) => updateNewCompanyData({ address: { number } })}
              />
              <Text
                value={newCompanyData.address?.zip || ''}
                columns={12}
                variant="outlined"
                label={'address.postCode'}
                changeHandler={(postCode) => updateNewCompanyData({ address: { zip: postCode } })}
              />
              <Text
                value={newCompanyData.address?.city || ''}
                columns={12}
                variant="outlined"
                label={'address.city'}
                changeHandler={(city) => updateNewCompanyData({ address: { city } })}
              />
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <Grid container direction="column">
              <Grid item xs={12}>
                <ImageUpload
                  image={newCompanyData.logo}
                  imageChanged={(image: string) => updateNewCompanyData({ logo: image })}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default CompanyMasterDataEdit
