import React, { useEffect } from 'react'
import { generatePath, Link, Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routes'
import { ProjectsOverview } from './ProjectsOverview'

import { CompanyMasterData } from './CompanyMasterData'
import { CompanyParamTypes } from '../../../../interfaces/PageParams'
import { TabbedPage } from '../../partials/TabbedPage/TabbedPage'
import CsButton from '@csinstruments/cs-react-theme/dist/components/Buttons/CsButton'
import { useTranslation } from 'react-i18next'
import { CompanyBuildings as BuildingsOverview } from './Buildings'
import { useGetQueryParam } from '../utilHooks'
import { CompanyPlaces } from './Places'

type Subpage = 'master' | 'buildings' | 'places' | 'projects'

enum Subpages {
  master,
  buildings,
  places,
  projects,
}

export const CompanyPage: React.FC = () => {
  const { t } = useTranslation('common')
  const { id, action } = useParams<CompanyParamTypes>()
  const chosenTab: Subpages = action && action in Subpages ? Subpages[action as Subpage] : 3
  const history = useHistory()
  const groupId = useGetQueryParam('groupId')

  // if URL is incomplete, redirect to "company/projects"
  useEffect(() => {
    if (!action) {
      let projectRoute = generatePath(ROUTES.companyAction, { id, action: 'projects' })
      if (groupId) {
        projectRoute += `?groupId=${groupId}`
      }
      history.push(projectRoute)
    }
  }, [action, id, history, groupId])

  const tabChanged = (v: number): void => {
    let subRoute = generatePath(ROUTES.companyAction, { id, action: Subpages[v] })
    if (groupId) {
      subRoute += `?groupId=${groupId}`
    }
    history.push(subRoute)
  }

  // let companiesRoute = ROUTES.companies
  // if (groupId) {
  //   companiesRoute += `?groupId=${groupId}`
  // }

  return (
    <>
      <Link style={{ textDecoration: 'none' }} to={ROUTES.companies}>
        <CsButton
          fullwidth={false}
          solid={true}
          label={t('actions.backToCompaniesOverview')}
          color={'primary'}
          onClick={() => undefined}
        />
      </Link>
      <TabbedPage
        chosenTab={chosenTab}
        tabChanged={tabChanged}
        labels={['company.masterData', 'objects.buildings', 'objects.location', 'objects.projects']}
      >
        <Switch>
          <Route exact path={ROUTES.companyBuildings} render={() => <BuildingsOverview />} />
          <Route exact path={ROUTES.companyPlaces} render={() => <CompanyPlaces />} />
          <Route exact path={ROUTES.companyProjects} render={() => <ProjectsOverview />} />
          <Route exact path={ROUTES.companyMaster} render={() => <CompanyMasterData />} />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </TabbedPage>
    </>
  )
}

export default CompanyPage
