import React from 'react'
import Grid from '@material-ui/core/Grid'
import { paddingBottomActionBar, paddingTop, standardContentPadding } from '../../../../styles/Shared'
import { Button, Card, CardActions, CardContent, Chip, Link, Typography } from '@material-ui/core'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import OpenInNew from '@material-ui/icons/OpenInNew'
import { useTranslation } from 'react-i18next'

export const Dashboard: React.FC = () => {
  const { t } = useTranslation('common')

  return (
    <div style={{ ...paddingTop, ...paddingBottomActionBar }}>
      <div style={standardContentPadding}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Card style={{ height: '100%', boxShadow: 'none', border: '1px solid lightgrey' }}>
              <CardContent style={{ minHeight: '180px' }}>
                <Typography gutterBottom component={'div'}>
                  <Chip label="Info" size="small" />
                </Typography>
                <Typography gutterBottom variant="h6">
                  {t('objects.learningProgram')}
                </Typography>
                <Typography variant="body2">{t('learningProgram.description')}</Typography>
              </CardContent>
              <CardActions>
                <Link href={t('learningProgram.url')} target="_blank">
                  <Button variant="contained" color={'primary'} startIcon={<OpenInNew />}>
                    {t('objects.learningProgram')}
                  </Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card style={{ height: '100%', boxShadow: 'none', border: '1px solid lightgrey' }}>
              <CardContent style={{ minHeight: '180px' }}>
                <Typography gutterBottom component={'div'}>
                  <Chip label="Social Media" size="small" />
                </Typography>
                <Typography gutterBottom variant="h6">
                  LinkedIn <LinkedInIcon />
                </Typography>
              </CardContent>
              <CardActions>
                <Link href={'https://www.linkedin.com/company/cs-instruments-gmbh/'} target="_blank">
                  <Button variant="contained" color={'primary'} startIcon={<OpenInNew />}>
                    {t('dashboard.followUs')}
                  </Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card style={{ height: '100%', boxShadow: 'none', border: '1px solid lightgrey' }}>
              <CardContent style={{ minHeight: '180px' }}>
                <Typography gutterBottom component={'div'}>
                  <Chip label="Updates" size="small" />
                </Typography>
                <Typography gutterBottom variant="h6">
                  {t('updateOverview.updateOverview')}
                </Typography>
                <Typography variant="body2">{t('updateOverview.description')}</Typography>
              </CardContent>
              <CardActions>
                <Link href={t('updateOverview.link')} target="_blank">
                  <Button variant="contained" color={'primary'} startIcon={<OpenInNew />}>
                    {t('actions.download')}
                  </Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card style={{ height: '100%', boxShadow: 'none', border: '1px solid lightgrey' }}>
              <CardContent style={{ minHeight: '180px' }}>
                <Typography gutterBottom component="div">
                  <Chip label="Video" size="small" />
                </Typography>
                <Typography gutterBottom variant="h6">
                  {t('dashboard.howTo')}
                </Typography>
                <Typography variant="body2">{t('dashboard.videoDescription')}</Typography>
              </CardContent>
              <CardActions>
                <Link href={t('dashboard.youtubeLink')} target="_blank">
                  <Button variant="contained" color={'primary'} startIcon={<OpenInNew />}>
                    {t('dashboard.openYoutube')}
                  </Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card style={{ height: '100%', boxShadow: 'none', border: '1px solid lightgrey' }}>
              <CardContent style={{ minHeight: '180px' }}>
                <Typography gutterBottom component={'div'}>
                  <Chip label="Contact" size="small" />
                </Typography>
                <Typography gutterBottom variant="h6">
                  {t('dashboard.contactUs')}
                </Typography>
                <Typography variant="body2" component={'div'}>
                  <div style={{ whiteSpace: 'pre-wrap' }}>{t('dashboard.address')}</div>
                </Typography>
              </CardContent>
              <CardActions>
                <Link href={`mailto:${t('dashboard.addressEmail')}`} target="_blank">
                  <Button variant="contained" color={'primary'} startIcon={<OpenInNew />}>
                    {t('dashboard.addressEmail')}
                  </Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card style={{ height: '100%', boxShadow: 'none', border: '1px solid lightgrey' }}>
              <CardContent style={{ minHeight: '180px' }}>
                <Typography gutterBottom component={'div'}>
                  <Chip label={t('dashboard.labelProducts')} size="small" />
                </Typography>
                <Typography gutterBottom variant="h6">
                  {t('dashboard.ourProducts')}
                </Typography>
                <Typography variant="body2" component={'div'}>
                  <div style={{ whiteSpace: 'pre-wrap' }}>{t('dashboard.productsText')}</div>
                </Typography>
              </CardContent>
              <CardActions>
                <Link href={`${t('dashboard.productsLink')}`} target="_blank">
                  <Button variant="contained" color={'primary'} startIcon={<OpenInNew />}>
                    {t('dashboard.visit')}
                  </Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Dashboard
