export const DEFAULT_CO2_AMOUNT = 0.527
export const DEFAULT_CO2_UNIT = 'KG_KWH'
export const DEFAULT_ENERGY_AMOUNT = 0.12
export const DEFAULT_ENERGY_AMOUNT_CF = 0.0034
export const DEFAULT_VOLUME_UNIT = 'NM3'
export const DEFAULT_COST_UNIT = 'EURO'
export const DEFAULT_CUBIC_METER_HOUR = 'NM3_HOUR'
// export const DEFAULT_ENERGY_UNIT = KWH_CUBICM
export const DEFAULT_COST_TIME = 8760
export const LtrMinCfmFactor = 28.31
export const LtrMinCubicMeterHourFactor = 0.06
export const CfmCubicMeterHourFactor = 1.69901082
export const BarPsiFactor = 14.5038
export const MeterInchFactor = 39.3701
export const KwhM3ToKwhCfFactor = 0.0283168466
export const KgKwhToLbsKwhFactor = 2.20462262
export const COST_BASE_SCF_TO_NM3 = 35.311
export const kWhPerNM3ToKWPer100cfm = 169.896
export const kgToLbs = 2.20462
export const internalPrecision = 6
export const viewPrecision = 3
export const viewLowPrecision = 2

export const calcAmount_DEFAULT_PRECISION = 3
export const calcAmount_DEFAULT_TOFIXED = 3
export const calcCo2_DEFAULT_PRECISION = 3
export const calcCo2_DEFAULT_TOFIXED = 3
export const costTime_DEFAULT_PRECISION = 0
export const costTime_DEFAULT_TOFIXED = 0
export const totalCosts_DEFAULT_PRECISION = 2
export const totalCosts_DEFAULT_TOFIXED = 2
export const calcRelative_DEFAULT_PRECISION = 1
export const calcRelative_DEFAULT_TOFIXED = 1
export const calcEnergyUsagePerYear_DEFAULT_TOFIXED = 3
export const calcEnergyUsagePerYear_DEFAULT_PRECISION = 3
export const convertProjectSpecificPower_DEFAULT_TOFIXED = 3
export const convertProjectSpecificPower_DEFAULT_PRECISION = 3
export const getCostBase_DEFAULT_TOFIXED = 3
export const getCostBase_DEFAULT_PRECISION = 3
export const calcEnergyAmount_DEFAULT_TOFIXED = 6
export const calcEnergyAmount_DEFAULT_PRECISION = 6
export const getCo2Amount_DEFAULT_TOFIXED = 3
export const getCo2Amount_DEFAULT_PRECISION = 3
export const calculateEnergyCosts_DEFAULT_TOFIXED = 3
export const calculateEnergyCosts_DEFAULT_PRECISION = 3

export const ISO_STANDARD = 'de-DE'
