type ExportNotification = {
  exportRecommended: boolean
  exportWasDone: () => void
}

export const useExportNotification = (): ExportNotification => {
  return {
    exportRecommended: false,
    exportWasDone: () => {
      console.error('function only available for electron')
    },
  }
}
