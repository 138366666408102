import { Building, Leakage, Project } from '../../../interfaces/BusinessObjects'
import { PersistedLeakage } from '../types/types'
import {
  createObject,
  deleteObjectById,
  deleteObjects,
  finalDeleteObjectById,
  finalDeleteTrashObjects,
  getDatastore,
  getDeletedObjects,
  getObjectById,
  getObjects,
  getObjectsByQuery,
  restoreObject,
  updateObjectById,
} from './shared/shared'
import { repositoryNames } from './repositoryNames'
import { createBuilding, getBuildings, getBuildingsByCompany } from './buildingRepository'
import { createProject, getProjectById } from './projectRepository'
import { v4 as uuidv4 } from 'uuid'
import { isUniqueString } from '../../../app/helpers/utils'
import { logToSentry } from '../../../app/helpers/sentryUtils'
import { copyImage, deleteImage } from '../helpers/imageHelper'

export const getLeakages = (): Promise<PersistedLeakage[]> => {
  logToSentry({
    message: 'DEBUG_V1_getLeakages',
    type: 'DEBUG_V1_getLeakages',
    value: 'DEBUG_V1_getLeakages',
    data: {},
    isRenderedByElectron: true,
  })
  return getObjects(getDatastore(repositoryNames.LEAKAGE))
}

export const deleteLeakages = (): Promise<number> => {
  return deleteObjects(getDatastore(repositoryNames.LEAKAGE))
}

export const getLeakageById = (id: string): Promise<PersistedLeakage> => {
  return getObjectById(getDatastore(repositoryNames.LEAKAGE), id)
}

export const createLeakage = (leakage: Leakage): Promise<PersistedLeakage> => {
  return createObject(getDatastore(repositoryNames.LEAKAGE), leakage)
}

export const updateLeakageById = (id: string, leakage: Leakage): Promise<PersistedLeakage> => {
  return updateObjectById(getDatastore(repositoryNames.LEAKAGE), id, leakage)
}

export const finalDeleteLeakageById = async (id: string): Promise<number> => {
  const leakage = await getLeakageById(id)
  if (leakage.images && leakage.images.length >= 1) {
    for (let i = 0; i < leakage.images.length; i++) {
      const imageObject = leakage.images[i]
      deleteImage(imageObject.src)
    }
  }
  return finalDeleteObjectById(getDatastore(repositoryNames.LEAKAGE), id)
}

export const deleteLeakage = (id: string): Promise<number> => {
  return deleteObjectById(getDatastore(repositoryNames.LEAKAGE), id)
}

export const getDeletedLeakages = (): Promise<PersistedLeakage[]> => {
  return getDeletedObjects(getDatastore(repositoryNames.LEAKAGE))
}

export const getLeakagesByProject = (projectId: string): Promise<PersistedLeakage[]> => {
  return getObjectsByQuery(getDatastore(repositoryNames.LEAKAGE), { projectId, _deleted_at: { $exists: false } })
}

export const finalDeleteTrashLeakages = (): Promise<number> => {
  return finalDeleteTrashObjects(getDatastore(repositoryNames.LEAKAGE))
}

export const restoreLeakage = (id: string): Promise<number> => {
  return restoreObject(getDatastore(repositoryNames.LEAKAGE), id)
}

// Moves leakages to a different project/building as specified by params.
// <code>copy</code> to copy leakages, i.e. new objects will be created and original leakages remain as they were.
export const moveLeakages = async (
  leakageIds: string[],
  targetProjectId: string | undefined,
  targetBuildingId: string | undefined,
  companyName: string,
  copy: boolean,
): Promise<string | undefined> => {
  // load leakages
  const leakages = (await getLeakages()).filter((l) => leakageIds.includes(l._id ?? ''))

  const buildingsByCompany = await getBuildingsByCompany(companyName)
  const buildingsByCompanyNameIdMap: { [k: string]: string } = {}
  buildingsByCompany.forEach((b) => {
    buildingsByCompanyNameIdMap[b.name || ''] = b.id || ''
  })

  // define buildings if no target available (copy buildings to new company)
  const buildingIdMappingObjects: { [currId: string]: string | undefined } = {}
  if (!targetBuildingId) {
    const buildingIds = leakages.map(({ buildingId }) => buildingId).filter(isUniqueString)
    const requiredBuildings = (await getBuildings()).filter(({ id }) => buildingIds.includes(id))

    requiredBuildings.forEach((building) => {
      if (buildingIdMappingObjects[building.id || '']) {
        return
      }

      if (building.company === companyName) {
        buildingIdMappingObjects[building.id || ''] = building.id
        return
      }

      const buildingIdOfMoveToCompanyName = buildingsByCompanyNameIdMap[building.name || '']
      if (buildingIdOfMoveToCompanyName) {
        buildingIdMappingObjects[building.id || ''] = buildingIdOfMoveToCompanyName
      }
    })

    const buildingsToCopy = requiredBuildings
      .filter((building) => !buildingIdMappingObjects[building.id || ''])
      .map((building): Building => {
        const newBuildingId = uuidv4()
        buildingIdMappingObjects[building.id || ''] = newBuildingId
        return { ...building, company: companyName, _id: newBuildingId, id: newBuildingId }
      })
    buildingsToCopy.forEach((building) => createBuilding(building))
  }

  // define project if no target available (copy project to new company)
  let newProjectId: string | undefined = undefined
  const projectId = leakages.map(({ projectId }) => projectId).pop() // all project ids are the same
  const project = await getProjectById(projectId || '')
  if (!targetProjectId && project?.company !== companyName) {
    newProjectId = uuidv4()
    const newProjectName = `${project.name} ${copy ? 'copied' : 'moved'} from ${project.company}`
    const projectToCopy: Project = { ...project, company: companyName, _id: newProjectId, name: newProjectName }
    await createProject(projectToCopy)
  }

  const leakagesToUpdate: Leakage[] = []
  for (let l = 0; l < leakages.length; l++) {
    const leakage = leakages[l]
    const newBuildingId = buildingIdMappingObjects[leakage.buildingId || '']
    const images = []
    if (copy && leakage?.images && leakage?.images?.length >= 1) {
      for (let i = 0; i < leakage?.images.length; i++) {
        const imageObject = leakage.images[i]
        const copiedImage = await copyImage(imageObject.src)
        images.push({ ...imageObject, src: copiedImage })
      }
    }
    leakagesToUpdate.push({
      ...leakage,
      buildingId: targetBuildingId || newBuildingId || leakage.buildingId,
      projectId: targetProjectId || newProjectId || leakage.projectId,
      _id: copy ? undefined : leakage._id,
      images: copy ? images : leakage.images,
    })
  }

  const createPersistencePromises = (): Promise<PersistedLeakage>[] => {
    return leakagesToUpdate.map((l) => (copy ? createLeakage(l) : updateLeakageById(l._id ?? '', l)))
  }

  await Promise.all(createPersistencePromises())
  return targetProjectId || newProjectId || leakages[0].projectId
}
