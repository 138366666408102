import React, { FC, ReactElement } from 'react'
import { Typography } from '@material-ui/core'
import { paddingBottom } from '../../../styles/Shared'
import { useTranslation } from 'react-i18next'

type PageHeadingProps = {
  title: string
}

export const PageHeading: FC<PageHeadingProps> = React.memo(({ title }): ReactElement => {
  const { t } = useTranslation('common')

  return (
    <>
      <Typography color="secondary" style={paddingBottom} variant={'h4'}>
        {t(title)}
      </Typography>
    </>
  )
})
PageHeading.displayName = 'PageHeading'
