import {
  createObject,
  deleteObjects,
  finalDeleteObjectById,
  getDatastore,
  getObjectById,
  getObjects,
  getObjectsByQuery,
  updateObjectById,
} from './shared/shared'
import { repositoryNames } from './repositoryNames'
import { Failure, Measure, Replacement } from '../../../interfaces/BusinessObjects'

export const getMeasures = (): Promise<Measure[]> => {
  return getObjects(getDatastore(repositoryNames.MEASURE))
}

export const getMeasureById = (id: string): Promise<Measure> => {
  return getObjectById(getDatastore(repositoryNames.MEASURE), id)
}

export const createMeasure = (text: string): Promise<Measure> => {
  return createObject(getDatastore(repositoryNames.MEASURE), { text })
}

export const updateMeasure = (id: string, text: string): Promise<Measure> => {
  return updateObjectById(getDatastore(repositoryNames.MEASURE), id, { text })
}

export const finalDeleteMeasure = (id: string): Promise<number> => {
  return finalDeleteObjectById(getDatastore(repositoryNames.MEASURE), id)
}

export const getMeasureByText = (text: string): Promise<Measure[]> => {
  return getObjectsByQuery(getDatastore(repositoryNames.MEASURE), { text })
}

export const getReplacements = (): Promise<Replacement[]> => {
  return getObjects(getDatastore(repositoryNames.REPLACEMENT))
}

export const getReplacementById = (id: string): Promise<Replacement> => {
  return getObjectById(getDatastore(repositoryNames.REPLACEMENT), id)
}

export const updateReplacement = (id: string, text: string): Promise<Replacement> => {
  return updateObjectById(getDatastore(repositoryNames.REPLACEMENT), id, { text })
}

export const createReplacement = (text: string): Promise<Replacement> => {
  return createObject(getDatastore(repositoryNames.REPLACEMENT), { text })
}

export const finalDeleteReplacement = (id: string): Promise<number> => {
  return finalDeleteObjectById(getDatastore(repositoryNames.REPLACEMENT), id)
}

export const getReplacementByText = (text: string): Promise<Replacement[]> => {
  return getObjectsByQuery(getDatastore(repositoryNames.REPLACEMENT), { text })
}

export const getFailures = (): Promise<Failure[]> => {
  return getObjects(getDatastore(repositoryNames.FAILURE))
}

export const getFailureById = (id: string): Promise<Failure> => {
  return getObjectById(getDatastore(repositoryNames.FAILURE), id)
}

export const updateFailure = (id: string, text: string): Promise<Failure> => {
  return updateObjectById(getDatastore(repositoryNames.FAILURE), id, { text })
}

export const createFailure = (text: string): Promise<Failure> => {
  return createObject(getDatastore(repositoryNames.FAILURE), { text })
}

export const finalDeleteFailure = (id: string): Promise<number> => {
  return finalDeleteObjectById(getDatastore(repositoryNames.FAILURE), id)
}

export const getFailureByText = (text: string): Promise<Failure[]> => {
  return getObjectsByQuery(getDatastore(repositoryNames.FAILURE), { text })
}

export const deleteFailures = (): Promise<number> => {
  return deleteObjects(getDatastore(repositoryNames.FAILURE))
}

export const deleteReplacements = (): Promise<number> => {
  return deleteObjects(getDatastore(repositoryNames.REPLACEMENT))
}

export const deleteMeasures = (): Promise<number> => {
  return deleteObjects(getDatastore(repositoryNames.MEASURE))
}
