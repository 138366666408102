import { useHistory } from 'react-router-dom'
import { LocationDescriptor } from 'history'
import { useEffect } from 'react'

export const useRedirect = (route: LocationDescriptor | undefined, values: unknown[]): void => {
  const history = useHistory()
  useEffect(() => {
    if (route && values.length && values.filter((v) => v).length) {
      history.push(route)
    }
  }, [values, history, route])
}
