import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import constants from '../../../../constants/constants'
import { useStyles } from './DatePickerStyles'

export type DatePickerProps = {
  label: string
  onChange?: (date: Date) => void
  xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  preselect?: Date | null
}

export const DatePicker: React.FC<DatePickerProps> = ({ label, preselect, onChange, xs, md }) => {
  const { t } = useTranslation('common')
  const [selectedDate, setSelectedDate] = React.useState<Date | null | undefined>(null)
  useStyles()

  useEffect(() => {
    setSelectedDate(preselect)
  }, [preselect])

  const handleDateChange = (date: Date | null): void => {
    setSelectedDate(date)
    if (onChange && date) {
      onChange(date)
    }
  }

  return (
    <>
      <Grid item xs={xs ? xs : 6} md={md ? md : xs ? xs : 6}>
        <KeyboardDateTimePicker
          disableToolbar
          style={{ verticalAlign: 'top' }}
          value={selectedDate}
          ampm={false}
          views={['date', 'year']}
          onChange={handleDateChange}
          invalidDateMessage={t('dataImport.invalidDate')}
          format={constants.DATE.ONLY_DATE}
          label={t(label)}
          fullWidth={true}
        />
      </Grid>
    </>
  )
}
