import { useEffect, useState, useMemo } from 'react'
import { Leakage, Link, User } from '../../interfaces/BusinessObjects'
import { ReportType } from '../helpers/reportGeneration/Types'
import { useTranslation } from 'react-i18next'
import { generateReportRequest } from './hookHelpers/reportGenerationHelpers'
import { createReport } from '../dataServices/webDataService'
import { useGetAuditor, useProject } from './projectHooks'
import { useCompany } from './companyHooks'
import { useLeakagesOfProjectWithImages } from './leakageHooks'
import { useBuildings } from './buildingHooks'

import { getCurrentDateAsFileString } from 'app/helpers/dates'
import { useGroupAbsolutePath } from './groupHooks'

type ReportInfo = {
  reportContent?: Buffer
  reportDone: boolean
  reportError: boolean
  reportLoading: boolean
  link?: Link
}

export const useReport = (
  projectId: string,
  companyId: string,
  groupId?: string,
): {
  reportInfo: ReportInfo
  generateReport(
    reportType: ReportType,
    leakages?: Leakage[],
    sortedIds?: string[],
    selectedIds?: string[],
  ): Promise<void>
  startReport: () => void
} => {
  const [reportInfo, setReportInfo] = useState<ReportInfo>({
    reportDone: false,
    reportError: false,
    reportLoading: false,
  })
  //const [user, SetUser] = useState<User>({})
  const { buildings } = useBuildings(companyId, groupId)
  const groupPath = useGroupAbsolutePath(!groupId ? '' : groupId)

  const { project } = useProject(projectId)
  const { auditorInfo } = useGetAuditor(project.Auditor?.id as string)
  //const { leakages: leakagesOfProject } = { leakages } //useLeakagesOfProject(projectId)
  const { company } = useCompany(companyId, groupId)
  const { leakages: leakagesOfProject } = useLeakagesOfProjectWithImages(projectId)
  const { t } = useTranslation('common')
  const [trigger, setTrigger] = useState<boolean>(false)

  const user: User = useMemo(() => {
    if (auditorInfo?.id) {
      return {
        prename: auditorInfo?.first_name ?? '',
        email: auditorInfo?.email ?? '',
        surname: auditorInfo?.last_name ?? '',
        street: auditorInfo?.street ?? '',
        city: auditorInfo?.city ?? '',
        image: auditorInfo?.image ?? '',
        zip: auditorInfo?.zip ?? '',
        role: auditorInfo?.role ?? '',
        phone: auditorInfo?.phone ?? '',
        company: auditorInfo?.company ?? '',
      }
    }
    return {}
  }, [auditorInfo])

  const startReport = () => {
    setTrigger(true)
  }

  const generateReport = async (
    reportType: ReportType,
    leakages?: Leakage[],
    sortedIds?: string[],
    selectedIds?: string[],
  ): Promise<void> => {
    setReportInfo({
      reportDone: false,
      reportError: false,
      reportLoading: true,
    })

    let reportLeakages: Leakage[] = leakages || leakagesOfProject
    reportLeakages.sort((l1, l2) => Number(l1.leakTag) - Number(l2.leakTag))

    if (sortedIds && sortedIds?.length > 0) {
      reportLeakages = sortedIds
        .map((id) => reportLeakages.find((l) => l._id === id) as Leakage)
        .filter((l) => l._id && l._id)
    }
    if (selectedIds && selectedIds.length > 0) {
      reportLeakages = reportLeakages.filter((l) => l._id && selectedIds.includes(l._id))
    }

    const companyPath = groupId && groupPath.length ? groupPath.map((p) => p.title).join('/') : undefined
    const request = generateReportRequest(project, reportLeakages, buildings, company, user, reportType, t, companyPath)

    const filename = `${project && project.name ? project.name : ''} ${getCurrentDateAsFileString()}.${reportType}`

    const reportFile = await createReport(request)

    if (reportFile) {
      const fileLink: Link = {
        extension: reportType === 'xlsx' ? '.xlsx' : '.pdf',
        description: '',
        filename,
        mimeType:
          reportType === 'xlsx'
            ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            : 'application/pdf',
        url: reportFile,
      }
      setReportInfo({
        link: fileLink,
        reportDone: true,
        reportError: false,
        reportLoading: false,
        reportContent: Buffer.from(reportFile, 'base64'),
      })
    } else {
      setReportInfo({ reportDone: false, reportError: true, reportLoading: false })
    }
  }

  useEffect(() => {
    if (trigger && leakagesOfProject.length) {
      generateReport('pdf', leakagesOfProject).catch((e) => console.log(e))
      setTrigger(false)
    }
  }, [project, leakagesOfProject, company, user, trigger])

  return { reportInfo, generateReport, startReport }
}
