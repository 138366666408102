import { ApolloClient, DefaultOptions, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { environment } from './environment'
import { setContext } from '@apollo/client/link/context'
import { getTokens } from './keycloakService'

export function getApolloClient(): ApolloClient<NormalizedCacheObject> {
  const link = createHttpLink({
    uri: `${environment.API.GQL_HTTP_URI}`,
  })
  const cache = new InMemoryCache()
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const [token] = getTokens()
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })
  return new ApolloClient({
    link: authLink.concat(link),
    cache,
    defaultOptions,
  })
}
