const checkForUndefinedVar = (variable: string | undefined): string | undefined => {
  if (variable && variable === 'undefined') {
    return undefined
  }
  return variable
}

interface API {
  GQL_HTTP_URI: string
  GQL_WS_URI: string
  GQL_SCHEMA_URI: string
  DATA_HTTP_URI: string
  REACT_APP_LOGIN_URL: string
  USER_PROFILE_FRONTEND: string
  USER_SERVICE_URL: string
  IAM_SERVICE_URL: string
  ORG_SERVICE_API: string
}

export interface Environment {
  APP_NAME: string
  APP_VERSION: string
  STAGE: string
  WEB_URL: string
  API: API
  USER_PREFERENCE_API: string
}

export const environment: Environment = {
  APP_NAME: process.env.REACT_APP_NAME || '',
  APP_VERSION: process.env.REACT_APP_VERSION || '',
  USER_PREFERENCE_API: process.env.REACT_APP_USER_PREFERENCE_API || '',
  STAGE: checkForUndefinedVar(process.env.REACT_APP_STAGE) || 'local',
  WEB_URL: checkForUndefinedVar(process.env.REACT_APP_WEB_URL) || '',
  API: {
    GQL_HTTP_URI: checkForUndefinedVar(process.env.REACT_APP_API_GQL_HTTP_URI) || '',
    DATA_HTTP_URI: checkForUndefinedVar(process.env.REACT_APP_API_DATA_HTTP_URI) || '',
    GQL_WS_URI: checkForUndefinedVar(process.env.REACT_APP_API_GQL_WS_URI) || '',
    GQL_SCHEMA_URI: checkForUndefinedVar(process.env.REACT_APP_API_GQL_SCHEMA_URI) || '',
    REACT_APP_LOGIN_URL: checkForUndefinedVar(process.env.REACT_APP_LOGIN_URL) || '',
    USER_PROFILE_FRONTEND: checkForUndefinedVar(process.env.REACT_APP_USER_PROFILE_FRONTEND) || '',
    USER_SERVICE_URL: checkForUndefinedVar(process.env.REACT_APP_USER_SERVICE) || '',
    IAM_SERVICE_URL: checkForUndefinedVar(process.env.REACT_APP_IAM_SERVICE) || '',
    ORG_SERVICE_API: checkForUndefinedVar(process.env.REACT_APP_ORG_SERVICE_API) || '',
  },
}
