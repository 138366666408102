import React from 'react'
import { paddingTopBottom } from '../../../../styles/Shared'
import Grid from '@material-ui/core/Grid'
import { Tab, Tabs } from '@material-ui/core'
import { Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BottomShadow } from '../BottomShadow/BottomShadow'

type TabbedPageProps = {
  chosenTab: number
  tabChanged: (v: number) => void
  labels: string[]
  children: React.ReactNode
}

export const TabbedPage: React.FC<TabbedPageProps> = ({ chosenTab, tabChanged, labels, children }) => {
  const { t } = useTranslation('common')

  return (
    <div style={paddingTopBottom}>
      <Tabs value={chosenTab} onChange={(x, i) => tabChanged(i)} indicatorColor="primary" textColor="primary">
        {labels.map((label) => (
          <Tab label={t(label)} key={label} />
        ))}
      </Tabs>
      <BottomShadow />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Switch>{children}</Switch>
        </Grid>
      </Grid>
    </div>
  )
}
