import React, { FC, ReactElement, useEffect, useState } from 'react'
import { useCreateOrUpdateUser, useUser } from '../../../hooks/userHooks'
import { User } from '../../../../interfaces/BusinessObjects'

export type UserAccessObject = {
  user: User
  createOrUpdateUser: (user: User) => Promise<void>
  updatedUser: boolean
}

export const UserAccessContext = React.createContext<UserAccessObject>({
  user: {},
  updatedUser: false,
  createOrUpdateUser: async () => {
    console.error('createOrUpdateUser must be supplied by UserProvider')
  },
})

export const UserProvider: FC<{ children: React.ReactNode }> = ({ children }): ReactElement => {
  const [user, setUser] = useState<User>({})
  const [updatedUser, setUpdatedUser] = useState(false)

  const fetchUserObject = useUser()
  const { createOrUpdateUser } = useCreateOrUpdateUser()

  useEffect(() => {
    if (!Object.keys(user).length && !fetchUserObject.loading && fetchUserObject.user) {
      setUser(fetchUserObject.user)
    }
  }, [fetchUserObject, user])

  const modifyUser = async (updateUser: User): Promise<void> => {
    const prevUser = Object.assign({}, user)
    setUser(updateUser)
    setUpdatedUser(false)
    try {
      await createOrUpdateUser(updateUser)
      setUpdatedUser(true)
      setTimeout(() => setUpdatedUser(false), 1000)
    } catch (e) {
      setUser(prevUser)
    }
  }

  return (
    <UserAccessContext.Provider value={{ updatedUser, user, createOrUpdateUser: modifyUser }}>
      {children}
    </UserAccessContext.Provider>
  )
}
