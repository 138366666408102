import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { paddingBottom, standardContentPadding } from '../../../styles/Shared'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { PageHeading } from '../partials/PageHeading'

export const LearningProgramPage: React.FC = (): ReactElement => {
  const { t } = useTranslation('common')
  return (
    <div style={standardContentPadding}>
      <div style={paddingBottom}>
        <Grid container justifyContent="flex-start">
          <Grid item xs={4}>
            <PageHeading title={'objects.learningProgram'} />
          </Grid>
        </Grid>
        <Typography variant="body1">
          {t('learningProgram.description')}{' '}
          <>
            <a target="_blank" href={t('learningProgram.url')} rel="noreferrer">
              {t('learningProgram.url')}
            </a>
          </>
        </Typography>
      </div>
    </div>
  )
}
