import { FAILURE, LeakageDescriptionFields, MEASURE, REPLACEMENT } from '../../interfaces/BusinessObjects'
import {
  useCreateFailureMutation,
  useCreateMeasureMutation,
  useCreateReplacementMutation,
  useDeleteLeakageDescriptionMutation,
  useLeakageDescriptionFieldsQuery,
  useUpdateFailureMutation,
  useUpdateMeasureMutation,
  useUpdateReplacementMutation,
} from '../../api/models'
import { useEffect, useMemo, useState } from 'react'
import { getSelectedRealm } from 'app/helpers/manageRealms'

export type LeakageDescriptionReturn = {
  fields: LeakageDescriptionFields
  loading: boolean
  error: boolean
  refresh: () => void
}

export type LeakageDescriptionTypeValuesReturn = {
  values: string[]
  loading: boolean
  error: boolean
  refresh: () => void
}

export const useLeakageDescriptionFields = (): LeakageDescriptionReturn => {
  const { data, loading, error, refetch } = useLeakageDescriptionFieldsQuery({
    variables: { realmName: getSelectedRealm() },
    skip: !getSelectedRealm(),
  })

  const fields = useMemo(() => {
    const measures = data?.leakageDescriptionFields.measures
    const failures = data?.leakageDescriptionFields.failures
    const replacements = data?.leakageDescriptionFields.replacements

    const fields: LeakageDescriptionFields = {
      measures:
        measures
          ?.slice()
          .sort((a, b) => (a.text > b.text ? 1 : -1))
          .map((m) => {
            return { _id: m.id, text: m.text }
          }) ?? [],
      replacements:
        replacements
          ?.slice()
          .sort((a, b) => (a.text > b.text ? 1 : -1))
          .map((r) => {
            return { _id: r.id, text: r.text }
          }) ?? [],
      failures:
        failures
          ?.slice()
          .sort((a, b) => (a.text > b.text ? 1 : -1))
          .map((f) => {
            return { _id: f.id, text: f.text }
          }) ?? [],
    }
    return fields
  }, [data])

  return {
    fields,
    loading,
    error: !!error,
    refresh: refetch,
  }
}

export const useLeakageDescriptionTypeValues = (type: string): LeakageDescriptionTypeValuesReturn => {
  const { data, loading, error, refetch } = useLeakageDescriptionFieldsQuery({
    variables: { realmName: getSelectedRealm() },
    skip: !getSelectedRealm(),
  })

  const values = useMemo(() => {
    if (!data?.leakageDescriptionFields) {
      return []
    }
    switch (type) {
      case MEASURE:
        return data?.leakageDescriptionFields.measures.map((v) => v.text).filter((t) => t)
      case REPLACEMENT:
        return data?.leakageDescriptionFields.replacements.map((v) => v.text).filter((t) => t)
      case FAILURE:
        return data?.leakageDescriptionFields.failures.map((v) => v.text).filter((t) => t)
      default:
        return []
    }
  }, [data, type])

  return {
    values,
    loading,
    error: !!error,
    refresh: refetch,
  }
}

export const useDeleteLeakageDescription = (): {
  deleted: boolean
  deleteObject: (id: string) => Promise<void>
} => {
  const [deleteMutation] = useDeleteLeakageDescriptionMutation()
  const [deleted, setDeleted] = useState(false)
  const deleteObject = async (id: string): Promise<void> => {
    setDeleted(false)
    if (!id) {
      console.error('object without ID -- skipping')
      return
    }
    const result = await deleteMutation({ variables: { id, realmName: getSelectedRealm() } })
    setDeleted(!!result.data?.deleteLeakageDescription.success)
  }

  return { deleteObject, deleted }
}

export const useCreateDescriptionObject = (): {
  created: boolean
  createObject: (text: string, type: string) => Promise<void>
} => {
  const [createMeasureMut, { data: measureData }] = useCreateMeasureMutation()
  const [createFailureMut, { data: failureData }] = useCreateFailureMutation()
  const [createReplacementMut, { data: replacementData }] = useCreateReplacementMutation()
  const [createdObject, setCreatedObject] = useState(false)

  const createObject = async (text: string, type: string): Promise<void> => {
    setCreatedObject(false)
    if (!text || !type) {
      console.error('no value provided, skipping')
      return
    }

    const variables = {
      variables: {
        realmName: getSelectedRealm(),
        text: text,
      },
    }
    switch (type) {
      case MEASURE:
        await createMeasureMut(variables)
        break
      case FAILURE:
        await createFailureMut(variables)
        break
      case REPLACEMENT:
        await createReplacementMut(variables)
        break
    }
  }

  useEffect(() => {
    if (measureData && measureData.createMeasure?.id) setCreatedObject(true)
  }, [measureData])
  useEffect(() => {
    if (failureData && failureData.createFailure?.id) setCreatedObject(true)
  }, [failureData])
  useEffect(() => {
    if (replacementData && replacementData.createReplacement?.id) setCreatedObject(true)
  }, [replacementData])

  return { created: createdObject, createObject }
}

export const useUpdateLeakageDescription = (
  maintenanceType?: string,
): {
  updated: boolean
  updateObject: (id: string, text: string) => Promise<void>
} => {
  const [updated, setUpdated] = useState(false)

  const [updateFailure, updatedFailure] = useUpdateFailureMutation()
  const [updateMeasure, updatedMeasure] = useUpdateMeasureMutation()
  const [updateReplacement, updatedReplacemenet] = useUpdateReplacementMutation()

  useEffect(() => {
    if (
      updatedFailure.data?.updateFailure.success ||
      updatedReplacemenet.data?.updateReplacement.success ||
      updatedMeasure.data?.updateMeasure.success
    ) {
      setUpdated(true)
    }
  }, [updatedFailure, updatedMeasure, updatedReplacemenet])

  const updateObject = async (id: string, text: string): Promise<void> => {
    if (!id || !text) {
      console.error('---skipping update, id')
      return
    }
    const variables = {
      id: id,
      text: text,
      realmName: getSelectedRealm(),
    }
    if (!maintenanceType) {
    }
    if (maintenanceType === 'FAILURE') {
      await updateFailure({ variables })
    }
    if (maintenanceType === 'MEASURE') {
      await updateMeasure({ variables })
    }
    if (maintenanceType === 'REPLACEMENT') {
      await updateReplacement({ variables })
    }
  }

  return { updateObject, updated }
}
