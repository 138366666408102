import { Grid, Modal, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { paddingTopBottom } from 'styles/Shared'
import { useStyles } from './DatabaseResultModelStyles'

interface DatabaseResultModelProps {
  open: boolean
  textHeading: string
  dataBaseActionStatus: boolean
  uploadStatus?: number
}

export const DatabaseResultModel: React.FC<DatabaseResultModelProps> = ({
  open,
  textHeading,
  dataBaseActionStatus,
  uploadStatus,
}: DatabaseResultModelProps) => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  return (
    <>
      <Modal className={classes.modal} open={open} disableEscapeKeyDown={dataBaseActionStatus}>
        <div className={classes.paper} style={{ marginBottom: '10em', paddingBottom: '10em' }}>
          <Typography variant={'h4'} gutterBottom align="center">
            {textHeading}
          </Typography>
          <Grid container spacing={2} style={paddingTopBottom}>
            {uploadStatus ? (
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom align="center">
                  [ {uploadStatus}% {t('modals.uploadStatus')} ]
                </Typography>
              </Grid>
            ) : (
              <></>
            )}

            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom align="center">
                {t('modals.databaseActionInfo')}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </>
  )
}
