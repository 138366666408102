import { Project } from '../../../interfaces/BusinessObjects'
import { PersistedProject } from '../types/types'
import {
  createObject,
  deleteObjectById,
  deleteObjects,
  finalDeleteObjectById,
  finalDeleteTrashObjects,
  getDatastore,
  getDeletedObjects,
  getObjectById,
  getObjects,
  getObjectsByQuery,
  restoreObject,
  updateObjectById,
} from './shared/shared'
import { repositoryNames } from './repositoryNames'
import { finalDeleteLeakageById, getLeakagesByProject } from './leakageRepository'

export const getProjects = (): Promise<PersistedProject[]> => {
  return getObjects(getDatastore(repositoryNames.PROJECT))
}

export const deleteProjects = (): Promise<number> => {
  return deleteObjects(getDatastore(repositoryNames.PROJECT))
}

export const getProjectById = (id: string): Promise<PersistedProject> => {
  return getObjectById(getDatastore(repositoryNames.PROJECT), id)
}

export const updateProjectById = (project: Project): Promise<PersistedProject> => {
  return updateObjectById(getDatastore(repositoryNames.PROJECT), project._id ?? '', project)
}

export const getProjectsByCompany = (company: string): Promise<PersistedProject[]> => {
  return getObjectsByQuery(getDatastore(repositoryNames.PROJECT), { company, _deleted_at: { $exists: false } })
}

export const createProject = (project: Project): Promise<PersistedProject> => {
  return createObject(getDatastore(repositoryNames.PROJECT), project)
}

export const deleteProject = (id: string): Promise<number> => {
  return deleteObjectById(getDatastore(repositoryNames.PROJECT), id)
}

export const getDeletedProjects = (): Promise<PersistedProject[]> => {
  return getDeletedObjects(getDatastore(repositoryNames.PROJECT))
}

export const finalDeleteProject = async (id: string): Promise<number> => {
  const leakages = await getLeakagesByProject(id)
  if (leakages.length >= 1) {
    for (let l = 0; l < leakages.length; l++) {
      const leakage = leakages[l]
      await finalDeleteLeakageById(leakage._id || '')
    }
  }
  return finalDeleteObjectById(getDatastore(repositoryNames.PROJECT), id)
}

export const restoreProject = (id: string): Promise<number> => {
  return restoreObject(getDatastore(repositoryNames.PROJECT), id)
}

export const finalDeleteTrashProjects = (): Promise<number> => {
  return finalDeleteTrashObjects(getDatastore(repositoryNames.PROJECT))
}
