import {
  AttributeInput,
  AttributeType,
  TagAttribute,
  TagInput,
  useCreateTagAttributeMutation,
  useGetRootContentQuery,
  useGetTagAttributesQuery,
  useUpdateTagAttributeMutation,
} from 'api/models'
import { useStyles } from '../MoveLeakageModal/MoveLeakageModalStyle'

import { getSelectedRealm } from 'app/helpers/manageRealms'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Modal, TextField, Typography } from '@material-ui/core'
import { CsButtonText, CsButtonPrimary } from '@csinstruments/cs-react-theme'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import { Text } from '../../partials/Inputs/Text'
import { DisplayCompany } from 'interfaces/BusinessObjects'
import { ResultAlert } from '../ResultAlert'

type Props = {
  openModal: boolean
  closeModal: () => void
  selectedTagAtt: (companyIDs: TagAttribute[]) => void
  companyIDs: string[]
  currentCompanies: DisplayCompany[]
  textInfo: string
  currentTagAttributes: TagAttribute[]
  enableCreate?: boolean
  isEditMode?: boolean
  refreshTags?: () => void
}
export const CreateUpdateTagAttribute: React.FC<Props> = ({
  openModal,
  closeModal,
  selectedTagAtt,
  companyIDs,
  currentCompanies,
  currentTagAttributes,
  enableCreate,
  textInfo,
  isEditMode,
  refreshTags,
}: Props) => {
  const classes = useStyles()
  const { t } = useTranslation('common')

  const [tagAttributes, setTagAttributes] = useState<TagAttribute[]>([])
  const [tagAttrbInp, setTagAttrbInp] = useState<TagInput>({ tagName: '', attributes: [] })
  const [selectedTagID, setSelectedTagID] = useState<string>()
  const [isError, setIsError] = useState<boolean>()

  // const { data: tagAttribs, refetch } = useGetTagAttributesQuery({
  //   variables: {
  //     realmName: getSelectedRealm(),
  //   },
  //   skip: !getSelectedRealm(),
  // })
  // const { data } = useGetRootContentQuery({
  //   variables: { realmName: getSelectedRealm() },
  //   skip: !getSelectedRealm(),
  // })
  const [createTagAttrFunc] = useCreateTagAttributeMutation()
  const [updateTagAttrFunc] = useUpdateTagAttributeMutation()

  useEffect(() => {
    if (companyIDs.length) {
      const attribInpt: AttributeInput[] = companyIDs.map((c) => ({
        attributeID: c,
        attributeType: AttributeType.CompanyAttribute,
      }))
      setTagAttrbInp((prv) => ({
        ...prv,
        attributes: attribInpt,
      }))
    }
  }, [companyIDs])

  useEffect(() => {
    if (currentTagAttributes.length && !enableCreate) {
      setTagAttributes(currentTagAttributes)
    }
  }, [currentTagAttributes, enableCreate])

  useEffect(() => {
    if (currentTagAttributes.length && isEditMode) {
      setSelectedTagID(currentTagAttributes[0].ID)
      const tagEditInp: TagInput = {
        tagName: currentTagAttributes[0].name,
        attributes: currentTagAttributes[0].attributes.map((ta) => ({
          attributeID: ta.attributeID,
          attributeType: ta.attributeType as AttributeType,
        })),
      }
      setTagAttrbInp(tagEditInp)
    }
  }, [currentTagAttributes, isEditMode])

  const allCompanies = useMemo(() => {
    if (!currentCompanies.length) return []
    return currentCompanies.map((c) => ({
      id: c?._id ?? '',
      name: c?.name ?? '',
    }))
  }, [currentCompanies])

  const submitTagAttributes = async () => {
    if (tagAttrbInp.tagName && !selectedTagID) {
      const success = await createTagAttrFunc({
        variables: {
          realmName: getSelectedRealm(),
          tagInfo: tagAttrbInp,
        },
      })
      if (success.data?.createTagAttribute) {
        selectedTagAtt([
          { ID: success.data.createTagAttribute, name: tagAttrbInp.tagName, attributes: tagAttrbInp.attributes },
        ])
        setTagAttrbInp({ tagName: '', attributes: [] })
        closeModal()
        setIsError(undefined)
      }
      if (success.errors?.length) {
        setIsError(true)
      }
    }
    if (selectedTagID && tagAttrbInp.tagName) {
      // const prevTagAttInp = allTagsData
      //   .find((tg) => tg.ID === selectedTagID)
      //   ?.attributes.map((at) => ({
      //     attributeID: at.attributeID,
      //     attributeType: AttributeType.CompanyAttribute,
      //   }))

      const updatedData = await updateTagAttrFunc({
        variables: {
          ID: selectedTagID,
          tagInfo: tagAttrbInp,
          realmName: getSelectedRealm(),
        },
      })
      if (updatedData.data?.updateTagAttribute) {
        selectedTagAtt([{ ID: selectedTagID, name: tagAttrbInp.tagName, attributes: tagAttrbInp.attributes }])
        setTagAttrbInp({ tagName: '', attributes: [] })
        isError && setIsError(undefined)
        closeModal()
      }
      if (updatedData.errors?.length) {
        setIsError(true)
      }
    }
    refreshTags && refreshTags()
  }
  const handleSelectAttributes = (cids: string[]) => {
    const attrbInps: AttributeInput[] = cids.map((cid) => ({
      attributeID: cid,
      attributeType: AttributeType.CompanyAttribute,
    }))
    setTagAttrbInp((prv) => ({ ...prv, attributes: attrbInps }))
  }
  const selectedComps = useMemo(() => {
    if (!tagAttrbInp.attributes.length) return []
    return allCompanies.filter((c) => tagAttrbInp.attributes.some((aid) => aid.attributeID === c.id))
  }, [tagAttrbInp.attributes, allCompanies])

  const newGroup = `${t('actions.add')}: `
  const filterTags = createFilterOptions<TagAttribute>()
  const currentTags = createFilterOptions<TagAttribute>()

  const handleTagCategory = (newValue: any) => {
    if (!newValue) return
    if (!newValue.ID) {
      const regex = new RegExp(`${newGroup}`, 'g')
      const value = newValue?.name?.replace(regex, '')
      setTagAttrbInp({
        tagName: value,
        attributes: tagAttrbInp.attributes,
      })
    } else {
      setSelectedTagID(newValue.ID)
      const prevTagAttInp = tagAttributes
        .find((tg) => tg.ID === newValue.ID)
        ?.attributes.map((at) => ({
          attributeID: at.attributeID,
          attributeType: AttributeType.CompanyAttribute,
        }))
      setTagAttrbInp({
        tagName: newValue.name,
        attributes: !prevTagAttInp ? [] : prevTagAttInp,
      })
    }
  }
  const onCancel = () => {
    setSelectedTagID(undefined)
    setTagAttrbInp({ tagName: '', attributes: [] })
    closeModal()
  }

  return (
    <>
      <ResultAlert alertText={'objects.groupError'} showAlert={!!isError} modifyShowAlert={setIsError} />
      <Modal open={openModal} onClose={onCancel} className={classes.modal} disableEscapeKeyDown>
        <div className={classes.paper} style={{ width: 800 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ textAlign: 'center', paddingBottom: 40 }}>
              <Typography variant={'h4'}>{t('objects.assignTo')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="left" variant="subtitle2">
                {textInfo + '. ' + t('objects.groupHelperText')}
              </Typography>
            </Grid>
            {/* <Grid item xs={12}>
              <Text
                label={t('actions.add')}
                value={tagAttrbInp.tagName}
                changeHandler={(name) => setTagAttrbInp({ tagName: name, attributes: tagAttrbInp.attributes })}
                columns={12}
              />
            </Grid> */}

            <Grid item xs={12}>
              {!isEditMode ? (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    handleTagCategory(newValue)
                  }}
                  options={tagAttributes}
                  filterOptions={(options, params) => {
                    const filtered = !currentTagAttributes.length
                      ? filterTags(options, params)
                      : currentTags(options, { ...params, ...currentTagAttributes[0] })
                    if (
                      params.inputValue !== '' &&
                      !filtered.find((item) => item?.name?.toLowerCase() === params.inputValue.toLowerCase())
                    ) {
                      filtered.push({
                        name: `${newGroup}${params.inputValue}`,
                        ID: '',
                        attributes: [],
                      })
                    }
                    return filtered
                  }}
                  getOptionLabel={(option) => option?.name}
                  style={{ marginTop: 15 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder={enableCreate ? t('objects.addNewGroup') : t('objects.groups')}
                    />
                  )}
                />
              ) : (
                <TextField
                  value={tagAttrbInp.tagName}
                  fullWidth
                  variant="standard"
                  label={t('objects.editGroupName')}
                  onChange={({ target }) =>
                    setTagAttrbInp((prv) => ({
                      ...prv,
                      tagName: target.value,
                    }))
                  }
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                onChange={(event, newValue: any) => {
                  //console.log({ newValue })
                  const newIDs = newValue.map((nv: { id: any }) => nv.id)
                  handleSelectAttributes(newIDs)
                }}
                options={allCompanies}
                multiple
                getOptionLabel={(option) => option?.name}
                value={selectedComps}
                style={{ marginTop: 15 }}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" placeholder={t('objects.company')} />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ marginTop: 40, borderTop: '1px solid lightgrey' }}>
            <Grid item xs={6}>
              <CsButtonText onClick={onCancel}>{t('modals.cancel')}</CsButtonText>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <CsButtonPrimary onClick={submitTagAttributes} disabled={!tagAttrbInp.tagName}>
                {t('actions.save')}
              </CsButtonPrimary>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </>
  )
}
