import { keycloak } from 'app/helpers/keycloakService'
import { enableLicBanner, getSelectedRealm, setSelectedRealm } from 'app/helpers/manageRealms'

import React, { ReactNode, useState } from 'react'
import { UserContext } from './UserAuthProvider'

interface CloudUserProviderProps {
  children: ReactNode
}

const CloudUserProvider = ({ children }: CloudUserProviderProps): React.ReactElement => {
  const [kcRealm, setKcRealm] = useState<string>(getSelectedRealm())

  const setSelectRealm = (realmName: string): void => {
    setKcRealm(realmName)
    setSelectedRealm(realmName)
    enableLicBanner()
  }

  return (
    <UserContext.Provider
      value={{
        isAuthenticated: keycloak.authenticated,
        keycloak: keycloak,
        selectRealm: setSelectRealm,
        globalRealmName: kcRealm,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default CloudUserProvider
