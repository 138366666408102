import { User } from '../../../interfaces/BusinessObjects'
import { createObject, getDatastore, getObjectById, updateObjectById } from './shared/shared'
import { repositoryNames } from './repositoryNames'

export const createUser = (user: User): Promise<User> => {
  return createObject(getDatastore(repositoryNames.USER), user)
}

export const getUserById = (id: string): Promise<User> => {
  return getObjectById(getDatastore(repositoryNames.USER), id)
}

export const updateUserById = (id: string, user: User): Promise<User> => {
  return updateObjectById(getDatastore(repositoryNames.USER), id, user)
}
