import { createContext, useContext } from 'react'

export enum LicenseStatusType {
  Activated = 'Activated',
  Deactivated = 'Deactivated',
}

export type LicenseStatusContextType = {
  licenseStatus: LicenseStatusType
  setLicenseStatus: (LicenseStatus: LicenseStatusType) => void
}

export const LicenseStatusContext = createContext<LicenseStatusContextType>({
  licenseStatus: LicenseStatusType.Deactivated,
  setLicenseStatus: (licenseStatus) => console.warn('no license provider', licenseStatus),
})

export const useLicenseStatus = (): LicenseStatusContextType => useContext(LicenseStatusContext)
