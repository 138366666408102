import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};


export type AbsolutePath = {
  __typename?: 'AbsolutePath';
  pathID: Scalars['String'];
  pathName: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  street?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type Attribute = {
  __typename?: 'Attribute';
  attributeID: Scalars['String'];
  attributeType?: Maybe<AttributeType>;
};

export type AttributeInput = {
  attributeID: Scalars['String'];
  attributeType: AttributeType;
};

export enum AttributeType {
  CompanyAttribute = 'companyAttribute',
  ProjectAttribute = 'projectAttribute',
  BuildingAttribute = 'buildingAttribute',
  GroupAttribute = 'groupAttribute'
}

export type Auditor = {
  __typename?: 'Auditor';
  id: Scalars['ID'];
  display_name: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  company: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  _deleted_at?: Maybe<Scalars['String']>;
  isAssigned?: Maybe<Scalars['Boolean']>;
};

export type AuditorInput = {
  id?: Maybe<Scalars['ID']>;
  display_name: Scalars['String'];
  company: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
};

export type Building = {
  __typename?: 'Building';
  id: Scalars['ID'];
  name: Scalars['String'];
  _deleted_at?: Maybe<Scalars['String']>;
  places?: Maybe<Array<Place>>;
  assigned?: Maybe<Scalars['Boolean']>;
  groupPath?: Maybe<Array<Scalars['String']>>;
  usedLeakages?: Maybe<Scalars['Int']>;
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['ID'];
  company: Scalars['String'];
  logo?: Maybe<Scalars['ID']>;
  buildings?: Maybe<Array<Building>>;
  projects?: Maybe<ProjectPage>;
  address?: Maybe<Address>;
  contact?: Maybe<Contact>;
  nProjects: Scalars['Int'];
  nBuildings: Scalars['Int'];
  _deleted_at?: Maybe<Scalars['String']>;
  canEdit?: Maybe<Scalars['Boolean']>;
  groupId?: Maybe<Scalars['String']>;
};

export type CompanyMeta = {
  __typename?: 'CompanyMeta';
  totalElements?: Maybe<Scalars['Int']>;
  rootAccess: Scalars['Boolean'];
  allowedCompanies: Array<Scalars['String']>;
  typePermissionEdit: Scalars['Boolean'];
};

export type CompanyPage = {
  __typename?: 'CompanyPage';
  _meta?: Maybe<CompanyMeta>;
  data?: Maybe<Array<Maybe<Company>>>;
};

export type CompanyType = {
  __typename?: 'CompanyType';
  id: Scalars['ID'];
  parentGroupId?: Maybe<Scalars['String']>;
  groupPath?: Maybe<Array<Scalars['String']>>;
  company: Scalars['String'];
  nProjects: Scalars['Int'];
  nBuildings: Scalars['Int'];
  canEdit?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['ID']>;
  _deleted_at?: Maybe<Scalars['String']>;
};

export type Contact = {
  __typename?: 'Contact';
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  prename?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
};


export type ExecutionResultResource = {
  __typename?: 'ExecutionResultResource';
  success?: Maybe<Scalars['Boolean']>;
};

export type Failure = {
  __typename?: 'Failure';
  id: Scalars['ID'];
  text: Scalars['String'];
};

export type GroupContent = {
  __typename?: 'GroupContent';
  groups: Array<Maybe<GroupType>>;
  companies: Array<Maybe<CompanyType>>;
  groupInfo?: Maybe<GroupType>;
};

export type GroupInput = {
  groupName: Scalars['String'];
  parentGroupId?: Maybe<Scalars['String']>;
};

export type GroupType = {
  __typename?: 'GroupType';
  id: Scalars['String'];
  parentGroupId?: Maybe<Scalars['String']>;
  groupPath?: Maybe<Array<Scalars['String']>>;
  groupName: Scalars['String'];
  canEdit?: Maybe<Scalars['Boolean']>;
  canCreateCompany?: Maybe<Scalars['Boolean']>;
  _deleted_at?: Maybe<Scalars['String']>;
};

export type Image = {
  __typename?: 'Image';
  id: Scalars['ID'];
  selected: Scalars['Boolean'];
  originalImage: Scalars['Boolean'];
  name: Scalars['String'];
  contents: Scalars['String'];
};

export type ImportBuilding = {
  name: Scalars['String'];
};

export type ImportCompany = {
  company: Scalars['String'];
  address?: Maybe<UpdateAddress>;
  contact?: Maybe<UpdateContact>;
  auditor?: Maybe<AuditorInput>;
  buildings?: Maybe<Array<ImportBuilding>>;
  projects?: Maybe<Array<ImportProject>>;
  image?: Maybe<Scalars['String']>;
};

export type ImportImage = {
  name: Scalars['String'];
  contents: Scalars['String'];
};

export type ImportLeakage = {
  building_name: Scalars['String'];
  commF?: Maybe<Scalars['String']>;
  commM?: Maybe<Scalars['String']>;
  commR?: Maybe<Scalars['String']>;
  commRup?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  costBase?: Maybe<Scalars['String']>;
  costRelevantAttributesEdited?: Maybe<Scalars['Boolean']>;
  costTime?: Maybe<Scalars['Int']>;
  costUnit?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  distance?: Maybe<Scalars['Float']>;
  gain?: Maybe<Scalars['Float']>;
  gasType?: Maybe<Scalars['String']>;
  imgName?: Maybe<Scalars['String']>;
  imgPath?: Maybe<Scalars['String']>;
  leakTag?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Float']>;
  location?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  pressure?: Maybe<Scalars['Float']>;
  resCost?: Maybe<Scalars['Float']>;
  resFlag?: Maybe<Scalars['Float']>;
  resLeakage?: Maybe<Scalars['Float']>;
  resUnitL?: Maybe<Unit>;
  sens2x?: Maybe<Scalars['Int']>;
  sensivity?: Maybe<Scalars['Int']>;
  sn?: Maybe<Scalars['Int']>;
  sw?: Maybe<Scalars['Float']>;
  threshold?: Maybe<Scalars['Int']>;
  unitD?: Maybe<Scalars['String']>;
  unitP?: Maybe<Unit>;
  unitV?: Maybe<Unit>;
  unitC?: Maybe<Unit>;
  version?: Maybe<Scalars['String']>;
  lossCosts?: Maybe<Scalars['Float']>;
  ElPr?: Maybe<Scalars['Float']>;
  SpPw?: Maybe<Scalars['Float']>;
  USpPw?: Maybe<Scalars['Float']>;
  SpPwId?: Maybe<Scalars['String']>;
  CMod?: Maybe<Scalars['String']>;
  SigE3?: Maybe<Scalars['String']>;
};

export type ImportProject = {
  costBase?: Maybe<Scalars['Float']>;
  costTime?: Maybe<Scalars['Int']>;
  costUnit?: Maybe<Scalars['String']>;
  energyAmount?: Maybe<Scalars['Float']>;
  energyUnit?: Maybe<Unit>;
  unitV?: Maybe<Unit>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  importDate?: Maybe<Scalars['String']>;
  leakages?: Maybe<Array<ImportLeakage>>;
  co2Amount?: Maybe<Scalars['Float']>;
  co2Unit?: Maybe<Unit>;
  energyCost?: Maybe<Scalars['Float']>;
  elPr?: Maybe<Scalars['Float']>;
  spPw?: Maybe<Scalars['Float']>;
  uSpPw?: Maybe<Scalars['Float']>;
  spPwId?: Maybe<Scalars['String']>;
  cMod?: Maybe<Scalars['String']>;
  sigE3?: Maybe<Scalars['String']>;
};

export type LdCoreServiceVersionInformation = {
  __typename?: 'LDCoreServiceVersionInformation';
  /** version of user ld core service */
  version: Scalars['String'];
  /** version in git + commit (git describe) */
  versionGit: Scalars['String'];
  /** timestamp of the build */
  buildTimestamp: Scalars['String'];
};

export type LdHardwareVersion = {
  __typename?: 'LDHardwareVersion';
  id: Scalars['String'];
  sn?: Maybe<Scalars['Int']>;
  sw?: Maybe<Scalars['Float']>;
  projectName?: Maybe<Scalars['String']>;
};

export type Leakage = {
  __typename?: 'Leakage';
  id: Scalars['ID'];
  commF?: Maybe<Scalars['String']>;
  commM?: Maybe<Scalars['String']>;
  commR?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  costBase?: Maybe<Scalars['String']>;
  costRelevantAttributesEdited?: Maybe<Scalars['Boolean']>;
  costTime?: Maybe<Scalars['Int']>;
  costUnit?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  distance?: Maybe<Scalars['Float']>;
  gain?: Maybe<Scalars['Float']>;
  gasType?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Image>>;
  leakTag?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Float']>;
  location?: Maybe<Scalars['String']>;
  place?: Maybe<Place>;
  pressure?: Maybe<Scalars['Float']>;
  resCost?: Maybe<Scalars['Float']>;
  resFlag?: Maybe<Scalars['Float']>;
  resLeakage?: Maybe<Scalars['Float']>;
  resUnitL?: Maybe<Unit>;
  sn?: Maybe<Scalars['Int']>;
  sw?: Maybe<Scalars['Float']>;
  threshold?: Maybe<Scalars['Int']>;
  unitD?: Maybe<Scalars['String']>;
  unitP?: Maybe<Unit>;
  unitV?: Maybe<Unit>;
  unitC?: Maybe<Unit>;
  version?: Maybe<Scalars['String']>;
  building?: Maybe<Building>;
  projectId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  priority?: Maybe<Priority>;
  resolvableUnderPressure?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  resolved?: Maybe<Scalars['Boolean']>;
  resolvedAt?: Maybe<Scalars['String']>;
  resolvedBy?: Maybe<Scalars['String']>;
  _deleted_at?: Maybe<Scalars['String']>;
  lossCosts?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  canEdit?: Maybe<Scalars['Boolean']>;
  groupPath?: Maybe<Array<Scalars['String']>>;
};

export type LeakageDescriptionFields = {
  __typename?: 'LeakageDescriptionFields';
  measures: Array<Measure>;
  failures: Array<Failure>;
  replacements: Array<Replacement>;
};

export type LeakageResult = {
  __typename?: 'LeakageResult';
  leakages: Array<Leakage>;
  pageInfo?: Maybe<PageInfo>;
};

export type Link = {
  __typename?: 'Link';
  url: Scalars['String'];
  mimeType: Scalars['String'];
  filename: Scalars['String'];
  description: Scalars['String'];
  extension: Scalars['String'];
};

export type Measure = {
  __typename?: 'Measure';
  id: Scalars['ID'];
  text: Scalars['String'];
};

export type Meta = {
  __typename?: 'Meta';
  totalElements?: Maybe<Scalars['Int']>;
};

export type MoveLeakage = {
  ids: Array<Scalars['ID']>;
  groupId?: Maybe<Scalars['String']>;
  buildingName?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['ID']>;
  companyID: Scalars['String'];
  copy: Scalars['Boolean'];
};

export type MoveLeakageResult = {
  __typename?: 'MoveLeakageResult';
  companyId: Scalars['String'];
  projectId: Scalars['String'];
  groupId?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createOrUpdateAuditor: Scalars['Boolean'];
  softDeleteAuditor: Scalars['Boolean'];
  restoreAuditor: Scalars['Boolean'];
  deleteAuditor: Scalars['Boolean'];
  createBuilding: Building;
  updateBuilding: Building;
  deleteBuilding: ExecutionResultResource;
  softDeleteBuilding: ExecutionResultResource;
  restoreBuilding: ExecutionResultResource;
  mergeBuildings: ExecutionResultResource;
  importCompanies: Array<Company>;
  createOrUpdateCompanies: Array<Company>;
  copyMoveCompanyToGroup: ExecutionResultResource;
  createOrUpdateCompany: Company;
  deleteCompany: ExecutionResultResource;
  softDeleteCompany: ExecutionResultResource;
  deleteCompanyByName: ExecutionResultResource;
  softDeleteCompanyByName: ExecutionResultResource;
  restoreCompany: ExecutionResultResource;
  restoreCompanyById: ExecutionResultResource;
  createGroup: GroupType;
  updateGroup: GroupType;
  deleteGroup: ExecutionResultResource;
  softDeleteGroup: ExecutionResultResource;
  restoreGroup: ExecutionResultResource;
  createTagAttribute?: Maybe<Scalars['ID']>;
  updateTagAttribute: Scalars['Boolean'];
  softDeleteTagAttribute: Scalars['Boolean'];
  deleteTagAttribute: Scalars['Boolean'];
  restoreTagAttribute: Scalars['Boolean'];
  createOrUpdateLeakage?: Maybe<Leakage>;
  deleteLeakage: ExecutionResultResource;
  softDeleteLeakage: ExecutionResultResource;
  softDeleteLeakages: ExecutionResultResource;
  restoreLeakage: ExecutionResultResource;
  moveLeakage?: Maybe<MoveLeakageResult>;
  createMeasure?: Maybe<Measure>;
  updateMeasure: ExecutionResultResource;
  deleteMeasure: ExecutionResultResource;
  createFailure?: Maybe<Failure>;
  updateFailure: ExecutionResultResource;
  deleteFailure: ExecutionResultResource;
  createReplacement?: Maybe<Replacement>;
  updateReplacement: ExecutionResultResource;
  deleteReplacement: ExecutionResultResource;
  deleteLeakageDescription: ExecutionResultResource;
  createPlace: Place;
  updatePlace: Place;
  softDeletePlace: ExecutionResultResource;
  restorePlace: ExecutionResultResource;
  deletePlace: ExecutionResultResource;
  mergePlaces: ExecutionResultResource;
  createOrUpdateProject?: Maybe<Project>;
  deleteProject: ExecutionResultResource;
  softDeleteProject: ExecutionResultResource;
  restoreProject: ExecutionResultResource;
};


export type MutationCreateOrUpdateAuditorArgs = {
  auditor: AuditorInput;
  realmName: Scalars['String'];
};


export type MutationSoftDeleteAuditorArgs = {
  auditor_id: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationRestoreAuditorArgs = {
  auditor_id: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationDeleteAuditorArgs = {
  auditor_id: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationCreateBuildingArgs = {
  building: UpdateBuilding;
  realmName: Scalars['String'];
};


export type MutationUpdateBuildingArgs = {
  building: UpdateBuilding;
  realmName: Scalars['String'];
};


export type MutationDeleteBuildingArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationSoftDeleteBuildingArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationRestoreBuildingArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationMergeBuildingsArgs = {
  id: Scalars['ID'];
  mergeInto: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationImportCompaniesArgs = {
  companies: Array<ImportCompany>;
  groupId?: Maybe<Scalars['String']>;
  targetCompanyName?: Maybe<Scalars['String']>;
  targetProjectId?: Maybe<Scalars['ID']>;
  targetBuildingName?: Maybe<Scalars['String']>;
  images: Array<ImportImage>;
  realmName: Scalars['String'];
};


export type MutationCreateOrUpdateCompaniesArgs = {
  companies: Array<UpdateCompany>;
  realmName: Scalars['String'];
};


export type MutationCopyMoveCompanyToGroupArgs = {
  companyIDs: Array<Scalars['String']>;
  targetGroupID: Scalars['String'];
  realmName: Scalars['String'];
  copyOnly: Scalars['Boolean'];
};


export type MutationCreateOrUpdateCompanyArgs = {
  company: UpdateCompany;
  realmName: Scalars['String'];
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationSoftDeleteCompanyArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationDeleteCompanyByNameArgs = {
  name: Scalars['String'];
  groupId?: Maybe<Scalars['String']>;
  realmName: Scalars['String'];
};


export type MutationSoftDeleteCompanyByNameArgs = {
  name: Scalars['String'];
  groupId?: Maybe<Scalars['String']>;
  realmName: Scalars['String'];
};


export type MutationRestoreCompanyArgs = {
  groupId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationRestoreCompanyByIdArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationCreateGroupArgs = {
  group: GroupInput;
  realmName: Scalars['String'];
};


export type MutationUpdateGroupArgs = {
  groupId: Scalars['String'];
  group: GroupInput;
  realmName: Scalars['String'];
};


export type MutationDeleteGroupArgs = {
  groupId: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationSoftDeleteGroupArgs = {
  groupId: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationRestoreGroupArgs = {
  groupId: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationCreateTagAttributeArgs = {
  tagInfo: TagInput;
  realmName: Scalars['String'];
};


export type MutationUpdateTagAttributeArgs = {
  Id: Scalars['ID'];
  tagInfo: TagInput;
  realmName: Scalars['String'];
};


export type MutationSoftDeleteTagAttributeArgs = {
  Id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationDeleteTagAttributeArgs = {
  Id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationRestoreTagAttributeArgs = {
  Id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationCreateOrUpdateLeakageArgs = {
  leakage: UpdateLeakage;
  realmName: Scalars['String'];
};


export type MutationDeleteLeakageArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationSoftDeleteLeakageArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationSoftDeleteLeakagesArgs = {
  ids: Array<Scalars['ID']>;
  realmName: Scalars['String'];
};


export type MutationRestoreLeakageArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationMoveLeakageArgs = {
  params: MoveLeakage;
  realmName: Scalars['String'];
};


export type MutationCreateMeasureArgs = {
  text: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationUpdateMeasureArgs = {
  id: Scalars['ID'];
  text: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationDeleteMeasureArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationCreateFailureArgs = {
  text: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationUpdateFailureArgs = {
  id: Scalars['ID'];
  text: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationDeleteFailureArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationCreateReplacementArgs = {
  text: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationUpdateReplacementArgs = {
  id: Scalars['ID'];
  text: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationDeleteReplacementArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationDeleteLeakageDescriptionArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationCreatePlaceArgs = {
  placeInput: PlaceInput;
  realmName: Scalars['String'];
};


export type MutationUpdatePlaceArgs = {
  placeInput: PlaceInput;
  realmName: Scalars['String'];
  placeID: Scalars['ID'];
};


export type MutationSoftDeletePlaceArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationRestorePlaceArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationDeletePlaceArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationMergePlacesArgs = {
  sourcePlaceId: Scalars['ID'];
  targetBuildingId: Scalars['ID'];
  targetPlaceId: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationCreateOrUpdateProjectArgs = {
  project: UpdateProject;
  realmName: Scalars['String'];
};


export type MutationDeleteProjectArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationSoftDeleteProjectArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type MutationRestoreProjectArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  offset: Scalars['Int'];
  count: Scalars['Int'];
  size: Scalars['Int'];
};

export type PageableParam = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};

export type Permissions = {
  __typename?: 'Permissions';
  editBuildings: Scalars['Boolean'];
  addBuildings: Scalars['Boolean'];
  editProjects: Scalars['Boolean'];
  addProjects: Scalars['Boolean'];
  editCompanies: Scalars['Boolean'];
  addCompanies: Scalars['Boolean'];
  importProjects: Scalars['Boolean'];
  exportManagement: Scalars['Boolean'];
  histogram: Scalars['Boolean'];
  database: Scalars['Boolean'];
  project: Scalars['Boolean'];
  company: Scalars['Boolean'];
  Leakage: Scalars['Boolean'];
  documentationRepair: Scalars['Boolean'];
  rootAdmin: Scalars['Boolean'];
  manageGroups: Scalars['Boolean'];
};

export type Place = {
  __typename?: 'Place';
  id: Scalars['ID'];
  name: Scalars['String'];
  building_id: Scalars['String'];
  _deleted_at?: Maybe<Scalars['String']>;
  assigned?: Maybe<Scalars['Boolean']>;
  usedLeakages?: Maybe<Scalars['Int']>;
};

export type PlaceInput = {
  name: Scalars['String'];
  building_id: Scalars['String'];
};

export enum Priority {
  None = 'NONE',
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW'
}

export type Project = {
  __typename?: 'Project';
  id: Scalars['ID'];
  costBase?: Maybe<Scalars['Float']>;
  costTime?: Maybe<Scalars['Int']>;
  costUnit?: Maybe<Scalars['String']>;
  energyAmount?: Maybe<Scalars['Float']>;
  energyUnit?: Maybe<Unit>;
  unitV?: Maybe<Unit>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  importDate?: Maybe<Scalars['String']>;
  Auditor?: Maybe<Auditor>;
  leakages?: Maybe<Array<Leakage>>;
  nLeakages: Scalars['Int'];
  totalLeakage: Scalars['Float'];
  totalCost: Scalars['Float'];
  _deleted_at?: Maybe<Scalars['String']>;
  co2Amount?: Maybe<Scalars['Float']>;
  co2Unit?: Maybe<Unit>;
  energyCost?: Maybe<Scalars['Float']>;
  canEdit?: Maybe<Scalars['Boolean']>;
  elPr?: Maybe<Scalars['Float']>;
  spPw?: Maybe<Scalars['Float']>;
  uSpPw?: Maybe<Scalars['Float']>;
  spPwId?: Maybe<Scalars['String']>;
  cMod?: Maybe<Scalars['String']>;
  sigE3?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  groupPath?: Maybe<Array<Scalars['String']>>;
};

export type ProjectMeta = {
  __typename?: 'ProjectMeta';
  canAdd?: Maybe<Scalars['Boolean']>;
};

export type ProjectPage = {
  __typename?: 'ProjectPage';
  projects: Array<Project>;
  meta: ProjectMeta;
};

export type Query = {
  __typename?: 'Query';
  getAuditors: Array<Maybe<Auditor>>;
  getDeletedAuditors: Array<Maybe<Auditor>>;
  getAuditor: Auditor;
  getVersion: LdCoreServiceVersionInformation;
  getPermissions: Permissions;
  /** returns LD500/510 device id and software version */
  getLDVersion?: Maybe<Array<Maybe<LdHardwareVersion>>>;
  deletedBuildings: Array<Building>;
  getBuildingById?: Maybe<Building>;
  buildings: Array<Building>;
  getCompanies: CompanyPage;
  getCompanyById?: Maybe<SingleCompanyResult>;
  getCompany?: Maybe<SingleCompanyResult>;
  deletedCompanies: Array<CompanyType>;
  getGroupId: Scalars['String'];
  getGroupsAndCompanies: GroupContent;
  getRootContent: GroupContent;
  deletedGroups: Array<Maybe<GroupType>>;
  getPathByGroupId?: Maybe<Array<AbsolutePath>>;
  getDeletedTagAttributes: Array<TagAttribute>;
  getTagAttributes: Array<TagAttribute>;
  getAllTagAttributes: Array<TagAttribute>;
  leakage?: Maybe<Leakage>;
  leakagesOfProject?: Maybe<LeakageResult>;
  leakagesOfProjectWithImages?: Maybe<LeakageResult>;
  deletedLeakages?: Maybe<LeakageResult>;
  leakageDescriptionFields: LeakageDescriptionFields;
  measure?: Maybe<Measure>;
  failure?: Maybe<Failure>;
  replacement?: Maybe<Replacement>;
  getPlaces: Array<Maybe<Place>>;
  deletedPlaces: Array<Maybe<Place>>;
  getProject?: Maybe<Project>;
  getProjectsOfCompany: ProjectPage;
  deletedProjects: Array<Project>;
};


export type QueryGetAuditorsArgs = {
  realmName: Scalars['String'];
};


export type QueryGetDeletedAuditorsArgs = {
  realmName: Scalars['String'];
};


export type QueryGetAuditorArgs = {
  auditor_id: Scalars['String'];
  realmName: Scalars['String'];
};


export type QueryGetPermissionsArgs = {
  realmName: Scalars['String'];
};


export type QueryGetLdVersionArgs = {
  realmName: Scalars['String'];
};


export type QueryDeletedBuildingsArgs = {
  realmName: Scalars['String'];
};


export type QueryGetBuildingByIdArgs = {
  buildingId: Scalars['ID'];
  realmName: Scalars['String'];
};


export type QueryBuildingsArgs = {
  companyId: Scalars['ID'];
  filterIDs?: Maybe<Array<Scalars['ID']>>;
  placesFilterIDs?: Maybe<Array<Scalars['ID']>>;
  groupId?: Maybe<Scalars['String']>;
  realmName: Scalars['String'];
};


export type QueryGetCompaniesArgs = {
  groupId?: Maybe<Scalars['String']>;
  realmName: Scalars['String'];
};


export type QueryGetCompanyByIdArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type QueryGetCompanyArgs = {
  groupId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  realmName: Scalars['String'];
};


export type QueryDeletedCompaniesArgs = {
  realmName: Scalars['String'];
};


export type QueryGetGroupIdArgs = {
  realmName: Scalars['String'];
  groupPath?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetGroupsAndCompaniesArgs = {
  realmName: Scalars['String'];
  groupId?: Maybe<Scalars['String']>;
};


export type QueryGetRootContentArgs = {
  realmName: Scalars['String'];
};


export type QueryDeletedGroupsArgs = {
  realmName: Scalars['String'];
};


export type QueryGetPathByGroupIdArgs = {
  realmName: Scalars['String'];
  groupId: Scalars['String'];
};


export type QueryGetDeletedTagAttributesArgs = {
  realmName: Scalars['String'];
};


export type QueryGetTagAttributesArgs = {
  realmName: Scalars['String'];
};


export type QueryGetAllTagAttributesArgs = {
  realmName: Scalars['String'];
};


export type QueryLeakageArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type QueryLeakagesOfProjectArgs = {
  projectId: Scalars['ID'];
  params?: Maybe<PageableParam>;
  realmName: Scalars['String'];
};


export type QueryLeakagesOfProjectWithImagesArgs = {
  projectId: Scalars['ID'];
  params?: Maybe<PageableParam>;
  realmName: Scalars['String'];
};


export type QueryDeletedLeakagesArgs = {
  params?: Maybe<PageableParam>;
  realmName: Scalars['String'];
};


export type QueryLeakageDescriptionFieldsArgs = {
  realmName: Scalars['String'];
};


export type QueryMeasureArgs = {
  id: Scalars['ID'];
};


export type QueryFailureArgs = {
  id: Scalars['ID'];
};


export type QueryReplacementArgs = {
  id: Scalars['ID'];
};


export type QueryGetPlacesArgs = {
  building_id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type QueryDeletedPlacesArgs = {
  realmName: Scalars['String'];
};


export type QueryGetProjectArgs = {
  id: Scalars['ID'];
  realmName: Scalars['String'];
};


export type QueryGetProjectsOfCompanyArgs = {
  companyId: Scalars['ID'];
  realmName: Scalars['String'];
};


export type QueryDeletedProjectsArgs = {
  realmName: Scalars['String'];
};

export type Replacement = {
  __typename?: 'Replacement';
  id: Scalars['ID'];
  text: Scalars['String'];
};

export enum Role {
  Customer = 'CUSTOMER'
}

export type SingleCompanyMeta = {
  __typename?: 'SingleCompanyMeta';
  editBuildings: Scalars['Boolean'];
  addBuildings: Scalars['Boolean'];
};

export type SingleCompanyResult = {
  __typename?: 'SingleCompanyResult';
  company: Company;
  meta?: Maybe<SingleCompanyMeta>;
};

export type TagAttribute = {
  __typename?: 'TagAttribute';
  ID: Scalars['ID'];
  name: Scalars['String'];
  attributes: Array<Attribute>;
  _deleted_at?: Maybe<Scalars['String']>;
};

export type TagInput = {
  tagName: Scalars['String'];
  attributes: Array<AttributeInput>;
};

export enum Unit {
  LtrMin = 'LTR_MIN',
  Nm3 = 'NM3',
  KhwCf = 'KHW_CF',
  Scf = 'SCF',
  Bar = 'BAR',
  M = 'M',
  Inch = 'INCH',
  Euro = 'EURO',
  Usd = 'USD',
  R = 'R',
  Psi = 'PSI',
  KwhM3 = 'KWH_M3',
  KwhCf = 'KWH_CF',
  KgKwh = 'KG_KWH',
  LbsKwh = 'LBS_KWH',
  Cf = 'CF',
  Cfm = 'CFM'
}

export type UpdateAddress = {
  street?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type UpdateBuilding = {
  name: Scalars['String'];
  company: Scalars['String'];
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type UpdateCompany = {
  groupId?: Maybe<Scalars['String']>;
  company: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  address?: Maybe<UpdateAddress>;
  contact?: Maybe<UpdateContact>;
  image?: Maybe<Scalars['String']>;
};

export type UpdateContact = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  prename?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
};

export type UpdateLeakage = {
  buildingName: Scalars['String'];
  buildingId: Scalars['ID'];
  project_id: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  commF?: Maybe<Scalars['String']>;
  commM?: Maybe<Scalars['String']>;
  commR?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  costBase?: Maybe<Scalars['String']>;
  costRelevantAttributesEdited?: Maybe<Scalars['Boolean']>;
  costTime?: Maybe<Scalars['Int']>;
  costUnit?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  distance?: Maybe<Scalars['Float']>;
  gain?: Maybe<Scalars['Float']>;
  gasType?: Maybe<Scalars['String']>;
  images?: Maybe<Array<UpdateLeakageImage>>;
  leakTag?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Float']>;
  location?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  pressure?: Maybe<Scalars['Float']>;
  resCost?: Maybe<Scalars['Float']>;
  resFlag?: Maybe<Scalars['Float']>;
  resLeakage?: Maybe<Scalars['Float']>;
  resUnitL?: Maybe<Unit>;
  sens2x?: Maybe<Scalars['Int']>;
  sensivity?: Maybe<Scalars['Int']>;
  sn?: Maybe<Scalars['Int']>;
  sw?: Maybe<Scalars['Float']>;
  threshold?: Maybe<Scalars['Int']>;
  unitD?: Maybe<Scalars['String']>;
  unitP?: Maybe<Unit>;
  unitV?: Maybe<Unit>;
  unitC?: Maybe<Unit>;
  version?: Maybe<Scalars['String']>;
  priority?: Maybe<Priority>;
  resolvableUnderPressure?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  resolved?: Maybe<Scalars['Boolean']>;
  resolvedAt?: Maybe<Scalars['String']>;
  resolvedBy?: Maybe<Scalars['String']>;
  lossCosts?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
};

export type UpdateLeakageImage = {
  fileId?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  checked: Scalars['Boolean'];
};

export type UpdateProject = {
  groupId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  AuditorName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  costBase?: Maybe<Scalars['Float']>;
  costTime?: Maybe<Scalars['Int']>;
  costUnit?: Maybe<Scalars['String']>;
  energyAmount?: Maybe<Scalars['Float']>;
  energyUnit?: Maybe<Unit>;
  unitV?: Maybe<Unit>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  importDate?: Maybe<Scalars['String']>;
  co2Amount?: Maybe<Scalars['Float']>;
  co2Unit?: Maybe<Unit>;
  energyCost?: Maybe<Scalars['Float']>;
  elPr?: Maybe<Scalars['Float']>;
  spPw?: Maybe<Scalars['Float']>;
  uSpPw?: Maybe<Scalars['Float']>;
  spPwId?: Maybe<Scalars['String']>;
  cMod?: Maybe<Scalars['String']>;
  sigE3?: Maybe<Scalars['String']>;
};

export type UpdateUser = {
  id?: Maybe<Scalars['ID']>;
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['ID']>;
  phone?: Maybe<Scalars['String']>;
  prename?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  adress?: Maybe<UpdateAddress>;
};

export type GetAuditorsQueryVariables = Exact<{
  realmName: Scalars['String'];
}>;


export type GetAuditorsQuery = (
  { __typename?: 'Query' }
  & { getAuditors: Array<Maybe<(
    { __typename?: 'Auditor' }
    & Pick<Auditor, 'id' | 'display_name' | 'first_name' | 'email' | 'company' | 'isAssigned'>
  )>> }
);

export type GetDeletedAuditorsQueryVariables = Exact<{
  realmName: Scalars['String'];
}>;


export type GetDeletedAuditorsQuery = (
  { __typename?: 'Query' }
  & { getDeletedAuditors: Array<Maybe<(
    { __typename?: 'Auditor' }
    & Pick<Auditor, 'id' | 'display_name' | 'email' | '_deleted_at'>
  )>> }
);

export type GetAuditorQueryVariables = Exact<{
  auditor_id: Scalars['String'];
  realmName: Scalars['String'];
}>;


export type GetAuditorQuery = (
  { __typename?: 'Query' }
  & { getAuditor: (
    { __typename?: 'Auditor' }
    & Pick<Auditor, 'id' | 'display_name' | 'first_name' | 'last_name' | 'company' | 'phone' | 'email' | 'role' | 'street' | 'number' | 'zip' | 'city' | 'image'>
  ) }
);

export type CreateOrUpdateAuditorMutationVariables = Exact<{
  auditor: AuditorInput;
  realmName: Scalars['String'];
}>;


export type CreateOrUpdateAuditorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createOrUpdateAuditor'>
);

export type SoftDeleteAuditorMutationVariables = Exact<{
  auditor_id: Scalars['String'];
  realmName: Scalars['String'];
}>;


export type SoftDeleteAuditorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'softDeleteAuditor'>
);

export type DeleteAuditorMutationVariables = Exact<{
  auditor_id: Scalars['String'];
  realmName: Scalars['String'];
}>;


export type DeleteAuditorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAuditor'>
);

export type RestoreAuditorMutationVariables = Exact<{
  auditor_id: Scalars['String'];
  realmName: Scalars['String'];
}>;


export type RestoreAuditorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'restoreAuditor'>
);

export type GetPermissionsQueryVariables = Exact<{
  realmName: Scalars['String'];
}>;


export type GetPermissionsQuery = (
  { __typename?: 'Query' }
  & { getPermissions: (
    { __typename?: 'Permissions' }
    & Pick<Permissions, 'editBuildings' | 'addBuildings' | 'editProjects' | 'addProjects' | 'editCompanies' | 'addCompanies' | 'importProjects' | 'exportManagement' | 'histogram' | 'database' | 'project' | 'company' | 'Leakage' | 'documentationRepair' | 'rootAdmin' | 'manageGroups'>
  ) }
);

export type CreateBuildingMutationVariables = Exact<{
  building: UpdateBuilding;
  realmName: Scalars['String'];
}>;


export type CreateBuildingMutation = (
  { __typename?: 'Mutation' }
  & { createBuilding: (
    { __typename?: 'Building' }
    & Pick<Building, 'id' | 'name'>
  ) }
);

export type UpdateBuildingMutationVariables = Exact<{
  building: UpdateBuilding;
  realmName: Scalars['String'];
}>;


export type UpdateBuildingMutation = (
  { __typename?: 'Mutation' }
  & { updateBuilding: (
    { __typename?: 'Building' }
    & Pick<Building, 'id'>
  ) }
);

export type DeleteBuildingMutationVariables = Exact<{
  id: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type DeleteBuildingMutation = (
  { __typename?: 'Mutation' }
  & { softDeleteBuilding: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type FinalDeleteBuildingMutationVariables = Exact<{
  id: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type FinalDeleteBuildingMutation = (
  { __typename?: 'Mutation' }
  & { deleteBuilding: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type RestoreBuildingMutationVariables = Exact<{
  id: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type RestoreBuildingMutation = (
  { __typename?: 'Mutation' }
  & { restoreBuilding: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type GetCompanyBuildingsQueryVariables = Exact<{
  groupId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  realmName: Scalars['String'];
}>;


export type GetCompanyBuildingsQuery = (
  { __typename?: 'Query' }
  & { getCompany?: Maybe<(
    { __typename?: 'SingleCompanyResult' }
    & { company: (
      { __typename?: 'Company' }
      & { buildings?: Maybe<Array<(
        { __typename?: 'Building' }
        & Pick<Building, 'id' | 'name' | 'assigned'>
        & { places?: Maybe<Array<(
          { __typename?: 'Place' }
          & Pick<Place, 'id'>
        )>> }
      )>> }
    ), meta?: Maybe<(
      { __typename?: 'SingleCompanyMeta' }
      & Pick<SingleCompanyMeta, 'editBuildings' | 'addBuildings'>
    )> }
  )> }
);

export type DeletedBuildingsQueryVariables = Exact<{
  realmName: Scalars['String'];
}>;


export type DeletedBuildingsQuery = (
  { __typename?: 'Query' }
  & { deletedBuildings: Array<(
    { __typename?: 'Building' }
    & Pick<Building, 'id' | 'name' | 'groupPath' | '_deleted_at'>
  )> }
);

export type MergeBuildingsMutationVariables = Exact<{
  id: Scalars['ID'];
  mergeInto: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type MergeBuildingsMutation = (
  { __typename?: 'Mutation' }
  & { mergeBuildings: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type GetBuildingByIdQueryVariables = Exact<{
  buildingId: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type GetBuildingByIdQuery = (
  { __typename?: 'Query' }
  & { getBuildingById?: Maybe<(
    { __typename?: 'Building' }
    & Pick<Building, 'id' | 'name' | 'assigned'>
    & { places?: Maybe<Array<(
      { __typename?: 'Place' }
      & Pick<Place, 'id' | 'name' | 'building_id' | 'assigned'>
    )>> }
  )> }
);

export type GetBuildingsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  filterIDs?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  placesFilterIDs?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  groupId?: Maybe<Scalars['String']>;
  realmName: Scalars['String'];
}>;


export type GetBuildingsQuery = (
  { __typename?: 'Query' }
  & { buildings: Array<(
    { __typename?: 'Building' }
    & Pick<Building, 'id' | 'name' | '_deleted_at' | 'usedLeakages'>
  )> }
);

export type SoftDeleteCompanyMutationVariables = Exact<{
  name: Scalars['String'];
  realmName: Scalars['String'];
  groupId?: Maybe<Scalars['String']>;
}>;


export type SoftDeleteCompanyMutation = (
  { __typename?: 'Mutation' }
  & { softDeleteCompanyByName: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type FinalDeleteCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type FinalDeleteCompanyMutation = (
  { __typename?: 'Mutation' }
  & { deleteCompany: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type RestoreCompanyMutationVariables = Exact<{
  name: Scalars['String'];
  realmName: Scalars['String'];
}>;


export type RestoreCompanyMutation = (
  { __typename?: 'Mutation' }
  & { restoreCompany: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type RestoreCompanyByIdMutationVariables = Exact<{
  id: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type RestoreCompanyByIdMutation = (
  { __typename?: 'Mutation' }
  & { restoreCompanyById: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type GetCompaniesQueryVariables = Exact<{
  groupId?: Maybe<Scalars['String']>;
  realmName: Scalars['String'];
}>;


export type GetCompaniesQuery = (
  { __typename?: 'Query' }
  & { getCompanies: (
    { __typename?: 'CompanyPage' }
    & { _meta?: Maybe<(
      { __typename?: 'CompanyMeta' }
      & Pick<CompanyMeta, 'totalElements' | 'allowedCompanies' | 'rootAccess' | 'typePermissionEdit'>
    )>, data?: Maybe<Array<Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'company' | 'canEdit'>
      & { buildings?: Maybe<Array<(
        { __typename?: 'Building' }
        & Pick<Building, 'name'>
      )>>, projects?: Maybe<(
        { __typename?: 'ProjectPage' }
        & { projects: Array<(
          { __typename?: 'Project' }
          & Pick<Project, 'id' | 'name' | 'canEdit'>
        )> }
      )> }
    )>>> }
  ) }
);

export type GetCompanyQueryVariables = Exact<{
  groupId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  realmName: Scalars['String'];
}>;


export type GetCompanyQuery = (
  { __typename?: 'Query' }
  & { getCompany?: Maybe<(
    { __typename?: 'SingleCompanyResult' }
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'company' | 'nBuildings' | 'nProjects' | 'logo' | 'canEdit'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'street' | 'number' | 'zip' | 'city'>
      )>, contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'email' | 'phone' | 'prename' | 'surname'>
      )>, buildings?: Maybe<Array<(
        { __typename?: 'Building' }
        & Pick<Building, 'name'>
      )>>, projects?: Maybe<(
        { __typename?: 'ProjectPage' }
        & { projects: Array<(
          { __typename?: 'Project' }
          & Pick<Project, 'id' | 'name' | 'importDate'>
        )> }
      )> }
    ), meta?: Maybe<(
      { __typename?: 'SingleCompanyMeta' }
      & Pick<SingleCompanyMeta, 'editBuildings' | 'addBuildings'>
    )> }
  )> }
);

export type DeletedCompaniesQueryVariables = Exact<{
  realmName: Scalars['String'];
}>;


export type DeletedCompaniesQuery = (
  { __typename?: 'Query' }
  & { deletedCompanies: Array<(
    { __typename?: 'CompanyType' }
    & Pick<CompanyType, 'id' | 'company' | 'groupPath' | '_deleted_at'>
  )> }
);

export type UpdateCompanyMutationVariables = Exact<{
  company: UpdateCompany;
  realmName: Scalars['String'];
}>;


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { createOrUpdateCompany: (
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
  ) }
);

export type ImportCompaniesMutationVariables = Exact<{
  companies: Array<ImportCompany> | ImportCompany;
  targetCompanyName?: Maybe<Scalars['String']>;
  targetProjectId?: Maybe<Scalars['ID']>;
  images: Array<ImportImage> | ImportImage;
  realmName: Scalars['String'];
  groupId?: Maybe<Scalars['String']>;
}>;


export type ImportCompaniesMutation = (
  { __typename?: 'Mutation' }
  & { importCompanies: Array<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'company' | 'groupId'>
    & { buildings?: Maybe<Array<(
      { __typename?: 'Building' }
      & Pick<Building, 'id' | 'name'>
    )>>, projects?: Maybe<(
      { __typename?: 'ProjectPage' }
      & { projects: Array<(
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'totalLeakage' | 'name'>
        & { leakages?: Maybe<Array<(
          { __typename?: 'Leakage' }
          & Pick<Leakage, 'id' | 'comment' | 'unitD'>
          & { building?: Maybe<(
            { __typename?: 'Building' }
            & Pick<Building, 'id'>
          )> }
        )>> }
      )> }
    )> }
  )> }
);

export type CopyMoveCompanyToGroupMutationVariables = Exact<{
  companyIDs: Array<Scalars['String']> | Scalars['String'];
  targetGroupID: Scalars['String'];
  realmName: Scalars['String'];
  copyOnly: Scalars['Boolean'];
}>;


export type CopyMoveCompanyToGroupMutation = (
  { __typename?: 'Mutation' }
  & { copyMoveCompanyToGroup: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type GetRootContentQueryVariables = Exact<{
  realmName: Scalars['String'];
}>;


export type GetRootContentQuery = (
  { __typename?: 'Query' }
  & { getRootContent: (
    { __typename?: 'GroupContent' }
    & { groups: Array<Maybe<(
      { __typename?: 'GroupType' }
      & Pick<GroupType, 'id' | 'parentGroupId' | 'groupPath' | 'groupName' | 'canEdit' | 'canCreateCompany'>
    )>>, companies: Array<Maybe<(
      { __typename?: 'CompanyType' }
      & Pick<CompanyType, 'id' | 'parentGroupId' | 'groupPath' | 'company' | 'logo' | 'nProjects' | 'nBuildings' | '_deleted_at' | 'canEdit'>
    )>> }
  ) }
);

export type DeleteGroupMutationVariables = Exact<{
  groupId: Scalars['String'];
  realmName: Scalars['String'];
}>;


export type DeleteGroupMutation = (
  { __typename?: 'Mutation' }
  & { deleteGroup: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type SoftDeleteGroupMutationVariables = Exact<{
  groupId: Scalars['String'];
  realmName: Scalars['String'];
}>;


export type SoftDeleteGroupMutation = (
  { __typename?: 'Mutation' }
  & { softDeleteGroup: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type RestoreGroupMutationVariables = Exact<{
  groupId: Scalars['String'];
  realmName: Scalars['String'];
}>;


export type RestoreGroupMutation = (
  { __typename?: 'Mutation' }
  & { restoreGroup: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type CreateGroupMutationVariables = Exact<{
  group: GroupInput;
  realmName: Scalars['String'];
}>;


export type CreateGroupMutation = (
  { __typename?: 'Mutation' }
  & { createGroup: (
    { __typename?: 'GroupType' }
    & Pick<GroupType, 'id' | 'groupName' | 'canEdit' | 'canCreateCompany'>
  ) }
);

export type UpdateGroupMutationVariables = Exact<{
  groupId: Scalars['String'];
  group: GroupInput;
  realmName: Scalars['String'];
}>;


export type UpdateGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateGroup: (
    { __typename?: 'GroupType' }
    & Pick<GroupType, 'id' | 'parentGroupId' | 'groupPath' | 'groupName' | 'canEdit' | 'canCreateCompany'>
  ) }
);

export type GetGroupsAndCompaniesQueryVariables = Exact<{
  realmName: Scalars['String'];
  groupId?: Maybe<Scalars['String']>;
}>;


export type GetGroupsAndCompaniesQuery = (
  { __typename?: 'Query' }
  & { getGroupsAndCompanies: (
    { __typename?: 'GroupContent' }
    & { groups: Array<Maybe<(
      { __typename?: 'GroupType' }
      & Pick<GroupType, 'id' | 'parentGroupId' | 'groupPath' | 'groupName' | 'canEdit' | 'canCreateCompany'>
    )>>, companies: Array<Maybe<(
      { __typename?: 'CompanyType' }
      & Pick<CompanyType, 'id' | 'parentGroupId' | 'groupPath' | 'company' | 'logo' | 'nProjects' | 'nBuildings' | '_deleted_at' | 'canEdit'>
    )>>, groupInfo?: Maybe<(
      { __typename?: 'GroupType' }
      & Pick<GroupType, 'id' | 'parentGroupId' | 'groupName' | 'canCreateCompany'>
    )> }
  ) }
);

export type GetDeletedGroupsQueryVariables = Exact<{
  realmName: Scalars['String'];
}>;


export type GetDeletedGroupsQuery = (
  { __typename?: 'Query' }
  & { deletedGroups: Array<Maybe<(
    { __typename?: 'GroupType' }
    & Pick<GroupType, 'id' | 'groupPath' | 'groupName' | '_deleted_at'>
  )>> }
);

export type GetPathByGroupIdQueryVariables = Exact<{
  realmName: Scalars['String'];
  groupId: Scalars['String'];
}>;


export type GetPathByGroupIdQuery = (
  { __typename?: 'Query' }
  & { getPathByGroupId?: Maybe<Array<(
    { __typename?: 'AbsolutePath' }
    & Pick<AbsolutePath, 'pathID' | 'pathName'>
  )>> }
);

export type LeakageDescriptionFieldsQueryVariables = Exact<{
  realmName: Scalars['String'];
}>;


export type LeakageDescriptionFieldsQuery = (
  { __typename?: 'Query' }
  & { leakageDescriptionFields: (
    { __typename?: 'LeakageDescriptionFields' }
    & { measures: Array<(
      { __typename?: 'Measure' }
      & Pick<Measure, 'id' | 'text'>
    )>, failures: Array<(
      { __typename?: 'Failure' }
      & Pick<Failure, 'id' | 'text'>
    )>, replacements: Array<(
      { __typename?: 'Replacement' }
      & Pick<Replacement, 'id' | 'text'>
    )> }
  ) }
);

export type MeasureQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MeasureQuery = (
  { __typename?: 'Query' }
  & { measure?: Maybe<(
    { __typename?: 'Measure' }
    & Pick<Measure, 'id' | 'text'>
  )> }
);

export type DeleteLeakageDescriptionMutationVariables = Exact<{
  id: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type DeleteLeakageDescriptionMutation = (
  { __typename?: 'Mutation' }
  & { deleteLeakageDescription: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type DeleteMeasureMutationVariables = Exact<{
  id: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type DeleteMeasureMutation = (
  { __typename?: 'Mutation' }
  & { deleteMeasure: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type FailureQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FailureQuery = (
  { __typename?: 'Query' }
  & { failure?: Maybe<(
    { __typename?: 'Failure' }
    & Pick<Failure, 'id' | 'text'>
  )> }
);

export type DeleteFailureMutationVariables = Exact<{
  id: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type DeleteFailureMutation = (
  { __typename?: 'Mutation' }
  & { deleteFailure: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type ReplacementQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReplacementQuery = (
  { __typename?: 'Query' }
  & { replacement?: Maybe<(
    { __typename?: 'Replacement' }
    & Pick<Replacement, 'id' | 'text'>
  )> }
);

export type DeleteReplacementMutationVariables = Exact<{
  id: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type DeleteReplacementMutation = (
  { __typename?: 'Mutation' }
  & { deleteReplacement: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type CreateMeasureMutationVariables = Exact<{
  text: Scalars['String'];
  realmName: Scalars['String'];
}>;


export type CreateMeasureMutation = (
  { __typename?: 'Mutation' }
  & { createMeasure?: Maybe<(
    { __typename?: 'Measure' }
    & Pick<Measure, 'id' | 'text'>
  )> }
);

export type CreateReplacementMutationVariables = Exact<{
  text: Scalars['String'];
  realmName: Scalars['String'];
}>;


export type CreateReplacementMutation = (
  { __typename?: 'Mutation' }
  & { createReplacement?: Maybe<(
    { __typename?: 'Replacement' }
    & Pick<Replacement, 'id' | 'text'>
  )> }
);

export type CreateFailureMutationVariables = Exact<{
  text: Scalars['String'];
  realmName: Scalars['String'];
}>;


export type CreateFailureMutation = (
  { __typename?: 'Mutation' }
  & { createFailure?: Maybe<(
    { __typename?: 'Failure' }
    & Pick<Failure, 'id' | 'text'>
  )> }
);

export type UpdateFailureMutationVariables = Exact<{
  id: Scalars['ID'];
  text: Scalars['String'];
  realmName: Scalars['String'];
}>;


export type UpdateFailureMutation = (
  { __typename?: 'Mutation' }
  & { updateFailure: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type UpdateMeasureMutationVariables = Exact<{
  id: Scalars['ID'];
  text: Scalars['String'];
  realmName: Scalars['String'];
}>;


export type UpdateMeasureMutation = (
  { __typename?: 'Mutation' }
  & { updateMeasure: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type UpdateReplacementMutationVariables = Exact<{
  id: Scalars['ID'];
  text: Scalars['String'];
  realmName: Scalars['String'];
}>;


export type UpdateReplacementMutation = (
  { __typename?: 'Mutation' }
  & { updateReplacement: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type DeleteLeakageMutationVariables = Exact<{
  id: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type DeleteLeakageMutation = (
  { __typename?: 'Mutation' }
  & { softDeleteLeakage: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type DeleteLeakagesMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type DeleteLeakagesMutation = (
  { __typename?: 'Mutation' }
  & { softDeleteLeakages: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type FinalDeleteLeakageMutationVariables = Exact<{
  id: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type FinalDeleteLeakageMutation = (
  { __typename?: 'Mutation' }
  & { deleteLeakage: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type RestoreLeakageMutationVariables = Exact<{
  id: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type RestoreLeakageMutation = (
  { __typename?: 'Mutation' }
  & { restoreLeakage: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type MoveLeakageMutationVariables = Exact<{
  params: MoveLeakage;
  realmName: Scalars['String'];
}>;


export type MoveLeakageMutation = (
  { __typename?: 'Mutation' }
  & { moveLeakage?: Maybe<(
    { __typename?: 'MoveLeakageResult' }
    & Pick<MoveLeakageResult, 'companyId' | 'projectId' | 'groupId'>
  )> }
);

export type CreateOrUpdateLeakageMutationVariables = Exact<{
  leakage: UpdateLeakage;
  realmName: Scalars['String'];
}>;


export type CreateOrUpdateLeakageMutation = (
  { __typename?: 'Mutation' }
  & { createOrUpdateLeakage?: Maybe<(
    { __typename?: 'Leakage' }
    & Pick<Leakage, 'id'>
  )> }
);

export type DeletedLeakagesQueryVariables = Exact<{
  realmName: Scalars['String'];
}>;


export type DeletedLeakagesQuery = (
  { __typename?: 'Query' }
  & { deletedLeakages?: Maybe<(
    { __typename?: 'LeakageResult' }
    & { leakages: Array<(
      { __typename?: 'Leakage' }
      & Pick<Leakage, 'id' | 'leakTag' | 'location' | '_deleted_at' | 'groupPath'>
      & { building?: Maybe<(
        { __typename?: 'Building' }
        & Pick<Building, 'name'>
      )>, place?: Maybe<(
        { __typename?: 'Place' }
        & Pick<Place, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type LeakagesOfProjectQueryVariables = Exact<{
  projectId: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type LeakagesOfProjectQuery = (
  { __typename?: 'Query' }
  & { leakagesOfProject?: Maybe<(
    { __typename?: 'LeakageResult' }
    & { leakages: Array<(
      { __typename?: 'Leakage' }
      & Pick<Leakage, 'id' | 'commF' | 'commM' | 'commR' | 'comment' | 'costBase' | 'costRelevantAttributesEdited' | 'costTime' | 'costUnit' | 'date' | 'distance' | 'gain' | 'gasType' | 'leakTag' | 'level' | 'location' | 'pressure' | 'resCost' | 'resFlag' | 'resLeakage' | 'resUnitL' | 'sn' | 'sw' | 'threshold' | 'unitD' | 'unitP' | 'unitV' | 'unitC' | 'version' | 'projectId' | 'companyId' | 'priority' | 'resolvableUnderPressure' | 'note' | 'resolved' | 'resolvedAt' | 'resolvedBy' | '_deleted_at' | 'lossCosts' | 'status' | 'canEdit'>
      & { images?: Maybe<Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'selected' | 'originalImage' | 'name' | 'contents'>
      )>>, place?: Maybe<(
        { __typename?: 'Place' }
        & Pick<Place, 'id' | 'name'>
      )>, building?: Maybe<(
        { __typename?: 'Building' }
        & Pick<Building, 'id' | 'name' | '_deleted_at'>
      )> }
    )>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'offset' | 'count' | 'size'>
    )> }
  )> }
);

export type LeakagesOfProjectWithImagesQueryVariables = Exact<{
  projectId: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type LeakagesOfProjectWithImagesQuery = (
  { __typename?: 'Query' }
  & { leakagesOfProjectWithImages?: Maybe<(
    { __typename?: 'LeakageResult' }
    & { leakages: Array<(
      { __typename?: 'Leakage' }
      & Pick<Leakage, 'id' | 'commF' | 'commM' | 'commR' | 'comment' | 'costBase' | 'costRelevantAttributesEdited' | 'costTime' | 'costUnit' | 'date' | 'distance' | 'gain' | 'gasType' | 'leakTag' | 'level' | 'location' | 'pressure' | 'resCost' | 'resFlag' | 'resLeakage' | 'resUnitL' | 'sn' | 'sw' | 'threshold' | 'unitD' | 'unitP' | 'unitV' | 'unitC' | 'version' | 'projectId' | 'companyId' | 'priority' | 'resolvableUnderPressure' | 'note' | 'resolved' | 'resolvedAt' | 'resolvedBy' | '_deleted_at' | 'lossCosts' | 'status' | 'canEdit'>
      & { images?: Maybe<Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'selected' | 'originalImage' | 'name' | 'contents'>
      )>>, place?: Maybe<(
        { __typename?: 'Place' }
        & Pick<Place, 'id' | 'name'>
      )>, building?: Maybe<(
        { __typename?: 'Building' }
        & Pick<Building, 'id' | 'name' | '_deleted_at'>
      )> }
    )>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'offset' | 'count' | 'size'>
    )> }
  )> }
);

export type GetUpdateInfoQueryVariables = Exact<{
  realmName: Scalars['String'];
}>;


export type GetUpdateInfoQuery = (
  { __typename?: 'Query' }
  & { getLDVersion?: Maybe<Array<Maybe<(
    { __typename?: 'LDHardwareVersion' }
    & Pick<LdHardwareVersion, 'id' | 'sn' | 'sw' | 'projectName'>
  )>>> }
);

export type GetLeakageByIdQueryVariables = Exact<{
  ID: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type GetLeakageByIdQuery = (
  { __typename?: 'Query' }
  & { leakage?: Maybe<(
    { __typename?: 'Leakage' }
    & Pick<Leakage, 'id' | 'commF' | 'commM' | 'commR' | 'comment' | 'costBase' | 'costRelevantAttributesEdited' | 'costTime' | 'costUnit' | 'date' | 'distance' | 'gain' | 'gasType' | 'leakTag' | 'level' | 'location' | 'pressure' | 'resCost' | 'resFlag' | 'resLeakage' | 'resUnitL' | 'sn' | 'sw' | 'threshold' | 'unitD' | 'unitP' | 'unitV' | 'unitC' | 'version' | 'projectId' | 'companyId' | 'priority' | 'resolvableUnderPressure' | 'note' | 'resolved' | 'resolvedAt' | 'resolvedBy' | '_deleted_at' | 'lossCosts' | 'status' | 'canEdit'>
    & { images?: Maybe<Array<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'selected' | 'originalImage' | 'name' | 'contents'>
    )>>, place?: Maybe<(
      { __typename?: 'Place' }
      & Pick<Place, 'id' | 'name'>
    )>, building?: Maybe<(
      { __typename?: 'Building' }
      & Pick<Building, 'id' | 'name' | '_deleted_at'>
    )> }
  )> }
);

export type GetPlacesQueryVariables = Exact<{
  buildingID: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type GetPlacesQuery = (
  { __typename?: 'Query' }
  & { getPlaces: Array<Maybe<(
    { __typename?: 'Place' }
    & Pick<Place, 'id' | 'name' | 'building_id'>
  )>> }
);

export type DeletedPlacesQueryVariables = Exact<{
  realmName: Scalars['String'];
}>;


export type DeletedPlacesQuery = (
  { __typename?: 'Query' }
  & { deletedPlaces: Array<Maybe<(
    { __typename?: 'Place' }
    & Pick<Place, 'id' | 'name' | '_deleted_at'>
  )>> }
);

export type CreatePlaceMutationVariables = Exact<{
  placeInput: PlaceInput;
  realmName: Scalars['String'];
}>;


export type CreatePlaceMutation = (
  { __typename?: 'Mutation' }
  & { createPlace: (
    { __typename?: 'Place' }
    & Pick<Place, 'id' | 'name' | 'building_id'>
  ) }
);

export type UpdatePlaceMutationVariables = Exact<{
  placeInput: PlaceInput;
  realmName: Scalars['String'];
  placeID: Scalars['ID'];
}>;


export type UpdatePlaceMutation = (
  { __typename?: 'Mutation' }
  & { updatePlace: (
    { __typename?: 'Place' }
    & Pick<Place, 'id' | 'name' | 'building_id'>
  ) }
);

export type DeletePlaceMutationVariables = Exact<{
  placeID: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type DeletePlaceMutation = (
  { __typename?: 'Mutation' }
  & { deletePlace: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type RestorePlaceMutationVariables = Exact<{
  placeID: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type RestorePlaceMutation = (
  { __typename?: 'Mutation' }
  & { restorePlace: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type SoftDeletePlaceMutationVariables = Exact<{
  placeID: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type SoftDeletePlaceMutation = (
  { __typename?: 'Mutation' }
  & { softDeletePlace: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type GetBuildingsAndPlacesQueryVariables = Exact<{
  companyId: Scalars['ID'];
  filterIDs?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  placesFilterIDs?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  groupId?: Maybe<Scalars['String']>;
  realmName: Scalars['String'];
}>;


export type GetBuildingsAndPlacesQuery = (
  { __typename?: 'Query' }
  & { buildings: Array<(
    { __typename?: 'Building' }
    & Pick<Building, 'id' | 'name' | '_deleted_at' | 'assigned' | 'usedLeakages'>
    & { places?: Maybe<Array<(
      { __typename?: 'Place' }
      & Pick<Place, 'id' | 'name' | 'building_id' | '_deleted_at' | 'assigned' | 'usedLeakages'>
    )>> }
  )> }
);

export type MergePlacesMutationVariables = Exact<{
  sourcePlaceId: Scalars['ID'];
  targetBuildingId: Scalars['ID'];
  targetPlaceId: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type MergePlacesMutation = (
  { __typename?: 'Mutation' }
  & { mergePlaces: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type GetCompanyProjectQueryVariables = Exact<{
  groupId?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  realmName: Scalars['String'];
}>;


export type GetCompanyProjectQuery = (
  { __typename?: 'Query' }
  & { getCompany?: Maybe<(
    { __typename?: 'SingleCompanyResult' }
    & { company: (
      { __typename?: 'Company' }
      & { projects?: Maybe<(
        { __typename?: 'ProjectPage' }
        & { projects: Array<(
          { __typename?: 'Project' }
          & Pick<Project, 'name' | 'id' | 'importDate' | 'totalLeakage' | 'unitV'>
          & { leakages?: Maybe<Array<(
            { __typename?: 'Leakage' }
            & Pick<Leakage, 'id' | 'date' | 'resUnitL' | 'resLeakage'>
          )>> }
        )>, meta: (
          { __typename?: 'ProjectMeta' }
          & Pick<ProjectMeta, 'canAdd'>
        ) }
      )> }
    ) }
  )> }
);

export type CreateOrUpdateProjectMutationVariables = Exact<{
  updateProject: UpdateProject;
  realmName: Scalars['String'];
}>;


export type CreateOrUpdateProjectMutation = (
  { __typename?: 'Mutation' }
  & { createOrUpdateProject?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'name' | 'id'>
  )> }
);

export type GetProjectByIdQueryVariables = Exact<{
  id: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type GetProjectByIdQuery = (
  { __typename?: 'Query' }
  & { getProject?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'costBase' | 'costTime' | 'costUnit' | 'energyAmount' | 'energyUnit' | 'unitV' | 'name' | 'company' | 'description' | 'importDate' | 'nLeakages' | 'totalLeakage' | 'totalCost' | '_deleted_at' | 'co2Amount' | 'co2Unit' | 'energyCost' | 'canEdit' | 'elPr' | 'spPw' | 'uSpPw' | 'spPwId' | 'cMod' | 'sigE3'>
    & { leakages?: Maybe<Array<(
      { __typename?: 'Leakage' }
      & Pick<Leakage, 'id'>
    )>>, Auditor?: Maybe<(
      { __typename?: 'Auditor' }
      & Pick<Auditor, 'id' | 'display_name' | 'email'>
    )> }
  )> }
);

export type DeleteProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type DeleteProjectMutation = (
  { __typename?: 'Mutation' }
  & { softDeleteProject: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type FinalDeleteProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type FinalDeleteProjectMutation = (
  { __typename?: 'Mutation' }
  & { deleteProject: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type RestoreProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type RestoreProjectMutation = (
  { __typename?: 'Mutation' }
  & { restoreProject: (
    { __typename?: 'ExecutionResultResource' }
    & Pick<ExecutionResultResource, 'success'>
  ) }
);

export type DeletedProjectsQueryVariables = Exact<{
  realmName: Scalars['String'];
}>;


export type DeletedProjectsQuery = (
  { __typename?: 'Query' }
  & { deletedProjects: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'groupPath' | '_deleted_at'>
  )> }
);

export type GetProjectAuditorsQueryVariables = Exact<{
  realmName: Scalars['String'];
}>;


export type GetProjectAuditorsQuery = (
  { __typename?: 'Query' }
  & { getAuditors: Array<Maybe<(
    { __typename?: 'Auditor' }
    & Pick<Auditor, 'id' | 'display_name'>
  )>> }
);

export type GetTagAttributesQueryVariables = Exact<{
  realmName: Scalars['String'];
}>;


export type GetTagAttributesQuery = (
  { __typename?: 'Query' }
  & { getTagAttributes: Array<(
    { __typename?: 'TagAttribute' }
    & Pick<TagAttribute, 'ID' | 'name'>
    & { attributes: Array<(
      { __typename?: 'Attribute' }
      & Pick<Attribute, 'attributeID' | 'attributeType'>
    )> }
  )> }
);

export type GetManageAbleAttributesQueryVariables = Exact<{
  realmName: Scalars['String'];
}>;


export type GetManageAbleAttributesQuery = (
  { __typename?: 'Query' }
  & { getAllTagAttributes: Array<(
    { __typename?: 'TagAttribute' }
    & Pick<TagAttribute, 'ID' | 'name'>
    & { attributes: Array<(
      { __typename?: 'Attribute' }
      & Pick<Attribute, 'attributeID' | 'attributeType'>
    )> }
  )> }
);

export type GetDeletedTagAttributesQueryVariables = Exact<{
  realmName: Scalars['String'];
}>;


export type GetDeletedTagAttributesQuery = (
  { __typename?: 'Query' }
  & { getDeletedTagAttributes: Array<(
    { __typename?: 'TagAttribute' }
    & Pick<TagAttribute, 'ID' | 'name' | '_deleted_at'>
  )> }
);

export type DeleteTagAttributeMutationVariables = Exact<{
  ID: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type DeleteTagAttributeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTagAttribute'>
);

export type RestoreTagAttributeMutationVariables = Exact<{
  ID: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type RestoreTagAttributeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'restoreTagAttribute'>
);

export type SoftDeleteTagAttributeMutationVariables = Exact<{
  ID: Scalars['ID'];
  realmName: Scalars['String'];
}>;


export type SoftDeleteTagAttributeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'softDeleteTagAttribute'>
);

export type UpdateTagAttributeMutationVariables = Exact<{
  ID: Scalars['ID'];
  tagInfo: TagInput;
  realmName: Scalars['String'];
}>;


export type UpdateTagAttributeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateTagAttribute'>
);

export type CreateTagAttributeMutationVariables = Exact<{
  tagInfo: TagInput;
  realmName: Scalars['String'];
}>;


export type CreateTagAttributeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createTagAttribute'>
);


export const GetAuditorsDocument = gql`
    query getAuditors($realmName: String!) {
  getAuditors(realmName: $realmName) {
    id
    display_name
    first_name
    email
    company
    isAssigned
  }
}
    `;

/**
 * __useGetAuditorsQuery__
 *
 * To run a query within a React component, call `useGetAuditorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuditorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuditorsQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useGetAuditorsQuery(baseOptions: Apollo.QueryHookOptions<GetAuditorsQuery, GetAuditorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuditorsQuery, GetAuditorsQueryVariables>(GetAuditorsDocument, options);
      }
export function useGetAuditorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuditorsQuery, GetAuditorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuditorsQuery, GetAuditorsQueryVariables>(GetAuditorsDocument, options);
        }
export type GetAuditorsQueryHookResult = ReturnType<typeof useGetAuditorsQuery>;
export type GetAuditorsLazyQueryHookResult = ReturnType<typeof useGetAuditorsLazyQuery>;
export type GetAuditorsQueryResult = Apollo.QueryResult<GetAuditorsQuery, GetAuditorsQueryVariables>;
export const GetDeletedAuditorsDocument = gql`
    query getDeletedAuditors($realmName: String!) {
  getDeletedAuditors(realmName: $realmName) {
    id
    display_name
    email
    _deleted_at
  }
}
    `;

/**
 * __useGetDeletedAuditorsQuery__
 *
 * To run a query within a React component, call `useGetDeletedAuditorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeletedAuditorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeletedAuditorsQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useGetDeletedAuditorsQuery(baseOptions: Apollo.QueryHookOptions<GetDeletedAuditorsQuery, GetDeletedAuditorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeletedAuditorsQuery, GetDeletedAuditorsQueryVariables>(GetDeletedAuditorsDocument, options);
      }
export function useGetDeletedAuditorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeletedAuditorsQuery, GetDeletedAuditorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeletedAuditorsQuery, GetDeletedAuditorsQueryVariables>(GetDeletedAuditorsDocument, options);
        }
export type GetDeletedAuditorsQueryHookResult = ReturnType<typeof useGetDeletedAuditorsQuery>;
export type GetDeletedAuditorsLazyQueryHookResult = ReturnType<typeof useGetDeletedAuditorsLazyQuery>;
export type GetDeletedAuditorsQueryResult = Apollo.QueryResult<GetDeletedAuditorsQuery, GetDeletedAuditorsQueryVariables>;
export const GetAuditorDocument = gql`
    query GetAuditor($auditor_id: String!, $realmName: String!) {
  getAuditor(auditor_id: $auditor_id, realmName: $realmName) {
    id
    display_name
    first_name
    last_name
    company
    phone
    email
    role
    street
    number
    zip
    city
    image
  }
}
    `;

/**
 * __useGetAuditorQuery__
 *
 * To run a query within a React component, call `useGetAuditorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuditorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuditorQuery({
 *   variables: {
 *      auditor_id: // value for 'auditor_id'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useGetAuditorQuery(baseOptions: Apollo.QueryHookOptions<GetAuditorQuery, GetAuditorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuditorQuery, GetAuditorQueryVariables>(GetAuditorDocument, options);
      }
export function useGetAuditorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuditorQuery, GetAuditorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuditorQuery, GetAuditorQueryVariables>(GetAuditorDocument, options);
        }
export type GetAuditorQueryHookResult = ReturnType<typeof useGetAuditorQuery>;
export type GetAuditorLazyQueryHookResult = ReturnType<typeof useGetAuditorLazyQuery>;
export type GetAuditorQueryResult = Apollo.QueryResult<GetAuditorQuery, GetAuditorQueryVariables>;
export const CreateOrUpdateAuditorDocument = gql`
    mutation createOrUpdateAuditor($auditor: AuditorInput!, $realmName: String!) {
  createOrUpdateAuditor(auditor: $auditor, realmName: $realmName)
}
    `;
export type CreateOrUpdateAuditorMutationFn = Apollo.MutationFunction<CreateOrUpdateAuditorMutation, CreateOrUpdateAuditorMutationVariables>;

/**
 * __useCreateOrUpdateAuditorMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateAuditorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateAuditorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateAuditorMutation, { data, loading, error }] = useCreateOrUpdateAuditorMutation({
 *   variables: {
 *      auditor: // value for 'auditor'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useCreateOrUpdateAuditorMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateAuditorMutation, CreateOrUpdateAuditorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateAuditorMutation, CreateOrUpdateAuditorMutationVariables>(CreateOrUpdateAuditorDocument, options);
      }
export type CreateOrUpdateAuditorMutationHookResult = ReturnType<typeof useCreateOrUpdateAuditorMutation>;
export type CreateOrUpdateAuditorMutationResult = Apollo.MutationResult<CreateOrUpdateAuditorMutation>;
export type CreateOrUpdateAuditorMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateAuditorMutation, CreateOrUpdateAuditorMutationVariables>;
export const SoftDeleteAuditorDocument = gql`
    mutation softDeleteAuditor($auditor_id: String!, $realmName: String!) {
  softDeleteAuditor(auditor_id: $auditor_id, realmName: $realmName)
}
    `;
export type SoftDeleteAuditorMutationFn = Apollo.MutationFunction<SoftDeleteAuditorMutation, SoftDeleteAuditorMutationVariables>;

/**
 * __useSoftDeleteAuditorMutation__
 *
 * To run a mutation, you first call `useSoftDeleteAuditorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteAuditorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteAuditorMutation, { data, loading, error }] = useSoftDeleteAuditorMutation({
 *   variables: {
 *      auditor_id: // value for 'auditor_id'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useSoftDeleteAuditorMutation(baseOptions?: Apollo.MutationHookOptions<SoftDeleteAuditorMutation, SoftDeleteAuditorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SoftDeleteAuditorMutation, SoftDeleteAuditorMutationVariables>(SoftDeleteAuditorDocument, options);
      }
export type SoftDeleteAuditorMutationHookResult = ReturnType<typeof useSoftDeleteAuditorMutation>;
export type SoftDeleteAuditorMutationResult = Apollo.MutationResult<SoftDeleteAuditorMutation>;
export type SoftDeleteAuditorMutationOptions = Apollo.BaseMutationOptions<SoftDeleteAuditorMutation, SoftDeleteAuditorMutationVariables>;
export const DeleteAuditorDocument = gql`
    mutation deleteAuditor($auditor_id: String!, $realmName: String!) {
  deleteAuditor(auditor_id: $auditor_id, realmName: $realmName)
}
    `;
export type DeleteAuditorMutationFn = Apollo.MutationFunction<DeleteAuditorMutation, DeleteAuditorMutationVariables>;

/**
 * __useDeleteAuditorMutation__
 *
 * To run a mutation, you first call `useDeleteAuditorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAuditorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAuditorMutation, { data, loading, error }] = useDeleteAuditorMutation({
 *   variables: {
 *      auditor_id: // value for 'auditor_id'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useDeleteAuditorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAuditorMutation, DeleteAuditorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAuditorMutation, DeleteAuditorMutationVariables>(DeleteAuditorDocument, options);
      }
export type DeleteAuditorMutationHookResult = ReturnType<typeof useDeleteAuditorMutation>;
export type DeleteAuditorMutationResult = Apollo.MutationResult<DeleteAuditorMutation>;
export type DeleteAuditorMutationOptions = Apollo.BaseMutationOptions<DeleteAuditorMutation, DeleteAuditorMutationVariables>;
export const RestoreAuditorDocument = gql`
    mutation restoreAuditor($auditor_id: String!, $realmName: String!) {
  restoreAuditor(auditor_id: $auditor_id, realmName: $realmName)
}
    `;
export type RestoreAuditorMutationFn = Apollo.MutationFunction<RestoreAuditorMutation, RestoreAuditorMutationVariables>;

/**
 * __useRestoreAuditorMutation__
 *
 * To run a mutation, you first call `useRestoreAuditorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreAuditorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreAuditorMutation, { data, loading, error }] = useRestoreAuditorMutation({
 *   variables: {
 *      auditor_id: // value for 'auditor_id'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useRestoreAuditorMutation(baseOptions?: Apollo.MutationHookOptions<RestoreAuditorMutation, RestoreAuditorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreAuditorMutation, RestoreAuditorMutationVariables>(RestoreAuditorDocument, options);
      }
export type RestoreAuditorMutationHookResult = ReturnType<typeof useRestoreAuditorMutation>;
export type RestoreAuditorMutationResult = Apollo.MutationResult<RestoreAuditorMutation>;
export type RestoreAuditorMutationOptions = Apollo.BaseMutationOptions<RestoreAuditorMutation, RestoreAuditorMutationVariables>;
export const GetPermissionsDocument = gql`
    query getPermissions($realmName: String!) {
  getPermissions(realmName: $realmName) {
    editBuildings
    addBuildings
    editProjects
    addProjects
    editCompanies
    addCompanies
    importProjects
    exportManagement
    histogram
    database
    project
    company
    Leakage
    documentationRepair
    rootAdmin
    manageGroups
  }
}
    `;

/**
 * __useGetPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useGetPermissionsQuery(baseOptions: Apollo.QueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, options);
      }
export function useGetPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, options);
        }
export type GetPermissionsQueryHookResult = ReturnType<typeof useGetPermissionsQuery>;
export type GetPermissionsLazyQueryHookResult = ReturnType<typeof useGetPermissionsLazyQuery>;
export type GetPermissionsQueryResult = Apollo.QueryResult<GetPermissionsQuery, GetPermissionsQueryVariables>;
export const CreateBuildingDocument = gql`
    mutation createBuilding($building: UpdateBuilding!, $realmName: String!) {
  createBuilding(building: $building, realmName: $realmName) {
    id
    name
  }
}
    `;
export type CreateBuildingMutationFn = Apollo.MutationFunction<CreateBuildingMutation, CreateBuildingMutationVariables>;

/**
 * __useCreateBuildingMutation__
 *
 * To run a mutation, you first call `useCreateBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBuildingMutation, { data, loading, error }] = useCreateBuildingMutation({
 *   variables: {
 *      building: // value for 'building'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useCreateBuildingMutation(baseOptions?: Apollo.MutationHookOptions<CreateBuildingMutation, CreateBuildingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBuildingMutation, CreateBuildingMutationVariables>(CreateBuildingDocument, options);
      }
export type CreateBuildingMutationHookResult = ReturnType<typeof useCreateBuildingMutation>;
export type CreateBuildingMutationResult = Apollo.MutationResult<CreateBuildingMutation>;
export type CreateBuildingMutationOptions = Apollo.BaseMutationOptions<CreateBuildingMutation, CreateBuildingMutationVariables>;
export const UpdateBuildingDocument = gql`
    mutation updateBuilding($building: UpdateBuilding!, $realmName: String!) {
  updateBuilding(building: $building, realmName: $realmName) {
    id
  }
}
    `;
export type UpdateBuildingMutationFn = Apollo.MutationFunction<UpdateBuildingMutation, UpdateBuildingMutationVariables>;

/**
 * __useUpdateBuildingMutation__
 *
 * To run a mutation, you first call `useUpdateBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBuildingMutation, { data, loading, error }] = useUpdateBuildingMutation({
 *   variables: {
 *      building: // value for 'building'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useUpdateBuildingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBuildingMutation, UpdateBuildingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBuildingMutation, UpdateBuildingMutationVariables>(UpdateBuildingDocument, options);
      }
export type UpdateBuildingMutationHookResult = ReturnType<typeof useUpdateBuildingMutation>;
export type UpdateBuildingMutationResult = Apollo.MutationResult<UpdateBuildingMutation>;
export type UpdateBuildingMutationOptions = Apollo.BaseMutationOptions<UpdateBuildingMutation, UpdateBuildingMutationVariables>;
export const DeleteBuildingDocument = gql`
    mutation deleteBuilding($id: ID!, $realmName: String!) {
  softDeleteBuilding(id: $id, realmName: $realmName) {
    success
  }
}
    `;
export type DeleteBuildingMutationFn = Apollo.MutationFunction<DeleteBuildingMutation, DeleteBuildingMutationVariables>;

/**
 * __useDeleteBuildingMutation__
 *
 * To run a mutation, you first call `useDeleteBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBuildingMutation, { data, loading, error }] = useDeleteBuildingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useDeleteBuildingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBuildingMutation, DeleteBuildingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBuildingMutation, DeleteBuildingMutationVariables>(DeleteBuildingDocument, options);
      }
export type DeleteBuildingMutationHookResult = ReturnType<typeof useDeleteBuildingMutation>;
export type DeleteBuildingMutationResult = Apollo.MutationResult<DeleteBuildingMutation>;
export type DeleteBuildingMutationOptions = Apollo.BaseMutationOptions<DeleteBuildingMutation, DeleteBuildingMutationVariables>;
export const FinalDeleteBuildingDocument = gql`
    mutation finalDeleteBuilding($id: ID!, $realmName: String!) {
  deleteBuilding(id: $id, realmName: $realmName) {
    success
  }
}
    `;
export type FinalDeleteBuildingMutationFn = Apollo.MutationFunction<FinalDeleteBuildingMutation, FinalDeleteBuildingMutationVariables>;

/**
 * __useFinalDeleteBuildingMutation__
 *
 * To run a mutation, you first call `useFinalDeleteBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalDeleteBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalDeleteBuildingMutation, { data, loading, error }] = useFinalDeleteBuildingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useFinalDeleteBuildingMutation(baseOptions?: Apollo.MutationHookOptions<FinalDeleteBuildingMutation, FinalDeleteBuildingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinalDeleteBuildingMutation, FinalDeleteBuildingMutationVariables>(FinalDeleteBuildingDocument, options);
      }
export type FinalDeleteBuildingMutationHookResult = ReturnType<typeof useFinalDeleteBuildingMutation>;
export type FinalDeleteBuildingMutationResult = Apollo.MutationResult<FinalDeleteBuildingMutation>;
export type FinalDeleteBuildingMutationOptions = Apollo.BaseMutationOptions<FinalDeleteBuildingMutation, FinalDeleteBuildingMutationVariables>;
export const RestoreBuildingDocument = gql`
    mutation restoreBuilding($id: ID!, $realmName: String!) {
  restoreBuilding(id: $id, realmName: $realmName) {
    success
  }
}
    `;
export type RestoreBuildingMutationFn = Apollo.MutationFunction<RestoreBuildingMutation, RestoreBuildingMutationVariables>;

/**
 * __useRestoreBuildingMutation__
 *
 * To run a mutation, you first call `useRestoreBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreBuildingMutation, { data, loading, error }] = useRestoreBuildingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useRestoreBuildingMutation(baseOptions?: Apollo.MutationHookOptions<RestoreBuildingMutation, RestoreBuildingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreBuildingMutation, RestoreBuildingMutationVariables>(RestoreBuildingDocument, options);
      }
export type RestoreBuildingMutationHookResult = ReturnType<typeof useRestoreBuildingMutation>;
export type RestoreBuildingMutationResult = Apollo.MutationResult<RestoreBuildingMutation>;
export type RestoreBuildingMutationOptions = Apollo.BaseMutationOptions<RestoreBuildingMutation, RestoreBuildingMutationVariables>;
export const GetCompanyBuildingsDocument = gql`
    query GetCompanyBuildings($groupId: String, $name: String!, $realmName: String!) {
  getCompany(groupId: $groupId, name: $name, realmName: $realmName) {
    company {
      buildings {
        id
        name
        places {
          id
        }
        assigned
      }
    }
    meta {
      editBuildings
      addBuildings
    }
  }
}
    `;

/**
 * __useGetCompanyBuildingsQuery__
 *
 * To run a query within a React component, call `useGetCompanyBuildingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyBuildingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyBuildingsQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      name: // value for 'name'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useGetCompanyBuildingsQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyBuildingsQuery, GetCompanyBuildingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyBuildingsQuery, GetCompanyBuildingsQueryVariables>(GetCompanyBuildingsDocument, options);
      }
export function useGetCompanyBuildingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyBuildingsQuery, GetCompanyBuildingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyBuildingsQuery, GetCompanyBuildingsQueryVariables>(GetCompanyBuildingsDocument, options);
        }
export type GetCompanyBuildingsQueryHookResult = ReturnType<typeof useGetCompanyBuildingsQuery>;
export type GetCompanyBuildingsLazyQueryHookResult = ReturnType<typeof useGetCompanyBuildingsLazyQuery>;
export type GetCompanyBuildingsQueryResult = Apollo.QueryResult<GetCompanyBuildingsQuery, GetCompanyBuildingsQueryVariables>;
export const DeletedBuildingsDocument = gql`
    query deletedBuildings($realmName: String!) {
  deletedBuildings(realmName: $realmName) {
    id
    name
    groupPath
    _deleted_at
  }
}
    `;

/**
 * __useDeletedBuildingsQuery__
 *
 * To run a query within a React component, call `useDeletedBuildingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeletedBuildingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedBuildingsQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useDeletedBuildingsQuery(baseOptions: Apollo.QueryHookOptions<DeletedBuildingsQuery, DeletedBuildingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeletedBuildingsQuery, DeletedBuildingsQueryVariables>(DeletedBuildingsDocument, options);
      }
export function useDeletedBuildingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeletedBuildingsQuery, DeletedBuildingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeletedBuildingsQuery, DeletedBuildingsQueryVariables>(DeletedBuildingsDocument, options);
        }
export type DeletedBuildingsQueryHookResult = ReturnType<typeof useDeletedBuildingsQuery>;
export type DeletedBuildingsLazyQueryHookResult = ReturnType<typeof useDeletedBuildingsLazyQuery>;
export type DeletedBuildingsQueryResult = Apollo.QueryResult<DeletedBuildingsQuery, DeletedBuildingsQueryVariables>;
export const MergeBuildingsDocument = gql`
    mutation mergeBuildings($id: ID!, $mergeInto: ID!, $realmName: String!) {
  mergeBuildings(id: $id, mergeInto: $mergeInto, realmName: $realmName) {
    success
  }
}
    `;
export type MergeBuildingsMutationFn = Apollo.MutationFunction<MergeBuildingsMutation, MergeBuildingsMutationVariables>;

/**
 * __useMergeBuildingsMutation__
 *
 * To run a mutation, you first call `useMergeBuildingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeBuildingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeBuildingsMutation, { data, loading, error }] = useMergeBuildingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      mergeInto: // value for 'mergeInto'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useMergeBuildingsMutation(baseOptions?: Apollo.MutationHookOptions<MergeBuildingsMutation, MergeBuildingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MergeBuildingsMutation, MergeBuildingsMutationVariables>(MergeBuildingsDocument, options);
      }
export type MergeBuildingsMutationHookResult = ReturnType<typeof useMergeBuildingsMutation>;
export type MergeBuildingsMutationResult = Apollo.MutationResult<MergeBuildingsMutation>;
export type MergeBuildingsMutationOptions = Apollo.BaseMutationOptions<MergeBuildingsMutation, MergeBuildingsMutationVariables>;
export const GetBuildingByIdDocument = gql`
    query GetBuildingById($buildingId: ID!, $realmName: String!) {
  getBuildingById(buildingId: $buildingId, realmName: $realmName) {
    id
    name
    places {
      id
      name
      building_id
      assigned
    }
    assigned
  }
}
    `;

/**
 * __useGetBuildingByIdQuery__
 *
 * To run a query within a React component, call `useGetBuildingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildingByIdQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useGetBuildingByIdQuery(baseOptions: Apollo.QueryHookOptions<GetBuildingByIdQuery, GetBuildingByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBuildingByIdQuery, GetBuildingByIdQueryVariables>(GetBuildingByIdDocument, options);
      }
export function useGetBuildingByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBuildingByIdQuery, GetBuildingByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBuildingByIdQuery, GetBuildingByIdQueryVariables>(GetBuildingByIdDocument, options);
        }
export type GetBuildingByIdQueryHookResult = ReturnType<typeof useGetBuildingByIdQuery>;
export type GetBuildingByIdLazyQueryHookResult = ReturnType<typeof useGetBuildingByIdLazyQuery>;
export type GetBuildingByIdQueryResult = Apollo.QueryResult<GetBuildingByIdQuery, GetBuildingByIdQueryVariables>;
export const GetBuildingsDocument = gql`
    query GetBuildings($companyId: ID!, $filterIDs: [ID!], $placesFilterIDs: [ID!], $groupId: String, $realmName: String!) {
  buildings(
    companyId: $companyId
    filterIDs: $filterIDs
    placesFilterIDs: $placesFilterIDs
    groupId: $groupId
    realmName: $realmName
  ) {
    id
    name
    _deleted_at
    usedLeakages
  }
}
    `;

/**
 * __useGetBuildingsQuery__
 *
 * To run a query within a React component, call `useGetBuildingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildingsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      filterIDs: // value for 'filterIDs'
 *      placesFilterIDs: // value for 'placesFilterIDs'
 *      groupId: // value for 'groupId'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useGetBuildingsQuery(baseOptions: Apollo.QueryHookOptions<GetBuildingsQuery, GetBuildingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBuildingsQuery, GetBuildingsQueryVariables>(GetBuildingsDocument, options);
      }
export function useGetBuildingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBuildingsQuery, GetBuildingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBuildingsQuery, GetBuildingsQueryVariables>(GetBuildingsDocument, options);
        }
export type GetBuildingsQueryHookResult = ReturnType<typeof useGetBuildingsQuery>;
export type GetBuildingsLazyQueryHookResult = ReturnType<typeof useGetBuildingsLazyQuery>;
export type GetBuildingsQueryResult = Apollo.QueryResult<GetBuildingsQuery, GetBuildingsQueryVariables>;
export const SoftDeleteCompanyDocument = gql`
    mutation softDeleteCompany($name: String!, $realmName: String!, $groupId: String) {
  softDeleteCompanyByName(name: $name, realmName: $realmName, groupId: $groupId) {
    success
  }
}
    `;
export type SoftDeleteCompanyMutationFn = Apollo.MutationFunction<SoftDeleteCompanyMutation, SoftDeleteCompanyMutationVariables>;

/**
 * __useSoftDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useSoftDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteCompanyMutation, { data, loading, error }] = useSoftDeleteCompanyMutation({
 *   variables: {
 *      name: // value for 'name'
 *      realmName: // value for 'realmName'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useSoftDeleteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<SoftDeleteCompanyMutation, SoftDeleteCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SoftDeleteCompanyMutation, SoftDeleteCompanyMutationVariables>(SoftDeleteCompanyDocument, options);
      }
export type SoftDeleteCompanyMutationHookResult = ReturnType<typeof useSoftDeleteCompanyMutation>;
export type SoftDeleteCompanyMutationResult = Apollo.MutationResult<SoftDeleteCompanyMutation>;
export type SoftDeleteCompanyMutationOptions = Apollo.BaseMutationOptions<SoftDeleteCompanyMutation, SoftDeleteCompanyMutationVariables>;
export const FinalDeleteCompanyDocument = gql`
    mutation finalDeleteCompany($id: ID!, $realmName: String!) {
  deleteCompany(id: $id, realmName: $realmName) {
    success
  }
}
    `;
export type FinalDeleteCompanyMutationFn = Apollo.MutationFunction<FinalDeleteCompanyMutation, FinalDeleteCompanyMutationVariables>;

/**
 * __useFinalDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useFinalDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalDeleteCompanyMutation, { data, loading, error }] = useFinalDeleteCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useFinalDeleteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<FinalDeleteCompanyMutation, FinalDeleteCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinalDeleteCompanyMutation, FinalDeleteCompanyMutationVariables>(FinalDeleteCompanyDocument, options);
      }
export type FinalDeleteCompanyMutationHookResult = ReturnType<typeof useFinalDeleteCompanyMutation>;
export type FinalDeleteCompanyMutationResult = Apollo.MutationResult<FinalDeleteCompanyMutation>;
export type FinalDeleteCompanyMutationOptions = Apollo.BaseMutationOptions<FinalDeleteCompanyMutation, FinalDeleteCompanyMutationVariables>;
export const RestoreCompanyDocument = gql`
    mutation restoreCompany($name: String!, $realmName: String!) {
  restoreCompany(name: $name, realmName: $realmName) {
    success
  }
}
    `;
export type RestoreCompanyMutationFn = Apollo.MutationFunction<RestoreCompanyMutation, RestoreCompanyMutationVariables>;

/**
 * __useRestoreCompanyMutation__
 *
 * To run a mutation, you first call `useRestoreCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreCompanyMutation, { data, loading, error }] = useRestoreCompanyMutation({
 *   variables: {
 *      name: // value for 'name'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useRestoreCompanyMutation(baseOptions?: Apollo.MutationHookOptions<RestoreCompanyMutation, RestoreCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreCompanyMutation, RestoreCompanyMutationVariables>(RestoreCompanyDocument, options);
      }
export type RestoreCompanyMutationHookResult = ReturnType<typeof useRestoreCompanyMutation>;
export type RestoreCompanyMutationResult = Apollo.MutationResult<RestoreCompanyMutation>;
export type RestoreCompanyMutationOptions = Apollo.BaseMutationOptions<RestoreCompanyMutation, RestoreCompanyMutationVariables>;
export const RestoreCompanyByIdDocument = gql`
    mutation restoreCompanyById($id: ID!, $realmName: String!) {
  restoreCompanyById(id: $id, realmName: $realmName) {
    success
  }
}
    `;
export type RestoreCompanyByIdMutationFn = Apollo.MutationFunction<RestoreCompanyByIdMutation, RestoreCompanyByIdMutationVariables>;

/**
 * __useRestoreCompanyByIdMutation__
 *
 * To run a mutation, you first call `useRestoreCompanyByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreCompanyByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreCompanyByIdMutation, { data, loading, error }] = useRestoreCompanyByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useRestoreCompanyByIdMutation(baseOptions?: Apollo.MutationHookOptions<RestoreCompanyByIdMutation, RestoreCompanyByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreCompanyByIdMutation, RestoreCompanyByIdMutationVariables>(RestoreCompanyByIdDocument, options);
      }
export type RestoreCompanyByIdMutationHookResult = ReturnType<typeof useRestoreCompanyByIdMutation>;
export type RestoreCompanyByIdMutationResult = Apollo.MutationResult<RestoreCompanyByIdMutation>;
export type RestoreCompanyByIdMutationOptions = Apollo.BaseMutationOptions<RestoreCompanyByIdMutation, RestoreCompanyByIdMutationVariables>;
export const GetCompaniesDocument = gql`
    query GetCompanies($groupId: String, $realmName: String!) {
  getCompanies(groupId: $groupId, realmName: $realmName) {
    _meta {
      totalElements
      allowedCompanies
      rootAccess
      allowedCompanies
      typePermissionEdit
    }
    data {
      id
      company
      canEdit
      buildings {
        name
      }
      projects {
        projects {
          id
          name
          canEdit
        }
      }
    }
  }
}
    `;

/**
 * __useGetCompaniesQuery__
 *
 * To run a query within a React component, call `useGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useGetCompaniesQuery(baseOptions: Apollo.QueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
      }
export function useGetCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
        }
export type GetCompaniesQueryHookResult = ReturnType<typeof useGetCompaniesQuery>;
export type GetCompaniesLazyQueryHookResult = ReturnType<typeof useGetCompaniesLazyQuery>;
export type GetCompaniesQueryResult = Apollo.QueryResult<GetCompaniesQuery, GetCompaniesQueryVariables>;
export const GetCompanyDocument = gql`
    query GetCompany($groupId: String, $name: String!, $realmName: String!) {
  getCompany(groupId: $groupId, name: $name, realmName: $realmName) {
    company {
      id
      company
      nBuildings
      nProjects
      logo
      canEdit
      address {
        street
        number
        zip
        city
      }
      contact {
        email
        phone
        email
        prename
        surname
      }
      buildings {
        name
      }
      projects {
        projects {
          id
          name
          importDate
        }
      }
    }
    meta {
      editBuildings
      addBuildings
    }
  }
}
    `;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      name: // value for 'name'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useGetCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
      }
export function useGetCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
        }
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>;
export type GetCompanyQueryResult = Apollo.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>;
export const DeletedCompaniesDocument = gql`
    query deletedCompanies($realmName: String!) {
  deletedCompanies(realmName: $realmName) {
    id
    company
    groupPath
    _deleted_at
  }
}
    `;

/**
 * __useDeletedCompaniesQuery__
 *
 * To run a query within a React component, call `useDeletedCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeletedCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedCompaniesQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useDeletedCompaniesQuery(baseOptions: Apollo.QueryHookOptions<DeletedCompaniesQuery, DeletedCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeletedCompaniesQuery, DeletedCompaniesQueryVariables>(DeletedCompaniesDocument, options);
      }
export function useDeletedCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeletedCompaniesQuery, DeletedCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeletedCompaniesQuery, DeletedCompaniesQueryVariables>(DeletedCompaniesDocument, options);
        }
export type DeletedCompaniesQueryHookResult = ReturnType<typeof useDeletedCompaniesQuery>;
export type DeletedCompaniesLazyQueryHookResult = ReturnType<typeof useDeletedCompaniesLazyQuery>;
export type DeletedCompaniesQueryResult = Apollo.QueryResult<DeletedCompaniesQuery, DeletedCompaniesQueryVariables>;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($company: UpdateCompany!, $realmName: String!) {
  createOrUpdateCompany(company: $company, realmName: $realmName) {
    id
  }
}
    `;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      company: // value for 'company'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const ImportCompaniesDocument = gql`
    mutation importCompanies($companies: [ImportCompany!]!, $targetCompanyName: String, $targetProjectId: ID, $images: [ImportImage!]!, $realmName: String!, $groupId: String) {
  importCompanies(
    companies: $companies
    targetCompanyName: $targetCompanyName
    targetProjectId: $targetProjectId
    images: $images
    realmName: $realmName
    groupId: $groupId
  ) {
    id
    company
    groupId
    buildings {
      id
      name
    }
    projects {
      projects {
        id
        totalLeakage
        name
        leakages {
          id
          building {
            id
          }
          comment
          unitD
        }
      }
    }
  }
}
    `;
export type ImportCompaniesMutationFn = Apollo.MutationFunction<ImportCompaniesMutation, ImportCompaniesMutationVariables>;

/**
 * __useImportCompaniesMutation__
 *
 * To run a mutation, you first call `useImportCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importCompaniesMutation, { data, loading, error }] = useImportCompaniesMutation({
 *   variables: {
 *      companies: // value for 'companies'
 *      targetCompanyName: // value for 'targetCompanyName'
 *      targetProjectId: // value for 'targetProjectId'
 *      images: // value for 'images'
 *      realmName: // value for 'realmName'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useImportCompaniesMutation(baseOptions?: Apollo.MutationHookOptions<ImportCompaniesMutation, ImportCompaniesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportCompaniesMutation, ImportCompaniesMutationVariables>(ImportCompaniesDocument, options);
      }
export type ImportCompaniesMutationHookResult = ReturnType<typeof useImportCompaniesMutation>;
export type ImportCompaniesMutationResult = Apollo.MutationResult<ImportCompaniesMutation>;
export type ImportCompaniesMutationOptions = Apollo.BaseMutationOptions<ImportCompaniesMutation, ImportCompaniesMutationVariables>;
export const CopyMoveCompanyToGroupDocument = gql`
    mutation copyMoveCompanyToGroup($companyIDs: [String!]!, $targetGroupID: String!, $realmName: String!, $copyOnly: Boolean!) {
  copyMoveCompanyToGroup(
    companyIDs: $companyIDs
    targetGroupID: $targetGroupID
    realmName: $realmName
    copyOnly: $copyOnly
  ) {
    success
  }
}
    `;
export type CopyMoveCompanyToGroupMutationFn = Apollo.MutationFunction<CopyMoveCompanyToGroupMutation, CopyMoveCompanyToGroupMutationVariables>;

/**
 * __useCopyMoveCompanyToGroupMutation__
 *
 * To run a mutation, you first call `useCopyMoveCompanyToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyMoveCompanyToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyMoveCompanyToGroupMutation, { data, loading, error }] = useCopyMoveCompanyToGroupMutation({
 *   variables: {
 *      companyIDs: // value for 'companyIDs'
 *      targetGroupID: // value for 'targetGroupID'
 *      realmName: // value for 'realmName'
 *      copyOnly: // value for 'copyOnly'
 *   },
 * });
 */
export function useCopyMoveCompanyToGroupMutation(baseOptions?: Apollo.MutationHookOptions<CopyMoveCompanyToGroupMutation, CopyMoveCompanyToGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyMoveCompanyToGroupMutation, CopyMoveCompanyToGroupMutationVariables>(CopyMoveCompanyToGroupDocument, options);
      }
export type CopyMoveCompanyToGroupMutationHookResult = ReturnType<typeof useCopyMoveCompanyToGroupMutation>;
export type CopyMoveCompanyToGroupMutationResult = Apollo.MutationResult<CopyMoveCompanyToGroupMutation>;
export type CopyMoveCompanyToGroupMutationOptions = Apollo.BaseMutationOptions<CopyMoveCompanyToGroupMutation, CopyMoveCompanyToGroupMutationVariables>;
export const GetRootContentDocument = gql`
    query getRootContent($realmName: String!) {
  getRootContent(realmName: $realmName) {
    groups {
      id
      parentGroupId
      groupPath
      groupName
      canEdit
      canCreateCompany
    }
    companies {
      id
      parentGroupId
      groupPath
      company
      logo
      nProjects
      nBuildings
      _deleted_at
      canEdit
    }
  }
}
    `;

/**
 * __useGetRootContentQuery__
 *
 * To run a query within a React component, call `useGetRootContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRootContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRootContentQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useGetRootContentQuery(baseOptions: Apollo.QueryHookOptions<GetRootContentQuery, GetRootContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRootContentQuery, GetRootContentQueryVariables>(GetRootContentDocument, options);
      }
export function useGetRootContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRootContentQuery, GetRootContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRootContentQuery, GetRootContentQueryVariables>(GetRootContentDocument, options);
        }
export type GetRootContentQueryHookResult = ReturnType<typeof useGetRootContentQuery>;
export type GetRootContentLazyQueryHookResult = ReturnType<typeof useGetRootContentLazyQuery>;
export type GetRootContentQueryResult = Apollo.QueryResult<GetRootContentQuery, GetRootContentQueryVariables>;
export const DeleteGroupDocument = gql`
    mutation deleteGroup($groupId: String!, $realmName: String!) {
  deleteGroup(groupId: $groupId, realmName: $realmName) {
    success
  }
}
    `;
export type DeleteGroupMutationFn = Apollo.MutationFunction<DeleteGroupMutation, DeleteGroupMutationVariables>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useDeleteGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGroupMutation, DeleteGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(DeleteGroupDocument, options);
      }
export type DeleteGroupMutationHookResult = ReturnType<typeof useDeleteGroupMutation>;
export type DeleteGroupMutationResult = Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<DeleteGroupMutation, DeleteGroupMutationVariables>;
export const SoftDeleteGroupDocument = gql`
    mutation softDeleteGroup($groupId: String!, $realmName: String!) {
  softDeleteGroup(groupId: $groupId, realmName: $realmName) {
    success
  }
}
    `;
export type SoftDeleteGroupMutationFn = Apollo.MutationFunction<SoftDeleteGroupMutation, SoftDeleteGroupMutationVariables>;

/**
 * __useSoftDeleteGroupMutation__
 *
 * To run a mutation, you first call `useSoftDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteGroupMutation, { data, loading, error }] = useSoftDeleteGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useSoftDeleteGroupMutation(baseOptions?: Apollo.MutationHookOptions<SoftDeleteGroupMutation, SoftDeleteGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SoftDeleteGroupMutation, SoftDeleteGroupMutationVariables>(SoftDeleteGroupDocument, options);
      }
export type SoftDeleteGroupMutationHookResult = ReturnType<typeof useSoftDeleteGroupMutation>;
export type SoftDeleteGroupMutationResult = Apollo.MutationResult<SoftDeleteGroupMutation>;
export type SoftDeleteGroupMutationOptions = Apollo.BaseMutationOptions<SoftDeleteGroupMutation, SoftDeleteGroupMutationVariables>;
export const RestoreGroupDocument = gql`
    mutation restoreGroup($groupId: String!, $realmName: String!) {
  restoreGroup(groupId: $groupId, realmName: $realmName) {
    success
  }
}
    `;
export type RestoreGroupMutationFn = Apollo.MutationFunction<RestoreGroupMutation, RestoreGroupMutationVariables>;

/**
 * __useRestoreGroupMutation__
 *
 * To run a mutation, you first call `useRestoreGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreGroupMutation, { data, loading, error }] = useRestoreGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useRestoreGroupMutation(baseOptions?: Apollo.MutationHookOptions<RestoreGroupMutation, RestoreGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreGroupMutation, RestoreGroupMutationVariables>(RestoreGroupDocument, options);
      }
export type RestoreGroupMutationHookResult = ReturnType<typeof useRestoreGroupMutation>;
export type RestoreGroupMutationResult = Apollo.MutationResult<RestoreGroupMutation>;
export type RestoreGroupMutationOptions = Apollo.BaseMutationOptions<RestoreGroupMutation, RestoreGroupMutationVariables>;
export const CreateGroupDocument = gql`
    mutation createGroup($group: GroupInput!, $realmName: String!) {
  createGroup(group: $group, realmName: $realmName) {
    id
    groupName
    canEdit
    canCreateCompany
  }
}
    `;
export type CreateGroupMutationFn = Apollo.MutationFunction<CreateGroupMutation, CreateGroupMutationVariables>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      group: // value for 'group'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useCreateGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateGroupMutation, CreateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument, options);
      }
export type CreateGroupMutationHookResult = ReturnType<typeof useCreateGroupMutation>;
export type CreateGroupMutationResult = Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<CreateGroupMutation, CreateGroupMutationVariables>;
export const UpdateGroupDocument = gql`
    mutation updateGroup($groupId: String!, $group: GroupInput!, $realmName: String!) {
  updateGroup(groupId: $groupId, group: $group, realmName: $realmName) {
    id
    parentGroupId
    groupPath
    groupName
    canEdit
    canCreateCompany
  }
}
    `;
export type UpdateGroupMutationFn = Apollo.MutationFunction<UpdateGroupMutation, UpdateGroupMutationVariables>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      group: // value for 'group'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useUpdateGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGroupMutation, UpdateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(UpdateGroupDocument, options);
      }
export type UpdateGroupMutationHookResult = ReturnType<typeof useUpdateGroupMutation>;
export type UpdateGroupMutationResult = Apollo.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<UpdateGroupMutation, UpdateGroupMutationVariables>;
export const GetGroupsAndCompaniesDocument = gql`
    query getGroupsAndCompanies($realmName: String!, $groupId: String) {
  getGroupsAndCompanies(realmName: $realmName, groupId: $groupId) {
    groups {
      id
      parentGroupId
      groupPath
      groupName
      canEdit
      canCreateCompany
    }
    companies {
      id
      parentGroupId
      groupPath
      company
      logo
      nProjects
      nBuildings
      _deleted_at
      canEdit
    }
    groupInfo {
      id
      parentGroupId
      groupName
      canCreateCompany
    }
  }
}
    `;

/**
 * __useGetGroupsAndCompaniesQuery__
 *
 * To run a query within a React component, call `useGetGroupsAndCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupsAndCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupsAndCompaniesQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGetGroupsAndCompaniesQuery(baseOptions: Apollo.QueryHookOptions<GetGroupsAndCompaniesQuery, GetGroupsAndCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupsAndCompaniesQuery, GetGroupsAndCompaniesQueryVariables>(GetGroupsAndCompaniesDocument, options);
      }
export function useGetGroupsAndCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupsAndCompaniesQuery, GetGroupsAndCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupsAndCompaniesQuery, GetGroupsAndCompaniesQueryVariables>(GetGroupsAndCompaniesDocument, options);
        }
export type GetGroupsAndCompaniesQueryHookResult = ReturnType<typeof useGetGroupsAndCompaniesQuery>;
export type GetGroupsAndCompaniesLazyQueryHookResult = ReturnType<typeof useGetGroupsAndCompaniesLazyQuery>;
export type GetGroupsAndCompaniesQueryResult = Apollo.QueryResult<GetGroupsAndCompaniesQuery, GetGroupsAndCompaniesQueryVariables>;
export const GetDeletedGroupsDocument = gql`
    query GetDeletedGroups($realmName: String!) {
  deletedGroups(realmName: $realmName) {
    id
    groupPath
    groupName
    _deleted_at
  }
}
    `;

/**
 * __useGetDeletedGroupsQuery__
 *
 * To run a query within a React component, call `useGetDeletedGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeletedGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeletedGroupsQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useGetDeletedGroupsQuery(baseOptions: Apollo.QueryHookOptions<GetDeletedGroupsQuery, GetDeletedGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeletedGroupsQuery, GetDeletedGroupsQueryVariables>(GetDeletedGroupsDocument, options);
      }
export function useGetDeletedGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeletedGroupsQuery, GetDeletedGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeletedGroupsQuery, GetDeletedGroupsQueryVariables>(GetDeletedGroupsDocument, options);
        }
export type GetDeletedGroupsQueryHookResult = ReturnType<typeof useGetDeletedGroupsQuery>;
export type GetDeletedGroupsLazyQueryHookResult = ReturnType<typeof useGetDeletedGroupsLazyQuery>;
export type GetDeletedGroupsQueryResult = Apollo.QueryResult<GetDeletedGroupsQuery, GetDeletedGroupsQueryVariables>;
export const GetPathByGroupIdDocument = gql`
    query getPathByGroupId($realmName: String!, $groupId: String!) {
  getPathByGroupId(realmName: $realmName, groupId: $groupId) {
    pathID
    pathName
  }
}
    `;

/**
 * __useGetPathByGroupIdQuery__
 *
 * To run a query within a React component, call `useGetPathByGroupIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPathByGroupIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPathByGroupIdQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGetPathByGroupIdQuery(baseOptions: Apollo.QueryHookOptions<GetPathByGroupIdQuery, GetPathByGroupIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPathByGroupIdQuery, GetPathByGroupIdQueryVariables>(GetPathByGroupIdDocument, options);
      }
export function useGetPathByGroupIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPathByGroupIdQuery, GetPathByGroupIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPathByGroupIdQuery, GetPathByGroupIdQueryVariables>(GetPathByGroupIdDocument, options);
        }
export type GetPathByGroupIdQueryHookResult = ReturnType<typeof useGetPathByGroupIdQuery>;
export type GetPathByGroupIdLazyQueryHookResult = ReturnType<typeof useGetPathByGroupIdLazyQuery>;
export type GetPathByGroupIdQueryResult = Apollo.QueryResult<GetPathByGroupIdQuery, GetPathByGroupIdQueryVariables>;
export const LeakageDescriptionFieldsDocument = gql`
    query leakageDescriptionFields($realmName: String!) {
  leakageDescriptionFields(realmName: $realmName) {
    measures {
      id
      text
    }
    failures {
      id
      text
    }
    replacements {
      id
      text
    }
  }
}
    `;

/**
 * __useLeakageDescriptionFieldsQuery__
 *
 * To run a query within a React component, call `useLeakageDescriptionFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeakageDescriptionFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeakageDescriptionFieldsQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useLeakageDescriptionFieldsQuery(baseOptions: Apollo.QueryHookOptions<LeakageDescriptionFieldsQuery, LeakageDescriptionFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LeakageDescriptionFieldsQuery, LeakageDescriptionFieldsQueryVariables>(LeakageDescriptionFieldsDocument, options);
      }
export function useLeakageDescriptionFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeakageDescriptionFieldsQuery, LeakageDescriptionFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LeakageDescriptionFieldsQuery, LeakageDescriptionFieldsQueryVariables>(LeakageDescriptionFieldsDocument, options);
        }
export type LeakageDescriptionFieldsQueryHookResult = ReturnType<typeof useLeakageDescriptionFieldsQuery>;
export type LeakageDescriptionFieldsLazyQueryHookResult = ReturnType<typeof useLeakageDescriptionFieldsLazyQuery>;
export type LeakageDescriptionFieldsQueryResult = Apollo.QueryResult<LeakageDescriptionFieldsQuery, LeakageDescriptionFieldsQueryVariables>;
export const MeasureDocument = gql`
    query measure($id: ID!) {
  measure(id: $id) {
    id
    text
  }
}
    `;

/**
 * __useMeasureQuery__
 *
 * To run a query within a React component, call `useMeasureQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeasureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeasureQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMeasureQuery(baseOptions: Apollo.QueryHookOptions<MeasureQuery, MeasureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeasureQuery, MeasureQueryVariables>(MeasureDocument, options);
      }
export function useMeasureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeasureQuery, MeasureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeasureQuery, MeasureQueryVariables>(MeasureDocument, options);
        }
export type MeasureQueryHookResult = ReturnType<typeof useMeasureQuery>;
export type MeasureLazyQueryHookResult = ReturnType<typeof useMeasureLazyQuery>;
export type MeasureQueryResult = Apollo.QueryResult<MeasureQuery, MeasureQueryVariables>;
export const DeleteLeakageDescriptionDocument = gql`
    mutation deleteLeakageDescription($id: ID!, $realmName: String!) {
  deleteLeakageDescription(id: $id, realmName: $realmName) {
    success
  }
}
    `;
export type DeleteLeakageDescriptionMutationFn = Apollo.MutationFunction<DeleteLeakageDescriptionMutation, DeleteLeakageDescriptionMutationVariables>;

/**
 * __useDeleteLeakageDescriptionMutation__
 *
 * To run a mutation, you first call `useDeleteLeakageDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLeakageDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLeakageDescriptionMutation, { data, loading, error }] = useDeleteLeakageDescriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useDeleteLeakageDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLeakageDescriptionMutation, DeleteLeakageDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLeakageDescriptionMutation, DeleteLeakageDescriptionMutationVariables>(DeleteLeakageDescriptionDocument, options);
      }
export type DeleteLeakageDescriptionMutationHookResult = ReturnType<typeof useDeleteLeakageDescriptionMutation>;
export type DeleteLeakageDescriptionMutationResult = Apollo.MutationResult<DeleteLeakageDescriptionMutation>;
export type DeleteLeakageDescriptionMutationOptions = Apollo.BaseMutationOptions<DeleteLeakageDescriptionMutation, DeleteLeakageDescriptionMutationVariables>;
export const DeleteMeasureDocument = gql`
    mutation deleteMeasure($id: ID!, $realmName: String!) {
  deleteMeasure(id: $id, realmName: $realmName) {
    success
  }
}
    `;
export type DeleteMeasureMutationFn = Apollo.MutationFunction<DeleteMeasureMutation, DeleteMeasureMutationVariables>;

/**
 * __useDeleteMeasureMutation__
 *
 * To run a mutation, you first call `useDeleteMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMeasureMutation, { data, loading, error }] = useDeleteMeasureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useDeleteMeasureMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMeasureMutation, DeleteMeasureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMeasureMutation, DeleteMeasureMutationVariables>(DeleteMeasureDocument, options);
      }
export type DeleteMeasureMutationHookResult = ReturnType<typeof useDeleteMeasureMutation>;
export type DeleteMeasureMutationResult = Apollo.MutationResult<DeleteMeasureMutation>;
export type DeleteMeasureMutationOptions = Apollo.BaseMutationOptions<DeleteMeasureMutation, DeleteMeasureMutationVariables>;
export const FailureDocument = gql`
    query failure($id: ID!) {
  failure(id: $id) {
    id
    text
  }
}
    `;

/**
 * __useFailureQuery__
 *
 * To run a query within a React component, call `useFailureQuery` and pass it any options that fit your needs.
 * When your component renders, `useFailureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFailureQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFailureQuery(baseOptions: Apollo.QueryHookOptions<FailureQuery, FailureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FailureQuery, FailureQueryVariables>(FailureDocument, options);
      }
export function useFailureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FailureQuery, FailureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FailureQuery, FailureQueryVariables>(FailureDocument, options);
        }
export type FailureQueryHookResult = ReturnType<typeof useFailureQuery>;
export type FailureLazyQueryHookResult = ReturnType<typeof useFailureLazyQuery>;
export type FailureQueryResult = Apollo.QueryResult<FailureQuery, FailureQueryVariables>;
export const DeleteFailureDocument = gql`
    mutation deleteFailure($id: ID!, $realmName: String!) {
  deleteFailure(id: $id, realmName: $realmName) {
    success
  }
}
    `;
export type DeleteFailureMutationFn = Apollo.MutationFunction<DeleteFailureMutation, DeleteFailureMutationVariables>;

/**
 * __useDeleteFailureMutation__
 *
 * To run a mutation, you first call `useDeleteFailureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFailureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFailureMutation, { data, loading, error }] = useDeleteFailureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useDeleteFailureMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFailureMutation, DeleteFailureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFailureMutation, DeleteFailureMutationVariables>(DeleteFailureDocument, options);
      }
export type DeleteFailureMutationHookResult = ReturnType<typeof useDeleteFailureMutation>;
export type DeleteFailureMutationResult = Apollo.MutationResult<DeleteFailureMutation>;
export type DeleteFailureMutationOptions = Apollo.BaseMutationOptions<DeleteFailureMutation, DeleteFailureMutationVariables>;
export const ReplacementDocument = gql`
    query replacement($id: ID!) {
  replacement(id: $id) {
    id
    text
  }
}
    `;

/**
 * __useReplacementQuery__
 *
 * To run a query within a React component, call `useReplacementQuery` and pass it any options that fit your needs.
 * When your component renders, `useReplacementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReplacementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReplacementQuery(baseOptions: Apollo.QueryHookOptions<ReplacementQuery, ReplacementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReplacementQuery, ReplacementQueryVariables>(ReplacementDocument, options);
      }
export function useReplacementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReplacementQuery, ReplacementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReplacementQuery, ReplacementQueryVariables>(ReplacementDocument, options);
        }
export type ReplacementQueryHookResult = ReturnType<typeof useReplacementQuery>;
export type ReplacementLazyQueryHookResult = ReturnType<typeof useReplacementLazyQuery>;
export type ReplacementQueryResult = Apollo.QueryResult<ReplacementQuery, ReplacementQueryVariables>;
export const DeleteReplacementDocument = gql`
    mutation deleteReplacement($id: ID!, $realmName: String!) {
  deleteReplacement(id: $id, realmName: $realmName) {
    success
  }
}
    `;
export type DeleteReplacementMutationFn = Apollo.MutationFunction<DeleteReplacementMutation, DeleteReplacementMutationVariables>;

/**
 * __useDeleteReplacementMutation__
 *
 * To run a mutation, you first call `useDeleteReplacementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReplacementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReplacementMutation, { data, loading, error }] = useDeleteReplacementMutation({
 *   variables: {
 *      id: // value for 'id'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useDeleteReplacementMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReplacementMutation, DeleteReplacementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReplacementMutation, DeleteReplacementMutationVariables>(DeleteReplacementDocument, options);
      }
export type DeleteReplacementMutationHookResult = ReturnType<typeof useDeleteReplacementMutation>;
export type DeleteReplacementMutationResult = Apollo.MutationResult<DeleteReplacementMutation>;
export type DeleteReplacementMutationOptions = Apollo.BaseMutationOptions<DeleteReplacementMutation, DeleteReplacementMutationVariables>;
export const CreateMeasureDocument = gql`
    mutation createMeasure($text: String!, $realmName: String!) {
  createMeasure(text: $text, realmName: $realmName) {
    id
    text
  }
}
    `;
export type CreateMeasureMutationFn = Apollo.MutationFunction<CreateMeasureMutation, CreateMeasureMutationVariables>;

/**
 * __useCreateMeasureMutation__
 *
 * To run a mutation, you first call `useCreateMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMeasureMutation, { data, loading, error }] = useCreateMeasureMutation({
 *   variables: {
 *      text: // value for 'text'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useCreateMeasureMutation(baseOptions?: Apollo.MutationHookOptions<CreateMeasureMutation, CreateMeasureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMeasureMutation, CreateMeasureMutationVariables>(CreateMeasureDocument, options);
      }
export type CreateMeasureMutationHookResult = ReturnType<typeof useCreateMeasureMutation>;
export type CreateMeasureMutationResult = Apollo.MutationResult<CreateMeasureMutation>;
export type CreateMeasureMutationOptions = Apollo.BaseMutationOptions<CreateMeasureMutation, CreateMeasureMutationVariables>;
export const CreateReplacementDocument = gql`
    mutation createReplacement($text: String!, $realmName: String!) {
  createReplacement(text: $text, realmName: $realmName) {
    id
    text
  }
}
    `;
export type CreateReplacementMutationFn = Apollo.MutationFunction<CreateReplacementMutation, CreateReplacementMutationVariables>;

/**
 * __useCreateReplacementMutation__
 *
 * To run a mutation, you first call `useCreateReplacementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReplacementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReplacementMutation, { data, loading, error }] = useCreateReplacementMutation({
 *   variables: {
 *      text: // value for 'text'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useCreateReplacementMutation(baseOptions?: Apollo.MutationHookOptions<CreateReplacementMutation, CreateReplacementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReplacementMutation, CreateReplacementMutationVariables>(CreateReplacementDocument, options);
      }
export type CreateReplacementMutationHookResult = ReturnType<typeof useCreateReplacementMutation>;
export type CreateReplacementMutationResult = Apollo.MutationResult<CreateReplacementMutation>;
export type CreateReplacementMutationOptions = Apollo.BaseMutationOptions<CreateReplacementMutation, CreateReplacementMutationVariables>;
export const CreateFailureDocument = gql`
    mutation createFailure($text: String!, $realmName: String!) {
  createFailure(text: $text, realmName: $realmName) {
    id
    text
  }
}
    `;
export type CreateFailureMutationFn = Apollo.MutationFunction<CreateFailureMutation, CreateFailureMutationVariables>;

/**
 * __useCreateFailureMutation__
 *
 * To run a mutation, you first call `useCreateFailureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFailureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFailureMutation, { data, loading, error }] = useCreateFailureMutation({
 *   variables: {
 *      text: // value for 'text'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useCreateFailureMutation(baseOptions?: Apollo.MutationHookOptions<CreateFailureMutation, CreateFailureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFailureMutation, CreateFailureMutationVariables>(CreateFailureDocument, options);
      }
export type CreateFailureMutationHookResult = ReturnType<typeof useCreateFailureMutation>;
export type CreateFailureMutationResult = Apollo.MutationResult<CreateFailureMutation>;
export type CreateFailureMutationOptions = Apollo.BaseMutationOptions<CreateFailureMutation, CreateFailureMutationVariables>;
export const UpdateFailureDocument = gql`
    mutation updateFailure($id: ID!, $text: String!, $realmName: String!) {
  updateFailure(id: $id, text: $text, realmName: $realmName) {
    success
  }
}
    `;
export type UpdateFailureMutationFn = Apollo.MutationFunction<UpdateFailureMutation, UpdateFailureMutationVariables>;

/**
 * __useUpdateFailureMutation__
 *
 * To run a mutation, you first call `useUpdateFailureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFailureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFailureMutation, { data, loading, error }] = useUpdateFailureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      text: // value for 'text'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useUpdateFailureMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFailureMutation, UpdateFailureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFailureMutation, UpdateFailureMutationVariables>(UpdateFailureDocument, options);
      }
export type UpdateFailureMutationHookResult = ReturnType<typeof useUpdateFailureMutation>;
export type UpdateFailureMutationResult = Apollo.MutationResult<UpdateFailureMutation>;
export type UpdateFailureMutationOptions = Apollo.BaseMutationOptions<UpdateFailureMutation, UpdateFailureMutationVariables>;
export const UpdateMeasureDocument = gql`
    mutation updateMeasure($id: ID!, $text: String!, $realmName: String!) {
  updateMeasure(id: $id, text: $text, realmName: $realmName) {
    success
  }
}
    `;
export type UpdateMeasureMutationFn = Apollo.MutationFunction<UpdateMeasureMutation, UpdateMeasureMutationVariables>;

/**
 * __useUpdateMeasureMutation__
 *
 * To run a mutation, you first call `useUpdateMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeasureMutation, { data, loading, error }] = useUpdateMeasureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      text: // value for 'text'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useUpdateMeasureMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMeasureMutation, UpdateMeasureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMeasureMutation, UpdateMeasureMutationVariables>(UpdateMeasureDocument, options);
      }
export type UpdateMeasureMutationHookResult = ReturnType<typeof useUpdateMeasureMutation>;
export type UpdateMeasureMutationResult = Apollo.MutationResult<UpdateMeasureMutation>;
export type UpdateMeasureMutationOptions = Apollo.BaseMutationOptions<UpdateMeasureMutation, UpdateMeasureMutationVariables>;
export const UpdateReplacementDocument = gql`
    mutation updateReplacement($id: ID!, $text: String!, $realmName: String!) {
  updateReplacement(id: $id, text: $text, realmName: $realmName) {
    success
  }
}
    `;
export type UpdateReplacementMutationFn = Apollo.MutationFunction<UpdateReplacementMutation, UpdateReplacementMutationVariables>;

/**
 * __useUpdateReplacementMutation__
 *
 * To run a mutation, you first call `useUpdateReplacementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReplacementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReplacementMutation, { data, loading, error }] = useUpdateReplacementMutation({
 *   variables: {
 *      id: // value for 'id'
 *      text: // value for 'text'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useUpdateReplacementMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReplacementMutation, UpdateReplacementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReplacementMutation, UpdateReplacementMutationVariables>(UpdateReplacementDocument, options);
      }
export type UpdateReplacementMutationHookResult = ReturnType<typeof useUpdateReplacementMutation>;
export type UpdateReplacementMutationResult = Apollo.MutationResult<UpdateReplacementMutation>;
export type UpdateReplacementMutationOptions = Apollo.BaseMutationOptions<UpdateReplacementMutation, UpdateReplacementMutationVariables>;
export const DeleteLeakageDocument = gql`
    mutation deleteLeakage($id: ID!, $realmName: String!) {
  softDeleteLeakage(id: $id, realmName: $realmName) {
    success
  }
}
    `;
export type DeleteLeakageMutationFn = Apollo.MutationFunction<DeleteLeakageMutation, DeleteLeakageMutationVariables>;

/**
 * __useDeleteLeakageMutation__
 *
 * To run a mutation, you first call `useDeleteLeakageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLeakageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLeakageMutation, { data, loading, error }] = useDeleteLeakageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useDeleteLeakageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLeakageMutation, DeleteLeakageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLeakageMutation, DeleteLeakageMutationVariables>(DeleteLeakageDocument, options);
      }
export type DeleteLeakageMutationHookResult = ReturnType<typeof useDeleteLeakageMutation>;
export type DeleteLeakageMutationResult = Apollo.MutationResult<DeleteLeakageMutation>;
export type DeleteLeakageMutationOptions = Apollo.BaseMutationOptions<DeleteLeakageMutation, DeleteLeakageMutationVariables>;
export const DeleteLeakagesDocument = gql`
    mutation deleteLeakages($ids: [ID!]!, $realmName: String!) {
  softDeleteLeakages(ids: $ids, realmName: $realmName) {
    success
  }
}
    `;
export type DeleteLeakagesMutationFn = Apollo.MutationFunction<DeleteLeakagesMutation, DeleteLeakagesMutationVariables>;

/**
 * __useDeleteLeakagesMutation__
 *
 * To run a mutation, you first call `useDeleteLeakagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLeakagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLeakagesMutation, { data, loading, error }] = useDeleteLeakagesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useDeleteLeakagesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLeakagesMutation, DeleteLeakagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLeakagesMutation, DeleteLeakagesMutationVariables>(DeleteLeakagesDocument, options);
      }
export type DeleteLeakagesMutationHookResult = ReturnType<typeof useDeleteLeakagesMutation>;
export type DeleteLeakagesMutationResult = Apollo.MutationResult<DeleteLeakagesMutation>;
export type DeleteLeakagesMutationOptions = Apollo.BaseMutationOptions<DeleteLeakagesMutation, DeleteLeakagesMutationVariables>;
export const FinalDeleteLeakageDocument = gql`
    mutation finalDeleteLeakage($id: ID!, $realmName: String!) {
  deleteLeakage(id: $id, realmName: $realmName) {
    success
  }
}
    `;
export type FinalDeleteLeakageMutationFn = Apollo.MutationFunction<FinalDeleteLeakageMutation, FinalDeleteLeakageMutationVariables>;

/**
 * __useFinalDeleteLeakageMutation__
 *
 * To run a mutation, you first call `useFinalDeleteLeakageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalDeleteLeakageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalDeleteLeakageMutation, { data, loading, error }] = useFinalDeleteLeakageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useFinalDeleteLeakageMutation(baseOptions?: Apollo.MutationHookOptions<FinalDeleteLeakageMutation, FinalDeleteLeakageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinalDeleteLeakageMutation, FinalDeleteLeakageMutationVariables>(FinalDeleteLeakageDocument, options);
      }
export type FinalDeleteLeakageMutationHookResult = ReturnType<typeof useFinalDeleteLeakageMutation>;
export type FinalDeleteLeakageMutationResult = Apollo.MutationResult<FinalDeleteLeakageMutation>;
export type FinalDeleteLeakageMutationOptions = Apollo.BaseMutationOptions<FinalDeleteLeakageMutation, FinalDeleteLeakageMutationVariables>;
export const RestoreLeakageDocument = gql`
    mutation restoreLeakage($id: ID!, $realmName: String!) {
  restoreLeakage(id: $id, realmName: $realmName) {
    success
  }
}
    `;
export type RestoreLeakageMutationFn = Apollo.MutationFunction<RestoreLeakageMutation, RestoreLeakageMutationVariables>;

/**
 * __useRestoreLeakageMutation__
 *
 * To run a mutation, you first call `useRestoreLeakageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreLeakageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreLeakageMutation, { data, loading, error }] = useRestoreLeakageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useRestoreLeakageMutation(baseOptions?: Apollo.MutationHookOptions<RestoreLeakageMutation, RestoreLeakageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreLeakageMutation, RestoreLeakageMutationVariables>(RestoreLeakageDocument, options);
      }
export type RestoreLeakageMutationHookResult = ReturnType<typeof useRestoreLeakageMutation>;
export type RestoreLeakageMutationResult = Apollo.MutationResult<RestoreLeakageMutation>;
export type RestoreLeakageMutationOptions = Apollo.BaseMutationOptions<RestoreLeakageMutation, RestoreLeakageMutationVariables>;
export const MoveLeakageDocument = gql`
    mutation moveLeakage($params: MoveLeakage!, $realmName: String!) {
  moveLeakage(params: $params, realmName: $realmName) {
    companyId
    projectId
    groupId
  }
}
    `;
export type MoveLeakageMutationFn = Apollo.MutationFunction<MoveLeakageMutation, MoveLeakageMutationVariables>;

/**
 * __useMoveLeakageMutation__
 *
 * To run a mutation, you first call `useMoveLeakageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveLeakageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveLeakageMutation, { data, loading, error }] = useMoveLeakageMutation({
 *   variables: {
 *      params: // value for 'params'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useMoveLeakageMutation(baseOptions?: Apollo.MutationHookOptions<MoveLeakageMutation, MoveLeakageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveLeakageMutation, MoveLeakageMutationVariables>(MoveLeakageDocument, options);
      }
export type MoveLeakageMutationHookResult = ReturnType<typeof useMoveLeakageMutation>;
export type MoveLeakageMutationResult = Apollo.MutationResult<MoveLeakageMutation>;
export type MoveLeakageMutationOptions = Apollo.BaseMutationOptions<MoveLeakageMutation, MoveLeakageMutationVariables>;
export const CreateOrUpdateLeakageDocument = gql`
    mutation createOrUpdateLeakage($leakage: UpdateLeakage!, $realmName: String!) {
  createOrUpdateLeakage(leakage: $leakage, realmName: $realmName) {
    id
  }
}
    `;
export type CreateOrUpdateLeakageMutationFn = Apollo.MutationFunction<CreateOrUpdateLeakageMutation, CreateOrUpdateLeakageMutationVariables>;

/**
 * __useCreateOrUpdateLeakageMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateLeakageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateLeakageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateLeakageMutation, { data, loading, error }] = useCreateOrUpdateLeakageMutation({
 *   variables: {
 *      leakage: // value for 'leakage'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useCreateOrUpdateLeakageMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateLeakageMutation, CreateOrUpdateLeakageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateLeakageMutation, CreateOrUpdateLeakageMutationVariables>(CreateOrUpdateLeakageDocument, options);
      }
export type CreateOrUpdateLeakageMutationHookResult = ReturnType<typeof useCreateOrUpdateLeakageMutation>;
export type CreateOrUpdateLeakageMutationResult = Apollo.MutationResult<CreateOrUpdateLeakageMutation>;
export type CreateOrUpdateLeakageMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateLeakageMutation, CreateOrUpdateLeakageMutationVariables>;
export const DeletedLeakagesDocument = gql`
    query deletedLeakages($realmName: String!) {
  deletedLeakages(params: {page: 0, size: 5000}, realmName: $realmName) {
    leakages {
      id
      leakTag
      building {
        name
      }
      place {
        id
        name
      }
      location
      _deleted_at
      groupPath
    }
  }
}
    `;

/**
 * __useDeletedLeakagesQuery__
 *
 * To run a query within a React component, call `useDeletedLeakagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeletedLeakagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedLeakagesQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useDeletedLeakagesQuery(baseOptions: Apollo.QueryHookOptions<DeletedLeakagesQuery, DeletedLeakagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeletedLeakagesQuery, DeletedLeakagesQueryVariables>(DeletedLeakagesDocument, options);
      }
export function useDeletedLeakagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeletedLeakagesQuery, DeletedLeakagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeletedLeakagesQuery, DeletedLeakagesQueryVariables>(DeletedLeakagesDocument, options);
        }
export type DeletedLeakagesQueryHookResult = ReturnType<typeof useDeletedLeakagesQuery>;
export type DeletedLeakagesLazyQueryHookResult = ReturnType<typeof useDeletedLeakagesLazyQuery>;
export type DeletedLeakagesQueryResult = Apollo.QueryResult<DeletedLeakagesQuery, DeletedLeakagesQueryVariables>;
export const LeakagesOfProjectDocument = gql`
    query leakagesOfProject($projectId: ID!, $realmName: String!) {
  leakagesOfProject(projectId: $projectId, realmName: $realmName) {
    leakages {
      id
      commF
      commM
      commR
      comment
      costBase
      costRelevantAttributesEdited
      costTime
      costUnit
      date
      distance
      gain
      gasType
      images {
        id
        selected
        originalImage
        name
        contents
      }
      leakTag
      level
      location
      place {
        id
        name
      }
      pressure
      resCost
      resFlag
      resLeakage
      resUnitL
      sn
      sw
      threshold
      unitD
      unitP
      unitV
      unitC
      version
      building {
        id
        name
        _deleted_at
      }
      projectId
      companyId
      priority
      resolvableUnderPressure
      note
      resolved
      resolvedAt
      resolvedBy
      _deleted_at
      lossCosts
      status
      canEdit
    }
    pageInfo {
      offset
      count
      size
    }
  }
}
    `;

/**
 * __useLeakagesOfProjectQuery__
 *
 * To run a query within a React component, call `useLeakagesOfProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeakagesOfProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeakagesOfProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useLeakagesOfProjectQuery(baseOptions: Apollo.QueryHookOptions<LeakagesOfProjectQuery, LeakagesOfProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LeakagesOfProjectQuery, LeakagesOfProjectQueryVariables>(LeakagesOfProjectDocument, options);
      }
export function useLeakagesOfProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeakagesOfProjectQuery, LeakagesOfProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LeakagesOfProjectQuery, LeakagesOfProjectQueryVariables>(LeakagesOfProjectDocument, options);
        }
export type LeakagesOfProjectQueryHookResult = ReturnType<typeof useLeakagesOfProjectQuery>;
export type LeakagesOfProjectLazyQueryHookResult = ReturnType<typeof useLeakagesOfProjectLazyQuery>;
export type LeakagesOfProjectQueryResult = Apollo.QueryResult<LeakagesOfProjectQuery, LeakagesOfProjectQueryVariables>;
export const LeakagesOfProjectWithImagesDocument = gql`
    query leakagesOfProjectWithImages($projectId: ID!, $realmName: String!) {
  leakagesOfProjectWithImages(projectId: $projectId, realmName: $realmName) {
    leakages {
      id
      commF
      commM
      commR
      comment
      costBase
      costRelevantAttributesEdited
      costTime
      costUnit
      date
      distance
      gain
      gasType
      images {
        id
        selected
        originalImage
        name
        contents
      }
      leakTag
      level
      location
      place {
        id
        name
      }
      pressure
      resCost
      resFlag
      resLeakage
      resUnitL
      sn
      sw
      threshold
      unitD
      unitP
      unitV
      unitC
      version
      building {
        id
        name
        _deleted_at
      }
      projectId
      companyId
      priority
      resolvableUnderPressure
      note
      resolved
      resolvedAt
      resolvedBy
      _deleted_at
      lossCosts
      status
      canEdit
    }
    pageInfo {
      offset
      count
      size
    }
  }
}
    `;

/**
 * __useLeakagesOfProjectWithImagesQuery__
 *
 * To run a query within a React component, call `useLeakagesOfProjectWithImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeakagesOfProjectWithImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeakagesOfProjectWithImagesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useLeakagesOfProjectWithImagesQuery(baseOptions: Apollo.QueryHookOptions<LeakagesOfProjectWithImagesQuery, LeakagesOfProjectWithImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LeakagesOfProjectWithImagesQuery, LeakagesOfProjectWithImagesQueryVariables>(LeakagesOfProjectWithImagesDocument, options);
      }
export function useLeakagesOfProjectWithImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeakagesOfProjectWithImagesQuery, LeakagesOfProjectWithImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LeakagesOfProjectWithImagesQuery, LeakagesOfProjectWithImagesQueryVariables>(LeakagesOfProjectWithImagesDocument, options);
        }
export type LeakagesOfProjectWithImagesQueryHookResult = ReturnType<typeof useLeakagesOfProjectWithImagesQuery>;
export type LeakagesOfProjectWithImagesLazyQueryHookResult = ReturnType<typeof useLeakagesOfProjectWithImagesLazyQuery>;
export type LeakagesOfProjectWithImagesQueryResult = Apollo.QueryResult<LeakagesOfProjectWithImagesQuery, LeakagesOfProjectWithImagesQueryVariables>;
export const GetUpdateInfoDocument = gql`
    query getUpdateInfo($realmName: String!) {
  getLDVersion(realmName: $realmName) {
    id
    sn
    sw
    projectName
  }
}
    `;

/**
 * __useGetUpdateInfoQuery__
 *
 * To run a query within a React component, call `useGetUpdateInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpdateInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpdateInfoQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useGetUpdateInfoQuery(baseOptions: Apollo.QueryHookOptions<GetUpdateInfoQuery, GetUpdateInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUpdateInfoQuery, GetUpdateInfoQueryVariables>(GetUpdateInfoDocument, options);
      }
export function useGetUpdateInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUpdateInfoQuery, GetUpdateInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUpdateInfoQuery, GetUpdateInfoQueryVariables>(GetUpdateInfoDocument, options);
        }
export type GetUpdateInfoQueryHookResult = ReturnType<typeof useGetUpdateInfoQuery>;
export type GetUpdateInfoLazyQueryHookResult = ReturnType<typeof useGetUpdateInfoLazyQuery>;
export type GetUpdateInfoQueryResult = Apollo.QueryResult<GetUpdateInfoQuery, GetUpdateInfoQueryVariables>;
export const GetLeakageByIdDocument = gql`
    query getLeakageById($ID: ID!, $realmName: String!) {
  leakage(id: $ID, realmName: $realmName) {
    id
    commF
    commM
    commR
    comment
    costBase
    costRelevantAttributesEdited
    costTime
    costUnit
    date
    distance
    gain
    gasType
    images {
      id
      selected
      originalImage
      name
      contents
    }
    leakTag
    level
    location
    place {
      id
      name
    }
    pressure
    resCost
    resFlag
    resLeakage
    resUnitL
    sn
    sw
    threshold
    unitD
    unitP
    unitV
    unitC
    version
    building {
      id
      name
      _deleted_at
    }
    projectId
    companyId
    priority
    resolvableUnderPressure
    note
    resolved
    resolvedAt
    resolvedBy
    _deleted_at
    lossCosts
    status
    canEdit
  }
}
    `;

/**
 * __useGetLeakageByIdQuery__
 *
 * To run a query within a React component, call `useGetLeakageByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeakageByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeakageByIdQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useGetLeakageByIdQuery(baseOptions: Apollo.QueryHookOptions<GetLeakageByIdQuery, GetLeakageByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLeakageByIdQuery, GetLeakageByIdQueryVariables>(GetLeakageByIdDocument, options);
      }
export function useGetLeakageByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLeakageByIdQuery, GetLeakageByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLeakageByIdQuery, GetLeakageByIdQueryVariables>(GetLeakageByIdDocument, options);
        }
export type GetLeakageByIdQueryHookResult = ReturnType<typeof useGetLeakageByIdQuery>;
export type GetLeakageByIdLazyQueryHookResult = ReturnType<typeof useGetLeakageByIdLazyQuery>;
export type GetLeakageByIdQueryResult = Apollo.QueryResult<GetLeakageByIdQuery, GetLeakageByIdQueryVariables>;
export const GetPlacesDocument = gql`
    query GetPlaces($buildingID: ID!, $realmName: String!) {
  getPlaces(building_id: $buildingID, realmName: $realmName) {
    id
    name
    building_id
  }
}
    `;

/**
 * __useGetPlacesQuery__
 *
 * To run a query within a React component, call `useGetPlacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlacesQuery({
 *   variables: {
 *      buildingID: // value for 'buildingID'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useGetPlacesQuery(baseOptions: Apollo.QueryHookOptions<GetPlacesQuery, GetPlacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlacesQuery, GetPlacesQueryVariables>(GetPlacesDocument, options);
      }
export function useGetPlacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlacesQuery, GetPlacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlacesQuery, GetPlacesQueryVariables>(GetPlacesDocument, options);
        }
export type GetPlacesQueryHookResult = ReturnType<typeof useGetPlacesQuery>;
export type GetPlacesLazyQueryHookResult = ReturnType<typeof useGetPlacesLazyQuery>;
export type GetPlacesQueryResult = Apollo.QueryResult<GetPlacesQuery, GetPlacesQueryVariables>;
export const DeletedPlacesDocument = gql`
    query DeletedPlaces($realmName: String!) {
  deletedPlaces(realmName: $realmName) {
    id
    name
    _deleted_at
  }
}
    `;

/**
 * __useDeletedPlacesQuery__
 *
 * To run a query within a React component, call `useDeletedPlacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeletedPlacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedPlacesQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useDeletedPlacesQuery(baseOptions: Apollo.QueryHookOptions<DeletedPlacesQuery, DeletedPlacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeletedPlacesQuery, DeletedPlacesQueryVariables>(DeletedPlacesDocument, options);
      }
export function useDeletedPlacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeletedPlacesQuery, DeletedPlacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeletedPlacesQuery, DeletedPlacesQueryVariables>(DeletedPlacesDocument, options);
        }
export type DeletedPlacesQueryHookResult = ReturnType<typeof useDeletedPlacesQuery>;
export type DeletedPlacesLazyQueryHookResult = ReturnType<typeof useDeletedPlacesLazyQuery>;
export type DeletedPlacesQueryResult = Apollo.QueryResult<DeletedPlacesQuery, DeletedPlacesQueryVariables>;
export const CreatePlaceDocument = gql`
    mutation CreatePlace($placeInput: PlaceInput!, $realmName: String!) {
  createPlace(placeInput: $placeInput, realmName: $realmName) {
    id
    name
    building_id
  }
}
    `;
export type CreatePlaceMutationFn = Apollo.MutationFunction<CreatePlaceMutation, CreatePlaceMutationVariables>;

/**
 * __useCreatePlaceMutation__
 *
 * To run a mutation, you first call `useCreatePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaceMutation, { data, loading, error }] = useCreatePlaceMutation({
 *   variables: {
 *      placeInput: // value for 'placeInput'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useCreatePlaceMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlaceMutation, CreatePlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlaceMutation, CreatePlaceMutationVariables>(CreatePlaceDocument, options);
      }
export type CreatePlaceMutationHookResult = ReturnType<typeof useCreatePlaceMutation>;
export type CreatePlaceMutationResult = Apollo.MutationResult<CreatePlaceMutation>;
export type CreatePlaceMutationOptions = Apollo.BaseMutationOptions<CreatePlaceMutation, CreatePlaceMutationVariables>;
export const UpdatePlaceDocument = gql`
    mutation UpdatePlace($placeInput: PlaceInput!, $realmName: String!, $placeID: ID!) {
  updatePlace(placeInput: $placeInput, realmName: $realmName, placeID: $placeID) {
    id
    name
    building_id
  }
}
    `;
export type UpdatePlaceMutationFn = Apollo.MutationFunction<UpdatePlaceMutation, UpdatePlaceMutationVariables>;

/**
 * __useUpdatePlaceMutation__
 *
 * To run a mutation, you first call `useUpdatePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaceMutation, { data, loading, error }] = useUpdatePlaceMutation({
 *   variables: {
 *      placeInput: // value for 'placeInput'
 *      realmName: // value for 'realmName'
 *      placeID: // value for 'placeID'
 *   },
 * });
 */
export function useUpdatePlaceMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlaceMutation, UpdatePlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlaceMutation, UpdatePlaceMutationVariables>(UpdatePlaceDocument, options);
      }
export type UpdatePlaceMutationHookResult = ReturnType<typeof useUpdatePlaceMutation>;
export type UpdatePlaceMutationResult = Apollo.MutationResult<UpdatePlaceMutation>;
export type UpdatePlaceMutationOptions = Apollo.BaseMutationOptions<UpdatePlaceMutation, UpdatePlaceMutationVariables>;
export const DeletePlaceDocument = gql`
    mutation DeletePlace($placeID: ID!, $realmName: String!) {
  deletePlace(id: $placeID, realmName: $realmName) {
    success
  }
}
    `;
export type DeletePlaceMutationFn = Apollo.MutationFunction<DeletePlaceMutation, DeletePlaceMutationVariables>;

/**
 * __useDeletePlaceMutation__
 *
 * To run a mutation, you first call `useDeletePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlaceMutation, { data, loading, error }] = useDeletePlaceMutation({
 *   variables: {
 *      placeID: // value for 'placeID'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useDeletePlaceMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlaceMutation, DeletePlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlaceMutation, DeletePlaceMutationVariables>(DeletePlaceDocument, options);
      }
export type DeletePlaceMutationHookResult = ReturnType<typeof useDeletePlaceMutation>;
export type DeletePlaceMutationResult = Apollo.MutationResult<DeletePlaceMutation>;
export type DeletePlaceMutationOptions = Apollo.BaseMutationOptions<DeletePlaceMutation, DeletePlaceMutationVariables>;
export const RestorePlaceDocument = gql`
    mutation RestorePlace($placeID: ID!, $realmName: String!) {
  restorePlace(id: $placeID, realmName: $realmName) {
    success
  }
}
    `;
export type RestorePlaceMutationFn = Apollo.MutationFunction<RestorePlaceMutation, RestorePlaceMutationVariables>;

/**
 * __useRestorePlaceMutation__
 *
 * To run a mutation, you first call `useRestorePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestorePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restorePlaceMutation, { data, loading, error }] = useRestorePlaceMutation({
 *   variables: {
 *      placeID: // value for 'placeID'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useRestorePlaceMutation(baseOptions?: Apollo.MutationHookOptions<RestorePlaceMutation, RestorePlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestorePlaceMutation, RestorePlaceMutationVariables>(RestorePlaceDocument, options);
      }
export type RestorePlaceMutationHookResult = ReturnType<typeof useRestorePlaceMutation>;
export type RestorePlaceMutationResult = Apollo.MutationResult<RestorePlaceMutation>;
export type RestorePlaceMutationOptions = Apollo.BaseMutationOptions<RestorePlaceMutation, RestorePlaceMutationVariables>;
export const SoftDeletePlaceDocument = gql`
    mutation SoftDeletePlace($placeID: ID!, $realmName: String!) {
  softDeletePlace(id: $placeID, realmName: $realmName) {
    success
  }
}
    `;
export type SoftDeletePlaceMutationFn = Apollo.MutationFunction<SoftDeletePlaceMutation, SoftDeletePlaceMutationVariables>;

/**
 * __useSoftDeletePlaceMutation__
 *
 * To run a mutation, you first call `useSoftDeletePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeletePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeletePlaceMutation, { data, loading, error }] = useSoftDeletePlaceMutation({
 *   variables: {
 *      placeID: // value for 'placeID'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useSoftDeletePlaceMutation(baseOptions?: Apollo.MutationHookOptions<SoftDeletePlaceMutation, SoftDeletePlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SoftDeletePlaceMutation, SoftDeletePlaceMutationVariables>(SoftDeletePlaceDocument, options);
      }
export type SoftDeletePlaceMutationHookResult = ReturnType<typeof useSoftDeletePlaceMutation>;
export type SoftDeletePlaceMutationResult = Apollo.MutationResult<SoftDeletePlaceMutation>;
export type SoftDeletePlaceMutationOptions = Apollo.BaseMutationOptions<SoftDeletePlaceMutation, SoftDeletePlaceMutationVariables>;
export const GetBuildingsAndPlacesDocument = gql`
    query GetBuildingsAndPlaces($companyId: ID!, $filterIDs: [ID!], $placesFilterIDs: [ID!], $groupId: String, $realmName: String!) {
  buildings(
    companyId: $companyId
    filterIDs: $filterIDs
    placesFilterIDs: $placesFilterIDs
    groupId: $groupId
    realmName: $realmName
  ) {
    id
    name
    _deleted_at
    places {
      id
      name
      building_id
      _deleted_at
      assigned
      usedLeakages
    }
    assigned
    usedLeakages
  }
}
    `;

/**
 * __useGetBuildingsAndPlacesQuery__
 *
 * To run a query within a React component, call `useGetBuildingsAndPlacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildingsAndPlacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildingsAndPlacesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      filterIDs: // value for 'filterIDs'
 *      placesFilterIDs: // value for 'placesFilterIDs'
 *      groupId: // value for 'groupId'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useGetBuildingsAndPlacesQuery(baseOptions: Apollo.QueryHookOptions<GetBuildingsAndPlacesQuery, GetBuildingsAndPlacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBuildingsAndPlacesQuery, GetBuildingsAndPlacesQueryVariables>(GetBuildingsAndPlacesDocument, options);
      }
export function useGetBuildingsAndPlacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBuildingsAndPlacesQuery, GetBuildingsAndPlacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBuildingsAndPlacesQuery, GetBuildingsAndPlacesQueryVariables>(GetBuildingsAndPlacesDocument, options);
        }
export type GetBuildingsAndPlacesQueryHookResult = ReturnType<typeof useGetBuildingsAndPlacesQuery>;
export type GetBuildingsAndPlacesLazyQueryHookResult = ReturnType<typeof useGetBuildingsAndPlacesLazyQuery>;
export type GetBuildingsAndPlacesQueryResult = Apollo.QueryResult<GetBuildingsAndPlacesQuery, GetBuildingsAndPlacesQueryVariables>;
export const MergePlacesDocument = gql`
    mutation mergePlaces($sourcePlaceId: ID!, $targetBuildingId: ID!, $targetPlaceId: ID!, $realmName: String!) {
  mergePlaces(
    sourcePlaceId: $sourcePlaceId
    targetBuildingId: $targetBuildingId
    targetPlaceId: $targetPlaceId
    realmName: $realmName
  ) {
    success
  }
}
    `;
export type MergePlacesMutationFn = Apollo.MutationFunction<MergePlacesMutation, MergePlacesMutationVariables>;

/**
 * __useMergePlacesMutation__
 *
 * To run a mutation, you first call `useMergePlacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergePlacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergePlacesMutation, { data, loading, error }] = useMergePlacesMutation({
 *   variables: {
 *      sourcePlaceId: // value for 'sourcePlaceId'
 *      targetBuildingId: // value for 'targetBuildingId'
 *      targetPlaceId: // value for 'targetPlaceId'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useMergePlacesMutation(baseOptions?: Apollo.MutationHookOptions<MergePlacesMutation, MergePlacesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MergePlacesMutation, MergePlacesMutationVariables>(MergePlacesDocument, options);
      }
export type MergePlacesMutationHookResult = ReturnType<typeof useMergePlacesMutation>;
export type MergePlacesMutationResult = Apollo.MutationResult<MergePlacesMutation>;
export type MergePlacesMutationOptions = Apollo.BaseMutationOptions<MergePlacesMutation, MergePlacesMutationVariables>;
export const GetCompanyProjectDocument = gql`
    query getCompanyProject($groupId: String, $companyName: String!, $realmName: String!) {
  getCompany(groupId: $groupId, name: $companyName, realmName: $realmName) {
    company {
      projects {
        projects {
          name
          id
          importDate
          totalLeakage
          unitV
          leakages {
            id
            date
            resUnitL
            resLeakage
          }
        }
        meta {
          canAdd
        }
      }
    }
  }
}
    `;

/**
 * __useGetCompanyProjectQuery__
 *
 * To run a query within a React component, call `useGetCompanyProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyProjectQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      companyName: // value for 'companyName'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useGetCompanyProjectQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyProjectQuery, GetCompanyProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyProjectQuery, GetCompanyProjectQueryVariables>(GetCompanyProjectDocument, options);
      }
export function useGetCompanyProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyProjectQuery, GetCompanyProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyProjectQuery, GetCompanyProjectQueryVariables>(GetCompanyProjectDocument, options);
        }
export type GetCompanyProjectQueryHookResult = ReturnType<typeof useGetCompanyProjectQuery>;
export type GetCompanyProjectLazyQueryHookResult = ReturnType<typeof useGetCompanyProjectLazyQuery>;
export type GetCompanyProjectQueryResult = Apollo.QueryResult<GetCompanyProjectQuery, GetCompanyProjectQueryVariables>;
export const CreateOrUpdateProjectDocument = gql`
    mutation createOrUpdateProject($updateProject: UpdateProject!, $realmName: String!) {
  createOrUpdateProject(project: $updateProject, realmName: $realmName) {
    name
    id
  }
}
    `;
export type CreateOrUpdateProjectMutationFn = Apollo.MutationFunction<CreateOrUpdateProjectMutation, CreateOrUpdateProjectMutationVariables>;

/**
 * __useCreateOrUpdateProjectMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateProjectMutation, { data, loading, error }] = useCreateOrUpdateProjectMutation({
 *   variables: {
 *      updateProject: // value for 'updateProject'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useCreateOrUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateProjectMutation, CreateOrUpdateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateProjectMutation, CreateOrUpdateProjectMutationVariables>(CreateOrUpdateProjectDocument, options);
      }
export type CreateOrUpdateProjectMutationHookResult = ReturnType<typeof useCreateOrUpdateProjectMutation>;
export type CreateOrUpdateProjectMutationResult = Apollo.MutationResult<CreateOrUpdateProjectMutation>;
export type CreateOrUpdateProjectMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateProjectMutation, CreateOrUpdateProjectMutationVariables>;
export const GetProjectByIdDocument = gql`
    query getProjectById($id: ID!, $realmName: String!) {
  getProject(id: $id, realmName: $realmName) {
    id
    costBase
    costTime
    costUnit
    energyAmount
    energyUnit
    unitV
    name
    company
    description
    importDate
    leakages {
      id
    }
    Auditor {
      id
      display_name
      email
    }
    nLeakages
    totalLeakage
    totalCost
    _deleted_at
    co2Amount
    co2Unit
    energyCost
    canEdit
    elPr
    spPw
    uSpPw
    spPwId
    cMod
    sigE3
  }
}
    `;

/**
 * __useGetProjectByIdQuery__
 *
 * To run a query within a React component, call `useGetProjectByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useGetProjectByIdQuery(baseOptions: Apollo.QueryHookOptions<GetProjectByIdQuery, GetProjectByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectByIdQuery, GetProjectByIdQueryVariables>(GetProjectByIdDocument, options);
      }
export function useGetProjectByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectByIdQuery, GetProjectByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectByIdQuery, GetProjectByIdQueryVariables>(GetProjectByIdDocument, options);
        }
export type GetProjectByIdQueryHookResult = ReturnType<typeof useGetProjectByIdQuery>;
export type GetProjectByIdLazyQueryHookResult = ReturnType<typeof useGetProjectByIdLazyQuery>;
export type GetProjectByIdQueryResult = Apollo.QueryResult<GetProjectByIdQuery, GetProjectByIdQueryVariables>;
export const DeleteProjectDocument = gql`
    mutation deleteProject($id: ID!, $realmName: String!) {
  softDeleteProject(id: $id, realmName: $realmName) {
    success
  }
}
    `;
export type DeleteProjectMutationFn = Apollo.MutationFunction<DeleteProjectMutation, DeleteProjectMutationVariables>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, options);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const FinalDeleteProjectDocument = gql`
    mutation finalDeleteProject($id: ID!, $realmName: String!) {
  deleteProject(id: $id, realmName: $realmName) {
    success
  }
}
    `;
export type FinalDeleteProjectMutationFn = Apollo.MutationFunction<FinalDeleteProjectMutation, FinalDeleteProjectMutationVariables>;

/**
 * __useFinalDeleteProjectMutation__
 *
 * To run a mutation, you first call `useFinalDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalDeleteProjectMutation, { data, loading, error }] = useFinalDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useFinalDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<FinalDeleteProjectMutation, FinalDeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinalDeleteProjectMutation, FinalDeleteProjectMutationVariables>(FinalDeleteProjectDocument, options);
      }
export type FinalDeleteProjectMutationHookResult = ReturnType<typeof useFinalDeleteProjectMutation>;
export type FinalDeleteProjectMutationResult = Apollo.MutationResult<FinalDeleteProjectMutation>;
export type FinalDeleteProjectMutationOptions = Apollo.BaseMutationOptions<FinalDeleteProjectMutation, FinalDeleteProjectMutationVariables>;
export const RestoreProjectDocument = gql`
    mutation restoreProject($id: ID!, $realmName: String!) {
  restoreProject(id: $id, realmName: $realmName) {
    success
  }
}
    `;
export type RestoreProjectMutationFn = Apollo.MutationFunction<RestoreProjectMutation, RestoreProjectMutationVariables>;

/**
 * __useRestoreProjectMutation__
 *
 * To run a mutation, you first call `useRestoreProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreProjectMutation, { data, loading, error }] = useRestoreProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useRestoreProjectMutation(baseOptions?: Apollo.MutationHookOptions<RestoreProjectMutation, RestoreProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreProjectMutation, RestoreProjectMutationVariables>(RestoreProjectDocument, options);
      }
export type RestoreProjectMutationHookResult = ReturnType<typeof useRestoreProjectMutation>;
export type RestoreProjectMutationResult = Apollo.MutationResult<RestoreProjectMutation>;
export type RestoreProjectMutationOptions = Apollo.BaseMutationOptions<RestoreProjectMutation, RestoreProjectMutationVariables>;
export const DeletedProjectsDocument = gql`
    query deletedProjects($realmName: String!) {
  deletedProjects(realmName: $realmName) {
    id
    name
    groupPath
    _deleted_at
  }
}
    `;

/**
 * __useDeletedProjectsQuery__
 *
 * To run a query within a React component, call `useDeletedProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeletedProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedProjectsQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useDeletedProjectsQuery(baseOptions: Apollo.QueryHookOptions<DeletedProjectsQuery, DeletedProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeletedProjectsQuery, DeletedProjectsQueryVariables>(DeletedProjectsDocument, options);
      }
export function useDeletedProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeletedProjectsQuery, DeletedProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeletedProjectsQuery, DeletedProjectsQueryVariables>(DeletedProjectsDocument, options);
        }
export type DeletedProjectsQueryHookResult = ReturnType<typeof useDeletedProjectsQuery>;
export type DeletedProjectsLazyQueryHookResult = ReturnType<typeof useDeletedProjectsLazyQuery>;
export type DeletedProjectsQueryResult = Apollo.QueryResult<DeletedProjectsQuery, DeletedProjectsQueryVariables>;
export const GetProjectAuditorsDocument = gql`
    query getProjectAuditors($realmName: String!) {
  getAuditors(realmName: $realmName) {
    id
    display_name
  }
}
    `;

/**
 * __useGetProjectAuditorsQuery__
 *
 * To run a query within a React component, call `useGetProjectAuditorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectAuditorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectAuditorsQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useGetProjectAuditorsQuery(baseOptions: Apollo.QueryHookOptions<GetProjectAuditorsQuery, GetProjectAuditorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectAuditorsQuery, GetProjectAuditorsQueryVariables>(GetProjectAuditorsDocument, options);
      }
export function useGetProjectAuditorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectAuditorsQuery, GetProjectAuditorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectAuditorsQuery, GetProjectAuditorsQueryVariables>(GetProjectAuditorsDocument, options);
        }
export type GetProjectAuditorsQueryHookResult = ReturnType<typeof useGetProjectAuditorsQuery>;
export type GetProjectAuditorsLazyQueryHookResult = ReturnType<typeof useGetProjectAuditorsLazyQuery>;
export type GetProjectAuditorsQueryResult = Apollo.QueryResult<GetProjectAuditorsQuery, GetProjectAuditorsQueryVariables>;
export const GetTagAttributesDocument = gql`
    query getTagAttributes($realmName: String!) {
  getTagAttributes(realmName: $realmName) {
    ID
    name
    attributes {
      attributeID
      attributeType
    }
  }
}
    `;

/**
 * __useGetTagAttributesQuery__
 *
 * To run a query within a React component, call `useGetTagAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagAttributesQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useGetTagAttributesQuery(baseOptions: Apollo.QueryHookOptions<GetTagAttributesQuery, GetTagAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagAttributesQuery, GetTagAttributesQueryVariables>(GetTagAttributesDocument, options);
      }
export function useGetTagAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagAttributesQuery, GetTagAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagAttributesQuery, GetTagAttributesQueryVariables>(GetTagAttributesDocument, options);
        }
export type GetTagAttributesQueryHookResult = ReturnType<typeof useGetTagAttributesQuery>;
export type GetTagAttributesLazyQueryHookResult = ReturnType<typeof useGetTagAttributesLazyQuery>;
export type GetTagAttributesQueryResult = Apollo.QueryResult<GetTagAttributesQuery, GetTagAttributesQueryVariables>;
export const GetManageAbleAttributesDocument = gql`
    query getManageAbleAttributes($realmName: String!) {
  getAllTagAttributes(realmName: $realmName) {
    ID
    name
    attributes {
      attributeID
      attributeType
    }
  }
}
    `;

/**
 * __useGetManageAbleAttributesQuery__
 *
 * To run a query within a React component, call `useGetManageAbleAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageAbleAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageAbleAttributesQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useGetManageAbleAttributesQuery(baseOptions: Apollo.QueryHookOptions<GetManageAbleAttributesQuery, GetManageAbleAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManageAbleAttributesQuery, GetManageAbleAttributesQueryVariables>(GetManageAbleAttributesDocument, options);
      }
export function useGetManageAbleAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManageAbleAttributesQuery, GetManageAbleAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManageAbleAttributesQuery, GetManageAbleAttributesQueryVariables>(GetManageAbleAttributesDocument, options);
        }
export type GetManageAbleAttributesQueryHookResult = ReturnType<typeof useGetManageAbleAttributesQuery>;
export type GetManageAbleAttributesLazyQueryHookResult = ReturnType<typeof useGetManageAbleAttributesLazyQuery>;
export type GetManageAbleAttributesQueryResult = Apollo.QueryResult<GetManageAbleAttributesQuery, GetManageAbleAttributesQueryVariables>;
export const GetDeletedTagAttributesDocument = gql`
    query getDeletedTagAttributes($realmName: String!) {
  getDeletedTagAttributes(realmName: $realmName) {
    ID
    name
    _deleted_at
  }
}
    `;

/**
 * __useGetDeletedTagAttributesQuery__
 *
 * To run a query within a React component, call `useGetDeletedTagAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeletedTagAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeletedTagAttributesQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useGetDeletedTagAttributesQuery(baseOptions: Apollo.QueryHookOptions<GetDeletedTagAttributesQuery, GetDeletedTagAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeletedTagAttributesQuery, GetDeletedTagAttributesQueryVariables>(GetDeletedTagAttributesDocument, options);
      }
export function useGetDeletedTagAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeletedTagAttributesQuery, GetDeletedTagAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeletedTagAttributesQuery, GetDeletedTagAttributesQueryVariables>(GetDeletedTagAttributesDocument, options);
        }
export type GetDeletedTagAttributesQueryHookResult = ReturnType<typeof useGetDeletedTagAttributesQuery>;
export type GetDeletedTagAttributesLazyQueryHookResult = ReturnType<typeof useGetDeletedTagAttributesLazyQuery>;
export type GetDeletedTagAttributesQueryResult = Apollo.QueryResult<GetDeletedTagAttributesQuery, GetDeletedTagAttributesQueryVariables>;
export const DeleteTagAttributeDocument = gql`
    mutation deleteTagAttribute($ID: ID!, $realmName: String!) {
  deleteTagAttribute(Id: $ID, realmName: $realmName)
}
    `;
export type DeleteTagAttributeMutationFn = Apollo.MutationFunction<DeleteTagAttributeMutation, DeleteTagAttributeMutationVariables>;

/**
 * __useDeleteTagAttributeMutation__
 *
 * To run a mutation, you first call `useDeleteTagAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagAttributeMutation, { data, loading, error }] = useDeleteTagAttributeMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useDeleteTagAttributeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTagAttributeMutation, DeleteTagAttributeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTagAttributeMutation, DeleteTagAttributeMutationVariables>(DeleteTagAttributeDocument, options);
      }
export type DeleteTagAttributeMutationHookResult = ReturnType<typeof useDeleteTagAttributeMutation>;
export type DeleteTagAttributeMutationResult = Apollo.MutationResult<DeleteTagAttributeMutation>;
export type DeleteTagAttributeMutationOptions = Apollo.BaseMutationOptions<DeleteTagAttributeMutation, DeleteTagAttributeMutationVariables>;
export const RestoreTagAttributeDocument = gql`
    mutation restoreTagAttribute($ID: ID!, $realmName: String!) {
  restoreTagAttribute(Id: $ID, realmName: $realmName)
}
    `;
export type RestoreTagAttributeMutationFn = Apollo.MutationFunction<RestoreTagAttributeMutation, RestoreTagAttributeMutationVariables>;

/**
 * __useRestoreTagAttributeMutation__
 *
 * To run a mutation, you first call `useRestoreTagAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreTagAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreTagAttributeMutation, { data, loading, error }] = useRestoreTagAttributeMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useRestoreTagAttributeMutation(baseOptions?: Apollo.MutationHookOptions<RestoreTagAttributeMutation, RestoreTagAttributeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreTagAttributeMutation, RestoreTagAttributeMutationVariables>(RestoreTagAttributeDocument, options);
      }
export type RestoreTagAttributeMutationHookResult = ReturnType<typeof useRestoreTagAttributeMutation>;
export type RestoreTagAttributeMutationResult = Apollo.MutationResult<RestoreTagAttributeMutation>;
export type RestoreTagAttributeMutationOptions = Apollo.BaseMutationOptions<RestoreTagAttributeMutation, RestoreTagAttributeMutationVariables>;
export const SoftDeleteTagAttributeDocument = gql`
    mutation softDeleteTagAttribute($ID: ID!, $realmName: String!) {
  softDeleteTagAttribute(Id: $ID, realmName: $realmName)
}
    `;
export type SoftDeleteTagAttributeMutationFn = Apollo.MutationFunction<SoftDeleteTagAttributeMutation, SoftDeleteTagAttributeMutationVariables>;

/**
 * __useSoftDeleteTagAttributeMutation__
 *
 * To run a mutation, you first call `useSoftDeleteTagAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteTagAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteTagAttributeMutation, { data, loading, error }] = useSoftDeleteTagAttributeMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useSoftDeleteTagAttributeMutation(baseOptions?: Apollo.MutationHookOptions<SoftDeleteTagAttributeMutation, SoftDeleteTagAttributeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SoftDeleteTagAttributeMutation, SoftDeleteTagAttributeMutationVariables>(SoftDeleteTagAttributeDocument, options);
      }
export type SoftDeleteTagAttributeMutationHookResult = ReturnType<typeof useSoftDeleteTagAttributeMutation>;
export type SoftDeleteTagAttributeMutationResult = Apollo.MutationResult<SoftDeleteTagAttributeMutation>;
export type SoftDeleteTagAttributeMutationOptions = Apollo.BaseMutationOptions<SoftDeleteTagAttributeMutation, SoftDeleteTagAttributeMutationVariables>;
export const UpdateTagAttributeDocument = gql`
    mutation updateTagAttribute($ID: ID!, $tagInfo: TagInput!, $realmName: String!) {
  updateTagAttribute(Id: $ID, realmName: $realmName, tagInfo: $tagInfo)
}
    `;
export type UpdateTagAttributeMutationFn = Apollo.MutationFunction<UpdateTagAttributeMutation, UpdateTagAttributeMutationVariables>;

/**
 * __useUpdateTagAttributeMutation__
 *
 * To run a mutation, you first call `useUpdateTagAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagAttributeMutation, { data, loading, error }] = useUpdateTagAttributeMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      tagInfo: // value for 'tagInfo'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useUpdateTagAttributeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTagAttributeMutation, UpdateTagAttributeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTagAttributeMutation, UpdateTagAttributeMutationVariables>(UpdateTagAttributeDocument, options);
      }
export type UpdateTagAttributeMutationHookResult = ReturnType<typeof useUpdateTagAttributeMutation>;
export type UpdateTagAttributeMutationResult = Apollo.MutationResult<UpdateTagAttributeMutation>;
export type UpdateTagAttributeMutationOptions = Apollo.BaseMutationOptions<UpdateTagAttributeMutation, UpdateTagAttributeMutationVariables>;
export const CreateTagAttributeDocument = gql`
    mutation createTagAttribute($tagInfo: TagInput!, $realmName: String!) {
  createTagAttribute(tagInfo: $tagInfo, realmName: $realmName)
}
    `;
export type CreateTagAttributeMutationFn = Apollo.MutationFunction<CreateTagAttributeMutation, CreateTagAttributeMutationVariables>;

/**
 * __useCreateTagAttributeMutation__
 *
 * To run a mutation, you first call `useCreateTagAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagAttributeMutation, { data, loading, error }] = useCreateTagAttributeMutation({
 *   variables: {
 *      tagInfo: // value for 'tagInfo'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useCreateTagAttributeMutation(baseOptions?: Apollo.MutationHookOptions<CreateTagAttributeMutation, CreateTagAttributeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTagAttributeMutation, CreateTagAttributeMutationVariables>(CreateTagAttributeDocument, options);
      }
export type CreateTagAttributeMutationHookResult = ReturnType<typeof useCreateTagAttributeMutation>;
export type CreateTagAttributeMutationResult = Apollo.MutationResult<CreateTagAttributeMutation>;
export type CreateTagAttributeMutationOptions = Apollo.BaseMutationOptions<CreateTagAttributeMutation, CreateTagAttributeMutationVariables>;