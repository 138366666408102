import React, { useCallback, useMemo } from 'react'
import { paddingTop, standardContentPadding } from '../../../../styles/Shared'
import Avatar from '@material-ui/core/Avatar'
import BusinessIcon from '@material-ui/icons/BusinessOutlined'
import Typography from '@material-ui/core/Typography'
import CreateIcon from '@material-ui/icons/CreateOutlined'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routes'
import { CompanyParamTypes } from '../../../../interfaces/PageParams'
import { useViewStyles } from './CompanyMasterDataStyle'
import { useCompany } from '../../../hooks/companyHooks'
import { FixedActionBar } from '@csinstruments/cs-react-theme/dist/components/FixedActionBar/FixedActionBar'
import { usePermissions } from 'app/hooks/usePermissions'
import { useGetQueryParam } from '../utilHooks'

export const CompanyMasterDataView: React.FC = ({}) => {
  const { t } = useTranslation('common')
  const history = useHistory()
  const classes = useViewStyles()
  const { id } = useParams<CompanyParamTypes>()
  const companyName = decodeURIComponent(id)
  const groupId = useGetQueryParam('groupId')
  const { company } = useCompany(companyName, groupId)
  const permissions = usePermissions()

  const goToEditPage = useCallback((): void => {
    let companyEditPath = generatePath(ROUTES.companyAction, { id, action: 'master' }) + '?edit=true'
    companyEditPath = !groupId ? companyEditPath : companyEditPath + `&groupId=${groupId}`
    history.push(companyEditPath)
  }, [history, id])

  const editingPermitted = useMemo(() => {
    return permissions.editCompanies
  }, [permissions])

  return (
    <div style={paddingTop}>
      <FixedActionBar
        buttonProps={[{ solid: true }]}
        actions={[goToEditPage]}
        labels={['actions.edit']}
        conditions={[!!(company && company.canEdit && editingPermitted)]}
        icons={[CreateIcon]}
      />
      <div style={standardContentPadding} className={classes.itemContainer}>
        <div className={classes.avatarContainer}>
          <Avatar>
            <BusinessIcon />
          </Avatar>
        </div>
        <div className={classes.infoContainer}>
          <div>
            <Typography style={{ textTransform: 'uppercase' }} color="secondary" gutterBottom>
              {t('objects.company')}
            </Typography>
            <Typography variant="h6" gutterBottom>
              {company.name}
            </Typography>
          </div>
          <div>
            <Typography style={{ textTransform: 'uppercase' }} color="secondary" gutterBottom>
              {t('address.address')}
            </Typography>
            <Typography variant="h6" gutterBottom>
              {company.address?.street} {company.address?.number}
              <br />
              {company.address?.zip} {company.address?.city}
            </Typography>
          </div>
          <div>
            <Typography style={{ textTransform: 'uppercase' }} color="secondary" gutterBottom>
              {t('contact.contact')}
            </Typography>
            <Typography variant="h6" gutterBottom>
              {company.contact?.prename} {company.contact?.surname}
              <br />
              {company.contact?.phone}
              <br />
              {company.contact?.email}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}
