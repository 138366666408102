import React, { useEffect, useMemo, useState } from 'react'
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined'
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined'
import CloudOffIcon from '@material-ui/icons/CloudOffOutlined'
import Grid from '@material-ui/core/Grid'
import { paddingBottomActionBar, paddingTop, standardContentPadding } from '../../../../styles/Shared'
import { StandardConfirmationModal } from '../../partials/StandardConfirmationModal/StandardConfirmationModal'
import { useClearDatabase, useExportDatabase, useImportDatabase } from '../../../hooks/databaseHooks'
import { FileSelectionResult, SingleFileUploadButton } from '../../partials/Buttons/SingleFileUploadButton'
import { ResultAlert } from '../../partials/ResultAlert'
import { ActionAlertProps } from '../../partials/Alert/Alert'
import { usePushDownloadFile } from '../../../hooks/usePushDownloadFile'
import { TranslatedCsButton } from '../../partials/Buttons/TranslatedCsButton'
import { DatabaseActionItem } from './DatabaseActionItem'
import { ProgressBar } from '../../partials/ProgressBar/ProgressBar'
import { isRenderedByElectron } from '../../../helpers/renderedBy'
import { useTranslation } from 'react-i18next'
import { usePermissions } from 'app/hooks/usePermissions'
import { DatabaseResultModel } from 'app/components/partials/DatabaseResultModal/DatabaseResultModel'

export const Database: React.FC = () => {
  const { t } = useTranslation('common')
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [doFetchDatabase, setDoFetchDatabase] = useState<boolean>(false)
  const { content, exportDone } = useExportDatabase(doFetchDatabase)
  const [importData, setImportData] = useState<FileSelectionResult | undefined>()
  const [uploadState, setUploadState] = useState<number>()
  const { importDone, importError } = useImportDatabase(importData, setUploadState)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [clearDbProgress, setClearDbProgress] = useState<boolean>(false)
  const [databaseResultStatus, setDatabaseResultStatus] = useState<boolean>(false)
  const [alertProps, setAlertProps] = useState<ActionAlertProps>({ label: '', severity: 'success' })
  const { clearDatabase, clearedDatabase } = useClearDatabase()
  const { downloadAction } = usePushDownloadFile(content, exportDone, 'export.zip')
  const permission = usePermissions()
  const hasPermission = useMemo(() => {
    return permission.database
  }, [permission.database])

  const hasRootPermission = useMemo(() => {
    return permission.rootAdmin
  }, [permission.rootAdmin])

  const hasExportPermission = useMemo(() => {
    return permission.exportDatabase
  }, [permission.exportDatabase])

  const loading = useMemo(() => {
    return !!((!exportDone && doFetchDatabase) || (!importDone && !importError && importData) || clearDbProgress)
  }, [exportDone, doFetchDatabase, importDone, importData, importError, clearDbProgress])

  useEffect(() => {
    if (clearedDatabase) {
      setAlertProps({ label: 'databaseImport.clearSuccess', severity: 'success' })
      setClearDbProgress(false)
      setShowAlert(true)
    }
  }, [clearedDatabase])

  useEffect(() => {
    if (importDone) {
      setAlertProps({ label: 'databaseImport.importSuccess', severity: 'success' })

      setImportData(undefined)
      setShowAlert(true)
    }

    if (importError) {
      setAlertProps({ label: 'databaseImport.importFail', severity: 'error' })

      setImportData(undefined)
      setShowAlert(true)
    }
  }, [importDone, importError])

  useEffect(() => {
    if (loading) {
      setDatabaseResultStatus(true)
    } else {
      setDatabaseResultStatus(false)
    }
  }, [loading])

  const handleDownload = (): void => {
    if (!exportDone) {
      setDoFetchDatabase(true)
    } else {
      downloadAction()
    }
  }

  const handleImportData = (result: FileSelectionResult): void => {
    if (result.error) {
      console.error('file not valid')
    } else {
      setImportData(result)
    }
  }

  const handleClearDatabase = (): void => {
    setClearDbProgress(true)
    clearDatabase()
  }

  return (
    <div style={{ ...paddingTop, ...paddingBottomActionBar }}>
      <ResultAlert
        alertText={alertProps.label}
        autoHideDuration={3000}
        severity={alertProps.severity}
        showAlert={showAlert}
        modifyShowAlert={setShowAlert}
      />
      <StandardConfirmationModal
        handleConfirm={handleClearDatabase}
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
      />
      <DatabaseResultModel
        open={databaseResultStatus}
        textHeading={t('modals.databaseActionHeading')}
        dataBaseActionStatus={databaseResultStatus}
        uploadStatus={uploadState}
      />
      <div style={standardContentPadding}>
        <Grid container>
          <Grid item xs={12}>
            <ProgressBar show={loading} progressValue={uploadState} />
          </Grid>

          <DatabaseActionItem description={t('database.import')} title={'actions.importDatabase'}>
            <SingleFileUploadButton
              buttonProps={{ solid: true, style: { verticalAlign: 'bottom' }, disabled: !hasRootPermission || loading }}
              label={'actions.doProjectImport'}
              icon={CloudUploadIcon}
              acceptedType={'application/*'}
              isCloudDb={!isRenderedByElectron()}
              changeHandler={(result) => handleImportData(result)}
            />
          </DatabaseActionItem>

          <DatabaseActionItem description={t('database.export')} title={'actions.exportDatabase'}>
            <TranslatedCsButton
              solid={true}
              disabled={!hasPermission || !hasExportPermission || loading}
              onClick={handleDownload}
              style={{ verticalAlign: 'bottom' }}
              fullwidth={true}
              label={'actions.generateFile'}
            >
              <CloudDownloadIcon />
            </TranslatedCsButton>
          </DatabaseActionItem>

          <DatabaseActionItem description={t('database.clear')} title={'actions.clearDatabase'}>
            <TranslatedCsButton
              solid={true}
              style={{ verticalAlign: 'bottom' }}
              fullwidth={true}
              onClick={() => setModalOpen(true)}
              label={'actions.clearData'}
              disabled={!hasRootPermission || loading}
            >
              <CloudOffIcon />
            </TranslatedCsButton>
          </DatabaseActionItem>
        </Grid>
      </div>
    </div>
  )
}

export default Database
