/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ProjectParamTypes } from '../../../../interfaces/PageParams'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useProject } from '../../../hooks/projectHooks'
import { Leakage } from 'interfaces/BusinessObjects'
import { getCostTime, getPressureCost } from '../../../helpers/calculations'

export type ProjectLeakagesLeakageInfoProps = {
  leakages: Leakage[]
}

export const ProjectLeakagesLeakageInfo: React.FC<ProjectLeakagesLeakageInfoProps> = ({ leakages }) => {
  const { projectId } = useParams<ProjectParamTypes>()
  const { t, i18n } = useTranslation('common')
  const { project } = useProject(projectId)

  const costTime = useMemo(() => {
    return getCostTime(project, undefined, t)
  }, [project.costTime, leakages, i18n.language])
  const pressureCost = useMemo(() => {
    return getPressureCost(project, t)
  }, [project, leakages, i18n.language])

  return (
    <Grid item xs={4}>
      {(costTime || pressureCost) && <Typography variant={'body1'}>{t('project.masterData')}</Typography>}
      {costTime && (
        <Typography variant={'body2'}>
          {t('project.workingHours')}: {costTime.text}
        </Typography>
      )}
      {pressureCost && (
        <Typography variant={'body2'}>
          {t('reports.pressureCost')}: {pressureCost}
        </Typography>
      )}
    </Grid>
  )
}
