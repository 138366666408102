import React from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import styles from './Label.module.css'

export type LabelProps = {
  text: string
  xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
}

export const Label: React.FC<LabelProps> = ({ text, xs, md }) => {
  const { t } = useTranslation('common')

  return (
    <>
      <Grid item xs={xs ? xs : 6} md={md ? md : xs ? xs : 6}>
        <div className={styles['label']}>
          <Typography variant={'subtitle1'}>{t(text)}</Typography>
        </div>
      </Grid>
    </>
  )
}
