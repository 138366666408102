import { environment } from 'app/helpers/environment'

const userPreferenceOperations: string[] = ['UpdateUserLanguage', 'changeOrgPreference']
const {
  API: { GQL_HTTP_URI, USER_SERVICE_URL },
  USER_PREFERENCE_API,
} = environment

/* getApolloClientURI returns clientURI based on operationName */
export const getApolloClientURI = (operationName: string): string => {
  if (operationName === 'GetUserProfile') {
    return USER_SERVICE_URL
  }
  if (userPreferenceOperations.includes(operationName)) {
    return USER_PREFERENCE_API
  }
  return GQL_HTTP_URI
}
