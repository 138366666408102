import React, { useCallback, useMemo } from 'react'
import BusinessIcon from '@material-ui/icons/BusinessOutlined'
import HomeIcon from '@material-ui/icons/HomeOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import PolicyIcon from '@material-ui/icons/Policy'
import { SvgIcon } from '@material-ui/core'
import StorageIcon from '@material-ui/icons/StorageOutlined'
import { useHistory, useLocation } from 'react-router-dom'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooksOutlined'
import WorkIcon from '@material-ui/icons/WorkOutlined'
import { NavigationDrawer } from '@csinstruments/cs-react-theme/dist/components/Drawer/NavigationDrawer'
import TextFieldsOutlinedIcon from '@material-ui/icons/TextFieldsOutlined'
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate'
import { isRenderedByElectron } from '../../../helpers/renderedBy'
import { environment } from '../../../../constants/environment'
import { ExitToApp, School } from '@material-ui/icons'
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined'
import { useCloudLicense } from 'app/hooks/useLicense'
import { useUserContext } from '../CloudAuthProvider/UserAuthProvider'

export type NavigationDrawerProps = {
  onDrawerAction: (open: boolean) => void
}

type NavigationEntry = {
  Icon: typeof SvgIcon
  text: string
  link: string
}

const entries: NavigationEntry[] = [
  { Icon: HomeIcon, text: 'objects.home', link: '' },
  { Icon: BusinessIcon, text: 'objects.companies', link: 'companies' },
  { Icon: ExitToApp, text: 'actions.dataImport', link: 'data-import' },
  { Icon: WorkIcon, text: 'actions.projectImport', link: 'project-import' },
  { Icon: TextFieldsOutlinedIcon, text: 'exportMaintenance.exportMaintenance', link: 'export-maintenance' },
  { Icon: SystemUpdateIcon, text: 'updateOverview.updateOverview', link: 'updates' },
  { Icon: StorageIcon, text: 'objects.database', link: 'database' },
  { Icon: SupervisedUserCircleOutlinedIcon, text: 'objects.auditor', link: 'auditor' },
  { Icon: DeleteIcon, text: 'objects.trashcan', link: 'trashcan' },
  { Icon: LibraryBooksIcon, text: 'objects.license', link: 'license' },
  { Icon: PolicyIcon, text: 'objects.accessManagement', link: environment.REACT_APP_IAM_URL },
  { Icon: School, text: 'objects.learningProgram', link: 'learn' },
]

export const CsLdNavigationDrawer: React.FC<NavigationDrawerProps> = ({ onDrawerAction }) => {
  const history = useHistory()
  const location = useLocation()
  const license = useCloudLicense()
  const { keycloak } = useUserContext()

  const active: string = useMemo(() => {
    const selected = entries.filter((e) => location.pathname.includes(e.link))
    return selected.length ? selected[selected.length - 1].link : ''
  }, [location])

  const hasAccess = useMemo(() => {
    return keycloak && keycloak ? license.hasAccess && !license.expired : true
  }, [license, keycloak])

  const linkClicked = useCallback(
    (link: string) => {
      if (hasAccess) {
        if (link.startsWith('http')) {
          window.open(link, '_blank')
        } else {
          history.push('/' + link)
        }
      }
    },
    [hasAccess, history],
  )

  const usedEntries = useMemo(() => {
    if (!isRenderedByElectron()) {
      return entries.filter((e) => e.link !== 'project-import' && e.link !== 'license')
    } else {
      return entries.filter((e) => e.text !== 'objects.accessManagement' && e.link !== 'auditor')
    }
  }, [])

  return (
    <NavigationDrawer
      activeLink={active}
      entries={usedEntries}
      onDrawerAction={onDrawerAction}
      onLinkClicked={linkClicked}
    />
  )
}
