import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type IamVersionInformation = {
  __typename?: 'IAMVersionInformation';
  /** version of iam */
  iamVersion: Scalars['String'];
  /** timestamp of the iam build */
  iamBuildTimestamp: Scalars['String'];
  /** version of used keycloak */
  keycloakVersion: Scalars['String'];
};

/**
 * structure which contains the language along with the value of the
 * text in that language
 */
export type LanguageString = {
  __typename?: 'LanguageString';
  /** language code e.g. de or en */
  language: Scalars['String'];
  /** value of the string in the corresponding language */
  value: Scalars['String'];
};

/**
 * structure which contains the language along with the value of the
 * text in that language
 */
export type LanguageStringInput = {
  /** language code e.g. de or en */
  language: Scalars['String'];
  /** value of the string in the corresponding language */
  value: Scalars['String'];
};

export enum LicenseUser {
  /** regular user */
  Regular = 'regular',
  /** cs user - no license required */
  Csuser = 'csuser'
}

/** if the scope on the special resource is given automatically grant also the meta resources scopes given in this structure */
export type MetaResourceScopeMap = {
  __typename?: 'MetaResourceScopeMap';
  /** one of the scopes available in the special resource */
  scopeName: Scalars['String'];
  /** list of meta resources and scopes which should be granted when the scopeName scope is granted */
  metaResourceScopes: Array<Maybe<MetaResourceScopes>>;
};

/** meta resources and scopes which should be granted */
export type MetaResourceScopes = {
  __typename?: 'MetaResourceScopes';
  /** name of the meta resource on which rights will be granted */
  metaResourceName: Scalars['String'];
  /** scopes on the meta resource to be granted */
  scopes: Array<Maybe<Scalars['String']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** sends verification Email to new email address. Only if the link in the verification email is clicked will the email address changed. */
  changeEmail?: Maybe<Scalars['Boolean']>;
  /** updates user Language preference */
  updateLangaugePreference?: Maybe<Scalars['Boolean']>;
  /** updates organization preference */
  updatePreferredOrganization?: Maybe<Scalars['Boolean']>;
};


export type MutationChangeEmailArgs = {
  email: Scalars['String'];
  redirectUri: Scalars['String'];
};


export type MutationUpdateLangaugePreferenceArgs = {
  lang: Scalars['String'];
};


export type MutationUpdatePreferredOrganizationArgs = {
  realmName: Scalars['String'];
};

/** element (directory) of a path along with the type */
export type PathElement = {
  __typename?: 'PathElement';
  /** name of the path element e.g. Bosch */
  name: Scalars['String'];
  /** corresponding type (meta), e.g. Company, null only for root directory */
  type?: Maybe<Scalars['String']>;
};

/** element (directory) of a path along with the type */
export type PathElementInput = {
  /** name of the path element e.g. Bosch */
  name: Scalars['String'];
  /** corresponding type (meta), e.g. Company */
  type?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /** get IAM version information */
  getVersion: IamVersionInformation;
};

/** Scope name along with a description for it */
export type ScopeDescription = {
  __typename?: 'ScopeDescription';
  /** name of the scope */
  scope: Scalars['String'];
  /** name of the scope in different languages */
  displayNameKey: Scalars['String'];
  /** description of the scope in different languages */
  descriptionKey: Scalars['String'];
};

/** license assigned to a user */
export type SingleUserLicense = {
  __typename?: 'SingleUserLicense';
  /** name of the group (application), e.g. leak-reporter */
  groupname: Scalars['String'];
  /** type of the license e.g. user or guest */
  licenseType: Scalars['String'];
  /** date until the license is valid */
  expirationDate: Scalars['String'];
  /** id of the license packet to which this license belongs */
  packetId: Scalars['String'];
  /** if null or regular a license of the cloud licenses. csuser is a free license */
  regularLicense?: Maybe<LicenseUser>;
};

/** App specific user roles */
export type UpAppRole = {
  __typename?: 'UPAppRole';
  /** name of the app to which the role list corresponds */
  appName: Scalars['String'];
  /** roles granted to the user for the given app */
  roles: Array<Maybe<UpAppRoleRole>>;
};

export type UpAppRoleRole = {
  __typename?: 'UPAppRoleRole';
  /** name of the role */
  roleName: Scalars['String'];
  /** key of the role, null for individual roles */
  displayNameKey?: Maybe<Scalars['String']>;
};

export type UpdateUserLanguageMutationVariables = Exact<{
  lang: Scalars['String'];
}>;


export type UpdateUserLanguageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateLangaugePreference'>
);

export type ChangeOrgPreferenceMutationVariables = Exact<{
  realmName: Scalars['String'];
}>;


export type ChangeOrgPreferenceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePreferredOrganization'>
);


export const UpdateUserLanguageDocument = gql`
    mutation UpdateUserLanguage($lang: String!) {
  updateLangaugePreference(lang: $lang)
}
    `;
export type UpdateUserLanguageMutationFn = Apollo.MutationFunction<UpdateUserLanguageMutation, UpdateUserLanguageMutationVariables>;

/**
 * __useUpdateUserLanguageMutation__
 *
 * To run a mutation, you first call `useUpdateUserLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserLanguageMutation, { data, loading, error }] = useUpdateUserLanguageMutation({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useUpdateUserLanguageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserLanguageMutation, UpdateUserLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserLanguageMutation, UpdateUserLanguageMutationVariables>(UpdateUserLanguageDocument, options);
      }
export type UpdateUserLanguageMutationHookResult = ReturnType<typeof useUpdateUserLanguageMutation>;
export type UpdateUserLanguageMutationResult = Apollo.MutationResult<UpdateUserLanguageMutation>;
export type UpdateUserLanguageMutationOptions = Apollo.BaseMutationOptions<UpdateUserLanguageMutation, UpdateUserLanguageMutationVariables>;
export const ChangeOrgPreferenceDocument = gql`
    mutation changeOrgPreference($realmName: String!) {
  updatePreferredOrganization(realmName: $realmName)
}
    `;
export type ChangeOrgPreferenceMutationFn = Apollo.MutationFunction<ChangeOrgPreferenceMutation, ChangeOrgPreferenceMutationVariables>;

/**
 * __useChangeOrgPreferenceMutation__
 *
 * To run a mutation, you first call `useChangeOrgPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeOrgPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeOrgPreferenceMutation, { data, loading, error }] = useChangeOrgPreferenceMutation({
 *   variables: {
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useChangeOrgPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<ChangeOrgPreferenceMutation, ChangeOrgPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeOrgPreferenceMutation, ChangeOrgPreferenceMutationVariables>(ChangeOrgPreferenceDocument, options);
      }
export type ChangeOrgPreferenceMutationHookResult = ReturnType<typeof useChangeOrgPreferenceMutation>;
export type ChangeOrgPreferenceMutationResult = Apollo.MutationResult<ChangeOrgPreferenceMutation>;
export type ChangeOrgPreferenceMutationOptions = Apollo.BaseMutationOptions<ChangeOrgPreferenceMutation, ChangeOrgPreferenceMutationVariables>;